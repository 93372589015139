import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private loadingSubject = new Subject<boolean>();

  public get loading$() {
    return this.loadingSubject.asObservable();
  }

  constructor() { }

  public setLoading(loading: boolean) {
    this.loadingSubject.next(loading);
  }
}
