import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';

import { ModalWizardModels } from 'src/app/components/modal-wizard/shared/models/modal-wizard.model';
import { BiddingStoreService, BiddingStoreKey } from '../../store/bidding.store';
import { BiddingModels } from '../../shared/models/bidding.model';

@UntilDestroy()
@Component({
  selector: 'app-place-bid-for-step-content',
  template: `
    <app-place-bid-for-form (formValidityChanged)="onFormValidityChanged($event)" (formValuesChanged)="onFormValuesChanged($event)"></app-place-bid-for-form>
  `,
})
export class PlaceBidForStepContentComponent implements ModalWizardModels.StepContent {
  @Input() stepId = BiddingStoreKey.bidForData;

  bidFor$ = this.biddingStore.getSelector(BiddingStoreKey.bidForData);
  form: FormGroup | null = null;

  private isFormValid = false;

  constructor(private biddingStore: BiddingStoreService) {}

  onFormValidityChanged(isValid: boolean) {
    this.isFormValid = isValid;
  }

  onFormValuesChanged(data: BiddingModels.PlaceBidForFormModel | null): void {
    this.biddingStore.update({
      [BiddingStoreKey.bidForData]: cloneDeep(data),
    });
  }

  canEntry() {
    return true;
  }

  canExit() {
    return this.isFormValid;
  }
}
