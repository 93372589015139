import { Models, PropertyBase } from '../../../models';
import { serializeCityName, serializeCountyName, serializeStateName } from './search-page-url-serializer.util';

/**
 * Full path from a property to a route
 * @param slug
 * @param p
 * @returns
 */
export const propertyToSearchPageUrl = (slug: string, p?: Models.PropertySearchResponse | Models.PropertyDetailsResponse | null) =>
  !slug || !p ? null : '/' + slug + propertyToUrl(p) + '/';

type PropertyToUrlType = Models.PropertySearchResponse | Models.PropertyDetailsResponse | Models.MyListing | Models.UserPropertiesBidsResponseProperty | null;

/**
 *
 * @param p
 * @returns
 */
export const propertyToUrl = (p?: PropertyToUrlType): string | null => {
  if (!p) {
    return '/';
  }

  const serializeAddress = (p: PropertyToUrlType): string | undefined => {
    if (p?.STATE && p?.COUNTY && p?.CITY && p?.POSTAL_CODE && p?.ADDRESS1 && p?.ITEM_ID) {
      const address1 = clearSegment(p.ADDRESS1);
      const address2 = clearSegment(p.ADDRESS2);
      return `${address1}${address2}/${p.ITEM_ID}`;
    }
    return undefined;
  };

  const cleanPath = (func: string | undefined) => func || null;

  const url = [
    cleanPath(serializeStateName(p.STATE)),
    cleanPath(serializeCountyName(p.COUNTY)),
    cleanPath(serializeCityName(p.CITY)),
    cleanPath(p?.POSTAL_CODE),
    cleanPath(serializeAddress(p)),
  ]
    .filter(Boolean)
    .join('/');

  return '/' + url;
};

export const propertyBaseToUrl = (slug: string, property: PropertyBase) => {
  return `/${slug}/${serializeStateName(property.STATE_CODE!)}/${serializeCountyName(property.COUNTY!)}/${serializeCityName(property.CITY!)}/${
    property.POSTAL_CODE
  }/${clearSegment(property.ADDRESS1!)}${clearSegment(property?.ADDRESS2)}/${property.ITEM_ID}`;
};

export const clearSegment = (segment: string | undefined | null): string => {
  if (!segment) {
    return '';
  }
  return segment.replace(/\//g, '%5C').trim();
};

export const unclearSegment = (segment: string | undefined | null): string => {
  if (!segment) {
    return '';
  }
  return segment.replace('%5C', '/').trim();
};
