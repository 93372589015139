import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup, FormControl, FormGroupDirective } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';

export interface InputErrorShowCondition {
  touch?: boolean;
  dirty?: boolean;
  submit?: boolean;
}

interface InputState {
  showError?: boolean;
  errorMessage?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-input-error',
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputErrorComponent implements OnInit {
  /** The form group control key name */
  @Input() controlName: string | null = null;
  /** Name used in the error message */
  @Input() friendlyName: string | null = null;
  /** Override any specific error messages with a generic invalid message */
  @Input() anyErrorShowInvalid = false;
  /** Show error when input is touched */
  @Input() showErrorOnTouch = true;
  /** Show error when input is dirty */
  @Input() showErrorOnDirty = false;
  /** Show error when form is submitted */
  @Input() showErrorOnFormSubmit = false;
  /** Overwrite the default error message */
  @Input() customErrorMessage: string | null = null;
  @Input() set errorCondition(errorCondition: InputErrorShowCondition) {
    this.showErrorOnTouch = errorCondition.touch ?? this.showErrorOnTouch;
    this.showErrorOnDirty = errorCondition.dirty ?? this.showErrorOnDirty;
    this.showErrorOnFormSubmit = errorCondition.submit ?? this.showErrorOnFormSubmit;
    this.updateError();
  }

  private valueFormGroup: FormGroup | null = null;
  private valueFormControl: FormControl | null = null;

  private formSubmitted = false;
  private inputTouched = false;
  private inputDirty = false;

  private controlState: InputState = {};
  controlState$ = new BehaviorSubject<InputState>(this.controlState);

  constructor(private formGroupDirective: FormGroupDirective) {}

  ngOnInit() {
    if (!this.controlName) throw new Error('InputErrorComponent requires controlName');
    if (!this.friendlyName) throw new Error('InputErrorComponent requires friendlyName');

    this.valueFormGroup = this.formGroupDirective.form;
    this.valueFormControl = this.getControl(this.valueFormGroup, this.controlName);

    this.listenToTouchedUpdates();
    this.listenToUnTouchedUpdates();
    this.subscribeToFormSubmitted();
    this.subscribeToFormControlUpdates();
    this.updateError();
  }

  private listenToTouchedUpdates() {
    if (!this.valueFormControl) return;
    const markAsTouched = this.valueFormControl.markAsTouched.bind(this.valueFormControl);
    this.valueFormControl.markAsTouched = () => {
      markAsTouched();
      this.inputTouched = true;
      this.updateControlState({ showError: this.shouldShowError(!!this.controlState.errorMessage) });
    };
  }

  private listenToUnTouchedUpdates() {
    if (!this.valueFormControl) return;
    const markAsUnTouched = this.valueFormControl.markAsUntouched.bind(this.valueFormControl);
    this.valueFormControl.markAsUntouched = () => {
      markAsUnTouched();
      this.inputTouched = false;
      this.updateControlState({ showError: this.shouldShowError(!!this.controlState.errorMessage) });
    };
  }

  private subscribeToFormSubmitted() {
    if (!this.valueFormGroup) return;
    this.formGroupDirective.ngSubmit.pipe(untilDestroyed(this)).subscribe(() => {
      this.formSubmitted = true;
      this.updateControlState({ showError: this.shouldShowError(!!this.controlState.errorMessage) });
    });
  }

  private subscribeToFormControlUpdates() {
    this.valueFormControl?.statusChanges.pipe(untilDestroyed(this)).subscribe(val => {
      this.inputDirty = !!this.valueFormControl?.dirty;
      this.updateError();
    });
  }

  private shouldShowError(hasMessage: boolean) {
    return (
      hasMessage &&
      ((this.showErrorOnTouch && this.inputTouched) || (this.showErrorOnDirty && this.inputDirty) || (this.showErrorOnFormSubmit && this.formSubmitted))
    );
  }

  private updateControlState(state: Partial<InputState>) {
    this.controlState = {
      ...this.controlState,
      ...state,
    };
    this.controlState$.next(this.controlState);
  }

  private updateError = () => {
    let errorMessage: string = this.getErrorMessage(this.valueFormControl);
    this.updateControlState({ errorMessage, showError: this.shouldShowError(!!errorMessage) });
  };

  private getErrorMessage(control: FormControl | null) {
    if (!control || control.valid) return '';

    const error = this.getFirstError();
    if (!error) return '';

    if (this.anyErrorShowInvalid) {
      return this.getInvalidErrorMessage();
    }
    return this.errorKeyToMessage(error);
  }

  private getFirstError() {
    if (this.valueFormControl?.invalid && this.valueFormControl?.errors) {
      const errorKeys = Object.keys(this.valueFormControl.errors);
      if (errorKeys.length) {
        return { key: errorKeys[0], value: this.valueFormControl.errors[errorKeys[0]] };
      }
    }
    return null;
  }

  private errorKeyToMessage(error: { key: string; value: any } | null) {
    if (!error) return '';
    switch (error.key) {
      case 'min':
        return `Please enter a ${this.friendlyName} of at least ${error.value.min}.`;
      case 'email':
      case 'phone':
        return this.getInvalidErrorMessage();
      case 'required':
        //return `Please enter your ${this.friendlyName}.`;
        return this.getInvalidErrorMessage();
      default:
        console.warn(`Unhandled error key: ${error.key}. Default error displayed`);
        return this.getInvalidErrorMessage();
    }
  }

  private getInvalidErrorMessage() {
    return this.customErrorMessage ? this.customErrorMessage : `Please enter a valid ${this.friendlyName}`;
  }

  private getControl(formGroup: FormGroup, controlName: string): FormControl | null {
    let valueFormControl = formGroup.get(controlName);
    if (!valueFormControl) {
      Object.keys(formGroup.controls).forEach(name => {
        const control = formGroup.controls[name];
        if (!valueFormControl && control instanceof FormGroup) {
          valueFormControl = this.getControl(control, controlName);
        }
      });
    }
    return valueFormControl as FormControl;
  }
}
