/**
 * Convert a string to a url safe slug
 * @param value
 * @returns
 */
export const stringToSlug = (value?: string | number | null) =>
  String(value)
    .trim()
    .replace(/\s+/g, ' ')
    .replace(/ /gi, '-')
    .replace(/--/gi, '-')
    .replace(/[^A-Z0-9-/]/gi, '');
