import { NtsState } from '@ntersol/state-management/lib/state.models';
import { SearchModels } from '../../../routes/houses/shared';
import { searchActions } from './search.actions';

/**
 *
 * @param state
 * @param action
 * @returns
 */
export const searchReducer = (state: SearchModels.State, action?: NtsState.Action<Partial<SearchModels.State>> | null): SearchModels.State => {
  // Action is nill
  if (!action) {
    return state;
  }

  // Default state change action that does not require any computation
  if (searchActions.stateChange.match(action)) {
    return Object.assign({}, state, action.payload);
  }

  return state;
};
