<div>
  <ng-container *ngIf="[TermsAgreeViewType.INTRO, TermsAgreeViewType.NORMAL].includes(view)">
    <p>
      <b>{{ 'ALL USERS MUST AGREE TO THESE ' + (isExtraCondition ? "ADDITIONAL " : "") + 'TERMS BEFORE BIDDING.' }}</b>
    </p>
    <p>
      Clicking on the button marked "Agree" and proceeding to enter any bid on any listed Property indicates your acknowledgment that 
      <b>you have read and accepted these terms and conditions and that you have received, reviewed, and accept all disclosures and addenda</b>.
    </p>
  </ng-container>

  <ng-container *ngIf="view !== TermsAgreeViewType.INTRO">
    <p>
      <b>If you have thoroughly read each of the {{ isExtraCondition ? "additional " : "" }} terms (rules) above and agree to abide by them fully, please click AGREE to continue.</b>
    </p>
  </ng-container>
</div>
