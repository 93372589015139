import { SearchModels, SearchStoreService, filtersChanged, locationToPathList } from '$stores';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NtsStateManagementService } from '@ntersol/state-management';
import { BehaviorSubject, Observable, distinctUntilChanged, filter, forkJoin, map, take } from 'rxjs';
import { Models } from '../../shared/models';
import { SessionStorageService, StorageKeys } from '../../shared/services/session-storage.service';
import { isLargeScreen, listenForLargeScreenDown } from '../../shared/utils';
import { MoreComponent } from './more/more.component';
import { MultiSelectOptions } from './toolbar.options';
import { ToolbarService } from './toolbar.service';

interface DisplayValue {
  label: string | undefined;
  value: number | undefined;
}

@UntilDestroy()
@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @ViewChild(MoreComponent, { static: false }) moreComponent!: MoreComponent;

  readonly propertySearchForm = this.toolbarService.propertySearchForm;

  readonly bedsFormControl = this.getControl('MINBEDS');
  readonly bathsFormControl = this.getControl('MINBATHS');

  public readonly largeScreenDownObserver$ = listenForLargeScreenDown();

  /** Are there any active filters */
  readonly hasFilters$ = this.searchStore.queryParamsFilters$.pipe(
    // If at least one filter, return true
    map(filters => !filters || Object.values(filters).some(filter => !!filter)),
    distinctUntilChanged(),
  );

  readonly auctionTypes$: Observable<DisplayValue[]> = this.toolbarService.auctionTypes$;
  readonly propertyTypes$: Observable<DisplayValue[]> = this.toolbarService.propertyTypes$;

  selectedAuctionTypes$ = new BehaviorSubject<(number | undefined)[]>([]);
  selectedPropertyTypes$ = new BehaviorSubject<(number | undefined)[]>([]);

  readonly auctionTypeLabel = 'Auction Types';
  readonly propertyTypeLabel = 'Property Types';

  private readonly locationStorage = this.sessionStorageService.getStorage<SearchModels.Location>(StorageKeys.searchPageLocation);

  propertyType(list: MultiSelectOptions[], val: number) {
    if (!list) {
      return;
    }
    return list.filter(v => v.value === val)[0];
  }

  constructor(
    private readonly sm: NtsStateManagementService,
    private readonly searchStore: SearchStoreService,
    private readonly router: Router,
    private readonly sessionStorageService: SessionStorageService,
    private readonly toolbarService: ToolbarService,
  ) {}

  ngOnInit() {
    // This call needed for "Saved Searches". For those onces that were created on mobile.
    // For mobile there is no "filters" property in query params. So that when user saves
    // a search, url string will be without "filters" property. It breaks API GET request
    // to the "listings" endpoint
    this.addDefaultFiltersToQueryParamsForDesktopIfNoFiltersPropertyExist();

    this.toolbarService.dispatchFilterChanges().pipe(untilDestroyed(this)).subscribe();
  }

  ngOnDestroy(): void {
    this.clearForm();
  }

  applyFiltersForMobile() {
    // We should wait for navigation which will be triggered when
    // this subject below will be triggered:
    //    this._filtersCanBeApplied$.next(true);
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        if (this.locationStorage.hasData()) {
          const pathList = [Models.SearchRouteSlug, ...locationToPathList(this.locationStorage.getData())];
          this.router.navigate(pathList, { queryParamsHandling: 'merge' });
          return;
        }
        this.router.navigate([Models.SearchRouteSlug], { queryParamsHandling: 'merge' });
      });

    this.toolbarService.allowApplyFilter();
  }

  /**
   * Clear and reset all filter data
   */
  clearForm() {
    this.propertySearchForm.reset();

    forkJoin({
      auctionTypes: this.extractTypeValues(this.auctionTypes$),
      propertyTypes: this.extractTypeValues(this.propertyTypes$),
    }).subscribe(({ auctionTypes, propertyTypes }) => {
      this.moreComponent.moreFormGroup.reset();
      this.propertySearchForm.patchValue({ TYPE: [...propertyTypes], CATEGORYLIST: [...auctionTypes] });
    });
  }

  private addDefaultFiltersToQueryParamsForDesktopIfNoFiltersPropertyExist() {
    this.searchStore.queryParamsFilters$
      .pipe(
        map(value => !!value),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe(hasFiltersPropertyInQueryParams => {
        if (!hasFiltersPropertyInQueryParams && isLargeScreen()) {
          this.sm.dispatch(filtersChanged(this.propertySearchForm.value));
        }
      });
  }

  private extractTypeValues(observable$: Observable<DisplayValue[]>) {
    return observable$.pipe(
      map(types => types.map((type: { value: any }) => type.value)),
      take(1),
    );
  }

  private getControl(name: keyof Models.QueryParamsSearchFilters) {
    return (this.propertySearchForm.controls[name] as FormControl) || null;
  }
}
