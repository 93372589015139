import { Injectable } from '@angular/core';
import { from, map, Observable, of, switchMap } from 'rxjs';
import { DomService } from '../../../services';

export interface GeoLocation {}

/**
 * Wraps the navigator location service
 */
@Injectable({
  providedIn: 'root',
})
export class GeolocationService {
  /**
   * Check if the user has enabled location services, does not fire popup
   */
  public geoLocatePermission$ = this.dom.window?.navigator?.permissions?.query
    ? // Check if permissions
      from(navigator.permissions.query({ name: 'geolocation' })).pipe(
        map(x => {
          if (x?.state === 'granted') {
            return true;
          } else if (x?.state === 'denied') {
            return false;
          }
          return null; // User closed the request popup without either selecting or denying access
        }),
      )
    : of(null); // Otherwise default to null

  /**
   * Return the user's current location (lat/lon). Gives control over whether or not to request permission
   * @param requestPermission If permission has not been given already, request it
   */
  public locationCurrent$ = (requestLocation = true, options?: PositionOptions | null): Observable<GeolocationPosition> =>
    this.geoLocatePermission$.pipe(
      switchMap(previousPermission =>
        previousPermission || requestLocation
          ? new Observable<GeolocationPosition>(subscriber => {
              if (this.dom.window?.navigator.geolocation) {
                this.dom.window?.navigator.geolocation.getCurrentPosition(
                  (position: GeolocationPosition) => subscriber.next(position),
                  (positionError: GeolocationPositionError) => subscriber.error(positionError),
                  options || {},
                );
              } else {
                subscriber.error(new Error('Geolocation API is not available'));
              }
            })
          : of(),
      ),
    );

  constructor(private dom: DomService) {}

  get currentUserLocationCoordinates$(): Observable<GeolocationCoordinates> {
    return this.locationCurrent$().pipe(map((geoPosition: GeolocationPosition) => geoPosition.coords));
  }
}
