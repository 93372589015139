import { environment } from '$env';
import { AuctionTimelineStates, Models } from '$models';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { isEqual } from 'lodash';
import { Observable, ReplaySubject, map, of, switchMap, take, tap } from 'rxjs';
import { HousesApiStoreService } from '../../routes/houses/shared';
import { mapPropertySearchResponseToCardServiceData, syncSearchDataWithCurrentBidData } from '../../routes/houses/shared/stores/houses-api.mapper';
import { missingImageUrl } from '../../routes/houses/shared/utils';
import { CardData, FlowFactory } from '../../shared/services/auction-card-flow-services/flow.service';
import { PropertyCard } from '../../shared/services/auction-card-flow-services/property-card-flows.service';
import { SearchStoreService } from '../../shared/stores';
import { ApiService } from '../../shared/stores/api';
import { propertyToSearchPageUrl } from '../../shared/stores/search/utils/property-to-url.util';
import { Poller, listenForLargeScreenDown } from '../../shared/utils';

@Component({
  selector: 'app-property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyCardComponent implements OnChanges, OnDestroy {
  @Input() property: Models.PropertySearchResponse | null = null;
  @Input() orientation: 'horizontal' | 'vertical' = 'vertical';
  @Input() type: 'default' | 'auction' = 'default';
  @Input() onMap = false;
  @Input() pageChange = false;
  @Input() showDeleteIcon = false;
  @Input() pollingCycleInSeconds: number = 3;
  @Input() pausePolling: boolean = false;

  public uiData?: PropertyCard;
  public readonly auctionTimelineStates = AuctionTimelineStates;
  public readonly propertyCategoryType = Models.PropertyCategoryType;
  public showOverlay = false;

  public readonly largeScreenDownObserver$ = listenForLargeScreenDown();
  private readonly _mainPhoto$ = new ReplaySubject<Pick<Models.PropertySearchResponsePhotoInfo, 'IMAGE_PATH' | 'IMAGE_DESCRIPTION'>>(1);
  public readonly mainPhoto$ = this._mainPhoto$.asObservable();

  get isTypeDefault() {
    return this.type === 'default';
  }

  get isTypeAuction() {
    return this.type === 'auction';
  }

  get isSavedPropertiesPage() {
    return this.router.url.includes('saved-properties');
  }

  get isHomePage() {
    return this.router.url === '/';
  }

  /** Vanity link for search engine crawlers, not actually used by the app */
  public href: string | null = '';

  public imageErrors: boolean[] = [];
  private readonly LIVE_AUCTION_CUTOFF_DAYS = 2;

  private cancelPolling?: () => void;

  constructor(
    private readonly searchStore: SearchStoreService,
    private readonly apiGlobal: ApiService,
    private readonly housesApi: HousesApiStoreService,
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef,
    private readonly flowFactory: FlowFactory,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['property'] && this.property && !isEqual(changes['property'].previousValue, changes['property'].currentValue) && this.cancelPolling) {
      this.cancelPolling();
      this.cancelPolling = undefined;
    }

    // For every input "property" variable change
    if (changes['property'] && this.property) {
      const propertyCardFlow = this.flowFactory.createPropertyCardFlow(mapPropertySearchResponseToCardServiceData(this.property));
      this.uiData = propertyCardFlow.getUiData();
      this.showOverlay = ['Returned', 'Pending 2nd Chance', 'Withdrawn'].includes(this.property?.STATUS || '');
      // Update url when property updates
      this.href = propertyToSearchPageUrl(this.searchStore.searchRouteSlug, this.property);

      this.largeScreenDownObserver$.pipe(take(1)).subscribe(isNotDesktop => {
        if (isNotDesktop && this.property) {
          this._mainPhoto$.next(this.extractMainPhoto(this.property));
        }
      });
    }

    // Poll only when there is no currently active polling and no pause is set
    if (
      changes['property'] &&
      this.property &&
      this.pausePolling === false &&
      !this.cancelPolling &&
      !(this.property.STATUS_ID && [Models.PropertyStatusType.SOLD, Models.PropertyStatusType.RETURNED].includes(this.property.STATUS_ID))
    ) {
      this.poll(this.uiData!, this.property);
    }

    // If pause is set and there is an active polling (cancelPolling is defined) then stop polling
    if (changes['pausePolling'] && this.pausePolling === true && this.cancelPolling && this.property) {
      this.cancelPolling();
      this.cancelPolling = undefined;
    }

    // If resume polling (pausePolling === false) then run polling again
    if (changes['pausePolling'] && this.pausePolling === false && !this.cancelPolling && this.property) {
      this.poll(this.uiData!, this.property);
    }
  }

  ngOnDestroy() {
    if (this.cancelPolling) {
      this.cancelPolling();
    }
  }

  public navigateTo(e: MouseEvent) {
    e.preventDefault();

    if (!this.href) {
      throw new Error('href variable should be defined');
    }

    // Get css class names of mouse target
    const className: string = (e.target as any).className;
    // If the class name is NOT the galleria prev/next, go to that prop details page
    if (className?.includes && !className?.includes('p-galleria-item')) {
      let navigationExtras: NavigationExtras = { queryParams: {} };
      // next observable is invoking synchronously. This is why assigning
      // to the navigationExtras and then navigation works
      this.housesApi.searchPayload$.pipe(take(1)).subscribe(payload => {
        if (payload) {
          navigationExtras = { ...navigationExtras, state: { payload } };
        }
      });
      this.router.navigate([this.href], navigationExtras);
    }
  }

  shouldShowAuctionCountdown(auctionDate: string | Date) {
    return dayjs().isAfter(dayjs(auctionDate).subtract(this.LIVE_AUCTION_CUTOFF_DAYS, 'day'));
  }

  private poll(uiData: PropertyCard, property: Models.PropertySearchResponse) {
    if (!uiData.activeDate) return;

    const cancelCallback = () => {
      return (
        uiData.auctionStatus === AuctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD ||
        property.STATUS_ID === Models.PropertyStatusType.RETURNED ||
        uiData.auctionStatus === AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_CANCELLED
      );
    };

    this.cancelPolling = Poller.poll(this.pollingCycleInSeconds, this.updatePropertyCardData.call(this, property.ITEM_ID!), cancelCallback);
  }

  private extractMainPhoto(property: Models.PropertySearchResponse): Pick<Models.PropertySearchResponsePhotoInfo, 'IMAGE_PATH' | 'IMAGE_DESCRIPTION'> {
    if (!property?.PHOTOS?.length) {
      return {
        IMAGE_PATH: missingImageUrl,
        IMAGE_DESCRIPTION: 'home for auction.',
      };
    }

    let mainImageIdx = property.PHOTOS.findIndex(photo => photo.MAIN_IMAGE === 1);
    const mainImage = mainImageIdx === -1 ? property.PHOTOS[0] : property.PHOTOS[mainImageIdx];
    const imageCopy = { ...mainImage };

    imageCopy.IMAGE_PATH = environment.endpoints.photoPath + 'small' + imageCopy.IMAGE_PATH;
    return imageCopy;
  }

  private updatePropertyCardData(itemId: number): Observable<void> {
    return this.housesApi.getCurrentBidData(itemId).pipe(
      map((response: Models.PropertyCurrentBidResponse) => syncSearchDataWithCurrentBidData(this.property!, response)),
      tap((cardData: CardData) => {
        // following condition is here because it handles cases when user
        // clicks fast on different pins on the map.
        // Scenario:
        // 1) User clicks on first map marker so that property card is expanded
        //    (currentbid endpoint is requested).
        // 2) In a very short time frame (less than 1 sec) user clicks on another map marker.
        //    But request from "1)" hasn't been completed yet
        // 3) Eventually request from "1)" is completed and code below (if without "if" condition)
        //    Updates the data with response data. But the problem is that it's already
        //    different property.
        // This is why we do need this condition which checks are we still considering the same property.
        if (itemId === this.property?.ITEM_ID) {
          this.uiData = this.flowFactory.createPropertyCardFlow(cardData).getUiData();
          this.cd.markForCheck();
        }
      }),
      switchMap(() => of()),
      take(1),
    );
  }

  /**
   * Delete this property from saved properties
   * @param id
   */
  public deleteSavedProperty(id: number) {
    this.apiGlobal.watchedPropertiesDelete(id).subscribe();
  }
}
