import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NewRelicService {
  load() {
    const agentID = environment.licenses.newRelic?.agentID;
    const applicationID = environment.licenses.newRelic?.applicationID;

    if (agentID && applicationID) {
      this.addCredentialsScript(agentID, applicationID);
      this.addNewRelicScriptSrc();
    }
  }

  private addNewRelicScriptSrc() {
    const newRelicUrl = 'https://js-agent.newrelic.com/nr-loader-spa-current.min.js';
    const scriptNewRelic = document.createElement('script');
    scriptNewRelic.type = 'text/javascript';
    scriptNewRelic.async = true;
    scriptNewRelic.src = newRelicUrl;
    document.head.appendChild(scriptNewRelic);
  }

  private addCredentialsScript(agentID: string, applicationID: string) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.textContent = this.createScript(agentID, applicationID);
    document.head.appendChild(script);
  }

  private createScript(agentID: string, applicationID: string) {
    return `
    ;window.NREUM||(NREUM={});NREUM.init={distributed_tracing:{enabled:true},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]}};

    ;NREUM.loader_config={accountID:"3296621",trustKey:"3296621",agentID:"${agentID}",licenseKey:"NRJS-43a9ee4e33e80e992f4",applicationID:"${applicationID}"};
    ;NREUM.info={beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:"NRJS-43a9ee4e33e80e992f4",applicationID:"${applicationID}",sa:1};
    ;window.NREUM||(NREUM={});NREUM.init={distributed_tracing:{enabled:true},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]}};
    `;
  }
}
