import { Injectable } from '@angular/core';
import { AuctionTimelineStates, Models } from '../../models';
import { BidCard } from './bid-card-flows.service';
import { PropertyCard } from './property-card-flows.service';

/**
 * This is the type this functionality can work with.
 * Since we are getting property data from multiple endpoints with different response types:
 * Models.PropertySearchResponse and Models.PropertyDetailsResponse
 * We should convert these response types to the current one CardServiceData.
 * Those convertors can be found in houses-api.store.ts:
 *  - mapPropertySearchResponseToCardServiceData
 *  - mapPropertyDetailsResponseToCardServiceData
 */
export interface CardData {
  statusId?: Models.PropertyStatusType;
  bed?: number;
  bath?: number;
  sqft?: number;
  bidamt?: number;
  finalReserve?: number;
  address?: string;
  city?: string;
  state?: string;
  categoryId?: Models.PropertyCategoryType;
  auction_start_date?: string;
  auction_end_date?: string;
  post_auction_end_date?: string;
  date_end?: string;
  sold_date?: string;
  handleId?: string;
  bidAssistAmt?: number;
  foreclosureSalesCancelledDate?: string;
  foreclosureSalesPostponedDate?: string;
  foreclosureSaleOpeningBid?: number;
  estimatedvalue?: number;
}

export interface FlowTypeConstructor<T> {
  readonly type: string;
  isFlowTypeFitData(data: CardData): boolean;
  new (data: CardData): FlowType<T>;
}

export abstract class FlowType<T> {
  abstract getUiData(): T;
  abstract get auctionState(): AuctionTimelineStates;
}

export enum CardTypes {
  bidCard = 'bid card',
  propertyCard = 'property card',
}

@Injectable({
  providedIn: 'root',
})
export class FlowFactory {
  private readonly types = new Set<FlowTypeConstructor<BidCard | PropertyCard>>();

  register(flowClass: FlowTypeConstructor<BidCard | PropertyCard>): void {
    if (this.types.has(flowClass)) {
      console.warn('flow has already been registered');
      return;
    }
    this.types.add(flowClass);
  }

  createBidCardFlow(data: CardData): FlowType<BidCard> {
    for (const FlowClass of this.types) {
      if (FlowClass.type === CardTypes.bidCard && FlowClass.isFlowTypeFitData(data)) {
        return new FlowClass(data) as FlowType<BidCard>;
      }
    }
    throw new Error('Unknown flow type');
  }

  createPropertyCardFlow(data: CardData): FlowType<PropertyCard> {
    for (const FlowClass of this.types) {
      if (FlowClass.type === CardTypes.propertyCard && FlowClass.isFlowTypeFitData(data)) {
        return new FlowClass(data) as FlowType<PropertyCard>;
      }
    }
    throw new Error('Unknown flow type');
  }
}
