import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable, take } from 'rxjs';
import { DomService } from '../../../shared/services';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

/**
 * Mobile level 2 header.
 *  Input fields:
 *    title - adds title at the center of the header
 *    action - current header has "<" back button. What will be called on click on
 *    this button is fully depend on the caller (parent component).
 *    There are 2 types available to pass to "action" field, Observable or function.
 */
@Directive()
export abstract class AppMobileHeaderDirective implements OnChanges {
  @Input() title: string = '';
  @Input() action: (() => void) | Observable<void> = () => {};
  @Input() actionRight?: unknown | null = null;
  @Input() iconRight?: IconProp | null = null;

  private clickAction?: () => void;
  private clickActionRight?: unknown | null = null;
  constructor(private dom: DomService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['action']?.currentValue) {
      if (typeof this.action === 'function') {
        this.clickAction = this.action;
      } else if (this.action instanceof Observable) {
        this.clickAction = () => {
          (this.action as Observable<void>).pipe(take(1)).subscribe();
        };
      } else {
        throw new Error('unknown type of action input variable');
      }
    }
    if (changes['actionRight']?.currentValue) {
      if (typeof this.actionRight === 'function') {
        this.clickActionRight = this.actionRight;
      } else if (this.actionRight instanceof Observable) {
        this.clickActionRight = () => {
          (this.actionRight as Observable<void>).pipe(take(1)).subscribe();
        };
      } else {
        throw new Error('unknown type of action input variable');
      }
    }
  }

  click() {
    if (!this.clickAction || typeof this.clickAction !== 'function') {
      this.dom.historyBack();
      return;
    }
    this.clickAction();
  }

  clickRight(e: Event) {
    if (!this.clickActionRight || typeof this.clickActionRight !== 'function') {
      this.dom.historyForward();
      return;
    }
    this.clickActionRight(e);
  }
}
