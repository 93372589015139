import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { removeNils } from '@ntersol/utils';
import { catchError, of, map as rxMap, filter, map, Observable } from 'rxjs';
import { get } from 'lodash';

import { Models } from '$models';
import { NotificationService } from '$services';
import { ModalWizardModels } from 'src/app/components/modal-wizard/shared/models/modal-wizard.model';
import { BiddingStoreService, BiddingStoreKey } from '../../store/bidding.store';
import { RequiredKeys } from '../../shared/models/bidding.model';

@Component({
  selector: 'app-buyer-agent-step-content',
  template: `
    <div class="buyer-agent-step-content">
      <app-buyer-agent-form 
        [agent]="buyerAgent$ | async"
        (formReady)="onFormReady($event)" 
        (formValuesChanged)="onFormValuesChanged($event)"
      ></app-buyer-agent-form>
    </div>
  `
})
export class BuyerAgentStepContentComponent implements ModalWizardModels.StepContent {

  @Input() stepId = BiddingStoreKey.buyerAgent as string;

  buyerAgent$ = this.biddingStore.buyerAgent.state$.pipe(
    filter(state => !state.loading && !state.modifying),
    rxMap(state => {
      this.buyerAgent = get(state, 'data[0]');
      return this.buyerAgent;
    })
  );

  private form: FormGroup | null = null;
  private values: { [key: string]: any } | null = null;
  private buyerAgent: Models.BuyerAgentRequest | null = null;

  constructor(
    private biddingStore: BiddingStoreService,
    private notification: NotificationService
  ) { }

  onFormReady(form: FormGroup) {
    this.form = form;
  }

  onFormValuesChanged(values: Record<RequiredKeys<Models.BuyerAgentRequest>, any> | null) {
    this.values = values;
  }

  canEntry(args: ModalWizardModels.StepValidationArgs) {
    return true;
  }

  canExit(args: ModalWizardModels.StepValidationArgs) {
    const isFormValid = this.form ? this.form.valid : true;
    const isFormTouched = this.form ? this.form.touched : false;

    if (args.direction === ModalWizardModels.StepDirection.backward) {
      if (isFormTouched) {
        this.form?.reset();
      }
      return of(true);
    }
    // Force form to validate values
    this.form?.markAllAsTouched();

    if (isFormValid) {
      const payload = this.values || {};
      // Save data and return True if saved successfully, otherwise return False 
      return this.addNewBuyerAgent(payload).pipe(
        catchError(err => of(this.addBuyerAgentErrorHandler(err)))
      );
    }

    return of(false);
  }

  private addNewBuyerAgent(payload: Models.BuyerAgentRequest): Observable<boolean | unknown> {
    return this.biddingStore.buyerAgent.post({ ... payload }).pipe(
      map((res: any) => {
        if (res?.SUCCESS === true) {
          this.form?.reset();
          this.biddingStore.buyerAgent.refresh().subscribe();
          this.notification.showToast({ 
            styleClass: 'p-toast-message-success', 
            closable: true, 
            detail: 'Buyer Agent successfully added.'
          });
          return true;
        }
        return this.addBuyerAgentErrorHandler({
          ...res,
          MESSAGE: "OOPS! Test."
        });
      })
    );
  }

  private addBuyerAgentErrorHandler(error: any): boolean {
    const message = error?.MESSAGE || 'Could not create Buyer Agent.';
    console.error(message);
    this.notification.showToast({ 
      styleClass: 'p-toast-message-error', 
      closable: true,
      summary: 'Error', 
      detail: message
    });
    return false;
  }
}
