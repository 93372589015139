import { AuctionTimelineStates, Models } from '../../models';
import { CardData } from './flow.service';

export function getAuctionStateForeclosure(propertyData: CardData): AuctionTimelineStates {
  if (propertyData.statusId === Models.PropertyStatusType.SOLD || propertyData.statusId === Models.PropertyStatusType.PENDING) {
    // Auction has ended with status SOLD.
    return AuctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD;
  } else if (propertyData.statusId === Models.PropertyStatusType.RETURNED) {
    // Auction has ended with status RETURNED.
    return AuctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED;
  } else if (propertyData.categoryId === Models.PropertyCategoryType.LIVE_FORECLOSURE_SALES && !!propertyData.foreclosureSalesPostponedDate) {
    return AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED;
  } else if (!propertyData.date_end) {
    return AuctionTimelineStates.AUCTION_COMING_SOON;
  } else if (Date.now() < Date.parse(propertyData.date_end)) {
    return AuctionTimelineStates.AUCTION_NOT_YET_STARTED;
  } else {
    // Technically categories 3 and 6 don't have active auction state, because these categories
    // only have single sale date. Before sale date - auction hasn't started yet but after, it can be
    // only in sold or returned states. But there is a time gap after the sale date when
    // auction neither sold or returned and we have specific UI to represent this state.
    // To not to create a new separate auction timeline state we just reuse some random state
    // from existing (we took ACTIVE_AUCTION) to be able to differentiate this UI bid/property
    // card state
    return AuctionTimelineStates.ACTIVE_AUCTION;
  }
}

/**
 * Based on converted data from API response, defines auction timeline state.
 * ORDER OF "IF" CONDITIONS MATTERS!!!
 * @param propertyData - converted from API response type to this service specific type data
 * @returns
 */
export function getAuctionStateRegular(propertyData: CardData): AuctionTimelineStates {
  if (propertyData.statusId === Models.PropertyStatusType.SOLD || propertyData.statusId === Models.PropertyStatusType.PENDING) {
    // Auction has ended with status SOLD.
    return AuctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD;
  } else if (propertyData.statusId === Models.PropertyStatusType.RETURNED) {
    // Auction has ended with status RETURNED.
    return AuctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED;
  } else if (propertyData.categoryId === Models.PropertyCategoryType.ONLINE_FORECLOSURE_SALES && !!propertyData.foreclosureSalesCancelledDate) {
    return AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_CANCELLED;
  } else if (propertyData.categoryId === Models.PropertyCategoryType.ONLINE_FORECLOSURE_SALES && !!propertyData.foreclosureSalesPostponedDate) {
    return AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED;
  } else if (
    // (propertyData.statusId === Models.PropertyStatusType.IN_NEGOTIATIONS || propertyData.statusId === Models.PropertyStatusType.AVAILABLE) &&
    !!propertyData.auction_end_date &&
    !!propertyData.post_auction_end_date &&
    Date.now() > Date.parse(propertyData.auction_end_date) &&
    Date.now() < Date.parse(propertyData.post_auction_end_date)
  ) {
    return AuctionTimelineStates.POST_AUCTION;
  } else if (
    // propertyData.statusId === Models.PropertyStatusType.AVAILABLE &&
    propertyData.auction_start_date &&
    propertyData.auction_end_date &&
    Date.now() >= Date.parse(propertyData.auction_start_date) &&
    Date.now() < Date.parse(propertyData.auction_end_date)
  ) {
    // Auction is currently active
    return AuctionTimelineStates.ACTIVE_AUCTION;
  } else if (!propertyData.auction_start_date) {
    // Auction coming soon
    return AuctionTimelineStates.AUCTION_COMING_SOON;
  } else if (propertyData.auction_start_date && Date.parse(propertyData.auction_start_date) > Date.now()) {
    // Auction not yet started
    return AuctionTimelineStates.AUCTION_NOT_YET_STARTED;
  } else if (propertyData.post_auction_end_date && Date.now() > Date.parse(propertyData.post_auction_end_date)) {
    // EXCEPTION: Sometimes it happens that POST_AUCTION_DATE is already passed but
    // STATUS_ID is still something out of Sold or Returned. In this case we should
    // temporarly set status as Returned, because this status displays "This auction has ended"
    // Answer from backend dev:
    //    "In this case, someone on the xlr8 side should have manually changed the status.
    //     In this case, if it is still a status_id of 9, at the end of post auction,
    //     display the auction ended card until the status_id gets changed to sold or returned."
    return AuctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED;
  } else if (
    propertyData.auction_end_date &&
    Date.now() > Date.parse(propertyData.auction_end_date) &&
    propertyData.statusId === Models.PropertyStatusType.AVAILABLE
  ) {
    // EXCEPTION: almost the same as previous one but the case when status is "available"
    // but auction_end_date is already in the past.
    // Answer from backend dev:
    //    "Some of our properties will never have a post auction. So if there is no post_auction,
    //     You should display “Auction_ended (or sold if its sold) after the auction_end_date
    //     has passed"
    // Since SOLD status is tested above we return status "returned" to show "This auction has ended"
    // state
    return AuctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED;
  } else {
    // this shouldn't ever happen, but if you are here then either a logic of defining
    // auction timeline state is incorrect or API started to return incorrect data
    throw new Error('Unknown auction state, please verify logic or API response data');
  }
}
