<div id="map-mobile-nav">
  <nav>
    <a [routerLink]="['/', searchRouteSlug]">
      <fa-icon icon="search"></fa-icon><br />
      Search
    </a>
    <a [routerLink]="['/', 'mobile', 'buy-sell']">
      <fa-icon icon="gavel"></fa-icon><br />
      {{ buySellTitle$ | async }}
    </a>
    <a [routerLink]="['/', 'saved-properties']">
      <fa-icon [icon]="['far', 'heart']"></fa-icon><br />
      Saved
    </a>
    <a [routerLink]="['/', 'my-profile', 'alerts-notifications']"> <fa-icon [icon]="['far', 'bell']"></fa-icon><br />Alerts</a>
    <a [routerLink]="['/', 'my-profile']">
      <fa-icon icon="user"></fa-icon><br />
      Profile
    </a>
  </nav>
</div>
