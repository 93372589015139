import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { AbstractControl, FormGroup } from '@angular/forms';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, catchError, map, throwError, tap, of, iif, switchMap, take } from 'rxjs';
import { forEach, has, isEmpty, get } from 'lodash';
import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';

import { environment } from '$env';
import { Models } from '$models';
import { BiddingModels } from '../models/bidding.model';
import { SettingsService } from '$settings';
import { AuthService, ModalsManagementService } from '$services';

import { BiddingStoreService } from '../../store/bidding.store';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';
import { BiddingModalComponent } from '../../bidding.component';
import { listenForLargeScreen } from '../../../../../shared/utils';
import { HousesApiStoreService } from '../../../../../routes/houses/shared/stores/houses-api.store';

export enum AuctionStatus {
  SOON = 'SOON',
  ACTIVE = 'ACTIVE',
  POST = 'POST',
  CLOSED = 'CLOSED',
  NONE = 'NONE',
}

@Injectable({
  providedIn: 'root',
})
export class BiddingService {
  static isAuctionSoon(property: Models.PropertyDetailsResponse | null | undefined): boolean {
    return property?.CATEGORYID === Models.PropertyCategoryType.AUCTIONS_COMING_SOON;
  }

  static isActiveAuction(property: Models.PropertyDetailsResponse | null | undefined): boolean {
    const targetProperty = property as Models.PropertyDetailsResponse;
    return (
      !BiddingService.isAuctionSoon(targetProperty) &&
      dayjs.utc().isAfter(dayjs.utc(targetProperty?.AUCTION_START_DATE)) &&
      dayjs.utc().isBefore(dayjs.utc(targetProperty?.AUCTION_END_DATE))
    );
  }

  static isPostAuction(property: Models.PropertyDetailsResponse | null | undefined): boolean {
    const targetProperty = property as Models.PropertyDetailsResponse;
    return (
      !BiddingService.isAuctionSoon(targetProperty) &&
      dayjs.utc().isAfter(dayjs.utc(targetProperty?.AUCTION_END_DATE)) &&
      dayjs.utc().isBefore(dayjs.utc(targetProperty?.POST_AUCTION_END_DATE)) &&
      (targetProperty?.STATUS_ID === Models.PropertyStatusType.AVAILABLE || targetProperty?.STATUS_ID === Models.PropertyStatusType.IN_NEGOTIATIONS)
    );
  }

  static isAboutToStartAuction(property: Models.PropertyDetailsResponse | null | undefined): boolean {
    const targetProperty = property as Models.PropertyDetailsResponse;
    return !BiddingService.isAuctionSoon(targetProperty) && dayjs.utc().isBefore(dayjs.utc(targetProperty?.AUCTION_END_DATE));
  }

  static isClosedAuction(property: Models.PropertyDetailsResponse | null | undefined): boolean {
    const targetProperty = property as Models.PropertyDetailsResponse;
    return (
      (!targetProperty?.POST_AUCTION_END_DATE && dayjs.utc().isAfter(dayjs.utc(targetProperty?.AUCTION_END_DATE))) ||
      dayjs.utc().isAfter(dayjs.utc(targetProperty?.POST_AUCTION_END_DATE))
    );
  }

  static getAuctionStatus(property: Models.PropertyDetailsResponse | null | undefined): AuctionStatus {
    if (!property) {
      return AuctionStatus.NONE;
    }

    if (BiddingService.isAuctionSoon(property)) {
      return AuctionStatus.SOON;
    }

    if (BiddingService.isActiveAuction(property)) {
      return AuctionStatus.ACTIVE;
    }

    if (BiddingService.isPostAuction(property)) {
      return AuctionStatus.POST;
    }

    if (BiddingService.isAboutToStartAuction(property)) {
      return AuctionStatus.SOON;
    }

    if (BiddingService.isClosedAuction(property)) {
      return AuctionStatus.CLOSED;
    }

    return AuctionStatus.NONE;
  }

  static hasNoDeposit(deposit: Models.BidDepositStatus | null | undefined): boolean {
    return !deposit || get(deposit, 'HOLDACTIVE', 0) === 0;
  }

  static isUserAgent(user: Models.LoginResponse | null): boolean {
    return user?.UserType === Models.UserType.Agent;
  }

  static isUserBidder(user: Models.LoginResponse | null): boolean {
    return user?.UserType === Models.UserType.Bidder;
  }

  static isUserClient(user: Models.LoginResponse | null): boolean {
    return user?.UserType === Models.UserType.Client;
  }

  static isUserInvestor(user: Models.LoginResponse | null): boolean {
    return user?.UserType === Models.UserType.Investor;
  }

  static isOhio(property: Models.PropertyDetailsResponse | null): boolean {
    return property?.CATEGORYID === Models.PropertyCategoryType.ONLINE_FORECLOSURE_SALES;
  }

  /**
   * Disable and reset (optional) specified component on form
   *
   * @param controls            Form group
   * @param ignoreControlNames  List of component names to ignore (optional)
   * @param doReset             Flag to reset component value as well (True by default)
   */
  static disableFormControls(controls: { [key: string]: AbstractControl }, ignoreControlNames: string[] = [], doReset = false) {
    forEach(controls, (control: AbstractControl, key: string) => {
      if (control instanceof FormGroup) {
        this.disableFormControls(control.controls, ignoreControlNames, doReset);
      } else if (!ignoreControlNames.includes(key)) {
        if (doReset) {
          controls[key].reset(null, { emitEvent: false });
        }
        controls[key].disable({ emitEvent: false });
      }
    });
  }

  /**
   * Enable all or specified components
   *
   * @param controls      Form group
   * @param controlNames  List of components to process (optional)
   */
  static enableFormGroupControls(controls: { [key: string]: FormGroup | AbstractControl }, controlNames?: string[]) {
    forEach(controls, (control: AbstractControl, key: string) => {
      if (control instanceof FormGroup) {
        this.enableFormGroupControls(control.controls, controlNames);
      } else if (isEmpty(controlNames) || (!isEmpty(controlNames) && controlNames?.includes(key))) {
        controls[key].enable({ emitEvent: false });
      }
    });
  }

  private currentUser: Models.LoginResponse | null = null;
  public readonly largeScreenObserver$ = listenForLargeScreen();

  constructor(
    private biddingStore: BiddingStoreService,
    private dialogService: DialogService,
    private http: HttpClient,
    private settings: SettingsService,
    private analytics: AnalyticsService,
    private currencyPipe: CurrencyPipe,
    private modalsManagement: ModalsManagementService,
    private readonly housesApiStoreService: HousesApiStoreService,
    private readonly authService: AuthService,
  ) {
    this.watchForCurrentUserChanged();
  }

  /**
   * Launch a modal window which gives the user a chance to bid
   */
  launchBidModal(): DynamicDialogRef | null {
    if (!this.currentUser) {
      return null;
    }

    let dialogConfig: DynamicDialogConfig = {
      height: '100%',
      closeOnEscape: false,
      styleClass: 'modal-wizard',
      showHeader: false,
      width: '100%',
      style: { maxHeight: '100%', borderRadius: '0' },
    };

    this.largeScreenObserver$.pipe(take(1)).subscribe(isDesktop => {
      if (isDesktop) {
        dialogConfig = { ...dialogConfig, width: '544px', style: { maxHeight: '90%', borderRadius: '20px' } };
      }
    });

    // Open log out modal window
    const ref = this.dialogService.open(BiddingModalComponent, dialogConfig);

    // Register modal popup to be able to close it after session is closed
    this.modalsManagement.register(ref);

    ref.onClose.subscribe(() => {
      // Restore initial store state
      this.biddingStore.reset();
    });

    return ref;
  }

  formatMoneyValue(value: number): string {
    return this.currencyPipe.transform(value, 'USD', 'symbol', '1.0-0') as string;
  }

  /**
   * Return options for "Bid Amount" and "BidAssist" dropdown
   *
   * @param bid            Specified (current) Bid
   * @param increment      Bid increment (optional, by default is 0)
   * @param isOpeningBid   True if no bids have been done yet (optional, by default is True)
   * @returns
   */
  getBidOptions(bid: number, increment = 0, isOpeningBid = true): { bids: number[]; increment: number } {
    const optionsMaxCount = 1000;
    let options = [];
    let prevBid = bid;

    if (increment === 0) {
      return {
        bids: [bid],
        increment: 0,
      };
    }

    if (isOpeningBid) {
      options.push(prevBid);
    }

    for (let index = 0; index < optionsMaxCount; index++) {
      const newBidOption = prevBid + increment;
      options.push(newBidOption);
      prevBid = newBidOption;
    }

    return {
      bids: options,
      increment,
    };
  }

  addCustomer(payload: Models.ListCustomersResponseCustomers): Observable<any> {
    // TODO: check what endpoint needs to be used for adding new customer?
    return this.http.post(environment.endpoints.apiUrl + 'agent/addCustomer', payload).pipe(
      map(res => {
        if (has(res, 'NEWCUSTOMERID')) {
          // Refresh store with updated data
          this.biddingStore.customers.reset();
          this.biddingStore.customers.refresh();
          return res;
        }
        return throwError({ message: 'Could not save a new customer.' });
      }),
      catchError(this.requestErrorHandler.bind(this)),
    );
  }

  private addDeposit(payload: Models.AddBidDepositRequest): Observable<boolean | unknown> {
    return this.http.post(environment.endpoints.apiUrl + 'bidding/deposit/add', payload).pipe(
      tap(() => {
        const userId = payload.CUSTOMER_ID || this.authService.loginResponse?.CustomerID;
        this.analytics.customEvent({
          'event': 'Payment Information',

          'dataDescriptor': payload.DATADESCRIPTOR,

          'dataValue': payload.DATAVALUE,

          'userID': userId,
        });
      }),
      catchError(this.requestErrorHandler.bind(this)),
    );
  }

  removeBuyerAgent = () =>
    this.http.put(environment.endpoints.apiUrl + 'user/current/buyeragent/remove', null).pipe(
      map(this.requestSuccessHandler.bind(this)),
      tap(() => this.biddingStore.buyerAgent.refresh().subscribe()),
      catchError(this.requestErrorHandler.bind(this)),
    );

  acceptDisclosure = (payload: Models.AcceptDisclosureRequest) =>
    this.http
      .post(environment.endpoints.apiUrl + 'bidding/disclosure/accept', payload)
      .pipe(map(this.requestSuccessHandler.bind(this)), catchError(this.requestErrorHandler.bind(this)));

  /**
   * Process bid data and add a new bid
   *
   * @param biddingPayload  Bid payload
   * @param bidForData      "Bid for" payload
   * @param bidDeposit      Deposit payload
   * @returns Observable<boolean | unknown>
   */
  processAddBid(
    biddingPayload: BiddingModels.BidddingRequest | null,
    bidForData?: BiddingModels.PlaceBidForFormModel | null,
    bidDeposit?: Models.AddBidDepositRequest | null,
  ): Observable<boolean | unknown> {
    // 1) Add a new customer if provided
    return iif(() => bidForData?.Bid_Target === BiddingModels.BidTargets.newCustomer, this.addCustomer(bidForData!), of(null)).pipe(
      // Update bid payload with new customer ID
      switchMap(newCustomerResponse => {
        if (newCustomerResponse) {
          biddingPayload = {
            ...biddingPayload,
            CUSTOMER_ID: newCustomerResponse.NEWCUSTOMERID.toString(),
          };
        }
        return of(biddingPayload);
      }),
      // 2) Add a new deposit if specified
      switchMap(biddingPayload => {
        // Update deposit payload with customer ID or set it to '0' (default)
        if (bidDeposit) {
          bidDeposit = {
            ...bidDeposit,
            CUSTOMER_ID: biddingPayload?.CUSTOMER_ID || '0',
          };
        }
        // Add deposit
        return bidDeposit ? this.addDeposit(bidDeposit) : of(null);
      }),
      // 3) Add bid
      switchMap(() => {
        if (biddingPayload) {
          return this.addBid(biddingPayload).pipe(
            tap(() => {
              this.housesApiStoreService.updateBidHistory(biddingPayload!.ITEM_ID!);
            }),
          );
        }
        return of(true);
      }),
    );
  }

  /**
   * Save Bid to database
   * @param bid   Bid data
   * @returns Observable<BiddingModels.AddBidRequest>
   */
  private addBid(payload: BiddingModels.BidddingRequest): Observable<boolean | unknown> {
    return this.http.post(environment.endpoints.apiUrl + '/bidding/add', payload).pipe(
      map(this.requestSuccessHandler.bind(this)),
      tap(() => {
        const userId = payload.CUSTOMER_ID || this.authService.loginResponse?.CustomerID;
        this.analytics.customEvent({
          'event': 'bidPlaced',

          'bidAmount': payload.BID_AMT,

          'auctionID': payload.ITEM_ID,

          'userID': userId,
        });
      }),
      catchError(error =>
        this.requestErrorHandler({
          ...(error as object),
          message: 'Something went wrong during bidding process. Please, try again later.',
        }),
      ),
    );
  }

  private requestSuccessHandler(response: any): any | Observable<any> {
    if ('SUCCESS' in response && response?.SUCCESS === true) {
      return response;
    }
    throw Error(response?.MESSAGE || response?.message);
  }

  private requestErrorHandler(error: any): Observable<any> {
    console.error(error?.MESSAGE || error?.message || 'Something went wrong. Please, try again later.');
    this.analytics.gtag('event', 'api_fail');
    return throwError(error);
  }

  /**
   * Watching current user changes
   */
  private watchForCurrentUserChanged(): void {
    this.settings.user$.subscribe({
      next: user => {
        this.currentUser = user;
      },
      error: () => {
        this.currentUser = null;
      },
    });
  }
}
