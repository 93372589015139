<div class="bid-deposit">
  <p>A $250 credit or debit card hold (Bid Deposit) is required to bid on RealtyBid.com. The hold will appear as a pending transaction, not as a charge on your account. We release the hold after the auction has completed unless you are the auction winner, then it is released after the successful execution of the purchase contract. In the event that you are bidding in more than one auction, the bid deposit will not be released until all auctions have concluded and purchase contracts submitted (where applicable).</p>
  <div class="bid-deposit-form-container">
    <form [formGroup]="form" [ngClass]="{ 'show-errors-on-touched': errorCondition.touch }" >
      <div class="p-fluid row">
        <ng-container *ngFor="let field of depositFormFeilds">
          <div class="col" [ngClass]="field.class ?? 'col-12'">
            <label *ngIf="field.label" [for]="field.formControlName ?? ''">
              {{ field.label || field.placeholder || '' }}
            </label> 
            <ng-container [ngSwitch]="field.type">
              <ng-container *ngSwitchCase="'inputmask'">
                <p-inputMask
                  [id]="field.formControlName ?? ''"
                  [formControlName]="field.formControlName ?? ''" 
                  [mask]="field.mask ?? ''" 
                  [slotChar]="field.slotChar ?? ' '"
                  [placeholder]="field.placeholder ?? ''"
                  [autoClear]="false"
                ></p-inputMask>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <input 
                  [formControlName]="field.formControlName ?? ''" 
                  [placeholder]="field.placeholder ?? ''" 
                  [type]="field.type ?? 'text'" 
                  [id]="field.formControlName ?? ''" 
                  pInputText />
              </ng-container>
            </ng-container>
            <app-input-error 
              [controlName]="field.formControlName ?? ''" 
              [friendlyName]="field.label ?? field.placeholder ?? ''"
              [errorCondition]="errorCondition"
              [anyErrorShowInvalid]="field.anyErrorShowInvalid ?? false"
              [customErrorMessage]="field.customErrorMessage ?? null"
            ></app-input-error>
          </div>
        </ng-container>
      </div>
    </form>
  </div>
</div>