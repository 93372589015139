import { ModalWizardModels } from "./modal-wizard.model";

export module ModalWizardConstants {

  export const MODAL_HEADER_TITLE_SECTION_CLASS_SELECTOR = 'p-dialog-title';
  export const MODAL_HEADER_SECTION_CLASS_SELECTOR = 'p-dialog-header';
  export const MODAL_BODY_SECTION_CLASS_SELECTOR = 'modal-wizard-card';

  export const PREV_TEXT = 'Back';
  export const NEXT_TEXT = 'Next';
  export const COMPLETE_TEXT = 'Done';

  export const BID_DEPOSIT_STEP_FOOTER_CONTENT = '<p><b>Note:</b> In the event that you, as the winning bidder, do not perform under the terms of your bidding agreement, you will forfeit your $250 bid deposit, and your card will be charged.</p>';

  export const DEFAULT_CONFIG: ModalWizardModels.WizardConfig = {
    selected: 0,
  };
}