import { Models } from '../../../../shared/models';
import { CardData } from '../../../../shared/services/auction-card-flow-services/flow.service';

export function mapPropertySearchResponseToCardServiceData(source: Models.PropertySearchResponse): CardData {
  return {
    statusId: source.STATUS_ID,
    bidamt: source.CURRENT_BID,
    address: source.ADDRESS1 + (source.ADDRESS2?.trim() ? ', ' + source.ADDRESS2 : ''),
    city: source.CITY,
    state: source.STATE,
    bed: source.BEDROOM_COUNT,
    bath: source.BATH_COUNT,
    sqft: source.SQ_FT,
    categoryId: source.CATEGORYID,
    auction_start_date: source.AUCTION_START_DATE,
    auction_end_date: source.AUCTION_END_DATE,
    post_auction_end_date: source.POST_AUCTION_END_DATE,
    sold_date: source.SOLD_DATE,
    date_end: source.DATE_END,
    handleId: source.CURRENT_HIGH_BIDDER,
    estimatedvalue: source.ESTIMATEDVALUE,
    // ohio part
    foreclosureSalesCancelledDate: source?.CANCELLED_SALE_DATE,
    foreclosureSalesPostponedDate: source?.POSTPONEMENT_DATE,
  };
}

export function mapPropertyDetailsResponseToCardServiceData(source: Models.PropertyDetailsResponse): CardData {
  return {
    statusId: source.STATUS_ID,
    bidamt: source.CURRENT_ONLINE_AUCTION_BID_INFORMATION?.BIDAMT,
    finalReserve: source.FINALRESERVE,
    address: source.ADDRESS1,
    city: source.CITY,
    state: source.STATE,
    bed: source.BEDROOM_COUNT,
    bath: source.BATH_COUNT,
    sqft: source.SQ_FT,
    categoryId: source.CATEGORYID,
    estimatedvalue: source.ESTIMATEDVALUE,
    auction_start_date: source.AUCTION_START_DATE,
    auction_end_date: source.AUCTION_END_DATE,
    post_auction_end_date: source.POST_AUCTION_END_DATE,
    sold_date: source.CLOSING_INFORMATION?.SOLDDATE,
    date_end: source.DATE_END,
    handleId: source.CURRENT_ONLINE_AUCTION_BID_INFORMATION?.HANDLEID,
    bidAssistAmt: source.CURRENT_ONLINE_AUCTION_BID_INFORMATION?.BIDASSISTAMT,

    // ohio part
    foreclosureSalesCancelledDate: source.LIVE_FORCLOSURE?.CANCELLED_SALE_DATE,
    foreclosureSalesPostponedDate: source.LIVE_FORCLOSURE?.POSTPONEMENT_DATE,
    foreclosureSaleOpeningBid: source.LIVE_FORCLOSURE?.SALEOPENINGBID !== undefined ? +source.LIVE_FORCLOSURE.SALEOPENINGBID : 0,
  };
}

export function syncSearchDataWithCurrentBidData(target: Models.PropertySearchResponse, source: Models.PropertyCurrentBidResponse): CardData {
  const targetSyncedCopy: Models.PropertySearchResponse = {
    ...target,
    STATUS_ID: source.STATUS_ID,
    CURRENT_BID: source.CURRENT_BID,
    DATE_END: source.DATE_END,
    POST_AUCTION_END_DATE: source.POST_AUCTION_END_DATE,
    AUCTION_START_DATE: source.AUCTION_START_DATE,
    AUCTION_END_DATE: source.AUCTION_END_DATE,
    POSTPONEMENT_DATE: source.POSTPONEMENT_DATE,
    CANCELLED_SALE_DATE: source.CANCELLED_SALE_DATE,
    SOLD_DATE: source.SOLD_DATE,
    CURRENT_HIGH_BIDDER: source.LEADERBIDDER,
  };

  return mapPropertySearchResponseToCardServiceData(targetSyncedCopy);
}

export function syncDetailsDataWithCurrentBidData(target: Models.PropertyDetailsResponse, source: Models.PropertyCurrentBidResponse): CardData {
  const targetSyncedCopy: Models.PropertyDetailsResponse = {
    ...target,
    STATUS_ID: source.STATUS_ID,
    // BIDAMT: source.CURRENT_BID,
    DATE_END: source.DATE_END,
    POST_AUCTION_END_DATE: source.POST_AUCTION_END_DATE,
    AUCTION_END_DATE: source.AUCTION_END_DATE,
    AUCTION_START_DATE: source.AUCTION_START_DATE,
    // Below is a little hack. "currentbid" endpoint returns RESERVE_MET which is already calculated,
    // but "details" (where we are merging currentbid data to) endpoint don't have this field.
    // but it has field FINALRESERVE which is used to define is reserve met or not by using
    // following logic: BIDNAMT >= FINALRESERVE then 'Reserve met' else 'Reserve not met'.
    // So, by defining FINALRESERVE as negative infinity we can prompt logic to the right direction.
    FINALRESERVE: source.RESERVE_MET === 'no' ? undefined : Number.NEGATIVE_INFINITY,
  };

  targetSyncedCopy.CURRENT_ONLINE_AUCTION_BID_INFORMATION!.HANDLEID = source.LEADERBIDDER;
  targetSyncedCopy.CURRENT_ONLINE_AUCTION_BID_INFORMATION!.BIDAMT = source.CURRENT_BID;
  targetSyncedCopy.LIVE_FORCLOSURE!.POSTPONEMENT_DATE = source.POSTPONEMENT_DATE;
  targetSyncedCopy.LIVE_FORCLOSURE!.CANCELLED_SALE_DATE = source.CANCELLED_SALE_DATE;
  targetSyncedCopy.LIVE_FORCLOSURE!.SALELOCATION = source.SALE_LOCATION;
  targetSyncedCopy.CLOSING_INFORMATION!.SOLDDATE = source.SOLD_DATE;
  // Below is a little hack. "currentbid" endpoint returns BID_ASSIST which is already calculated,
  // but "details" (where we are merging currentbid data to) endpoint don't have this field.
  // but it has field BIDASSISTAMT which is used to define is bidAssist or noBidAssist by using
  // following logic: BIDASSISTAMT > BIDAMT then 'bidAssist' else 'No bidAssist'.
  // So, by defining BIDASSISTAMT as positive infinity we can prompt logic to the right direction.
  targetSyncedCopy.CURRENT_ONLINE_AUCTION_BID_INFORMATION!.BIDASSISTAMT = source.BID_ASSIST === 'N' ? undefined : Number.POSITIVE_INFINITY;

  return mapPropertyDetailsResponseToCardServiceData(targetSyncedCopy);
}
