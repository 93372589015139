import { Component, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, tap } from 'rxjs/operators';

import { environment } from '$env';
import { AuthService, LoadingService, NavigationService } from '$services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as dayjs from 'dayjs';
import {
  LiveForeclosureArizonaFlowType as BidCardLiveForeclosureArizonaFlowType,
  LiveForeclosureFlowType as BidCardLiveForeclosureFlowType,
  RegularFlowType as BidCardRegularFlowType,
} from './shared/services/auction-card-flow-services/bid-card-flows.service';
import { FlowFactory } from './shared/services/auction-card-flow-services/flow.service';
import {
  LiveForeclosureFlowType as PropertyCardLiveForeclosureFlowType,
  RegularFlowType as PropertyCardPropertyCardLiveForeclosureFlowType,
} from './shared/services/auction-card-flow-services/property-card-flows.service';
import { LocalStorageService } from './shared/services/local-storage.service';
import { NewRelicService } from './shared/services/new-relic.service';
import { GLOBAL_TOAST_KEY } from './shared/services/notification.service';
import { SessionStorageService } from './shared/services/session-storage.service';
import { isBrowser } from './shared/utils';
// LiveForeclosureFlowType
dayjs.extend(require('dayjs/plugin/advancedFormat'));
dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/timezone'));

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {
  public isBrowser = isBrowser;
  public loading$ = this.loadingService.loading$;
  public GLOBAL_TOAST_KEY = GLOBAL_TOAST_KEY;

  /** Generate css classes based on the url route */
  public cssClasses$ = this.router.events.pipe(
    filter((event): event is NavigationEnd => event instanceof NavigationEnd),
    map(event => {
      // Extracting the pathname without query parameters
      const url = new URL(event.urlAfterRedirects, 'http://localhost');
      const pathSegments = url.pathname.split('/').filter(p => !!p); // Remove empty routes

      // Generate class names. If there are segments, prefix them appropriately.
      return pathSegments.map((segment, index) => (index < pathSegments.length - 1 ? 'ancestor-' : 'route-') + segment);
    }),
  );

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private loadingService: LoadingService,
    private meta: Meta,
    private auth: AuthService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly localStorageService: LocalStorageService,
    private readonly flowFactory: FlowFactory,
    private readonly navigationService: NavigationService,
    private readonly newRelicService: NewRelicService,
  ) {
    this.registerCardUiFlows();
    this.newRelicService.load();
  }

  ngOnInit() {
    this.sessionStorageService.init();
    this.localStorageService.init();
    this.routeChange();
    // Manage authentication events. These are subscriptions so for memory management reasons they are managed here instead of the service
    // Handle logout modal
    this.auth.logoutTimerExpired$.pipe(untilDestroyed(this)).subscribe();
    // Handle refresh token
    this.auth.refreshToken$.pipe(untilDestroyed(this)).subscribe();
  }

  /**
   * Actions to perform on route change
   * Page titles are in app.routes.ts
   */
  private routeChange() {
    this.router.events
      .pipe(
        filter((event): event is NavigationEnd => event instanceof NavigationEnd),
        // Store route URL in the navigation service
        tap(event => {
          this.navigationService.routeChange(event.urlAfterRedirects || event.url);
        }),
        map(() => this.activatedRoute),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        mergeMap(route => route.data),
      )
      .subscribe(event => {
        // If title is specified, change title
        if (event['title']) {
          this.title.setTitle(event['title'] + ' | ' + environment.properties.appName);
        }
        // If meta description is specified, update meta tag
        if (event['description']) {
          this.meta.updateTag({
            name: 'description',
            content: event['description'],
          });
        }
      });
  }

  ngOnDestroy(): void {
    this.auth.ngOnDestroy();
  }

  private registerCardUiFlows() {
    // Bid card flows
    this.flowFactory.register(BidCardLiveForeclosureFlowType);
    this.flowFactory.register(BidCardLiveForeclosureArizonaFlowType);
    this.flowFactory.register(BidCardRegularFlowType);
    // Property card flows
    this.flowFactory.register(PropertyCardLiveForeclosureFlowType);
    this.flowFactory.register(PropertyCardPropertyCardLiveForeclosureFlowType);
  }
}
