import { AuctionStatus, AuctionTimelineStates, Models } from '../../models';
import { getAuctionStateForeclosure, getAuctionStateRegular } from './flow-strategies.service';
import { CardData, CardTypes, FlowType, FlowTypeConstructor } from './flow.service';

type bidStatusForeclosureBidCard = 'Sold' | 'Auction date & time';
type bidStatusOhioBidCard = 'This auction has been cancelled';

/**
 * Represents bid card (In property details page)
 */
export interface BidCard extends AuctionStatus {
  bidStatus?:
    | 'Opening Bid'
    | 'Current Bid'
    | 'SOLD (Winning Bid)'
    | 'Auction Coming Soon'
    | 'This auction has ended'
    | bidStatusForeclosureBidCard
    | bidStatusOhioBidCard;
  bidAmt?: number;
  activeDateTitle?: 'Auction Starts In' | 'Auction Time Left' | 'Post Auction Time Left' | 'Auction Ended' | 'Auction Cancelled' | 'Auction Postponed';
  activeDate?: string;
  reserveMetTitle?: 'Reserve met' | 'Reserve not met';
  bidder?: string;
  bidAssistTitle?: 'BidAssist' | 'No BidAssist';
  estimatedvalue?: number;
}

export const LiveForeclosureFlowType: FlowTypeConstructor<BidCard> = class LiveForeclosureFlowType extends FlowType<BidCard> {
  public static readonly type = CardTypes.bidCard;
  public static isFlowTypeFitData(data: CardData): boolean {
    return isForeclosureSalesFlowType(data);
  }
  private originalData: CardData;
  constructor(data: CardData) {
    super();
    this.originalData = data;
  }

  override getUiData(): BidCard {
    switch (this.auctionState) {
      case AuctionTimelineStates.AUCTION_NOT_YET_STARTED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_NOT_YET_STARTED,
          bidStatus: 'Auction date & time',
          activeDateTitle: 'Auction Starts In',
          activeDate: this.originalData.date_end,
        };
      case AuctionTimelineStates.AUCTION_COMING_SOON:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_COMING_SOON,
          bidStatus: 'Auction date & time',
          activeDateTitle: 'Auction Starts In',
        };
      case AuctionTimelineStates.ACTIVE_AUCTION:
        return {
          auctionStatus: AuctionTimelineStates.ACTIVE_AUCTION,
          bidStatus: 'Auction date & time',
          activeDate: this.originalData.date_end,
        };
      case AuctionTimelineStates.POST_AUCTION:
        throw new Error('Live Foreclosure cannot be in Post Auction state');
      case AuctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD,
          bidStatus: 'Sold',
          activeDate: this.originalData.date_end,
        };
      case AuctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED,
          bidStatus: 'This auction has ended',
        };
      case AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_CANCELLED:
        throw new Error('Live Foreclosure cannot be in Cancelled state');
      case AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED,
          activeDateTitle: 'Auction Postponed',
          bidAmt: this.originalData.bidamt,
          bidStatus: this.originalData.handleId === 'Bid Now' ? 'Opening Bid' : 'Current Bid',
          activeDate: this.originalData.date_end,
        };
    }
  }

  override get auctionState(): AuctionTimelineStates {
    return getAuctionStateForeclosure(this.originalData);
  }
};

export const LiveForeclosureArizonaFlowType: FlowTypeConstructor<BidCard> = class LiveForeclosureArizonaFlowType extends FlowType<BidCard> {
  public static readonly type = CardTypes.bidCard;
  public static isFlowTypeFitData(data: CardData): boolean {
    return isLiveForecloureArizonaFlowType(data);
  }
  private originalData: CardData;
  constructor(data: CardData) {
    super();
    this.originalData = data;
  }

  override getUiData(): BidCard {
    switch (this.auctionState) {
      case AuctionTimelineStates.AUCTION_NOT_YET_STARTED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_NOT_YET_STARTED,
          bidStatus: 'Opening Bid',
          activeDateTitle: 'Auction Starts In',
          bidAmt: this.originalData.foreclosureSaleOpeningBid,
          activeDate: this.originalData.date_end,
        };
      case AuctionTimelineStates.AUCTION_COMING_SOON:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_COMING_SOON,
          activeDateTitle: 'Auction Starts In',
        };
      case AuctionTimelineStates.ACTIVE_AUCTION:
        return {
          auctionStatus: AuctionTimelineStates.ACTIVE_AUCTION,
          bidStatus: 'Opening Bid',
          bidAmt: this.originalData.foreclosureSaleOpeningBid,
          activeDate: this.originalData.date_end,
        };
      case AuctionTimelineStates.POST_AUCTION:
        throw new Error('Live Foreclosure cannot be in Post Auction state');
      case AuctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD,
          bidStatus: 'Sold',
          activeDate: this.originalData.date_end,
        };
      case AuctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED,
          bidStatus: 'This auction has ended',
        };
      case AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_CANCELLED:
        throw new Error('Live Foreclosure cannot be in Online Foreclosure Cancelled state');
      case AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED,
          activeDateTitle: 'Auction Postponed',
          bidAmt: this.originalData.bidamt,
          bidStatus: this.originalData.handleId === 'Bid Now' ? 'Opening Bid' : 'Current Bid',
          activeDate: this.originalData.date_end,
        };
    }
  }

  override get auctionState(): AuctionTimelineStates {
    return getAuctionStateForeclosure(this.originalData);
  }
};

export const RegularFlowType: FlowTypeConstructor<BidCard> = class RegularFlowType extends FlowType<BidCard> {
  public static readonly type = CardTypes.bidCard;
  public static isFlowTypeFitData(data: CardData): boolean {
    return isRegularFlowType(data);
  }
  private originalData: CardData;
  constructor(data: CardData) {
    super();
    this.originalData = data;
  }

  override getUiData(): BidCard {
    switch (this.auctionState) {
      case AuctionTimelineStates.AUCTION_NOT_YET_STARTED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_NOT_YET_STARTED,
          bidStatus: 'Opening Bid',
          bidAmt: this.originalData.bidamt,
          activeDateTitle: 'Auction Starts In',
          activeDate: this.originalData.date_end,
        };
      case AuctionTimelineStates.AUCTION_COMING_SOON:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_COMING_SOON,
          bidStatus: 'Auction Coming Soon',
          estimatedvalue: this.originalData.estimatedvalue,
        };
      case AuctionTimelineStates.ACTIVE_AUCTION:
        return {
          auctionStatus: AuctionTimelineStates.ACTIVE_AUCTION,
          bidStatus: this.originalData.handleId === 'Bid Now' ? 'Opening Bid' : 'Current Bid',
          reserveMetTitle:
            this.originalData.bidamt !== undefined && this.originalData.finalReserve !== undefined && this.originalData.bidamt >= this.originalData.finalReserve
              ? 'Reserve met'
              : 'Reserve not met',
          bidAmt: this.originalData.bidamt,
          activeDateTitle: 'Auction Time Left',
          activeDate: this.originalData.date_end,
          bidder: this.originalData.handleId, // Bid to
          bidAssistTitle:
            this.originalData.handleId === 'Bid Now'
              ? undefined
              : this.originalData.bidAssistAmt !== undefined &&
                this.originalData.bidamt !== undefined &&
                this.originalData.bidAssistAmt > this.originalData.bidamt
              ? 'BidAssist'
              : 'No BidAssist',
        };
      case AuctionTimelineStates.POST_AUCTION:
        return {
          auctionStatus: AuctionTimelineStates.POST_AUCTION,
          bidStatus: this.originalData.handleId === 'Bid Now' ? 'Opening Bid' : 'Current Bid',
          reserveMetTitle:
            this.originalData.bidamt !== undefined && this.originalData.finalReserve !== undefined && this.originalData.bidamt >= this.originalData.finalReserve
              ? 'Reserve met'
              : 'Reserve not met',
          bidAmt: this.originalData.bidamt,
          activeDateTitle: 'Post Auction Time Left',
          activeDate: this.originalData.date_end,
          bidder: this.originalData.handleId,
          bidAssistTitle:
            this.originalData.handleId === 'Bid Now'
              ? undefined
              : this.originalData.bidAssistAmt !== undefined &&
                this.originalData.bidamt !== undefined &&
                this.originalData.bidAssistAmt > this.originalData.bidamt
              ? 'BidAssist'
              : 'No BidAssist',
        };
      case AuctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD,
          bidStatus: 'SOLD (Winning Bid)',
          reserveMetTitle:
            this.originalData.bidamt !== undefined && this.originalData.finalReserve !== undefined && this.originalData.bidamt >= this.originalData.finalReserve
              ? 'Reserve met'
              : 'Reserve not met',
          bidAmt: this.originalData.bidamt,
          bidder: this.originalData.handleId,
          activeDateTitle: 'Auction Ended',
          activeDate: this.originalData.sold_date,
        };
      case AuctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED,
          bidStatus: 'This auction has ended',
          activeDateTitle: 'Auction Ended',
          activeDate: this.originalData.date_end,
        };
      case AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_CANCELLED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_CANCELLED,
          bidStatus: 'This auction has been cancelled',
          activeDateTitle: 'Auction Cancelled',
          activeDate: this.originalData.date_end,
        };
      case AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED,
          activeDateTitle: 'Auction Postponed',
          bidAmt: this.originalData.bidamt,
          bidStatus: this.originalData.handleId === 'Bid Now' ? 'Opening Bid' : 'Current Bid',
          activeDate: this.originalData.date_end,
        };
      default:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_COMING_SOON,
          estimatedvalue: this.originalData.estimatedvalue,
        };
    }
  }

  override get auctionState(): AuctionTimelineStates {
    return getAuctionStateRegular(this.originalData);
  }
};

function isLiveForecloureArizonaFlowType(propertyData: CardData): boolean {
  return propertyData.categoryId === Models.PropertyCategoryType.LIVE_FORECLOSURE_SALES && propertyData.state === 'AZ';
}

function isForeclosureSalesFlowType(propertyData: CardData): boolean {
  return (
    !isLiveForecloureArizonaFlowType(propertyData) &&
    (propertyData.categoryId === Models.PropertyCategoryType.LIVE_FORECLOSURE_SALES ||
      propertyData.categoryId === Models.PropertyCategoryType.LOCAL_COUNTY_ONLINE_FORECLOSURE_SALES)
  );
}

function isRegularFlowType(propertyData: CardData): boolean {
  return !isLiveForecloureArizonaFlowType(propertyData) && !isForeclosureSalesFlowType(propertyData);
}
