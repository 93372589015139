<div class="wrapper badge" [style.color]="mainColor" *ngIf="vipLevelsStatistics">
  <h5 class="badge--heading" [style.backgroundColor]="mainColor">{{ headerText }}</h5>
  <img [src]="currentBadgePath" [alt]="altImageText" class="badge--active-badge__large badge-img" />
  <p class="badge--vip-state-text" role="status" [style.backgroundColor]="vipStateBackgroundColor">{{ vipStateText }}</p>
  <div class="statistics">
    <div class="statistics--item statistics--properties-purchased">
      <div class="icon-wrapper" [style.backgroundColor]="mainColor">
        <fa-icon [icon]="['fas', 'house']"></fa-icon>
      </div>
      <span class="statistics--item statistics--number">{{ vipLevelsStatistics.numberPropertiesPurchased }}</span>
      <p class="statistics--short-description">Properties Purchased</p>
    </div>

    <div class="statistics--item statistics--purchase-price">
      <div class="icon-wrapper" [style.backgroundColor]="mainColor">
        <fa-icon [icon]="['fas', 'dollar-sign']"></fa-icon>
      </div>
      <span class="statistics--number">{{ vipLevelsStatistics.averagePurchasePrice | currency: 'USD':'symbol':'1.0-0' }}</span>
      <p class="statistics--short-description">Average Purchase Price</p>
    </div>

    <div class="statistics--item statistics--purchase-price-to-value">
      <div class="icon-wrapper" [style.backgroundColor]="mainColor">
        <fa-icon [icon]="['fas', 'percent']"></fa-icon>
      </div>
      <span class="statistics--number">{{ vipLevelsStatistics.percentOfValue }}%</span>
      <p class="statistics--short-description">Average Purchase Price to Value</p>
    </div>

    <div class="statistics--item statistics--properties-bid-on-in">
      <div class="icon-wrapper" [style.backgroundColor]="mainColor">
        <fa-icon [icon]="['fas', 'gavel']"></fa-icon>
      </div>
      <span class="statistics--number">{{ vipLevelsStatistics.numberPropsBidOn }}</span>
      <p class="statistics--short-description">Properties Bid On in {{ currentYear }}</p>
    </div>
  </div>
  <p-divider styleClass="badge--divider"></p-divider>
  <a href="/help-center/investor-program" class="badge--vip-investor-program-link">Click here to learn more about our VIP Investor Program</a>
  <div class="badge--badges-wrapper badges-wrapper">
    <img src="/assets/images/program-premium-pro.svg" alt="premium-pro badge" class="badges-wrapper--premium-pro badge-img" />
    <img src="/assets/images/program-premium.svg" alt="premium badge" class="badges-wrapper--permium badge-img" />
    <img src="/assets/images/program-gold.svg" alt="gold badge" class="badges-wrapper--gold badge-img" />
    <img src="/assets/images/program-silver.svg" alt="silver badge" class="badges-wrapper--silver badge-img" />
    <img src="/assets/images/program-bronze.svg" alt="bronze badge" class="badges-wrapper--bronze badge-img" />
  </div>
</div>
