// @angular modules
import { SiteModule } from '$site';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injector, NgModule, enableProdMode } from '@angular/core'; // APP_INITIALIZER,
import { BrowserModule, BrowserTransferStateModule, HAMMER_GESTURE_CONFIG, HammerGestureConfig, HammerModule } from '@angular/platform-browser';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Main entrypoint component
import { environment } from '$env';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UrlSerializer } from '@angular/router';
import * as Hammer from 'hammerjs';
import { AppComponent } from './app.component';
import { AppRouterModule } from './app.routes.module';
import { ComponentsLazyLoad } from './components/components.lazy.module';
import { NoContentComponent } from './routes/no-content/no-content.component';
import { HttpInterceptorService } from './shared/interceptors/http.interceptor';
import { loadCookiebot } from './shared/utils/cookiebot.utils';
import { TrailingSlashUrlSerializer } from './shared/utils/url-serializer.util';

// Enables faster prod mode, does disable some dirty error checking though
if (environment.production) {
  enableProdMode();
}

// Components
export const APP_COMPONENTS = [AppComponent, NoContentComponent];

export let InjectorInstance: Injector;

const hammerConfig = new HammerGestureConfig();
hammerConfig.overrides = {
  swipe: { direction: Hammer.DIRECTION_ALL },
};

@NgModule({
  declarations: [APP_COMPONENTS],
  imports: [
    BrowserModule.withServerTransition({ appId: environment.appID }),
    BrowserTransferStateModule,
    HttpClientModule,
    BrowserAnimationsModule, // Adds 3k over no animation
    AppRouterModule,
    HammerModule,
    ComponentsLazyLoad,
    /** Uncomment to enable SW
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.settings.enableServiceWorker,
      registrationStrategy: 'registerImmediately',
    }),
     */
    SiteModule,
  ],
  providers: [
    { provide: UrlSerializer, useClass: TrailingSlashUrlSerializer },
    // HTTP interceptor for auth
    HttpInterceptorService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    // App initializer for startup
    {
      provide: APP_INITIALIZER,
      useFactory: () => appInit,
      multi: true,
    },
    { provide: HAMMER_GESTURE_CONFIG, useValue: hammerConfig, deps: [] },
  ],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {
  constructor(private injector: Injector) {
    InjectorInstance = this.injector;
  }
}

function appInit(): Promise<void> | void {
  loadCookiebot();
}
