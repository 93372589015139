import { Models } from '$models';

export type KeysOfType<T, U> = { [K in keyof T]: T[K] extends U ? K : never }[keyof T];
export type RequiredKeys<T> = Exclude<KeysOfType<T, Exclude<T[keyof T], undefined>>, undefined>;

export module BiddingModels {
  // manualy added enums and interfaces

  export enum BiddingResult {
    success = 'SUCCESS',
    failed = 'FAILED',
  }

  export enum BidTargets {
    existingCustomer = 'existingCustomer',
    newCustomer = 'newCustomer',
    selfCustomer = 'selfCustomer',
  }

  export enum BidStepIds {
    termsOfUse = 'terms-of-use',
    confirmBid = 'confirm-bid',
    bidResult = 'bid-result',
    bidDeposit = 'bid-deposit',
    placeBidFor = 'place-bid-for',
    placeBidForm = 'place-bid-form',
    manageBuyerAgent = 'manage-buyer-agent',
  }

  export interface PlaceBidForFormModel extends Models.ListCustomersResponseCustomers {
    Bid_Target?: BidTargets;
  }

  export interface AuthorizeNetPaymentCardModel {
    fullname: string;
    cardNumber: string;
    month: string;
    year: string;
    cardCode: string;
    zip: string;
  }

  /**
   * Interface represents data for "Active Action Bid" form
   */
  export interface BiddingModel {
    BID_AMT?: number;
    BID_ASSIST_AMT?: number;
    BID_ASSIST_CARRYOVER?: 'Y' | 'N';
    OCCUPANCY_TYPE?: string;
    PURCHASE_TYPE?: string;
    PURCHASEPROFILEID?: number;
  }

  /**
   * Interface represents Bid data needed for making a bid
   */
  export interface BidddingRequest extends BiddingModel {
    // Property ID?
    ITEM_ID?: number;
    // User ID?
    CUSTOMER_ID?: string;
  }

  export interface OccupancyType {
    ID: string;
    OCCUPANCYTYPE: string;
  }

  export interface PurchaseType {
    ID: string;
    PURCHASETYPE: string;
  }

  export interface BuyerAgentClickEvent {
    agent?: Models.BuyerAgentRequest;
  }
}
