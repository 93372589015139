import { SearchStoreService, googlePlacesToLocation, googlePlacesToUrl } from '$stores';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { distinctUntilChanged, map, take } from 'rxjs';
import { viewportCentralUS } from '../../routes/houses/shared';
import { Models } from '../../shared/models';
import { GoogleApiService } from '../../shared/services/google-api.service';
import { SearchRequestType, Selection } from './location-search-input/location-search-input.component';

@UntilDestroy()
@Component({
  selector: 'app-property-search-bar',
  templateUrl: './property-search-bar.component.html',
  styleUrls: ['./property-search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertySearchBarComponent {
  @Input() loadStoredSearchTerm = true;
  @Input() replaceQueryParams = false;
  @Input() enableSmallDropDownOnHighResolutionScreen = false;
  @Input() routeOptions: Partial<Models.SearchPageQueryParams> | undefined;
  @Input() advancedSearchMobileRoutingPath: string[] = [];

  showAdvancedSearchDropdown: boolean = true;

  public readonly searchTitle = 'Address, City, State, County, ZIP';

  public queryParamsSearchTermInit$ = this.searchStore.queryParamsSearch$.pipe(
    map(searchQueryParams => searchQueryParams?.term || ''),
    distinctUntilChanged(),
  );

  constructor(private readonly searchStore: SearchStoreService, private readonly googleApiService: GoogleApiService, private readonly router: Router) {}

  /**
   * Errors can occur at many points in the lookup process, this fn is a placeholder for how to handle this in the future
   */
  private error() {
    console.error('An error occured somewhere in the lookup process');
  }

  public onSelected(event: Selection) {
    switch (event.type) {
      case SearchRequestType.NO_SELECTION:
        let queryParams: Pick<Models.SearchPageQueryParams, 'search' | 'map' | 'filters'> = {
          map: { viewportBounds: viewportCentralUS },
        };
        // filters should be reset only directly by user but not through a search bar
        this.searchStore.queryParamsFilters$.pipe(take(1)).subscribe(filters => {
          if (filters) {
            queryParams = { ...queryParams, filters };
          }
        });

        if (this.routeOptions) {
          queryParams = { ...queryParams, ...this.routeOptions };
        }
        this.searchStore.routeChange(this.searchStore.searchRouteSlug, {
          replaceQueryParams: true,
          queryParams,
        });
        break;
      case SearchRequestType.CURRENT_LOCATION:
        this.handleGooglePlace(event.payload!.key, event.payload?.searchTerm);
        break;
      case SearchRequestType.DIRECT_MATCHES:
        this.searchStore.routeChange(`${this.searchStore.searchRouteSlug}/${event.payload!.url}`);
        break;
      case SearchRequestType.GOOGLE_RESULT:
        this.handleGooglePlace(event.payload!.key, event.payload?.searchTerm);
        break;
      case SearchRequestType.HISTORY:
        if (!event.payload?.url) {
          return;
        }
        this.handleGooglePlace(event.payload.url, event.payload?.searchTerm);
        break;
      case SearchRequestType.SAVED_SEARCH:
        // Saved Searches has exact url string including query parameters.
        // So there is no need to call searchStore.routeChange method.
        this.router.navigateByUrl(event.payload!.url!);
        break;
      default:
        // should never happen
        throw new Error('Unknown event type');
    }
  }

  private handleGooglePlace(place_id: string, term: string = '') {
    this.googleApiService
      .getGooglePlaceDetails(place_id)
      .pipe(take(1))
      .subscribe(googleResult => {
        const url = googlePlacesToUrl(googleResult);
        if (googleResult && url) {
          this.searchStore.searchHistoryChange({ label: googleResult.formatted_address ?? '', value: place_id });
          const searchTerm = googlePlacesToLocation(googleResult) || term;

          let queryParams: Pick<Models.SearchPageQueryParams, 'search' | 'map'> = {
            search: { placeId: place_id, term: searchTerm },
            map: { viewportBounds: googleResult.geometry?.viewport?.toJSON() },
          };
          if (this.routeOptions) {
            queryParams = { ...queryParams, ...this.routeOptions };
          }
          // Only reload route if NOT on houses route
          this.searchStore.routeChange('/' + this.searchStore.searchRouteSlug + url, {
            replaceQueryParams: this.replaceQueryParams,
            queryParams,
          });
        } else {
          this.error();
        }
      });
  }
}
