import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { NtsWizard } from '@ntersol/wizard';
import { BehaviorSubject, map, mergeMap, pairwise, startWith, take, tap } from 'rxjs';
import { DomService } from '../../shared/services';
import { listenForMediumScreen } from '../../shared/utils';

interface SectionState extends NtsWizard.SectionState {
  title?: string | null;
}

@Component({
  selector: 'app-wizard-nav',
  templateUrl: './wizard-nav.component.html',
  styleUrls: ['./wizard-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardNavComponent {
  @Input() routerLinkBase: string | null = '';
  /** Can the user go to any section or only in one direction */
  @Input() linear = this.dom?.window?.location.host !== 'localhost:4200';

  /** Wizard sections for this nav */
  @Input() set sections(sections: NtsWizard.Section[] | null) {
    this.sections$.next(
      (sections || []).map((s, i) => {
        const isFirst: boolean = i === 0 ? true : false;

        // Convert sections into section state
        return Object.assign(
          {},
          { active: isFirst, completed: false, sectionId: s.urlSlug, completedDate: null, routeHistory: [], started: isFirst, startedDate: null },
          s,
        ) as NtsWizard.SectionState;
      }),
    );
  }

  @Input() hideMenuCheckMark: boolean = false;
  public listenForMediumScreen$ = listenForMediumScreen();

  private sections$ = new BehaviorSubject<SectionState[] | null>(null);

  /** Looks at the url and compares to section state to update status */
  public sectionsState$ = this.route.params.pipe(
    startWith({} as Params),
    pairwise(), // Get previous and current emissions
    mergeMap(([prev, current]) =>
      this.sections$.pipe(
        map(sections => {
          // Get all previous sections from the PREVIOUS url
          // This is used to detect when a user goes back without completing the current section
          // If that happens, do not mark the latest section complete
          const prevSections: string[] = [];
          for (let index = 0; index < (sections ?? []).length; index++) {
            const active = (sections ?? [])[index];
            if (active.sectionId === prev['sectionUrl']) {
              break;
            } else {
              prevSections.push(active.sectionId);
            }
          }
          return (sections || [])
            .map(s => ({ ...s })) // New ref to avoid mutation
            .map(section => {
              // If a user goes forward in the section advancement, mark current section complete
              if (section.sectionId === prev['sectionUrl'] && !prevSections.includes(current['sectionUrl'])) {
                section.completed = true;
              }
              // If url matches currect section, mark active and set started
              if (section.sectionId === current['sectionUrl']) {
                section.active = true;
                section.started = true;
              } else {
                section.active = false; // Set all others to inactive
              }
              return section;
            });
        }),
        take(1), // Prevent hot obv loop
      ),
    ),
    tap(sections => this.sections$.next(sections)), // Update source section with updated status
  );

  public locationClasses$ = this.sections$.pipe(
    map(sections => {
      const activeSection = sections?.find(s => s.active) ?? null;
      return !activeSection ? '' : `section-${activeSection.sectionId} route-${activeSection.routeStart}`;
    }),
  );

  constructor(private route: ActivatedRoute, private dom: DomService) {}
}
