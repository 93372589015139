import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthorizenetComponent } from './authorize-net.component';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    AuthorizenetComponent,
  ],
  providers: [],
  exports: [
    AuthorizenetComponent
  ]
})
export class AuthorizenetModule { }
