<div class="bed-bath-container" [ngClass]="{ mobile: isMobile }" (clickOutside)="isOpen = false">
  <div class="input" (click)="isOpen = !isOpen">
    <div>
      <span class="title ml-5 mr-5">More</span>
      <span class="selected-amount ml-10">({{ numberOfSelected$ | async }})</span>
    </div>
    <fa-icon class="ml-15" [class]="{ flip: isOpen }" icon="angle-down"></fa-icon>
  </div>

  <div class="options-container" *ngIf="isOpen">
    <div class="options pb-3">
      <form [formGroup]="moreFormGroup">
        <p class="mb-2 section-header">All Purchase Options</p>
        <div formArrayName="FINANCING">
          <div *ngFor="let _ of FINANCING.controls; index as i; last as isLast" [ngClass]="{ 'mb-4': isLast, 'mb-2': !isLast }">
            <p-checkbox
              name="FINANCING"
              label="{{ i === 0 ? 'Financing Allowed' : 'Cash Only' }}"
              name="{{ i === 0 ? 'financing' : 'cash' }}"
              formControlName="{{ i }}"
              [binary]="true"></p-checkbox>
          </div>
        </div>
        <p class="mb-2" section-header>Occupancy</p>
        <div formArrayName="OCCUPANCY">
          <div *ngFor="let _ of OCCUPANCY.controls; index as i; last as isLast" [ngClass]="{ 'mb-4': isLast, 'mb-2': !isLast }">
            <p-checkbox
              name="OCCUPANCY"
              label="{{ i === 0 ? 'Vacant' : i === 1 ? 'Occupied' : 'Unknown' }}"
              formControlName="{{ i }}"
              value="{{ i === 0 ? 'Vacant' : i === 1 ? 'Occupied' : 'Unknown' }}"></p-checkbox>
          </div>
        </div>

        <p class="mb-2" section-header>Additional Options</p>
        <div class="mb-2"><p-checkbox label="Reserve Met" formControlName="RESERVEMET" [binary]="true"></p-checkbox></div>
        <div class="mb-2"><p-checkbox label="Ending Today" formControlName="ENDINGTODAY" [binary]="true"></p-checkbox></div>
        <div class="mb-2"><p-checkbox label="Post Auctions" formControlName="POSTAUCTIONS" [binary]="true"></p-checkbox></div>
        <div class="mb-4"><p-checkbox label="No Buyer Premium" formControlName="NOBUYERPREMIUMS" [binary]="true"></p-checkbox></div>
      </form>
    </div>
  </div>
</div>
