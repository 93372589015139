import { statesLowerCase } from '../../../data/states.data';
import { SearchModels } from '../search.models';

/**
 * Convert the google places result to a uniform search term
 * @param place
 * @returns
 */
export const googlePlacesToLocation = (place?: google.maps.places.PlaceResult | null): string | null => {
  if (!place) {
    return null;
  }
  // Extract location info from google palces
  const state = place.address_components?.filter(p => p.types.includes('administrative_area_level_1'))[0] ?? null;
  const county = place.address_components?.filter(p => p.types.includes('administrative_area_level_2'))[0] ?? null;
  const city = place.address_components?.filter(p => p.types.includes('locality'))[0] ?? null;
  const zip = place.address_components?.filter(p => p.types.includes('postal_code'))[0] ?? null;
  // Street info
  const streetNum = place.address_components?.filter(p => p.types.includes('street_number'))[0] ?? null;
  const street = place.address_components?.filter(p => p.types.includes('route'))[0] ?? null;

  let location: string | null = null;

  if (state && county && city && zip && street && streetNum) {
    location = `${streetNum.short_name} ${street.short_name} ${city.short_name}, ${state.short_name} ${zip.short_name}`;
  } else if (state && county && city && zip) {
    location = `${city.short_name}, ${state.short_name} ${zip.short_name}`;
  } else if (state && county && city) {
    location = `${city.short_name}, ${state.short_name}`;
  } else if (state && county) {
    location = `${county.short_name}, ${state.short_name}`;
  } else if (state) {
    location = `${state.long_name}`;
  }

  return location ? location : null;
};

/**
 * Convert the google places result to a Location object
 * @param place
 * @returns
 */
export const googlePlacesToLocationObject = (place?: google.maps.places.PlaceResult | null): SearchModels.Location | null => {
  if (!place) {
    return null;
  }
  // Extract location info from google palces
  const state = place.address_components?.filter(p => p.types.includes('administrative_area_level_1'))[0] ?? null;
  const county = place.address_components?.filter(p => p.types.includes('administrative_area_level_2'))[0] ?? null;
  const city = place.address_components?.filter(p => p.types.includes('locality'))[0] ?? null;
  const zip = place.address_components?.filter(p => p.types.includes('postal_code'))[0] ?? null;
  // Street info
  const streetNum = place.address_components?.filter(p => p.types.includes('street_number'))[0] ?? null;
  const street = place.address_components?.filter(p => p.types.includes('route'))[0] ?? null;

  let location: SearchModels.Location = {};

  if (street && streetNum) {
    location.address = `${streetNum.short_name} ${street.short_name}`;
  }
  if (zip) {
    location.zip = zip.short_name;
  }
  if (city) {
    location.city = city.short_name;
  }
  if (county) {
    location.county = county.short_name;
  }
  if (state) {
    location.state = state.short_name;
  }

  return location ? location : null;
};

/**
 * Modify the search term before sending it to google places
 * @param str
 * @returns
 */
export const biasSearchResults = (str: string) => {
  // If the search is a state, append the word "state" to ensure a state result is returned
  // Otherwise a search for "wa" won't actually return washington state
  if (statesLowerCase.includes(str.toLowerCase())) {
    return str + ' state';
  }
  return str;
};
