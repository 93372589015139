import { MetaDefinition } from '@angular/platform-browser';
import { environment } from '$env';
import { SearchModels } from '../models/search.models';
import { searchLocationToSearchTerm } from './search-location-to-searchterm.util';
import { toTitleCase } from './text.util';
import { getLongStateName } from '../../../../shared/utils/us-states-converter.utils';

/**
 * Convert search location into a page title
 * @param loc
 * @param append
 * @returns
 */
export const searchLocationToPageTitle = (loc?: SearchModels.Location | null, append = ' | ' + environment.properties.appName) => {
  const defaultTitle = `Real Estate Auctions & Foreclosure Homes In ` + append;

  const term = searchLocationToSearchTerm(loc);
  if (!loc || !term) {
    return defaultTitle;
  }
  return `Real Estate Auctions & Foreclosure Homes In ${term}` + append;
};

/**
 * Generate a page title for prop details
 * @param loc
 * @param append
 * @returns
 */
export const searchLocationPropDetailsPageTitle = (loc?: SearchModels.Location | null, append = ' | ' + environment.properties.appName) => {
  return `${toTitleCase(loc?.address)} ${searchLocationToSearchTerm(loc)} House Auction` + append;
};

/**
 * Generate meta description for prop details
 * @param loc
 * @param append
 * @returns
 */
export const searchLocationPropDetailsMetaDescription = (loc?: SearchModels.Location | null, append = ' | ' + environment.properties.appName) => ({
  name: 'description',
  content: `Property details for ${toTitleCase(loc?.address)}, ${searchLocationToSearchTerm(loc)} House Auction` + append,
});

/**
 * Generate the meta description from the search location
 * @param loc
 * @returns
 */
export const searchLocationToMetaDescription = (loc?: SearchModels.Location | null): MetaDefinition => {
  if (!loc || !loc.state)
    return {
      name: 'description',
      content: 'Browse hundreds of houses for auction across the United States through RealtyBid`s online real estate portal. Get started now.',
    };

  return {
    name: 'description',
    content: `Browse real estate auctions and foreclosure listings in ${convertLocationToSeoString(
      loc,
    )}. See something you like? Register and bid on an online auction now or prepare for the live foreclosure sale that interests you.`,
  };
};

/**
 * Generate the meta keywords from the search location
 * @param loc
 * @returns
 */
export const searchLocationToMetaKeywords = (loc?: SearchModels.Location | null): MetaDefinition => ({
  name: 'keywords',
  content:
    `foreclosures, bank owned, auctions, real estate, ` +
    [loc?.zip, loc?.city, loc?.county, loc?.state]
      .filter(x => x)
      .map(x => toTitleCase(x))
      .join(', '),
});

function convertLocationToSeoString(loc: SearchModels.Location): string | null {
  if (!loc.state) return null;

  if (loc.address) {
    return `${getLongStateName(loc.state)}, ${loc.city}, ${loc.county}, ${loc.zip}, ${loc.address}`;
  } else if (loc.zip) {
    return `${getLongStateName(loc.state)}, ${loc.city}, ${loc.county}, ${loc.zip}`;
  } else if (loc.city) {
    return `${getLongStateName(loc.state)}, ${loc.city}, ${loc.county}`;
  } else if (loc.county) {
    return `${getLongStateName(loc.state)}, ${loc.county}`;
  } else {
    return getLongStateName(loc.state);
  }
}
