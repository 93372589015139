import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ClickOutsideDirective, FocusDirective, FullScreenDirective, ShowOnScreenSizeDirective, HoverOverDirective, UploadDirective } from './directives';
import {
  CastPipe,
  CountPipe,
  DayjsFormatPipe,
  DebouncePipe,
  DurationPipe,
  FilterPipe,
  HtmlRemovePipe,
  LimitPipe,
  MatchesCategoryTypesPipe,
  PhoneNumberPipe,
  SafeHtmlPipe,
  SlugPipe,
  SortPipe,
  StringPipe,
  TextCasePipe,
} from './pipes';
import { RemoveHtmlPipe } from './pipes/remove-html.pipe';

// Pipes, Angular
// Pipes, Custom
// Directives

// Pipes + Directives
export const APP_PIPES_DIRECTIVES = [
  // Pipes
  CountPipe,
  DebouncePipe,
  PhoneNumberPipe,
  DurationPipe,
  FilterPipe,
  HtmlRemovePipe,
  SafeHtmlPipe,
  SlugPipe,
  SortPipe,
  StringPipe,
  TextCasePipe,
  LimitPipe,
  RemoveHtmlPipe,
  CastPipe,
  MatchesCategoryTypesPipe,
  DayjsFormatPipe,

  // Directives
  FullScreenDirective,
  FocusDirective,
  ClickOutsideDirective,
  HoverOverDirective,
  UploadDirective,
  ShowOnScreenSizeDirective,
];

@NgModule({
  imports: [
    // Angular
    CommonModule,
  ],
  providers: [DatePipe, CurrencyPipe],
  declarations: [APP_PIPES_DIRECTIVES],
  exports: [APP_PIPES_DIRECTIVES],
  entryComponents: [],
})
export class SharedModule {}
