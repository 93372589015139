import { Directive, Output, EventEmitter, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[hovered]',
})
export class HoverOverDirective<T> {
  @Input() hoverId?: T;
  @Output() hover = new EventEmitter<T>();
  @Output() leave = new EventEmitter<T>();

  @HostListener('mouseenter') onMouseEnter() {
    this.hover.emit(this.hoverId);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.leave.emit(this.hoverId);
  }
}
