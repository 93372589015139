import { Models } from '$models';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseBuySellList } from '../base-buy-sell-list.component';

@Component({
  selector: 'app-desktop-buy-sell-list',
  templateUrl: './desktop-buy-sell-list.component.html',
  styleUrls: ['./desktop-buy-sell-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DesktopBuySellListComponent extends BaseBuySellList {
  @Input() isLoggedIn: boolean | null = false;
  @Input() items: Models.MenuItemGroup[] | null = [];
  redirect(url: string, command?: () => any) {
    if (command) {
      command();
    }
    this.dom.window!.location!.href = url;
  }
}
