<div class="place-bid-from-form">
  <form [formGroup]="form" [ngClass]="{ 'show-errors-on-touched': errorCondition.touch }">
    <!-- GROUP: I am bidding for an existing customer -->

    <div class="form-group" [class.active]="isExistingCustomerSelected">
      <p class="group-selector" formGroupName="bidTarget">
        <p-radioButton label="I am bidding for an existing customer" [value]="bidTargets.existingCustomer" formControlName="RADIO"></p-radioButton>
      </p>
      <div class="p-fluid group-content dropdown" formGroupName="existingCustomer">
        <p-dropdown
          appendTo="body"
          [options]="(customers$ | async) ?? []"
          id="CUSTOMER_ID"
          formControlName="CUSTOMER_ID"
          placeholder=" -- Select Customer --"
          [showTransitionOptions]="'100ms'"
          [hideTransitionOptions]="'50ms'"
          [showClear]="true"></p-dropdown>
        <app-input-error controlName="CUSTOMER_ID" [errorCondition]="errorCondition" friendlyName="Customer" customErrorMessage="Required"></app-input-error>
      </div>
    </div>

    <!-- GROUP: I am bidding for a new customer -->

    <div class="form-group" [class.active]="isNewCustomerSelected">
      <p class="group-selector" formGroupName="bidTarget">
        <p-radioButton label="I am bidding for a new customer" [value]="bidTargets.newCustomer" formControlName="RADIO"></p-radioButton>
      </p>
      <div class="group-content p-fluid row">
        <app-add-edit-customer
          [showSubTitle]="false"
          [inputStyles]="addEditCustomerFormInputStyles"
          (formReady)="onEditCustomerFormReady($event)"
          (formValuesChanged)="onEditCustomerFormValuesChanged($event)"></app-add-edit-customer>
      </div>
    </div>

    <!-- GROUP: I am bidding for myself -->

    <div class="form-group" [class.active]="isSelfCustomerSelected" formGroupName="bidTarget">
      <p-radioButton label="I am bidding for myself" [value]="bidTargets.selfCustomer" formControlName="RADIO"></p-radioButton>
    </div>
  </form>
</div>
