import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, combineLatest, of, catchError, Observable, map, debounceTime } from 'rxjs';

import { Models } from '$models';
import { SettingsService } from '$settings';
import { ModalWizardModels } from 'src/app/components/modal-wizard/shared/models/modal-wizard.model';
import { BiddingModels } from '../../shared/models/bidding.model';
import { BiddingStoreService, BiddingStoreKey } from '../../store/bidding.store';
import { BiddingService } from '../../shared/services/bidding.service';

@UntilDestroy()
@Component({
  selector: 'app-confirm-bid-step-content',
  template: ` <app-confirm-bid (bidPayloadChanged)="onBidPayloadChanged($event)"> </app-confirm-bid> `,
  styles: [
    `
      .confirm-bid .section {
        margin-top: 1.5rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidConfirmStepContentComponent implements ModalWizardModels.StepContent, OnInit {
  @Input() stepId = 'confirm-bid-step';

  private user: Models.LoginResponse | null = null;
  private property: Models.PropertyDetailsResponse | null = null;
  private bidDeposit: Models.AddBidDepositRequest | null = null;
  private bidForData: BiddingModels.PlaceBidForFormModel | null = null;
  private biddingPayload: BiddingModels.BidddingRequest | null = null;

  constructor(private settings: SettingsService, private biddingStore: BiddingStoreService, private biddingService: BiddingService) {}

  ngOnInit(): void {
    this.watchForRequiredBidDataChanged();
  }

  onBidPayloadChanged(payload: BiddingModels.BidddingRequest | null): void {
    this.biddingPayload = payload;
  }

  canEntry(args: ModalWizardModels.StepValidationArgs) {
    return true;
  }

  canExit(args: ModalWizardModels.StepValidationArgs) {
    // If going backward, allow it
    if (args.direction === ModalWizardModels.StepDirection.backward) {
      return of(true);
    }
    // Preocess data and add bid
    if (!this.user || !this.property || !this.biddingPayload) {
      return of(true);
    }
    return this.biddingService.processAddBid(this.biddingPayload, this.bidForData, this.bidDeposit).pipe(
      map(this.handleBidSuccess.bind(this)),
      catchError(error => this.handleBidError(error, args)),
      debounceTime(10),
    );
  }

  private handleBidSuccess(response: any): Observable<any> {
    // Update bidding result to success to display "Bidding Success" message in the next step.
    this.biddingStore.update({
      [BiddingStoreKey.bidResult]: BiddingModels.BiddingResult.success,
      [BiddingStoreKey.bidResultMessage]: response?.MESSAGE || 'Successful Bid!',
    });
    // Allow moving to the next step to display "Success" message
    return of(true);
  }

  private handleBidError(error: any, args: ModalWizardModels.StepValidationArgs): Observable<boolean> {
    // If unauthorized or forbidden error, close modal popup.
    if (error.status === 401 || error.status === 403) {
      // Since interceptor redirects user to login page, closing modal popup.
      args.wizardRef.complete();
      return of(true);
    }
    // Update bidding result to failed to display "Bidding Failed" message in the next step.
    this.biddingStore.update({
      [BiddingStoreKey.bidResult]: BiddingModels.BiddingResult.failed,
      [BiddingStoreKey.bidResultMessage]: error?.error?.MESSAGE || error?.message,
    });
    // Allow moving to the next step to display "Failed" message
    return of(true);
  }

  /**
   * Watching bid-related data changes
   */
  private watchForRequiredBidDataChanged(): void {
    combineLatest([
      this.settings.user$,
      this.biddingStore.propertyDetails.state$,
      this.biddingStore.getSelector(BiddingStoreKey.bidDeposit),
      this.biddingStore.getSelector(BiddingStoreKey.bidForData),
    ])
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe({
        next: ([user, property, bidDeposit, bidForData]) => {
          this.user = user;
          this.property = property.data;
          this.bidDeposit = bidDeposit;
          this.bidForData = bidForData;
        },
        error: () => {
          this.user = null;
          this.property = null;
          this.bidDeposit = null;
          this.bidForData = null;
        },
      });
  }
}
