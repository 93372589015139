<ng-container
  *ngIf="{
    term: queryParamsSearchTermInit$ | async
  } as componentData">
  <app-location-search-input
    [searchInputFieldPlaceholder]="searchTitle"
    [searchTerm]="componentData.term || ''"
    [enableSmallDropDownOnHighResolutionScreen]="enableSmallDropDownOnHighResolutionScreen"
    (selected)="onSelected($event)"
    [showAdvancedSearchDropdown]="showAdvancedSearchDropdown"
    [advancedSearchMobileRoutingPath]="advancedSearchMobileRoutingPath"></app-location-search-input>
</ng-container>
