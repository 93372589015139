import { Component, ChangeDetectionStrategy } from '@angular/core';
import { map } from 'rxjs';
import { BiddingModels } from '../../shared/models/bidding.model';
import { BiddingStoreService, BiddingStoreKey } from '../../store/bidding.store';

@Component({
  selector: 'app-bid-result',
  templateUrl: './bid-result.component.html',
  styleUrls: ['./bid-result.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidResultComponent {

  biddingResult = BiddingModels.BiddingResult;

  property$ = this.biddingStoreService.propertyDetails.state$.pipe(
    map(state => state.data)
  );
  bidData$ = this.biddingStoreService.getSelector(BiddingStoreKey.bidData);
  result$ = this.biddingStoreService.getSelector(BiddingStoreKey.bidResult);
  message$ = this.biddingStoreService.getSelector(BiddingStoreKey.bidResultMessage);

  constructor(private biddingStoreService: BiddingStoreService) { }
}
