<div class="cov-dropdown" [ngClass]="{ mobile: isMobile }">
  <div class="input" [class]="{ open: selectedDropDownName === formPropertyName }" (click)="displayField(formPropertyName)">
    <div class="flex">
      <div class="title ml-10 mr-5">
        <span *ngFor="let word of splitWords(title); let i = index" [class]="'word-' + (i + 1)">{{ word }}</span>
      </div>
      <span class="selected-amount">({{ selectedItems || selectedAmount }})</span>
    </div>
    <fa-icon class="ml-15" [class]="{ flip: selectedDropDownName === formPropertyName }" icon="angle-down"></fa-icon>
  </div>

  <ng-container *ngIf="selectedDropDownName === formPropertyName">
    <div class="options-container">
      <div class="options" [ngClass]="formPropertyName" (clickOutside)="closeDropdown()">
        <div [class]="{ 'nested-group': groupedOptions.length > 1 }" *ngFor="let group of groupedOptions; let groupIdx = index">
          <span *ngIf="group.subTitle" class="sub-title ml-10">{{ group?.subTitle }}</span>
          <div *ngFor="let opt of group?.options; let optIdx = index">
            <div class="option ml-5 mr-5" (click)="selected(opt, groupIdx, optIdx)" [class]="{ selected: opt?.selected }">
              <input type="checkbox" [checked]="opt?.selected" class="ml-5 mr-10" />
              <span>{{ opt?.label }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>
