import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export enum TermsAgreeViewType {
  NORMAL = 'normal',
  INTRO = 'intro',
  ACTION = 'action'
}

@Component({
  selector: 'app-agree-terms',
  templateUrl: './agree-terms.component.html',
  styleUrls: ['./agree-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AgreeTermsComponent {

  @Input() isExtraCondition = false;
  @Input() view: TermsAgreeViewType = TermsAgreeViewType.NORMAL;

  TermsAgreeViewType = TermsAgreeViewType;

  constructor() { }
}
