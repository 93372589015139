import { Injectable } from '@angular/core';
import { vipLevelAssetsMapping, VipLevel } from '../../../../shared/models/assets.model';

export interface ThemeData {
  mainColor: string;
  vipStateBackgroundColor: string;
  currentBadgePath: string;
  altImageText: string;
  headerText: string;
  vipStateText: string;
}

@Injectable({
  providedIn: 'root',
})
export class VipLevelBannerService {
  getVipLevelUiData(level: VipLevel): ThemeData {
    switch (level) {
      case VipLevel.BRONZE:
        return {
          mainColor: vipLevelAssetsMapping[VipLevel.BRONZE].color,
          vipStateBackgroundColor: '#A9A1741a',
          currentBadgePath: vipLevelAssetsMapping[VipLevel.BRONZE].path,
          altImageText: vipLevelAssetsMapping[VipLevel.BRONZE].alt,
          headerText: 'Congratulations, you are a BRONZE level VIP!',
          vipStateText: 'SILVER status is {O} properties away!',
        };
      case VipLevel.SILVER:
        return {
          mainColor: vipLevelAssetsMapping[VipLevel.SILVER].color,
          vipStateBackgroundColor: '#9193951a',
          currentBadgePath: vipLevelAssetsMapping[VipLevel.SILVER].path,
          altImageText: vipLevelAssetsMapping[VipLevel.SILVER].alt,
          headerText: 'Congratulations, you are a SILVER level VIP!',
          vipStateText: 'GOLD status is {O} properties away!',
        };
      case VipLevel.GOLD:
        return {
          mainColor: vipLevelAssetsMapping[VipLevel.GOLD].color,
          vipStateBackgroundColor: '#A9A1741a',
          currentBadgePath: vipLevelAssetsMapping[VipLevel.GOLD].path,
          altImageText: vipLevelAssetsMapping[VipLevel.GOLD].alt,
          headerText: 'Congratulations, you are a GOLD level VIP!',
          vipStateText: 'PREMIUM status is {O} properties away!',
        };
      case VipLevel.PREMIUM:
        return {
          mainColor: vipLevelAssetsMapping[VipLevel.PREMIUM].color,
          vipStateBackgroundColor: '#2161921a',
          currentBadgePath: vipLevelAssetsMapping[VipLevel.PREMIUM].path,
          altImageText: vipLevelAssetsMapping[VipLevel.PREMIUM].alt,
          headerText: 'Congratulations, you are a PREMIUM level VIP!',
          vipStateText: 'PREMIUM PRO status is {O} properties away!',
        };
      case VipLevel.PREMIUMPRO:
        return {
          mainColor: vipLevelAssetsMapping[VipLevel.PREMIUMPRO].color,
          vipStateBackgroundColor: '#DF29271a',
          currentBadgePath: vipLevelAssetsMapping[VipLevel.PREMIUMPRO].path,
          altImageText: vipLevelAssetsMapping[VipLevel.PREMIUMPRO].alt,
          headerText: 'Congratulations, you are a PREMIUM PRO level VIP!',
          vipStateText: 'You`ve reached PREMIUM PRO status!',
        };
      default:
        throw new Error('Unknown investor VIP level');
    }
  }
}
