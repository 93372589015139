import { CssBreakpoint } from '$utils';
import { Directive, HostListener, Input, TemplateRef, ViewContainerRef } from '@angular/core';

export enum ScreenSize {
  Mobile = 'mobile',
  Desktop = 'desktop',
}

@Directive({
  selector: '[appShowOnScreenSize]',
})
export class ShowOnScreenSizeDirective {
  private _size?: string;

  @Input() set appShowOnScreenSize(size: string) {
    if (!size) {
      throw new Error('appShowOnScreenSize directive needs a size definition');
    }
    this._size = size;
    this.showElement();
  }

  sizes = ScreenSize;

  constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.showElement();
  }

  private showElement(): void {
    const isLargeScreen = window.innerWidth >= CssBreakpoint.lg;

    if ((this._size === this.sizes.Desktop && isLargeScreen) || (this._size === this.sizes.Mobile && !isLargeScreen)) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
