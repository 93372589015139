import { AuthService, AuthState, DomService } from '$services';
import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, filter, map } from 'rxjs';

export interface LoginStorageData {
  login: {
    email: string;
    remember: boolean;
  };
}

enum TAB {
  LOGIN = 0,
  SIGN_UP = 1,
}

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit, OnDestroy {
  _displayHeader = true;

  @Input() set displayHeader(data: boolean) {
    this._displayHeader = data;
  }

  TAB = TAB;
  currentTab$ = new BehaviorSubject<TAB>(TAB.LOGIN);
  public hasLoggedInOnce$ = this.auth.hasLoggedInOnce$;
  signupLogin: string | null = null;

  public readonly navigationState = this.router.getCurrentNavigation()?.extras.state;

  /** Generate a custom message based on requested route */
  public customMessage$ = this.route.queryParams.pipe(
    map(params => {
      const requestedUrl = params['requestedUrl'];
      if (params['currentUrl']?.includes('watchlist') || params['target']?.includes('watchlist')) {
        return 'Please log in or sign up to have access to the Saved Properties feature.';
      }

      if (requestedUrl === '/my-profile/alerts-notifications/' || params['currentUrl'] === '/my-profile/alerts-notifications') {
        return 'Please log in or sign up to have access to the Notifications feature.';
      }
      if (requestedUrl === '/saved-properties/' || params['currentUrl'] === '/saved-properties') {
        return 'Please log in or sign up to have access to the Saved Properties feature.';
      }
      if (params['target']?.includes('bidding')) {
        return 'Please login or sign up to have access to bid on listed properties.';
      }

      if (!requestedUrl) {
        return null;
      }

      return null;
    }),
  );

  get loginTabTitle(): string {
    const target = this.route.snapshot.queryParams['target'];
    const currentUrl = this.route.snapshot.queryParams['currentUrl'];
    if (target || currentUrl === '/saved-properties/' || currentUrl === '/my-profile/alerts-notifications/') {
      return 'Before you continue...';
    }
    return 'Welcome back!';
  }

  get loginTabSubTitle(): string {
    const target = this.route.snapshot.queryParams['target'];

    if (this.route.snapshot.queryParams['currentUrl'] === '/saved-properties/') {
      return 'Please log in or sign up to have access to the Saved Properties feature.';
    }

    if (this.route.snapshot.queryParams['currentUrl'] === '/my-profile/alerts-notifications/') {
      return 'Please log in or sign up to have access to the Notifications feature.';
    }

    switch (target) {
      case 'bidding':
        return 'Please login or sign up to have access to bid on listed properties.';
      case 'watchlist':
        return 'Please log in or sign up to have access to the Saved Properties feature.';
      case 'alerts-notifications':
        return 'Please log in or sign up to have access to the Notifications feature.';
      default:
        return 'Please enter your details below to get started.';
    }
  }

  get singupTabTitle(): string {
    const target = this.route.snapshot.queryParams['target'];
    if (target) {
      return 'Before you continue...';
    }
    return 'Welcome to RealtyBid';
  }

  get signupTabSubTitle(): string {
    const target = this.route.snapshot.queryParams['target'];
    switch (target) {
      case 'bidding':
        return 'Please login or sign up to have access to bid on listed properties.';
      default:
        return 'Create an account to start buying and selling through RealtyBid.com today.';
    }
  }

  constructor(private readonly router: Router, private readonly route: ActivatedRoute, private readonly dom: DomService, private readonly auth: AuthService) {
    this.listenToNavigationEvents();
  }

  ngOnInit() {
    this.auth.authState = AuthState.loggedOut;
  }

  /** When user changes tab, navigate to matching url */
  handleTabChanged(tab: TAB, signupLogin?: string | null) {
    if (!this._displayHeader) return;
    this.signupLogin = signupLogin || null;
    const route = tab === TAB.LOGIN ? '/login' : '/sign-up';
    this.router.navigate([route], { queryParamsHandling: 'preserve' });
  }

  public setCurrentTab(tab: TAB, signupLogin?: string | null) {
    this.currentTab$.next(tab);
    this.signupLogin = signupLogin || null;
  }

  /** Listen to router events and use url to determine tab to display */
  private listenToNavigationEvents() {
    this.router.events.pipe(untilDestroyed(this)).subscribe(x => {
      if (x instanceof NavigationEnd) {
        this.setTabFromUrl(x.url);
      }
    });
  }

  private setTabFromUrl(url: string) {
    const tab = this.urlToTab(url);
    // Avoid change after checked error with setTimeout
    this.dom.window?.setTimeout(() => this.setCurrentTab(tab), 0);
  }

  private urlToTab(url: string): TAB {
    if (url.startsWith('/sign-up')) {
      return TAB.SIGN_UP;
    }
    return TAB.LOGIN;
  }

  ngOnDestroy(): void {}
}
