<div class="container">
  <div class="row mt-4">
    <div class="col">
      <app-breadcrumbs [breadCrumbs]="breadCrumbs" [breadcrumbPrepend]="{ label: 'Home', url: '/' }"></app-breadcrumbs>
    </div>
  </div>
  <div class="row">
    <div class="col mb-3 position-relative" [ngClass]="actionsMobileClasses?.join(' ')">
      <ng-content select="[action]"></ng-content>
      <div class="mt-4 mb-3" *ngIf="!heading">
        <ng-content select="[custom-header]"></ng-content>
      </div>
      <h1 *ngIf="heading" class="mt-4 mb-3 h2">{{ heading }}</h1>
      <div class="header-right-container">
        <ng-content select="[header-right-container]"></ng-content>
      </div>
    </div>
    <p-divider styleClass="m-0 gray-300"></p-divider>
  </div>
  <div *ngIf="description" class="row">
    <div class="description col-12 col-lg-9 col-xxl-7">
      {{ description }}
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ng-content></ng-content>
    </div>
  </div>
</div>
