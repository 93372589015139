import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHtml',
})
export class RemoveHtmlPipe implements PipeTransform {
  transform(value: string | null | undefined): string {
    return value?.replace(/<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/gi, '').trimStart() || '';
  }
}
