import { Injectable } from '@angular/core';
import { NtsStateManagementService } from '@ntersol/state-management';
import { MapModels } from '../..';

export const viewportCentralUS: google.maps.LatLngBoundsLiteral = {
  north: 49.382808,
  south: 24.521208,
  west: -124.736342,
  east: -66.945392,
};

const viewportWholeUS: google.maps.LatLngBoundsLiteral = {
  north: 72,
  south: 10,
  west: -171,
  east: -62,
};

const initialState: MapModels.State = {
  options: {
    disableDefaultUI: true,
    zoomControl: false,
    gestureHandling: 'greedy',
    maxZoom: 20, // street level
    minZoom: 4, // whole US
    restriction: { latLngBounds: viewportWholeUS, strictBounds: false },
  },
};

@Injectable({
  providedIn: 'root',
})
export class MapStoreService {
  /** Map UI store */
  private store = this.sm.createUIStore<MapModels.State>(initialState, {});

  public options$ = this.store.select$('options');

  constructor(private sm: NtsStateManagementService) {
    // Do not put logic in here, use initialize method instead
  }

  /**
   * Update store state
   * @param state
   */
  public update(state: Partial<MapModels.State>) {
    this.store.update({ ...state });
  }

  public reset() {
    this.store.update({ ...initialState });
  }
}
