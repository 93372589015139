import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { DomService } from '../../../shared/services';
import { listenForLargeScreen } from '../../../shared/utils';

@UntilDestroy()
@Component({
  selector: 'app-mobile-layout',
  templateUrl: './mobile-layout.component.html',
  styleUrls: ['./mobile-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileLayoutComponent implements OnInit {
  constructor(private dom: DomService) {}

  ngOnInit(): void {
    listenForLargeScreen()
      .pipe(
        tap(isLarge => {
          if (isLarge) {
            this.dom.window?.history.back();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }
}
