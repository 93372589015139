<ng-container
  *ngIf="{
    largeScreenDownObserver: largeScreenDownObserver$ | async
  } as componentData">
  <a
    *ngIf="property"
    class="container property-card"
    [ngStyle]="{ 'pointer-events': showOverlay ? 'none' : 'cursor' }"
    [ngClass]="{
      'vertical': orientation === 'vertical',
      'horizontal': orientation === 'horizontal',
      'mobile': componentData.largeScreenDownObserver,
      'on-map': onMap
    }"
    (click)="navigateTo($event)">
    <div class="overlay" *ngIf="showOverlay">
      <div class="text-center" *ngIf="property.STATUS === 'Withdrawn'">Property Withdrawn</div>
      <div class="text-center" *ngIf="property.STATUS === 'Pending 2nd Chance' || property.STATUS === 'Returned'">
        Temporarily Unavailable<br />
        <small> Check back soon </small>
      </div>
    </div>
    <div class="content-main">
      <!-- Vertical layout -->
      <ng-container *ngIf="orientation === 'vertical'">
        <ng-template [ngTemplateOutlet]="images"></ng-template>
        <div class="content" *ngIf="isTypeDefault">
          <ng-template [ngTemplateOutlet]="footerDetails"></ng-template>
          <ng-template [ngTemplateOutlet]="auction" [ngTemplateOutletContext]="{ labelConfig: 'short' }"></ng-template>
        </div>
        <div class="content" *ngIf="isTypeAuction">
          <ng-template [ngTemplateOutlet]="auction" [ngTemplateOutletContext]="{ labelConfig: 'short' }"></ng-template>
          <ng-template [ngTemplateOutlet]="bid"></ng-template>
          <ng-template [ngTemplateOutlet]="footerDetails"></ng-template>
        </div>
        <ng-template [ngTemplateOutlet]="saved"></ng-template>
      </ng-container>

      <!-- Horizontal layout -->
      <ng-container *ngIf="orientation === 'horizontal'">
        <ng-template [ngTemplateOutlet]="images"></ng-template>
        <div class="content" *ngIf="isTypeDefault">
          <ng-template [ngTemplateOutlet]="footerDetails"></ng-template>
          <ng-template [ngTemplateOutlet]="auction" [ngTemplateOutletContext]="{ labelConfig: onMap ? 'short' : 'long' }"></ng-template>
        </div>
        <div class="content" *ngIf="isTypeAuction">
          <ng-template [ngTemplateOutlet]="auction" [ngTemplateOutletContext]="{ labelConfig: onMap ? 'short' : 'long' }"></ng-template>
          <ng-template [ngTemplateOutlet]="bid"></ng-template>
          <ng-template [ngTemplateOutlet]="footerDetails"></ng-template>
        </div>
        <ng-template [ngTemplateOutlet]="saved"></ng-template>
      </ng-container>

      <!-- Templates -->
      <ng-template #images>
        <div class="image-container">
          <!-- For mobile on the map we don't need all the images, just main image to show -->
          <ng-container *ngIf="componentData.largeScreenDownObserver && onMap; else galleria">
            <img [src]="photoData.IMAGE_PATH" [alt]="photoData.IMAGE_DESCRIPTION" *ngIf="mainPhoto$ | async as photoData" />
          </ng-container>
          <ng-template #galleria>
            <app-galleria [property]="property" [fitImage]="true" [preload]="false" size="sm"></app-galleria>
          </ng-template>
          <app-property-save-button class="save-button" [ITEM_ID]="property.ITEM_ID"></app-property-save-button>
          <app-auction-type-indicator *ngIf="!onMap" class="type" [categoryType]="uiData?.categoryId"></app-auction-type-indicator>
        </div>
      </ng-template>

      <ng-template #bid>
        <div *ngIf="uiData?.bidStatus" class="bid">
          <div class="label footnote small-md">{{ uiData?.bidStatus }}</div>
          <div class="price subtitle">
            <ng-container>{{ uiData?.bidAmt | currency: 'USD':'symbol':'1.0-0' }}</ng-container>
          </div>
        </div>
      </ng-template>

      <ng-template #footerDetails>
        <div class="details">
          <div class="location small" *ngIf="!onMap">{{ uiData?.address }}</div>
          <div class="location small" *ngIf="!onMap">{{ uiData?.city }}, {{ uiData?.state }}</div>
          <div class="description footnote small-md">
            <span>{{ uiData?.bed }} Bd</span> / <span>{{ uiData?.bath }} Bath</span> / <span>{{ uiData?.sqft | number }} Sq Ft</span>
          </div>
          <div
            class="description footnote small-md"
            *ngIf="uiData?.auctionStatus === auctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED && isSavedPropertiesPage">
            Auction Postponed
          </div>
        </div>
      </ng-template>

      <ng-template #auction let-labelConfig="labelConfig">
        <div class="auction">
          <ng-container [ngSwitch]="property.CATEGORYID">
            <ng-container *ngSwitchCase="propertyCategoryType.LIVE_FORECLOSURE_SALES" [ngTemplateOutlet]="foreclosure"></ng-container>
            <ng-container *ngSwitchCase="propertyCategoryType.LOCAL_COUNTY_ONLINE_FORECLOSURE_SALES" [ngTemplateOutlet]="foreclosure"></ng-container>
            <ng-container *ngSwitchCase="propertyCategoryType.ONLINE_FORECLOSURE_SALES" [ngTemplateOutlet]="ohio"></ng-container>
            <ng-container *ngSwitchCase="propertyCategoryType.MARKET_LISTING" [ngTemplateOutlet]="listing"></ng-container>
            <ng-container *ngSwitchDefault [ngTemplateOutlet]="regular"></ng-container>
          </ng-container>
        </div>

        <!-- REGULAR template covers all the states except exception like "Live Foreclosure" -->
        <ng-template #regular>
          <ng-container [ngSwitch]="uiData?.auctionStatus">
            <!-- SOLD STATE -->
            <ng-container *ngSwitchCase="auctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD">
              <div class="label footnote small-md">{{ uiData?.activeDateTitle }} {{ uiData?.activeDate | date: 'EEEE, MMMM d' }}</div>
              <h4 class="final-state">SOLD</h4>
            </ng-container>
            <!-- RETURNED STATE -->
            <ng-container *ngSwitchCase="auctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED">
              <h5 class="final-state">This auction has ended</h5>
            </ng-container>
            <!-- ALL OTHER STATES -->
            <ng-container *ngSwitchDefault>
              <div class="label footnote small-md">{{ uiData?.activeDateTitle }}</div>
              <ng-container *ngIf="uiData?.activeDate; then countdownTimer; else tbdDate"></ng-container>
              <!-- Countdown timer only when date is defined -->
              <ng-template #countdownTimer>
                <div class="countdown-timer">
                  <app-countdown-timer
                    [auctionDate]="property.STATUS === 'Withdrawn' ? null : uiData?.activeDate"
                    [segmentLabel]="labelConfig"></app-countdown-timer>
                </div>
              </ng-template>
              <!-- If no date defined then show TBD -->
              <ng-template #tbdDate>
                <div class="date subtitle">
                  <h6 class="date-or-tbd">TBD</h6>
                </div>
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-template>

        <!-- FORECLOSURES -->
        <ng-template #foreclosure>
          <ng-container [ngSwitch]="uiData?.auctionStatus">
            <ng-container *ngSwitchCase="auctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD">
              <div class="label footnote small-md">
                {{ orientation === 'horizontal' ? uiData?.activeDateTitle : '' }}
                <span class="fw-bold">{{ uiData?.activeDate | date: 'mediumDate' }}</span>
              </div>
              <h4 class="final-state">SOLD</h4>
            </ng-container>
            <ng-container *ngSwitchCase="auctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED">
              <div class="label footnote small-md">
                Auction Postponed<br />
                {{ uiData?.activeDateTitle }}
              </div>
              <ng-container [ngTemplateOutlet]="auctionPostponed"></ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="auctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED">
              <h5 class="final-state">This auction has ended</h5>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="label footnote small-md">{{ uiData?.activeDateTitle }}</div>
              <h6 class="date-or-tbd">
                {{ uiData?.activeDate ? (uiData?.activeDate | date: 'EEEE, MMMM d') : 'TBD' }}
              </h6>
            </ng-container>
          </ng-container>
        </ng-template>

        <!-- OHIO case -->
        <ng-template #ohio>
          <ng-container [ngSwitch]="uiData?.auctionStatus">
            <ng-container *ngSwitchCase="auctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD">
              <div class="label footnote small-md">{{ uiData?.activeDateTitle }} {{ uiData?.activeDate | date: 'EEEE, MMMM d' }}</div>
              <h4 class="final-state">SOLD</h4>
            </ng-container>
            <ng-container *ngSwitchCase="auctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED">
              <h5 class="final-state">This auction has ended</h5>
            </ng-container>
            <ng-container *ngSwitchCase="auctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_CANCELLED">
              <div class="label footnote small-md">
                {{ uiData?.activeDateTitle }} <strong>{{ uiData?.activeDate | date: 'mediumDate' }}</strong>
              </div>
              <h5 class="final-state">Auction has been cancelled</h5>
            </ng-container>
            <ng-container *ngSwitchCase="auctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED">
              <div class="label footnote small-md">
                Auction Postponed<br />
                {{ uiData?.activeDateTitle }}
              </div>
              <ng-container [ngTemplateOutlet]="auctionPostponed"></ng-container>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="label footnote small-md">{{ uiData?.activeDateTitle }}</div>
              <ng-container *ngIf="uiData?.activeDate; then countdownTimer; else tbdDate"></ng-container>
              <!-- Countdown timer only when date is defined -->
              <ng-template #countdownTimer>
                <div class="countdown-timer">
                  <app-countdown-timer
                    [auctionDate]="property.STATUS === 'Withdrawn' ? null : uiData?.activeDate"
                    [segmentLabel]="labelConfig"></app-countdown-timer>
                </div>
              </ng-template>
              <!-- If no date defined then show TBD -->
              <ng-template #tbdDate>
                <div class="date subtitle">
                  <h6 class="date-or-tbd">TBD</h6>
                </div>
              </ng-template>
            </ng-container>
          </ng-container>
        </ng-template>

        <!-- Marketing listing-->
        <ng-template #listing>
          <tr>
            <td class="label footnote small-md">List Price:</td>
            <td class="price subtitle">
              {{ uiData?.estimatedvalue | currency: 'USD':'symbol':'1.0-0' }}
            </td>
          </tr>
        </ng-template>
      </ng-template>

      <ng-template #saved> </ng-template>

      <ng-template #auctionPostponed>
        <h4 class="final-state" *ngIf="orientation === 'vertical' && !isHomePage && !onMap && componentData.largeScreenDownObserver && !isSavedPropertiesPage">
          <strong>{{ uiData?.activeDate | date: 'mediumDate' }}</strong>
        </h4>
        <h6
          class="date-or-tbd final-state"
          *ngIf="(orientation === 'horizontal' && !componentData.largeScreenDownObserver) || (orientation === 'horizontal' && !onMap)">
          {{ uiData?.activeDate | date: 'mediumDate' }}
        </h6>
        <div
          class="label footnote small-md"
          *ngIf="
            (!isHomePage && orientation === 'vertical' && !isSavedPropertiesPage && !componentData.largeScreenDownObserver) ||
            (orientation === 'horizontal' && onMap)
          ">
          Auction postponed
        </div>
      </ng-template>
    </div>
    <a class="delete" *ngIf="showDeleteIcon && property.ITEM_ID" (click)="deleteSavedProperty(property.ITEM_ID)"><fa-icon icon="trash"></fa-icon></a>
  </a>
</ng-container>
