import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { merge, fromEvent, of, Subscription, interval } from 'rxjs';
import { concatMap, throttleTime, tap, filter, map, catchError, distinctUntilChanged, startWith, switchMap, take } from 'rxjs/operators';
import { DialogService } from 'primeng/dynamicdialog';
import { environment } from '$env';
import { SettingsService } from '$settings';
import { Models } from '../models/global.models';
import { LogoutModalComponent } from '../../components/modals';
import { NtsStateManagementService, ntsUIStoreCreator } from '@ntersol/state-management';
import { AnalyticsService } from './analytics.service';
import { ModalsManagementService } from './modals-management.service';
import { DomService } from './dom.service';
import { Actions } from '../../components/modals/logout/logout-modal.component';

export enum AuthState {
  initial,
  loggedIn,
  loggedOut,
  sessionExpired,
  newSignup,
}

interface AuthStore {
  authState: AuthState;
  token: string | null;
  loginResponse: Models.LoginResponse | null;
  hasLoggedInOnce: boolean | null;
}

const initialState: AuthStore = {
  authState: AuthState.initial,
  token: null,
  loginResponse: null,
  hasLoggedInOnce: null,
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private store = ntsUIStoreCreator<AuthStore>(initialState, { persistId: 'authStore' });

  public isLoggedIn$ = this.store.state$.pipe(map(state => state.authState === AuthState.loggedIn));
  public hasLoggedInOnce$ = this.store.state$.pipe(map(x => x.hasLoggedInOnce));
  /** Auth status based on authstate */
  public authState$ = this.store.state$.pipe(
    map(x => x.authState),
    distinctUntilChanged(),
  );
  public get authState() {
    return this.store.state$.value.authState;
  }

  public set authState(authState: AuthState) {
    this.store.update({ authState });
  }

  public userName$ = this.store.state$.pipe(map(x => x.loginResponse?.Name ?? null));
  public userType$ = this.store.state$.pipe(map(x => x.loginResponse?.UserType ?? null));

  public get loginResponse() {
    return this.store.state$.value.loginResponse;
  }
  public set loginResponse(loginResponse: Models.LoginResponse | null) {
    this.store.update({ loginResponse: loginResponse });
  }

  /** How often to refresh the token after user interaction. In seconds */
  private readonly tokenRefreshInterval = 60; // Default: 60 seconds
  /** How long should the user be idle before loading the modal. The cookie expires after 5 minutes. In seconds */
  private readonly idleDuration = 60 * 4; // Default: 60 * 4 = 4 minutes
  /** How long should the log out modal be displayed before logging the user out. In seconds */
  private readonly logoutModalDuration = 60; // Default: 60 seconds

  /** User interaction events. Watches mouse movement, clicks, scroll and key presses. SSR safe */
  private readonly userActions$ = this.dom.document
    ? merge(
        fromEvent(this.dom.document, 'keypress'),
        fromEvent(this.dom.document, 'mousemove'),
        fromEvent(this.dom.document, 'click'),
        fromEvent(this.dom.document, 'scroll'),
      )
    : merge();

  /** Throttle userActions  */
  private readonly refreshEvent$ = this.userActions$.pipe(
    startWith(0),
    throttleTime(1000, undefined, { leading: false, trailing: true }), // Throttle to every one second
  );

  /** Logout timer that resets after every user interaction event */
  public logoutTimerExpired$ = this.refreshEvent$.pipe(
    switchMap(() => interval(1000)), // Reset interval everytime refresh fires
    map(val => (val > this.idleDuration ? true : false)), // If val is greater than duration, convert to true or false
    startWith(false),
    distinctUntilChanged(),
    filter(expired => expired && this.authState === AuthState.loggedIn), // Don't show if logout modal is already visible or no token
    tap(() => this.launchLogoutModal()),
  );

  /** Refresh the token automatically */
  public refreshToken$ = this.refreshEvent$.pipe(
    // Throttle time using refresh interval
    // Needs to be before filter to prevent duplicate calls when the logout modal fires or on login
    throttleTime(this.tokenRefreshInterval * 1000, undefined, { leading: false, trailing: true }),
    // Token refresh can only occur after refreshEvent$ is initialized
    // Only capture refresh events if token present
    // Only refresh token if timer not expired
    // Only refresh if logged in
    filter(refreshEvent => !!refreshEvent && this.authState === AuthState.loggedIn),
    tap(() => this.refreshToken()), // Make refresh calls
  );

  private subs: Subscription[] = [];

  constructor(
    private dialogService: DialogService,
    private http: HttpClient,
    private router: Router,
    private settings: SettingsService,
    private analytics: AnalyticsService,
    private sms: NtsStateManagementService,
    private modalsManagement: ModalsManagementService,
    private dom: DomService,
  ) {}

  /**
   * Log the user in
   * @param data
   */
  public logIn(data: Models.LoginRequest) {
    return this.http.post<Models.LoginResponse>(environment?.endpoints?.apiUrl + 'public/' + environment?.endpoints?.authLogin, data).pipe(
      tap(response => {
        if (response.DisplayName) {
          this.analytics.identify(response.DisplayName);
        }
        this.authState = AuthState.loggedIn;
        this.settings.update({ user: response });
        this.loginResponse = response;
        this.store.update({ hasLoggedInOnce: true });
        this.analytics.customEvent({
          'event': 'User Login',

          'userID': response.CustomerID,
        });
      }),
      concatMap(() => this.http.get<Models.UserDetailsResponse>(environment.endpoints.apiUrl + 'user/current/userDetails')),
      tap(userDetails => {
        this.settings.update({ userDetails: userDetails });
      }),
    );
  } // end LogIn

  /**
   * Refresh the token
   */
  private refreshToken() {
    // Null check against env files
    if (!environment.endpoints.apiUrl || !environment.endpoints.authTokenRefresh) {
      return false;
    } // 'https://apidev.realtybid.com/session/sessioninfo'
    const refreshApi = this.http.get<Models.Auth>(environment.endpoints.apiUrl + environment.endpoints.authTokenRefresh);

    refreshApi.subscribe(
      () => {
        if (this.authState) {
          // Make sure a token is present before it is replaced
          this.authState = AuthState.loggedIn;
          // this.token = response.data.token;
        }
      },
      () => this.logOut(AuthState.sessionExpired),
    );
    // Return observable if needed by a component
    return refreshApi;
  } // end RefreshToken

  /**
   * Sends a password reset email
   * @param email
   */
  public sendPasswordResetEmail(email: string) {
    return this.http.post(environment.endpoints.apiUrl + '/public/user/reset-password/begin', { EmailAddress: email });
  }

  /**
   * Reset a user's password
   * @param token
   * @param password
   */
  public resetPassword(token: string, password: string) {
    return this.http.post(environment.endpoints.apiUrl + '/public/user/reset-password/end', {
      VerificationToken: token,
      NewPassword: password,
    });
  }

  /**
   * Sign up a new user
   * @param data Sign up info
   */
  public signUp(data: Models.BidderRequest) {
    return this.http.post<{ SUCCESS: boolean }>(environment.endpoints.apiUrl + 'public/register/bidder', data).pipe(
      tap((response: any) => {
        this.analytics.gtag('event', 'signup');
        this.analytics.customEvent({
          'event': 'userRegistration',

          'userID': response['MESSAGE'],
        });
        this.authState = AuthState.newSignup;
        this.store.update({ hasLoggedInOnce: true });
      }),
      catchError(err => {
        this.analytics.gtag('event', 'signup_fail');
        throw err;
      }),
    );
  }

  /**
   * Check if an email is already in use
   * @param email The email to check
   */
  public checkEmailTaken(email: string) {
    if (email === 'taken@email.com') {
      return of(true);
    } // TODO: Remove with real request
    return of(false);
  }

  /**
   * Launch a modal window which gives the user a chance to continue working
   */
  private launchLogoutModal(): void {
    // Open log out modal window
    const ref = this.dialogService.open(LogoutModalComponent, {
      data: this.logoutModalDuration, // How long should the countdown display in seconds
      header: 'No recent activity',
      dismissableMask: true,
      styleClass: 'dialog-logout',
    });
    // When modal closes
    ref.onClose.subscribe((action: Actions) => {
      if (action === Actions.expired) {
        this.logOut(AuthState.sessionExpired);
      } else if (action === Actions.extend) {
        this.refreshToken(); // Immediately refresh token
      } else if (action === Actions.logout) {
        this.logOut(AuthState.loggedOut, { goToUrl: '/' });
      } else {
        throw new Error(`Unknown action ${action}`);
      }
    });
  }

  /**
   * Log the user out. Clear stored data and redirect to login page
   */
  public logOut(
    authState: AuthState,
    options: {
      preventNavigation?: boolean;
      currentUrl?: string;
      goToUrl?: string;
      triggeredByUser?: boolean;
    } = { preventNavigation: false },
  ): void {
    // Get current login state
    this.isLoggedIn$.pipe(take(1)).subscribe(isLoggedIn => {
      this.http.get(environment.endpoints.apiUrl + 'public/login/logout').subscribe();
      this.analytics.gtag('event', 'logout');
      this.loginResponse = null;
      this.authState = authState;
      this.settings.update({ user: null, userDetails: null });
      this.sms.resetStores('api'); // Empty all API data and reset stores
      this.modalsManagement.unregisterAll(); // Close all openned modal popups
      if (!options.preventNavigation) {
        if (authState === AuthState.loggedOut && !!options.triggeredByUser) {
          this.router.navigate([options.goToUrl ?? '/login'], {
            queryParams: { currentUrl: options.currentUrl ?? this.dom?.window?.location.pathname },
            state: { triggeredByUser: true },
          });
          return;
        }
        this.router.navigate([options.goToUrl ?? '/login'], {
          queryParams: { currentUrl: options.currentUrl ?? this.dom?.window?.location.pathname },
        });
      }
    });
  } // end LogOut

  public ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
    this.subs = [];
  }
}
