import { Component, ChangeDetectionStrategy, Input, ViewChild, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  NgForm,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
} from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map, Observable, startWith } from 'rxjs';
import { InputErrorShowCondition } from '../input-error/input-error.component';

function hasLessThanEightCharacters(control: AbstractControl): { [key: string]: any } | null {
  if (control.value && control.value.length < 8) {
    return { invalidCharacterLength: true };
  }
  return null;
}

function hasNumber(control: AbstractControl): { [key: string]: any } | null {
  if (control.value && !control.value.match(/\d+/g)) {
    return { missingNumber: true };
  }
  return null;
}

function hasUpperAndLowerCase(control: AbstractControl): { [key: string]: any } | null {
  if (control.value && /[A-Z]/.test(control.value) && /[a-z]/.test(control.value)) {
    return null;
  }
  return { missingUpperOrLowerCaseLetter: true };
}

function hasUpperCase(control: AbstractControl): { [key: string]: any } | null {
  if (control.value && /[A-Z]/.test(control.value)) {
    return null;
  }
  return { missingUpperOrLowerCaseLetter: true };
}

function hasAtLeastOneSpecialCharacter(control: AbstractControl): { [key: string]: any } | null {
  if (control.value && /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(control.value)) {
    return null;
  }
  return { specialCharacter: true };
}

interface ValidationClasses {
  invalidCharacterLength: string;
  missingNumber: string;
  missingUpperOrLowerCaseLetter: string;
  specialCharacter: string;
}

const defaultValidationClasses = {
  invalidCharacterLength: '',
  missingNumber: '',
  missingUpperOrLowerCaseLetter: '',
  specialCharacter: '',
};

@UntilDestroy()
@Component({
  selector: 'app-password-strength-input',
  templateUrl: './password-strength-input.component.html',
  styleUrls: ['./password-strength-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PasswordStrengthInputComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: PasswordStrengthInputComponent,
    },
  ],
})
export class PasswordStrengthInputComponent implements OnInit, ControlValueAccessor {
  @Input() showHelpText: boolean = false;
  @Input() showStrengthFeedback: boolean = true;
  @Input() errorCondition: InputErrorShowCondition = {};
  @Input() customErrorMessage: string = '';
  @Input() labelId: string = '';

  @ViewChild(FormGroupDirective, { static: true }) childFormGroupDirective: FormGroupDirective | null = null;
  @ViewChild('myForm') ngForm: NgForm | null = null;

  form: FormGroup = this.fb.group({
    password: ['', [hasLessThanEightCharacters, hasNumber, hasUpperCase, hasAtLeastOneSpecialCharacter]],
  });

  password: string | null = null;

  validationClasses$: Observable<ValidationClasses> | null = null;

  touched = false;

  disabled = false;

  constructor(private fb: FormBuilder, private parentFormGroupDirective: FormGroupDirective) {}

  ngOnInit() {
    // When parent form is submitted, also submit this internal form. Needed for input error validation css
    this.parentFormGroupDirective.ngSubmit.pipe(untilDestroyed(this)).subscribe(e => this.ngForm?.onSubmit(e));
    this.setAutocompleteOff();
  }

  onChange = (password: string) => {};

  onTouched = () => {};

  writeValue(password: string) {
    if (password === null) {
      this.passwordControl.reset(undefined, { emitEvent: false });
      return;
    }

    this.passwordControl.setValue(password, { emitEvent: false });
  }

  registerOnChange(onChange: any) {
    this.passwordControl.valueChanges?.pipe(untilDestroyed(this)).subscribe(onChange);
    this.validationClasses$ = this.passwordControl.valueChanges?.pipe(startWith(defaultValidationClasses), map(this.getValidationClasses));
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    if (disabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.passwordControl?.errors || null;
  }

  get passwordControl(): AbstractControl {
    // Assertion below is correct because password control is created with the instance
    // of the class
    return this.form.get('password')!;
  }

  getValidationClasses = () => {
    return Object.keys(defaultValidationClasses).reduce((classes, key) => {
      classes[key as keyof ValidationClasses] = this.getErrorClass(key);
      return classes;
    }, {} as any);
  };

  private getErrorClass(errorKey: string) {
    return !this.hasValue() ? '' : this.checkError(this.passwordControl, errorKey) ? 'error' : 'success';
  }

  private hasValue() {
    return !!this.passwordControl?.value;
  }

  private checkError(control: AbstractControl | null, error: string): boolean {
    if (control?.value === '') return true;
    return !!control?.errors?.[error] && !!control?.dirty;
  }

  private setAutocompleteOff() {
    document.getElementsByName('password').forEach(element => {
      const input = element.querySelector('input');
      if (input) {
        input.setAttribute('autocomplete', 'off');
      }
    });
  }
}
