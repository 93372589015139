<div class="place-bid-form">
  <form [formGroup]="form">
    <ng-container *ngIf="user$ | async">
      <div class="form-row">
        <h6 class="label bold">{{ PROPERTY_ADDRESS_LABEL }}:</h6>
        <p class="value">{{ (propertyDetails$ | async)?.ADDRESSLINE }}</p>
      </div>

      <div *ngIf="biddingFor$ | async as biddingFor" class="form-row">
        <h6 class="label bold">{{ BIDDING_FOR }}:</h6>
        <p class="value">{{ biddingFor }}</p>
      </div>

      <div class="form-row">
        <h6 class="label">{{ (currentBid$ | async)?.LEADERBIDDER === BID_NOW ? OPENING_BID_LABEL : CURRENT_BID_LABEL }}:</h6>
        <h2 class="value">{{ (currentBid$ | async)?.CURRENT_BID || 0 | currency: 'USD':'symbol':'1.0-0' }}</h2>
      </div>

      <div class="p-fluid row dropdown">
        <div class="col col-12">
          <label for="BID_AMT">{{ BID_AMT_PLACEHOLDER }}</label>
          <p-dropdown
            appendTo="body"
            [options]="bidOptions || []"
            id="BID_AMT"
            formControlName="BID_AMT"
            placeholder=" -- Please Select --"
            [showTransitionOptions]="'100ms'"
            [hideTransitionOptions]="'50ms'"></p-dropdown>
          <app-input-error controlName="BID_AMT" [friendlyName]="BID_AMT_PLACEHOLDER || ''" customErrorMessage="Required"></app-input-error>
          <div class="nts-form-field-hint">{{ bidAmountHint }}</div>
        </div>
      </div>

      <p class="form-row">
        <nts-form-field
          formControlName="OCCUPANCY_TYPE"
          [placeholder]="OWNERSHIP_TYPE_PLACEHOLDER"
          type="radio"
          [options]="occupancyTypes$ | async"></nts-form-field>
      </p>

      <p class="form-row">
        <nts-form-field
          formControlName="PURCHASE_TYPE"
          [placeholder]="PURCHASE_TYPE_PLACEHOLDER"
          type="radio"
          [options]="purchaseTypes$ | async"></nts-form-field>
      </p>

      <ng-container *ngIf="!isPostAuction">
        <div class="p-fluid row dropdown">
          <div class="col col-12">
            <label for="BID_ASSIST_AMT">{{ BID_ASSIST_AMT_PLACEHOLDER }}</label>
            <p-dropdown
              appendTo="body"
              [options]="bidAssistOptions || []"
              id="BID_ASSIST_AMT"
              formControlName="BID_ASSIST_AMT"
              placeholder=" -- Please Select --"
              [showTransitionOptions]="'100ms'"
              [hideTransitionOptions]="'50ms'"
              [showClear]="true"></p-dropdown>
            <app-input-error controlName="BID_ASSIST_AMT" [friendlyName]="BID_AMT_PLACEHOLDER || ''" customErrorMessage="Required"></app-input-error>
            <div class="nts-form-field-hint">{{ BID_ASSIST_AMOUNT_HINT }}</div>
          </div>
        </div>

        <p class="form-row" *ngIf="property?.CATEGORYID !== propertyCategoryType.ONLINE_FORECLOSURE_SALES">
          <nts-form-field
            formControlName="BID_ASSIST_CARRYOVER"
            [placeholder]="BID_ASSIST_CARRYOVER_PLACEHOLDER"
            type="radio"
            [options]="yesNoOptions"></nts-form-field>
        </p>
      </ng-container>

      <div *ngIf="isUserInvestor" class="form-row dropdown">
        <div class="p-fluid row dropdown">
          <div class="col col-12">
            <label for="PURCHASEPROFILEID">{{ PURCHASEPROFILEID_PLACEHOLDER }}</label>
            <p-dropdown
              appendTo="body"
              [options]="(purchaseProfiles$ | async) || []"
              id="PURCHASEPROFILEID"
              formControlName="PURCHASEPROFILEID"
              placeholder=" -- Please Select --"
              [showTransitionOptions]="'100ms'"
              [hideTransitionOptions]="'50ms'"
              [showClear]="true"></p-dropdown>
            <app-input-error
              controlName="PURCHASEPROFILEID"
              [friendlyName]="PURCHASEPROFILEID_PLACEHOLDER || ''"
              customErrorMessage="Required"></app-input-error>
          </div>
        </div>
      </div>

      <ng-container *ngIf="!isUserAgent">
        <div class="p-fluid row dropdown">
          <div class="col col-12">
            <fieldset class="mt-1">
              <ng-container *ngIf="buyerAgent$ | async; else addBuyerAgentLink">
                <app-buyer-agent-info [agent]="buyerAgent$ | async" [displayTitle]="false"></app-buyer-agent-info>
                <div class="form-row">
                  <a href (click)="onBuyerAgentRemoveClick($event)" title="Remove Buyer Agent">Remove</a>
                </div>
              </ng-container>
            </fieldset>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </form>
</div>

<ng-template #addBuyerAgentLink>
  <a href (click)="onBuyerAgentClick($event)" title="Add a new Buyer Agent">Add Buyer Agent</a>
</ng-template>
