import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-horizontal-stepper',
  templateUrl: './horizontal-stepper.component.html',
  styleUrls: ['./horizontal-stepper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HorizontalStepperComponent implements OnInit, OnChanges {
  @Input() steps = 0;
  @Input() currentStep: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null)

  stepArray!: number[];
  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['steps'].currentValue) {
      this.stepArray = Array(this.steps).fill(0).map((v, i) => i)
    }
  }

  ngOnInit(): void {
  }

}
