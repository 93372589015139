import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DomService } from '../../../shared/services';
import { AppMobileHeaderDirective } from './app-mobile-header.directive';

export interface MobileHeaderLevel3TypeAccessor {
  headerTitle: string;
  closeButtonAction: () => void;
}

/**
 * Mobile level 3 header.
 *  Input fields:
 *    title - adds title at the center of the header
 *    action - current header has "x" close button. What will be called on click on
 *    this button is fully depend on the caller (parent component).
 *    There are 2 types available to pass to "action" field, Observable or function.
 */
@UntilDestroy()
@Component({
  selector: 'app-mobile-header-level-3',
  template: `
    <div class="wrapper">
      <p class="h5 title">{{ title }}</p>
      <p-button class="action-button" styleClass="mobile-header-level-3 p-button-text" icon="pi pi-times" (onClick)="click()"></p-button>
    </div>
  `,
  styles: [
    `
      .wrapper {
        display: flex;
        align-items: center;
        padding-block: 1.265625rem;
      }
      .action-button {
        flex: 0;
        margin-left: auto;
      }
      .title {
        flex: 1;
        margin: 0;
        width: 100%;
        font-size: 18px;
        text-align: center;
      }
      ::ng-deep .wrapper .mobile-header-level-3 {
        color: #737578;
      }
      ::ng-deep .wrapper .mobile-header-level-3.p-button {
        background: transparent;
        border: transparent;
        color: black;
      }
      ::ng-deep .wrapper .mobile-header-level-3.p-button .p-button-icon {
        font-size: 14px;
      }
      ::ng-deep .wrapper .mobile-header-level-3.p-button.p-button-text:enabled:hover {
        background: transparent;
        border: transparent;
        color: black;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMobileHeaderLevel3Component extends AppMobileHeaderDirective {
  constructor(dom: DomService) {
    super(dom);
  }
}
