<ng-container
  *ngIf="{
    largeScreenObserver: largeScreenObserver$ | async
  } as componentData">
  <form [formGroup]="passwordForm" novalidate (ngSubmit)="submitForm()" class="d-flex flex-column w-100">
    <div class="mb-4" *ngIf="showOldPasswordField">
      <label id="old-password">Old Password</label>
      <p-password
        name="password"
        styleClass="w-100 p-input-icon-right"
        inputStyleClass="w-100"
        [toggleMask]="true"
        [feedback]="false"
        ariaLabelledBy="old-password"
        formControlName="oldPassword"></p-password>
      <div *ngIf="oldPasswordControl.hasError('oldPassword')">
        <small class="forgot-password-error p-error"> {{ oldPasswordControl.errors!['oldPassword'] }} </small>
        <a class="forgot-password-link" routerLink="/forgot-password/">Forgot password</a>
      </div>
      <small *ngIf="(oldPasswordControl.touched || oldPasswordControl.dirty) && !oldPasswordControl.value" class="p-error">
        Please enter your old password
      </small>
    </div>
    <div class="mb-4">
      <label id="new-password">New Password</label>
      <app-password-strength-input
        [errorCondition]="passwordErrorCondition"
        [customErrorMessage]="'Please enter a valid password'"
        formControlName="password"
        labelId="new-password">
      </app-password-strength-input>
    </div>
    <div [ngClass]="{ 'mb-5': !componentData.largeScreenObserver, 'mb-4': componentData.largeScreenObserver }">
      <label id="confirm-password">Re-enter Password</label>
      <p-password
        name="password"
        styleClass="w-100 p-input-icon-right"
        inputStyleClass="w-100"
        [toggleMask]="true"
        [feedback]="false"
        ariaLabelledBy="confirm-password"
        formControlName="confirmPassword"></p-password>
      <small
        *ngIf="passwordForm.errors?.['matchValue']
        && ((newPasswordControl.touched || newPasswordControl.dirty)
        && (confirmPasswordControl.touched || confirmPasswordControl.dirty))
        || ((confirmPasswordControl.touched || confirmPasswordControl.dirty) && !confirmPasswordControl.value)"
        class="p-error">
        Passwords do not match
      </small>
    </div>

    <div class="d-flex justify-content-end" style="margin-bottom: 64px">
      <button
        pButton
        pRipple
        type="submit"
        iconPos="right"
        [loading]="waiting"
        label="Change password"
        [ngClass]="{
          'w-100': !componentData.largeScreenObserver
        }"></button>
    </div>
  </form>
</ng-container>
