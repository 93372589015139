import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, InjectionToken, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Subject, debounceTime, map } from 'rxjs';
import { ApiService } from '../../../shared/stores/api';

export abstract class ProperyFeaturesService {
  abstract formGroup: FormGroup;
  abstract safeToNavigate$: BehaviorSubject<boolean>;
  abstract triggerFormValidation$: Subject<boolean>;
  abstract formControl(path: string): FormControl;
  showOccupancySection: boolean = false;
}

export const PROPERTY_FEATURES_TOKEN = new InjectionToken<ProperyFeaturesService>('PROPERTY FEATURES TOKEN');

enum PropertyFeaturesFormFieldNames {
  propertyType = 'propertyType',
  numberOfBedrooms = 'numberOfBedrooms',
  numberOfBaths = 'numberOfBaths',
  numberOfHalfBath = 'numberOfHalfBath',
  sqft = 'sqft',
  lotSizeAcres = 'lotSizeAcres',
  yearBuilt = 'yearBuilt',
  fireplace = 'fireplace',
  garageSize = 'garageSize',
  heatType = 'heatType',
  basement = 'basement',
  occupancy = 'occupancy',
}

enum PropertyType {
  SINGLEFAMILYTYPE = 126,
  LANDTYPE = 115,
  COMMERCIALTYPE = 127,
}

@UntilDestroy()
@Component({
  selector: 'app-property-features-common',
  templateUrl: './property-features-common.component.html',
  styleUrls: ['./property-features-common.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyFeaturesCommonComponent implements OnInit {
  formControlType = this.svc.formGroup.get('TYPE') as FormControl;
  formControlSqFt = this.svc.formGroup.get('SQ_FT') as FormControl;
  formControlLotSize = this.svc.formGroup.get('ACRES') as FormControl;
  formControlLotSizeValue = new FormControl('');
  formControlLotSizeOption = new FormControl('0');
  formControlYearBuilt = this.svc.formGroup.get('YEAR_BUILT') as FormControl;
  formControlBedCount = this.svc.formGroup.get('BEDROOM_COUNT') as FormControl;
  formControlBathCount = this.svc.formGroup.get('BATH_COUNT') as FormControl;
  formControlHalfBathCount = this.svc.formGroup.get('HALF_BATH_COUNT') as FormControl;
  formControlFirePlace = this.svc.formGroup.get('FIREPLACE') as FormControl;
  formControlGarageSize = this.svc.formGroup.get('GARAGE_SIZE') as FormControl;
  formControlHeatType = this.svc.formGroup.get('HEAT_TYPE') as FormControl;
  formControlBasement = this.svc.formGroup.get('BASEMENT') as FormControl;
  formControlOccupancy = this.svc.formGroup.get('OCCUPANCY') as FormControl;

  readonly propertyTypes$ = this.apiService.propertyType.selectAll$.pipe(
    map(list => list?.map(type => ({ label: type.TYPE_CAT_DESC, value: type.PROP_TYPE_CAT_ID })) || []),
  );

  showOccupancySection: boolean = false;

  sqftText = '<div>Square Feet<sup class="required">*</sup></div>';
  // lot size is always required
  lotSizeText = '<div>Lot Size<sup class="required">*</sup></div>';
  yearBuiltText = '<div>Year Built<sup class="required">*</sup></div>';

  requiredBathAsterisk = '*';
  requiredBedAsterisk = '*';

  readonly LOT_SIZE_OPTIONS = [
    { label: 'Square foot', value: '0' },
    { label: 'Acres', value: '1' },
  ];

  readonly GARAGE_SIZE_OPTIONS = [
    { label: 'Unknown', value: '' },
    { label: 'None', value: '0' },
    { label: '1 Car', value: '1' },
    { label: '2 Car', value: '2' },
    { label: '3 Car', value: '3' },
    { label: '4 Car', value: '4' },
  ];

  readonly HEAT_TYPE = [
    { label: 'Unknown', value: 'Unknown' },
    { label: 'None', value: 'None' },
    { label: 'Gas', value: 'Gas' },
    { label: 'Electric', value: 'Electric' },
    { label: 'Other', value: 'Other' },
  ];

  readonly FIREPLACE_OPTIONS = [
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' },
    { label: 'Unknown', value: 'U' },
  ];

  readonly BASEMENT_OPTIONS = [
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' },
    { label: 'Unknown', value: 'U' },
  ];

  readonly OCCUPANCY_OPTIONS = [
    { label: 'Vacant', value: 'Vacant' },
    { label: 'Occupied', value: 'Occupied' },
    { label: 'Unknown', value: 'Unknown' },
  ];

  readonly propertyFeaturesFormFieldNames = PropertyFeaturesFormFieldNames;

  constructor(
    private readonly apiService: ApiService,
    @Inject(PROPERTY_FEATURES_TOKEN) public svc: ProperyFeaturesService,
    private changeDet: ChangeDetectorRef,
  ) {
    if (this.svc.showOccupancySection !== undefined) {
      this.showOccupancySection = this.svc.showOccupancySection;
    }
    this.setLotSize();

    this.svc.safeToNavigate$.next(this.isValid());
    this.formControlLotSize.addValidators(Validators.required);

    this.setSquareFeet();

    this.svc.formGroup.valueChanges.pipe(debounceTime(500), untilDestroyed(this)).subscribe(() => {
      this.svc.safeToNavigate$.next(this.isValid());
    });

    this.svc.triggerFormValidation$.pipe(untilDestroyed(this)).subscribe(val => {
      const controls = [
        this.formControlType,
        this.formControlSqFt,
        this.formControlLotSize,
        this.formControlYearBuilt,
        this.formControlBedCount,
        this.formControlBathCount,
      ];
      controls.forEach(c => {
        c.patchValue(c.value);
        c.markAsDirty();
      });
      this.changeDet.detectChanges();
    });
  }

  checkBasementForNull() {
    const basementVal = this.svc.formGroup.get('BASEMENT')?.value;
    if (basementVal === null) {
      this.svc.formGroup.get('BASEMENT')?.patchValue('U');
      this.changeDet.detectChanges();
    }
  }

  ngOnInit(): void {
    if (!this.formControlType.value) {
      this.formControlType.patchValue(PropertyType.SINGLEFAMILYTYPE);
    }
    this.typeSelected(this.formControlType.value);
  }

  setSquareFeet() {
    const sqft = this.formControlSqFt?.value;
    if (sqft === 0) {
      this.formControlSqFt?.patchValue(null);
    }
  }

  removeValidation() {
    const controls = [this.formControlType, this.formControlSqFt, this.formControlYearBuilt, this.formControlBedCount, this.formControlBathCount];
    controls.forEach(control => {
      control.clearValidators();
      control.updateValueAndValidity();
    });

    this.toggleValidatorAsterisk(false);
  }

  toggleValidatorAsterisk(toggle: boolean) {
    this.requiredBathAsterisk = toggle ? '*' : '';
    this.requiredBedAsterisk = toggle ? '*' : '';

    this.sqftText = toggle ? '<div>Square Feet<sup class="required">*</sup></div>' : '<div>Square Feet</div>';
    this.yearBuiltText = toggle ? '<div>Year Built<sup class="required">*</sup></div>' : '<div>Year Built</div>';
  }

  addValidation() {
    const controls = [this.formControlType];

    if (this.formControlType.value !== PropertyType.COMMERCIALTYPE) {
      this.formControlBedCount.addValidators(Validators.min(1));
      controls.push(this.formControlBedCount);
    }
    this.formControlBathCount.addValidators(Validators.min(1));
    this.formControlSqFt.addValidators(Validators.min(0.0000001));
    this.formControlYearBuilt.addValidators(Validators.min(0.0000001));
    controls.push(this.formControlBathCount);
    controls.push(this.formControlSqFt);
    controls.push(this.formControlYearBuilt);
    controls.push(this.formControlLotSize);
    controls.forEach(control => {
      control.addValidators(Validators.required);
      control.updateValueAndValidity();
    });

    this.toggleValidatorAsterisk(true);
  }

  markPristine() {
    const controls = [this.formControlSqFt, this.formControlLotSize, this.formControlYearBuilt, this.formControlBedCount, this.formControlBathCount];

    controls.forEach(control => {
      control.markAsPristine();
      control.markAsUntouched();
      control.updateValueAndValidity();
    });
  }

  typeSelected(value: number) {
    this.markPristine();

    if (value === PropertyType.LANDTYPE) {
      this.removeValidation();
      this.formControlSqFt?.patchValue(null);
    } else if (value === PropertyType.COMMERCIALTYPE) {
      this.addValidation();

      this.removeValidatorsForCommercial();
    } else {
      this.addValidation();
    }
    this.changeDet.detectChanges();
  }

  changeLotSizeOption() {
    if (this.formControlLotSizeOption.value === '0') {
      this.formControlLotSize.setValue(`${this.formControlLotSizeValue.value} SqFt`);
    } else {
      this.formControlLotSize.setValue(`${this.formControlLotSizeValue.value} Acres`);
    }
  }

  changeLotSizeValue(event: any) {
    if (this.formControlLotSizeOption.value === '0') {
      this.formControlLotSize.setValue(`${event} SqFt`);
    } else {
      this.formControlLotSize.setValue(`${event} Acres`);
    }
  }

  private setLotSize() {
    this.formControlLotSizeOption = new FormControl('0');
    if (this.formControlLotSize.value) {
      let lotSize: string = this.formControlLotSize.value.split(' ', 2);
      this.formControlLotSizeValue = new FormControl(lotSize[0]);

      if (lotSize.length > 1) {
        if (
          lotSize[1].toUpperCase() === 'ACRES' ||
          lotSize[1].toUpperCase() === 'ACRE' ||
          lotSize[1].toUpperCase() === 'AC.' ||
          lotSize[1].toUpperCase() === 'AC' ||
          lotSize[1].toUpperCase() === 'A'
        ) {
          this.formControlLotSizeOption = new FormControl('1');
        }
      }
    }
  }

  private removeValidatorsForCommercial() {
    // bedroom
    this.formControlBedCount.clearValidators();
    this.formControlBedCount.updateValueAndValidity();
    this.requiredBedAsterisk = '';

    // Bath
    this.formControlBathCount.clearValidators();
    this.formControlBathCount.updateValueAndValidity();
    this.requiredBathAsterisk = '';
  }

  isValid(): boolean {
    const controls = [
      this.formControlType,
      this.formControlSqFt,
      this.formControlLotSize,
      this.formControlYearBuilt,
      this.formControlBedCount,
      this.formControlBathCount,
      this.formControlLotSize,
    ];
    return !controls.some(val => !val.valid);
  }
}
