import { environment } from '$env';

export function loadCookiebot() {
  if (!environment.settings.enableCookiebot) {
    return;
  }

  const script1 = createScript1();
  const script2 = createScript2();
  document.head.appendChild(script1);
  document.head.appendChild(script2);
}

function createScript1(): HTMLScriptElement {
  const script: HTMLScriptElement = document.createElement('script');
  script.async = true;
  script.src = `https://consent.cookiebot.com/${environment.licenses.cookiebotKey}/cc.js?renew=false&referer=www.realtybid.com&dnt=false`;
  return script;
}

function createScript2(): HTMLScriptElement {
  const script2: HTMLScriptElement = document.createElement('script');
  script2.async = true;
  script2.id = 'Cookiebot';
  script2.src = 'https://consent.cookiebot.com/uc.js';
  script2.dataset['cbid'] = environment.licenses.cookiebotKey;
  script2.dataset['blockingmode'] = 'auto';
  return script2;
}
