import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class LocationSearchInputService {
  advancedSearchOptions = [
    { label: 'Property Address', value: 'default' },
    { label: 'Property Item ID', value: 'itemID' },
    { label: 'Property Trustee #', value: 'trusteeNumber' },
    { label: 'Property Case #', value: 'caseNumber' },
    { label: 'Property Parcel #', value: 'parcelID' },
  ];
  selectedValueControl = new FormControl('default');
}
