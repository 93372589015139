<ng-container *ngIf="breadCrumbs?.length">
  <div class="small">
    <ng-container *ngIf="breadcrumbPrepend">
      <a class="me-1 gray-500 text-decoration-none" [routerLink]="breadcrumbPrepend.url">{{ breadcrumbPrepend.label }}</a>
      <span class="me-1 gray-500">/</span>
    </ng-container>
    <ng-container *ngFor="let bc of breadCrumbs; let last = last">
      <ng-container *ngIf="!last; else lastBreadcrumb">
        <a *ngIf="bc.state" [routerLink]="bc.url" [state]="bc.state" class="me-1 gray-500 text-decoration-none">{{ bc.label }}</a>
        <a *ngIf="!bc.state" [routerLink]="bc.url" class="me-1 gray-500 text-decoration-none">{{ bc.label }}</a>
        <span class="me-1 gray-500">/</span>
      </ng-container>
      <ng-template #lastBreadcrumb>
        <span class="me-1 text-color strong">{{ unclear(bc.label) }}</span>
      </ng-template>
    </ng-container>
  </div>
</ng-container>
