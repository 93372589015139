import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, BaseRouteReuseStrategy, NoPreloading, PreloadAllModules, RouteReuseStrategy, RouterModule, Routes } from '@angular/router';
import { environment } from '../environments/environment';
import { LayoutMainComponent } from './components/masterpage';
import { MobileLayoutComponent } from './components/masterpage/mobile-layout/mobile-layout.component';
import { ProgramBadgeWrapperComponent } from './routes/my-profile/routes/program-badge-wrapper/program-badge-wrapper.component';
import { NoContentComponent } from './routes/no-content/no-content.component';
import { AuthGuard } from './shared/guards/auth.guard';

class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
  public override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return future.routeConfig === curr.routeConfig || (future.data['reuseComponent'] && curr.component === future.component);
  }
}

const ROUTES: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    loadChildren: () => import('./routes/login/login.module').then(m => m.LoginModule),
    data: {
      title: 'Login to RealtyBid for Real Estate Auctions & Foreclosures',
      description:
        'Login to RealtyBid, home of online real estate auctions and foreclosure listings across the United States. Browse online house auctions and place your bid.',
    },
  },
  {
    path: 'sign-up',
    pathMatch: 'full',
    loadChildren: () => import('./routes/login/login.module').then(m => m.LoginModule),
    data: {
      title: 'Sign Up for RealtyBid & Find Real Estate Auctions & Foreclosures',
      description: 'Sign up for RealtyBid, home of online real estate auctions & foreclosure listings',
    },
  },
  {
    path: 'forgot-password',
    pathMatch: 'full',
    loadChildren: () => import('./routes/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
  },

  // Routes to render mobile sidebars
  {
    path: 'mobile',
    component: MobileLayoutComponent,
    children: [
      {
        path: 'vip-level-badge',
        component: ProgramBadgeWrapperComponent,
      },
      {
        path: 'buy-sell',
        loadChildren: () => import('./routes/mobile-sidebar/mobile-buy-sell-list/mobile-buy-sell-list.module').then(m => m.MobileBuySellListModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'advanced-search-options',
        loadChildren: () =>
          import('./routes/mobile-sidebar/mobile-advanced-search-options/mobile-advanced-search-options.module').then(m => m.MobileAdvancedSearchOptionsModule),
      },
      {
        path: 'user-profile',
        loadChildren: () => import('./routes/mobile-sidebar/mobile-user-profile/mobile-user-profile.module').then(m => m.MobileUserProfileModule),
      },
      {
        path: 'save-search',
        loadChildren: () => import('./routes/mobile-sidebar/mobile-save-search/mobile-save-search.module').then(m => m.MobileSaveSearchModule),
        canActivate: [AuthGuard],
      },
    ],
  },

  // Routes that use masterpage go here
  // canActivate with AuthGuard determines if this is an authenticated only route
  {
    path: '',
    component: LayoutMainComponent,
    data: { showLayoutFooterForDesktop: true },
    children: [
      {
        path: 'houses-for-auction',
        data: { showLayoutFooterForDesktop: false },
        loadChildren: () => import('./routes/houses/houses.module').then(m => m.HousesModule),
      },
      {
        path: 'state-licenses',
        loadChildren: () => import('./routes/state-licenses/state-licenses.module').then(m => m.StateLicensesModule),
      },
      {
        path: 'careers',
        loadChildren: () => import('./routes/careers/careers.module').then(m => m.CareersModule),
      },
      {
        path: 'about-us',
        loadChildren: () => import('./routes/about-us/about-us.module').then(m => m.AboutUsModule),
      },
      {
        path: 'contact-us',
        loadChildren: () => import('./routes/contact-us/contact-us.module').then(m => m.ContactUsModule),
      },
      {
        path: 'help-center',
        loadChildren: () => import('./routes/help-center/help-center.module').then(m => m.HelpCenterModule),
      },
      {
        path: 'styleguide',
        loadChildren: () => import('./routes/styleguide/styleguide.module').then(m => m.StyleguideModule),
      },
      {
        path: 'terms-of-service',
        loadChildren: () => import('./routes/terms-of-service/terms-of-service.module').then(m => m.TermsOfServiceModule),
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('./routes/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
      },
      {
        path: 'register-agent',
        loadChildren: () => import('./routes/agent-registration/agent-registration.module').then(m => m.AgentRegistrationModule),
      },
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule),
      },
      /**
       * AUTHED
       */

      {
        path: 'my-listings',
        loadChildren: () => import('./routes/my-listings/my-listings.module').then(m => m.MyListingsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'verify-reo',
        loadChildren: () => import('./routes/verify-reo/verify-reo.module').then(m => m.VerifyReoModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'my-customers',
        loadChildren: () => import('./routes/my-customers/my-customers.module').then(m => m.MyCustomersModule),
        canActivate: [AuthGuard],
      },

      {
        path: 'purchase-profile',
        loadChildren: () => import('./routes/new-purchase-profile/new-purchase-profile.module').then(m => m.NewPurchaseProfileModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'purchased-properties',
        loadChildren: () => import('./routes/purchased-properties/purchased-properties.module').then(m => m.PurchasedPropertiesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'my-profile',
        loadChildren: () => import('./routes/my-profile/my-profile.module').then(m => m.MyProfileModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'saved-properties',
        loadChildren: () => import('./routes/saved-properties/saved-properties.module').then(m => m.SavedPropertiesModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'current-bids',
        loadChildren: () => import('./routes/current-bids/current-bids.module').then(m => m.CurrentBidsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'bid-deposit-status',
        loadChildren: () => import('./routes/bid-deposit-status/bid-deposit-status.module').then(m => m.BidDepositStatusModule),
        canActivate: [AuthGuard],
      },
      {
        path: '**',
        component: NoContentComponent,
        data: { title: 'Page Not Found' },
      },
    ],
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      useHash: false,
      preloadingStrategy: environment.settings.preloadRoutes ? PreloadAllModules : NoPreloading,
      scrollPositionRestoration: 'top',
      relativeLinkResolution: 'legacy',
      initialNavigation: 'enabledBlocking',
      onSameUrlNavigation: 'reload',
      anchorScrolling: 'enabled',
    }),
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy }],
  exports: [RouterModule],
})
export class AppRouterModule {}
