import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-property-options',
  templateUrl: './property-options.component.html',
  styleUrls: ['./property-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertyOptionsComponent {
  @Input() label!: string;
  @Input() required = false;
  @Input() control!: FormControl;
  @Input() options!: any[];
  @Output() selectOption = new EventEmitter();
}
