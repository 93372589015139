import { Injectable, Optional, Inject, EventEmitter } from '@angular/core';
import { Subject, Observable, isObservable } from 'rxjs';

import { MODAL_WIZARD_CONFIG_TOKEN } from '../modal-wizard-config.token';
import { ModalWizardConstants } from '../models/modal-wizard.constants';
import { ModalWizardModels } from '../models/modal-wizard.model';
import { ModalWizardStep } from '../directives/modal-wizard-step.directive';

@Injectable({
  providedIn: 'root',
})
export class ModalWizardService {
  private resetWizardSource: Subject<any> = new Subject<any>();
  private showNextStepSource: Subject<any> = new Subject<any>();
  private showPrevStepSource: Subject<any> = new Subject<any>();
  private showLastStepSource: Subject<any> = new Subject<any>();
  private showStepSource: Subject<ModalWizardStep> = new Subject<ModalWizardStep>();
  private stepChangedArgsSource: Subject<ModalWizardModels.StepChangedArgs> = new Subject<ModalWizardModels.StepChangedArgs>();

  resetWizard$: Observable<any> = this.resetWizardSource.asObservable();
  showNextStep$: Observable<any> = this.showNextStepSource.asObservable();
  showPrevStep$: Observable<any> = this.showPrevStepSource.asObservable();
  showLastStep$: Observable<any> = this.showLastStepSource.asObservable();
  showStep$: Observable<ModalWizardStep> = this.showStepSource.asObservable();
  stepChangedArgs$: Observable<ModalWizardModels.StepChangedArgs> = this.stepChangedArgsSource.asObservable();

  public validationEmitter$ = new EventEmitter();

  private defaultConfig: ModalWizardModels.WizardConfig = { ...ModalWizardConstants.DEFAULT_CONFIG };

  constructor(
    //private wizardStoreService: ModalWizardStoreService,
    @Optional() @Inject(MODAL_WIZARD_CONFIG_TOKEN) private config: ModalWizardModels.WizardConfig,
  ) {
    if (this.config) {
      this.defaultConfig = Object.assign(this.defaultConfig, this.config);
    }
  }

  getDefaultConfig(): ModalWizardModels.WizardConfig {
    return { ...this.defaultConfig };
  }

  reset() {
    this.resetWizardSource.next(null);
  }

  nextStep() {
    this.showNextStepSource.next(null);
  }

  prevStep() {
    this.showPrevStepSource.next(null);
  }

  lastStep() {
    this.showLastStepSource.next(null);
  }

  showStep(step: ModalWizardStep) {
    this.showStepSource.next(step);
  }

  stepChanged(args: ModalWizardModels.StepChangedArgs) {
    this.stepChangedArgsSource.next(args);
  }

  validateStep(type: string, args: ModalWizardModels.StepValidationArgs): boolean | Observable<boolean> {
    let step = type == 'entry' ? args?.toStep : args?.fromStep;
    let stepSpecificValidateMethod;

    if (step && step.componentRef) {
      stepSpecificValidateMethod = type == 'entry' ? step.componentRef.instance.canEntry : step.componentRef.instance.canExit;
    }

    if (stepSpecificValidateMethod) {
      if (typeof stepSpecificValidateMethod === typeof true) {
        return <boolean>stepSpecificValidateMethod;
      } else if (stepSpecificValidateMethod instanceof Function) {
        stepSpecificValidateMethod = stepSpecificValidateMethod.bind(step?.componentRef?.instance, args);
        let result = stepSpecificValidateMethod && stepSpecificValidateMethod();

        if (isObservable(result)) {
          return result as Observable<boolean>;
        } else if (typeof result === typeof true) {
          return <boolean>result;
        }
      }
    }

    return true;
  }
}
