import { Component, Input, OnInit } from '@angular/core';
import { ModalWizardModels } from 'src/app/components/modal-wizard/shared/models/modal-wizard.model';
import { BiddingModels } from '../../shared/models/bidding.model';
import { BiddingStoreService, BiddingStoreKey } from '../../store/bidding.store';

@Component({
  selector: 'app-bid-result-step-content',
  template: `
    <div class="bid-result-step-content">
      <app-bid-result></app-bid-result>
    </div>
  `
})
export class BidResultStepContentComponent implements ModalWizardModels.StepContent, OnInit {

  @Input() stepId = 'bid-result-step';

  biddingResult = BiddingModels.BiddingResult;
  result$ = this.biddingStoreService.getSelector(BiddingStoreKey.bidResult);

  constructor(private biddingStoreService: BiddingStoreService) { }

  ngOnInit(): void { }

  canEntry(args: ModalWizardModels.StepValidationArgs) {
    return true;
  }

  canExit(args: ModalWizardModels.StepValidationArgs) {
    return true;
  }
}
