import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { listenForSmallScreen } from '$utils';

export interface FileData {
  fileId: number;
  fileName: string;
  file?: Blob;
  thumbnailUrl?: string;
  isMain?: boolean;
  savedFileSize?: number;
  category?: string;
}

export interface AddNewFile {
  files: File[];
}

export interface MainFile {
  file_id: number;
  value?: boolean;
}

export interface RemoveFile {
  file_id: number;
}

@UntilDestroy()
@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadFilesComponent implements OnChanges, OnInit {
  @Input() files: FileData[] | null = null;
  @Input() maxFileSize: number = 1024;
  @Input() allowedMimeTypes?: string[];
  @Input() supportedFilesLabel = '';
  @Input() canSetMainFile = false;
  @Input() isLoading: boolean | null = false;
  @Input() categoryLabel: string | null | undefined = '';

  @Output() onAdd = new EventEmitter<AddNewFile>();
  @Output() onSetMain = new EventEmitter<MainFile>();
  @Output() onRemove = new EventEmitter<RemoveFile>();
  @Output() onRemoveAll = new EventEmitter();

  readonly mainFileControl = new FormControl();
  private lastSelectedFileId?: number;
  listenForSmallScreen = listenForSmallScreen;

  isDesktop: boolean = true;

  constructor(private notificationService: MessageService, private domSanitizer: DomSanitizer) {
    this.listenForSmallScreen()
      .pipe(untilDestroyed(this))
      .subscribe(val => (this.isDesktop = val));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['files'] && this.files) {
      this.syncMainFileControlOnListOfFilesChanges();
    }
  }

  ngOnInit() {
    this.emitMainFileChanges();
  }

  public handleDroppedFiles(evt: FileList) {
    this.onFileChange([...Array.from(evt)]);
  }

  public handleInputFiles(evt: any) {
    const el = evt.target as HTMLInputElement;
    const fileList: FileList | null = el.files;
    if (fileList) {
      this.onFileChange([...Array.from(fileList)]);
    }
    el.value = ''; // Allows input type file to trigger event if same file(s) is/are selected twice in a row
  }

  public deleteFile(fileId: number) {
    this.onRemove.emit({ file_id: fileId });
  }

  public createThumbnail(file: Blob | null) {
    if (file) {
      return this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
    } else {
      return '';
    }
  }

  public returnFileSize(number: number | null | undefined): string {
    if (!number) return '';
    if (number < 1024) {
      return `${number} bytes`;
    } else if (number >= 1024 && number < 1048576) {
      return `${(number / 1024).toFixed(1)} KB`;
    } else if (number >= 1048576) {
      return `${(number / 1048576).toFixed(1)} MB`;
    } else {
      return '0';
    }
  }

  public handleRemoveAll(): void {
    this.onRemoveAll.emit();
  }

  trackByFn(index: number, item: FileData): number {
    return item.fileId;
  }

  private findMainFile(): FileData | null {
    return this.files?.find(file => file.isMain === true) || null;
  }

  private syncMainFileControlOnListOfFilesChanges() {
    const mainFile = this.findMainFile();
    if (mainFile && mainFile.fileId !== this.lastSelectedFileId) {
      this.lastSelectedFileId = mainFile.fileId;
      this.mainFileControl.patchValue(mainFile?.fileId);
    }
  }

  private emitMainFileChanges() {
    this.mainFileControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (value !== this.lastSelectedFileId) {
        this.lastSelectedFileId = value;
        this.onSetMain.emit({ file_id: value });
      }
    });
  }

  private onFileChange(files: File[] | null) {
    let hasError = false;
    if (files) {
      for (let i = 0; i < files.length; i++) {
        // Check file size restrictions
        if (files[i].size > this.maxFileSize) {
          const nameLength: number = this.isDesktop ? 20 : 10;
          this.notificationService.add({
            severity: 'error',
            summary: this.truncateFilename(files[i].name, nameLength) + ': Invalid file size, ',
            detail: `maximum upload size is ${this.returnFileSize(this.maxFileSize)}`,
            key: 'file-size-error',
          });
          hasError = true;
          break;
        }

        // Check file type restrictions
        if (this.allowedMimeTypes && !this.allowedMimeTypes.includes(files[i].type)) {
          const nameLength: number = this.isDesktop ? 40 : 20;
          this.notificationService.add({
            severity: 'error',
            summary: this.truncateFilename(files[i].name, nameLength) + ': Invalid file type, ',
            detail: this.canSetMainFile ? `supports only JPG, PNG, and GIF` : `supports only JPG, PNG, GIF, MS Word and PDF`,
            key: 'file-type-error',
          });
          hasError = true;
          break;
        }
      }
    }

    if (files && files.length > 0 && !hasError) {
      // Emit dnd files or input file element files
      this.onAdd.emit({ files: files });
    }
  }

  public truncateFilename(filename: string, maxLength: number): string {
    if (!filename || maxLength <= 0) {
      return '';
    }

    if (filename.length > maxLength) {
      return filename.substring(0, maxLength - 3) + '...';
    } else {
      return filename;
    }
  }
}
