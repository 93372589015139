import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

export const GLOBAL_TOAST_KEY = 'GLOBAL-TOAST-KEY';
export const DEFAULT_TOAST_LIFE_MS = 5000;

/**
 * Wrapper for primeng message service
 */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(private messageService: MessageService) {}

  /**
   * Show a toast message
   * Adds a default {@link Message.life} of {@link DEFAULT_TOAST_LIFE_MS} if none defined
   * Adds a default {@link Message.key} of {@link GLOBAL_TOAST_KEY} if none defined
   */
  showToast(message: Message) {
    if (message.life == null) message.life = DEFAULT_TOAST_LIFE_MS;
    if (message.key == null) message.key = GLOBAL_TOAST_KEY;
    this.messageService.add(message);
  }
}
