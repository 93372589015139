import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { iif, of, throwError, tap, switchMap, map, catchError, filter, take } from 'rxjs';
import { has } from 'lodash';

import { SettingsService } from '$settings';
import { Models } from '$models';
import { NotificationService } from '$services';
import { BiddingService } from '../modals/bidding/shared/services/bidding.service';
import { BiddingStoreService } from '../modals/bidding/store/bidding.store';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

const BID_BUTTON_TITLE = 'Bid Now';

@UntilDestroy()
@Component({
  selector: 'app-bid-button',
  template: `
    <ng-container *ngIf="!displayAsLink; else bidLink">
      <button
        pButton
        pRipple
        [ngClass]="buttonClass"
        [disabled]="disabled"
        class="bid-button w-100"
        [label]="BID_BUTTON_TITLE"
        (click)="launchBidModal($event)"
        [title]="BID_BUTTON_TITLE"
      ></button>
    </ng-container>
    <ng-template #bidLink>
      <a class="bid-link" [class.disabled]="disabled" (click)="!disabled && launchBidModal($event)" [title]="BID_BUTTON_TITLE">{{ BID_BUTTON_TITLE }}</a>
    </ng-template>
  `,
  styles: [
    `
      a {
        cursor: pointer;
      }
      a.disabled {
        border-color: transparent !important;
        background-color: transparent !important;
      }
      button {
        white-space: nowrap;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidButtonComponent implements OnInit {
  @Input() propertyId: number | null = null;
  @Input() displayAsLink = false;
  @Input() disabled = false;
  @Input() buttonClass = '';

  @Output() done: EventEmitter<boolean> = new EventEmitter<boolean>();

  BID_BUTTON_TITLE = BID_BUTTON_TITLE;

  user: Models.LoginResponse | null = null;

  user$ = this.settings.user$.pipe(
    untilDestroyed(this),
    map(user => {
      this.user = user;
      return user;
    }),
    catchError(() => {
      this.user = null;
      return of(null);
    }),
  );

  private dialogRef: DynamicDialogRef | null = null;

  constructor(
    private biddingStore: BiddingStoreService,
    private biddingService: BiddingService,
    private notification: NotificationService,
    private settings: SettingsService,
    private router: Router,
  ) {
    this.user$.subscribe();
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        untilDestroyed(this),
      )
      .subscribe(() => {
        if (this.dialogRef) {
          this.dialogRef.close();
          this.dialogRef = null;
        }
      });
  }

  /**
   * Launch a modal window which gives the user a chance to bid
   */
  launchBidModal(e: MouseEvent): void {
    e.preventDefault();

    this.biddingStore.propertyDetails.reset();

    if (!this.user) {
      this.redirectToLogin();
      return;
    }

    // Getting property details
    iif(() => !!this.propertyId, this.biddingStore.propertyDetails.get({ apiUrlAppend: `/${this.propertyId}/details` }), of(null))
      .pipe(
        take(1),
        tap((res: Models.PropertyDetailsResponse | unknown) => {
          if (has(res, 'ITEM_ID')) {
            this.dialogRef = this.biddingService.launchBidModal();
          }
        }),
        switchMap((res: Models.PropertyDetailsResponse | unknown) => {
          if (res && Object.keys(res as Models.PropertyDetailsResponse).length) {
            // Getting the highest bid for it
            return this.biddingStore.currentBid$;
          }
          this.biddingStore.propertyDetails.reset();
          return throwError({ message: 'Could not get property details.' });
        }),
      )
      .subscribe({
        error: err => {
          if (![0, 404].includes(err.status)) {
            this.notification.showToast({
              styleClass: 'p-toast-message-error',
              closable: true,
              summary: 'Error',
              detail: err.MESSAGE || err.message,
            });
          }
        },
      });
    this.dialogRef?.onClose.pipe(take(1)).subscribe(() => this.done.emit(true));
  }

  private redirectToLogin() {
    // Don't throw a redirect url if this is the dashboard since that is default on login
    const returnUrl = this.router.url !== '/' && this.router.url !== '/login' ? this.router.url.split('?')[0] : null;
    this.router.navigate(['/login'], { queryParams: { currentUrl: returnUrl, target: 'bidding' } });
  }
}
