import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { catchError } from 'rxjs';
import { cloneDeep } from 'lodash';

import { Models } from '$models';
import { NotificationService } from '$services';
import { ModalWizardModels } from 'src/app/components/modal-wizard/shared/models/modal-wizard.model';
import { BiddingModels } from '../../shared/models/bidding.model';
import { BiddingStoreService, BiddingStoreKey } from '../../store/bidding.store';
import { ModalWizardComponent } from 'src/app/components/modal-wizard/modal-wizard.component';
import { BIDDING_DEFAULT_DATA } from '../../components/place-bid-form/place-bid-form.component';
import { BiddingService } from '../../shared/services/bidding.service';

@Component({
  selector: 'app-place-bid-form-step-content',
  template: `
    <app-place-bid-form
      (formReady)="onFormReady($event)"
      (formValuesChanged)="onFormValuesChanged($event)"
      (buyerAgentClick)="onBuyerAgentClicked($event)"
      (buyerAgentRemoveClick)="onBuyerAgentRemoveClicked($event)"
    ></app-place-bid-form>
  `,
})
export class PlaceBidFormStepContentComponent implements ModalWizardModels.StepContent {
  @Input() stepId = BiddingStoreKey.bidData;

  form: FormGroup | null = null;
  userType = Models.UserType;

  private values: Record<string, any> = {};
  private wizard: ModalWizardComponent | null = null;
  private forcedExit = false;

  constructor(private notification: NotificationService, private biddingStore: BiddingStoreService, private bidding: BiddingService) {}

  onFormReady(form: FormGroup): void {
    this.form = form;
  }

  onFormValuesChanged(data: any): void {
    this.values = data;
  }

  onBuyerAgentClicked(event: BiddingModels.BuyerAgentClickEvent): void {
    this.forcedExit = true;
    this.wizard?.goToStep(BiddingModels.BidStepIds.manageBuyerAgent);
  }

  onBuyerAgentRemoveClicked(event: BiddingModels.BuyerAgentClickEvent): void {
    if (event?.agent) {
      this.removeBuyerAgent();
    }
  }

  canEntry(args: ModalWizardModels.StepValidationArgs) {
    this.wizard = args.wizardRef;
    return true;
  }

  canExit(args: ModalWizardModels.StepValidationArgs) {
    // If form data wasn't changed, let move to child step
    if (this.forcedExit || (this.form?.untouched && !!args.toStep?.parentStepId)) {
      this.forcedExit = false;
      return true;
    }
    const isFormValid = this.form ? this.form.valid : true;

    // If moving backward, reset form and update state
    if (args.direction === ModalWizardModels.StepDirection.backward) {
      if (!isFormValid) {
        this.form?.reset({ ...BIDDING_DEFAULT_DATA }, { emitEvent: false });
        // Update store
        this.biddingStore.update({
          [BiddingStoreKey.bidData]: null,
        });
        return true;
      }
    }

    if (isFormValid) {
      // Stores data in store
      this.biddingStore.update({
        [BiddingStoreKey.bidData]: cloneDeep(this.values),
      });
      return true;
    }

    return false;
  }

  private removeBuyerAgent(): void {
    this.bidding
      .removeBuyerAgent()
      .pipe(
        catchError(error => {
          this.notification.showToast({
            styleClass: 'p-toast-message-error',
            closable: true,
            summary: 'Error',
            detail: 'Could not remove buyer agent.',
          });
          return error;
        }),
      )
      .subscribe();
  }
}
