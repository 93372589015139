import { Injectable } from '@angular/core';
import { environment } from '$env';
import { HttpClient } from '@angular/common/http';
import { Models } from '../../../../shared/models';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  public bidDepositStatus = (id: number) =>
    this.http.post<Models.BidDepositStatus>(environment.endpoints.apiUrl + 'bidding/deposit/my/status', { CUSTOMER_ID: id });

  constructor(private readonly http: HttpClient) {}
}
