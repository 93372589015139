import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-bid-summary',
  template: `
    <div class="bid-summary">
      <div *ngIf="property" class="section property-address">
        <h6>Property Address:</h6>
        <p>{{ property?.ADDRESS1 }} {{ property?.ADDRESS2 }}, {{ property?.CITY }}, {{ property?.STATE }} {{ property?.POSTAL_CODE }}</p>
      </div>
      <div *ngIf="bidAmount" class="section bid-amount">
        <h6>Bid Amount: </h6>
        <h2>{{ bidAmount | currency: 'USD':'symbol':'1.0-0' }}</h2>
      </div>
      <div *ngIf="bidAssistAmount" class="section bid-assist-amount">
        <h6>BidAssist Amount:</h6>
        <h2>{{ bidAssistAmount | currency: 'USD':'symbol':'1.0-0' }}</h2>
      </div>
      </div>
  `,
  styles: [
    `
      .bid-summary {
        margin-bottom: 38px;
      }
      .bid-summary .section {
        margin-top: 25px;
      }
      .bid-summary .section p {
        margin-bottom: 0;
      }
      .bid-summary .section h6 {
        margin-bottom: 0.2rem;
      }
      .bid-summary .section.bid-amount h6,
      .bid-summary .section.bid-assist-amount h6 {
        font-weight: normal !important;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BidSummaryComponent {

  @Input() bidAmount?: number;
  @Input() bidAssistAmount?: number;
  @Input() property?: any;

  constructor() { }
}
