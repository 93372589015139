<div class="mt-4">
  <h4>Ohio User Terms</h4>

  <p><b>User Terms for Those Bidding on Ohio “1st Chance” Foreclosure Sale Properties only</b></p>

  <p>
    These additional User Terms (“Terms”) apply to each Ohio foreclosure sale auction conducted by the court-authorized Private Selling Officer (“PSO”) in
    cooperation with RealtyBid online at <a rel="noopener" href="https://www.realtybid.com" target="_blank" rel="noreferrer noreopen">www.RealtyBid.com</a>. As
    used in these additional Terms, each prospective buyer who participates in any auction is a “Bidder.” A “Winning Bidder” is the bidder that submits the
    highest net bid in an Auction, meets all the terms and conditions of the sale and is acknowledged by the PSO and RealtyBid.com as the winning bidder. As
    used in these Terms, each bid submitted in any auction is an “Offer.”
  </p>

  <p>
    Neither PSO, RealtyBid.com, plaintiff, or Seller (including their parent, subsidiary, or affiliated entities) nor any of their respective directors,
    members, managers, employees, agents, or representatives (collectively, the “Parties”) is acting as an agent for any Bidder in any capacity. By submitting a
    bid on the Site, Bidder acknowledges having read and accepted these additional Terms.
  </p>

  <p><b>All USERS MUST AGREE TO THE USER TERMS BEFORE BIDDING.</b></p>

  <b>1. Fees & Services</b>
  <p>
    Payment of Sale Deposit for Properties in Ohio: the winning bidder must further pay a Sale Deposit required by Ohio state statute to the designated title
    company (the “Ohio Sale Deposit”). The amount of the Ohio Sale Deposit specified by Ohio law is as follows:
  </p>

  <div class="tos-table-ohio">
    <div class="tos-table-ohio_header">Appraised Value of Property</div>
    <div class="tos-table-ohio_header">Sale Deposit</div>
    <div>$0 to $10,000</div>
    <div>$2,000</div>
    <div>$10,001 to $200,000</div>
    <div>$5,000</div>
    <div>Over $200,001</div>
    <div>$10,000</div>
  </div>

  <p>
    Unless otherwise specified in writing by the PSO or RealtyBid.com, if the auction occurs online, the Ohio Sale Deposit must be received no later than
    twenty-four (24) hours after the end of the auction. For a Live Event, the Ohio Sale Deposit must be received immediately after the conclusion of the
    auction. If the Ohio Sale Deposit (online) is not paid in the allotted twenty-four (24) hours by the winning bidder, the following will occur: (1) the PSO
    and/or RealtyBid.com may declare Winning Bidder to be in default of these Terms (2) the sale may be stopped from being finalized; (3) the Property may be
    re-auctioned by the PSO on the date set forth in the notice of sale; (4) the winning bidder consents to a Court order vacating the sale; and (5) the winning
    bidder shall be subject to the failure to perform section below.
  </p>

  <p>
    We reserve the right to change our User Terms at any time relating to fees and other items mentioned or not mentioned in this published document. We may in
    our sole discretion change any or all our services at any time. If a change occurs in a particular service, the fees for that service are effective at the
    introduction of the service. If a Property is subject to a buyer’s premium as outlined in the Internet Transaction Fee (a.k.a. Buyer's Premium), the
    Internet Transaction Fee will be disclosed on the Property Bid Page and will be paid by the BUYER at closing. The bidding will not reflect the Internet
    Transaction Fee, but the Purchase Agreement shall include the Internet Transaction Fee through a RealtyBid Addendum, which shall be attached to the Purchase
    Agreement and signed by both Buyer and Seller.
  </p>

  <b>2. Buying</b>
  <p>
    Bidding/Winning: Each bid is, by law, an irrevocable offer to purchase, and each higher bid provided by a Bidder shall cancel an earlier bid placed by such
    Bidder. If the Bidder who places the highest bid in an auction fails to meet the requirements of these Terms, such Bidder’s bid may be rejected at the sole
    and absolute discretion of the PSO and RealtyBid.com. A Winning Buyer will be declared upon verification that Bidder has met these Terms, including, but not
    limited to, timely payment of the the sale deposit. A Winning Buyer’s high bid shall be deemed the “Winning Bid Amount” and the “Total Purchase Price”
    unless there is a Buyer’s Premium, in which case the sum of the Buyer’s Premium and the Winning Bid Amount will be considered the Total Purchase Price
    payable by the Winning Buyer. The Winning Bid amount must be greater than the Minimum Sale Price, defined below, for a sale to be valid. In the event a
    Winning Bid Amount is not equal to or above the Minimum Sale Price then the sale may be stopped from being finalized and the Winning Bidder consents to a
    Court order vacating the sale of such Property. If an auction for a Property completes with no bid above the Minimum Sales Price, a subsequent Auction will
    be held on the date provided in the Notice of Sale.
  </p>

  <p>
    Purchaser Information Form: If the auction occurs online, a Purchaser Information Form will be emailed to Winning Buyer for completion using the email
    address associated with the Winning Buyer’s RealtyBid.com account. Winning Buyer must complete and return the Purchaser Information Form and any other
    documentation supplied to Winning Buyer within 24 hours after the closing of the Auction (unless a longer timeframe is specified in writing by PSO or
    RealtyBid.com), time being of the essence. If an auction takes place at a Live Event, a Purchaser Information Form will be provided to Winning Buyer for
    completion at the sale. Winning buyer must fill out the Purchaser immediately upon receipt from the PSO. If Winning Buyer fails to timely complete and sign
    any portion of the required documentation, the PSO or RealtyBid.com may declare Winning Buyer to be in default of these Terms and Winning Buyer may be held
    in contempt of court and the Property may immediately be resold. If Winning Buyer elects to take title (Vesting Name) in the name of an entity (not as an
    individual) then the appropriate information must be provided on the Purchaser Information Form. Additional information may be required to be provided to
    PSO, RealtyBid.com or title company upon request.
  </p>

  <p>
    Property Conveyance: All Properties are to be conveyed by a Private Selling Officer’s Deed signed by the PSO in accordance with section 5302.1 of the Ohio
    Revised Code and court order. No warranties of title are made in connection with the conveyance of title.
  </p>

  <p><b>SUBJECT TO COURT CONFIRMATION:</b> All Property sales are subject to confirmation by the court.</p>

  <b>3. Auction Details</b>
  <p>
    Minimum Sale Bid: Unless otherwise specified for a particular Property, all Properties in auction have a minimum selling bid (aka Opening Bid) equal to
    two-thirds of the appraised value of the Property as set forth by statute. The minimum sales bid may not apply for all sales as allowed by statute or court
    order.
  </p>

  <p>
    Balance Due: The remaining balance of the Total Purchase Price must be paid with thirty (30) days after the confirmation of sale by the court. Any failure
    to timely pay the required Balance Due amount may nullify the sale and result in a finding of contempt of court. Winning Buyer shall also be responsible for
    those costs, allowances and taxes that the proceeds of the sale are insufficient to cover. Winning Buyer is responsible for all closing costs.
  </p>

  <p>
    Bid Extension Time: If a bid is placed in the last five (5) minutes before the scheduled auction end time, bidding will extend for an additional five (5)
    minutes. Bid extensions will continue until a five-minute period of time elapses after the stated auction end time with no advance in the bidding.
  </p>

  <p>
    Provisional Sale: If the first auction of a Property ends with no bid meeting or exceeding the Minimum Sale Bid, the Property may be re-auctioned on the
    Provisional Sale Date as listed in the Notice of Sale. This Provisional Sale may be held without regard to a Minimum Sale Bid, as permitted by statute and
    at the discretion of RealtyBid.com. The Provisional Sale will take place on
    <a href="https://www.realtybid.com" target="_blank" rel="noreferrer noreopen" alt="www.realtybid.com">www.realtybid.com</a> and will follow the same
    processes and procedure as the first Auction.
  </p>

  <p>
    Cancellations and Postponements: The sale of a particular Property may be cancelled or postponed for various reasons, and there is no guarantee that a
    particular Property will be auctioned. As such, the sale may be postponed or cancelled at any time prior to or during the auction so Bidders should check
    back often for updated sale date information. All cancelled or postponed sales as well as the date of the rescheduled sale (if applicable) will be announced
    on the WebSite.
  </p>

  <b>4. General Information</b>

  <p>These Terms and the conduct of each auction shall be governed by the laws of Ohio.</p>

  <p>
    By registering to bid online for an auction, Bidders understand that a Winning Bidder is legally obligated to timely pay the Sale Deposit and the remaining
    Balance Due on the Total Purchase Price. RealtyBid and the PSO reserve the right to reject any Offer in their sole and absolute discretion. Any failure to
    timely pay the required amounts may result in a finding of contempt of court and/or other consequences.
  </p>

  <p>
    Please be aware that all transfers of title are pursuant to Ohio Code
    <a href="https://codes.ohio.gov/ohio-revised-code/section-2329.31" target="_blank" rel="noreferrer noreopen">2329.31</a>:
  </p>

  <b>2329.31 Confirmation and order for deed</b>
  <p>
    (A) Upon the return of any writ of execution for the satisfaction of which lands and tenements have been sold, on careful examination of the proceedings of
    the officer making the sale, if the court of common pleas finds that the sale was made, in all respects, in conformity with sections
    <a href="https://codes.ohio.gov/ohio-revised-code/section-2329.01" target="_blank" rel="noreferrer noreopen">2329.01</a> to
    <a href="https://codes.ohio.gov/ohio-revised-code/section-2329.61" target="_blank" rel="noreferrer noreopen">2329.61</a> of the Revised Code, it shall,
    within thirty days of the return of the writ, direct the clerk of the court of common pleas to make an entry on the journal that the court is satisfied of
    the legality of such sale. Nothing in this section prevents the court of common pleas from staying the confirmation of the sale to permit a Property owner
    time to redeem the Property or for any other reason that it determines is appropriate. In those instances, the sale shall be confirmed within thirty days
    after the termination of any stay of confirmation.
  </p>

  <p>
    (B) The officer making the sale shall require the purchaser to pay within thirty days of the confirmation of the sale the balance due on the purchase price
    of the lands and tenements.
  </p>

  <p>(C)</p>
  <p>
    (1) The officer making the sale shall record the prepared deed required by section
    <a href="https://codes.ohio.gov/ohio-revised-code/section-2329.36" target="_blank" rel="noreferrer noreopen">2329.36</a> of the Revised Code within fourteen
    days after the confirmation of sale and payment of the balance due.
  </p>

  <p>(2)</p>

  <p>
    (a) If the deed is not prepared and recorded within the fourteen-day period, the purchaser may file a motion with the court to proceed with the transfer of
    title. If the court finds that a proper sale was made, it shall enter an order transferring the title of the lands and tenements to the purchaser, ordering
    the plaintiff to present a certified copy of the order to the county recorder for recording, and ordering the county recorder to record the order in the
    record of deeds. The order, when filed with the county recorder, shall have the same effect as a deed prepared pursuant to section
    <a href="https://codes.ohio.gov/ohio-revised-code/section-2329.36" target="_blank" rel="noreferrer noreopen">2329.36</a> of the Revised Code.
  </p>

  <p>
    (b) Upon the issuance of the court order described in division (C)(2)(a) of this section, the plaintiff, or the plaintiff's attorney, shall present a
    certified copy of the order to be recorded in the office of the county recorder. The county recorder shall record the order in the record of deeds.
  </p>

  <p>
    (c) The clerk shall issue a copy of the court order to the county auditor to transfer record ownership of the lands and tenements for the purpose of real
    estate taxes. Real estate taxes coming due after the date of the sale shall not prohibit the auditor from transferring ownership of the lands and tenements
    on its records or cause the recorder to deny recording. The real estate taxes shall become the responsibility of the new title holder of the lands and
    tenements. The sheriff shall not require the confirmation of sale to be amended for taxes not due and payable as of the date of the sale.
  </p>

  <p>Amended by 132nd General Assembly File No. TBD, HB 49, §101.01, eff. 9/29/2017.</p>

  <p>Amended by 131st General Assembly File No. TBD, HB 390, §101.01, eff. 9/28/2016.</p>

  <p>Effective Date: 10-01-1953; 2008 HB138 09-11-2008</p>
</div>
