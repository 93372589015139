<div class="container g-0" *ngIf="{ authState: authState$ | async, errorApi: errorApi$ | async, waiting: waiting$ | async } as comp">
  <div class="row flex-column align-items-center m-auto">
    <!-- Logout and session expired messages -->
    <p-message
      severity="warn"
      class="g-0"
      styleClass="w-100 mb-4"
      text="Session expired, please log in again"
      *ngIf="!comp.errorApi && comp.authState === authState.sessionExpired"></p-message>
    <p-message
      severity="success"
      class="g-0"
      styleClass="w-100 mb-4"
      text="Sign up successful, please log in to continue"
      *ngIf="comp.authState === authState.newSignup">
    </p-message>
    <p-message
      severity="success"
      class="g-0"
      styleClass="w-100 mb-4"
      text="You have successfully logged out"
      *ngIf="showLogoutMessage && comp.authState === authState.loggedOut">
    </p-message>
    <!-- Login failure -->
    <p-message severity="error" class="g-0 mb-4" [text]="comp.errorApi.errorMsg" *ngIf="comp.errorApi"> </p-message>

    <div class="col g-0">
      <!--
      <p>cURL Login Test</p>
      <iframe src="/assets/login.html" height="220px" width="100%" [frameBorder]="0"></iframe>
      <hr />
      -->
      <form
        [formGroup]="formMain"
        novalidate
        (ngSubmit)="onLogin()"
        class="d-flex flex-column"
        [ngClass]="{ 'show-errors-on-touched': errorCondition.touch, 'show-errors-on-submit': errorCondition.submit }">
        <div class="mb-4">
          <label for="userName">Email</label>
          <input id="userName" type="text" class="w-100" pInputText formControlName="userName" placeholder="e.g. johnwhite@gmail.com" />
          <app-input-error controlName="userName" friendlyName="email address" [anyErrorShowInvalid]="true" [errorCondition]="errorCondition"></app-input-error>
        </div>

        <p class="mb-4">
          <label for="password">Password</label>
          <p-password
            name="password"
            id="password"
            styleClass="w-100 p-input-icon-right"
            inputStyleClass="w-100"
            [toggleMask]="true"
            [feedback]="false"
            formControlName="password"></p-password>
          <app-input-error controlName="password" friendlyName="password" [anyErrorShowInvalid]="true" [errorCondition]="errorCondition"></app-input-error>
        </p>

        <div class="d-flex flex-column gap-4 justify-content-between align-items-center mt-5 flex-md-row gap-md-0 mt-md-1">
          <p-checkbox formControlName="remember" class="small align-self-start" [binary]="true" label="Remember me"></p-checkbox>
          <button
            class="align-self-stretch align-self-md-start"
            pButton
            pRipple
            type="submit"
            iconPos="right"
            [disabled]="comp.waiting"
            [loading]="!!comp.waiting"
            label="Log in"></button>
        </div>
      </form>

      <div *ngIf="showForgotPassword" class="d-flex flex-column mt-4 g-0 align-items-center align-items-md-start">
        <a routerLink="/forgot-password/" queryParamsHandling="merge">
          <button pButton class="p-button-link primary-color align-self-start p-0" label="Forgot Password"></button>
        </a>
      </div>
    </div>
  </div>
</div>
