import { AuthService } from '$services';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { listenForLargeScreen } from '../../../shared/utils';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
  @Input() isHomePage: boolean | null = false;
  public readonly loggedIn$ = this.auth.isLoggedIn$;
  public readonly listenForLargeScreen$ = listenForLargeScreen();

  constructor(private readonly auth: AuthService) {}
}
