<div
  class="galleria image-container"
  [ngClass]="['img-size-' + size]"
  *ngIf="{
    imagesSrc: imagesSrc$ | async,
    showCarousel: showCarousel$ | async
  } as galleria">
  <p-galleria
    *ngIf="galleria.imagesSrc?.length"
    [value]="galleria.imagesSrc || []"
    [activeIndex]="activeIndex"
    [showThumbnails]="false"
    [showItemNavigators]="galleria.showCarousel || false"
    [showIndicators]="showIndicators && !!galleria.showCarousel"
    [changeItemOnIndicatorHover]="galleria.showCarousel || false"
    [showIndicatorsOnItem]="galleria.showCarousel || false"
    [circular]="true"
    (mouseover)="preloadImages()"
    (activeIndexChange)="onActiveIndexChange($event)">
    <ng-template pTemplate="item" let-item>
      <div
        class="image-wrapper w-100"
        [ngStyle]="{
          'background-image': fitImage ? 'url(' + item?.previewImageSrc + ')' : null
        }">
        <img
          [src]="item?.previewImageSrc"
          [alt]="item?.alt"
          [ngClass]="imageErrors[item?.index] || fitImage ? 'd-none' : 'd-block'"
          (load)="imageErrors[item?.index] = false"
          (error)="imageErrors[item?.index] = true" />
        <img class="image" [src]="missingImageUrl" [ngClass]="imageErrors[item?.index] ? 'd-block' : 'd-none'" />
      </div>
    </ng-template>
  </p-galleria>

  <ng-content></ng-content>
</div>
