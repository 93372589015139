import { AuctionStatus, AuctionTimelineStates, Models } from '../../models';
import { getAuctionStateForeclosure, getAuctionStateRegular } from './flow-strategies.service';
import { CardData, CardTypes, FlowType, FlowTypeConstructor } from './flow.service';

interface PropertyCardCommon {
  address?: string;
  city?: string;
  state?: string;
  bed?: number;
  bath?: number;
  sqft?: number;
  categoryId?: number;
  estimatedvalue?: number;
}

/**
 * Represents property card (for example when we click on marker on the map we get this card)
 */
export interface PropertyCard extends AuctionStatus, PropertyCardCommon {
  bidStatus?: 'Opening Bid' | 'Current Bid' | 'Winning bid';
  bidAmt?: number;
  activeDateTitle?: 'Auction Starts In' | 'Auction date' | 'Auction ends in' | 'Post Auction ends in' | 'New auction date';
  activeDate?: string;
  estimatedvalue?: number;
}

export const LiveForeclosureFlowType: FlowTypeConstructor<PropertyCard> = class LiveForeclosureFlowType extends FlowType<PropertyCard> {
  public static readonly type = CardTypes.propertyCard;
  public static isFlowTypeFitData(data: CardData): boolean {
    return isForeclosureSalesFlowType(data);
  }
  private originalData: CardData;
  constructor(data: CardData) {
    super();
    this.originalData = data;
  }

  override getUiData(): PropertyCard {
    let returnObject: PropertyCardCommon = {
      address: this.originalData.address,
      city: this.originalData.city,
      state: this.originalData.state,
      bed: this.originalData.bed,
      bath: this.originalData.bath,
      sqft: this.originalData.sqft,
      categoryId: this.originalData.categoryId,
      estimatedvalue: this.originalData.estimatedvalue,
    };

    switch (this.auctionState) {
      case AuctionTimelineStates.AUCTION_NOT_YET_STARTED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_NOT_YET_STARTED,
          activeDateTitle: 'Auction date',
          activeDate: this.originalData.date_end,
          ...returnObject,
        };
      case AuctionTimelineStates.AUCTION_COMING_SOON:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_COMING_SOON,
          activeDateTitle: 'Auction date',
          ...returnObject,
        };
      case AuctionTimelineStates.ACTIVE_AUCTION:
        return {
          auctionStatus: AuctionTimelineStates.ACTIVE_AUCTION,
          activeDateTitle: 'Auction date',
          activeDate: this.originalData.date_end,
          ...returnObject,
        };
      case AuctionTimelineStates.POST_AUCTION:
        throw new Error('Live Foreclosure cannot be in Post Auction state');
      case AuctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD,
          activeDateTitle: 'Auction date',
          activeDate: this.originalData.date_end,
          ...returnObject,
        };
      case AuctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED,
          ...returnObject,
        };
      case AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_CANCELLED:
        throw new Error('Live Foreclosure cannot be in Cancelled state');
      case AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED,
          activeDateTitle: 'New auction date',
          activeDate: this.originalData.date_end,
          ...returnObject,
        };
    }
  }

  override get auctionState(): AuctionTimelineStates {
    return getAuctionStateForeclosure(this.originalData);
  }
};

export const RegularFlowType: FlowTypeConstructor<PropertyCard> = class RegularFlowType extends FlowType<PropertyCard> {
  public static readonly type = CardTypes.propertyCard;
  public static isFlowTypeFitData(data: CardData): boolean {
    return isRegularFlowType(data);
  }
  private originalData: CardData;
  constructor(data: CardData) {
    super();
    this.originalData = data;
  }

  override getUiData(): PropertyCard {
    let returnObject: PropertyCardCommon = {
      address: this.originalData.address,
      city: this.originalData.city,
      state: this.originalData.state,
      bed: this.originalData.bed,
      bath: this.originalData.bath,
      sqft: this.originalData.sqft,
      categoryId: this.originalData.categoryId,
      estimatedvalue: this.originalData.estimatedvalue,
    };

    switch (this.auctionState) {
      case AuctionTimelineStates.AUCTION_NOT_YET_STARTED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_NOT_YET_STARTED,
          activeDateTitle: 'Auction Starts In',
          activeDate: this.originalData.date_end,
          bidStatus: 'Opening Bid',
          bidAmt: this.originalData.bidamt,
          ...returnObject,
        };
      case AuctionTimelineStates.AUCTION_COMING_SOON:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_COMING_SOON,
          activeDateTitle: 'Auction date',
          ...returnObject,
        };
      case AuctionTimelineStates.ACTIVE_AUCTION:
        return {
          auctionStatus: AuctionTimelineStates.ACTIVE_AUCTION,
          bidStatus: this.originalData.handleId === 'Bid Now' ? 'Opening Bid' : 'Current Bid',
          bidAmt: this.originalData.bidamt,
          activeDateTitle: 'Auction ends in',
          activeDate: this.originalData.date_end,
          ...returnObject,
        };
      case AuctionTimelineStates.POST_AUCTION:
        return {
          auctionStatus: AuctionTimelineStates.POST_AUCTION,
          bidStatus: this.originalData.handleId === 'Bid Now' ? 'Opening Bid' : 'Current Bid',
          bidAmt: this.originalData.bidamt,
          activeDateTitle: 'Post Auction ends in',
          activeDate: this.originalData.date_end,
          ...returnObject,
        };
      case AuctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_HAS_ENDED_AS_SOLD,
          bidStatus: 'Winning bid',
          bidAmt: this.originalData.bidamt,
          activeDate: this.originalData.sold_date,
          ...returnObject,
        };
      case AuctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_HAS_ENDED_AS_RETURNED,
          ...returnObject,
        };
      case AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_CANCELLED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_CANCELLED,
          activeDateTitle: 'Auction date',
          activeDate: this.originalData.date_end,
          ...returnObject,
        };
      case AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED:
        return {
          auctionStatus: AuctionTimelineStates.AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED,
          activeDateTitle: 'New auction date',
          activeDate: this.originalData.date_end,
          ...returnObject,
        };
    }
  }

  override get auctionState(): AuctionTimelineStates {
    return getAuctionStateRegular(this.originalData);
  }
};

function isForeclosureSalesFlowType(propertyData: CardData): boolean {
  return (
    propertyData.categoryId === Models.PropertyCategoryType.LIVE_FORECLOSURE_SALES ||
    propertyData.categoryId === Models.PropertyCategoryType.LOCAL_COUNTY_ONLINE_FORECLOSURE_SALES
  );
}

function isRegularFlowType(propertyData: CardData): boolean {
  return !isForeclosureSalesFlowType(propertyData);
}
