import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html',
  styleUrls: ['./loading-spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingSpinnerComponent implements OnInit {
  @Input() loading: boolean | null = true;
  @Input() inline: boolean | null = false;
  @Input() fullScreen: boolean | null = false;
  @Input() size: string | null = null;

  constructor() { }

  ngOnInit(): void {
  }

}
