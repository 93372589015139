import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Dropdown } from 'primeng/dropdown';

/**
 * Usage example:
 *
  <app-icon-dropdown
    [formControl]="test"
    [options]="[
      { label: 'Property Address', value: 'default' },
      { label: 'Property Item ID', value: 'itemID' },
      { label: 'Property Trustee #', value: 'trusteeNumber' },
      { label: 'Property Case #', value: 'caseNumber' },
      { label: 'Property Parcel #', value: 'parcelID' }
    ]"></app-icon-dropdown>
 */

@UntilDestroy()
@Component({
  selector: 'app-icon-dropdown',
  templateUrl: './icon-dropdown.component.html',
  styleUrls: ['./icon-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IconDropdownComponent),
      multi: true,
    },
  ],
})
export class IconDropdownComponent implements OnInit, ControlValueAccessor {
  @Input() options!: Dropdown['options'];
  @ViewChild(Dropdown, { read: Dropdown }) dropdown!: Dropdown;

  onChange?: (value: any) => void;
  onTouched: () => void = () => {};
  readonly localFormControl = new FormControl('');

  ngOnInit() {
    if (!this.options) {
      throw new Error('Required input field "options" is not set');
    }

    this.localFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (!!this.onChange) {
        this.onChange(value);
      }
    });
  }

  writeValue(value: any) {
    this.localFormControl.setValue(value);
  }

  registerOnChange(onChange: (value: any) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  toggleDropdown() {
    if (this.dropdown.overlayVisible) {
      this.dropdown.hide();
    } else {
      this.dropdown.show();
    }
  }
}
