export interface FeatureNavRoute {
  id: number;
  title: string;
  name?: string;
  relativeUrl: string;
  nextRoute?: number;
  previousRoute?: number;
  visited?: boolean;
  routeState?: {[k: string]: any} | null;
  disabled?: boolean;
  isSubmitRoute?:boolean;
  isSaved?: boolean;
}

export const FEATURE_NAV_ROUTES_DEFAULT: FeatureNavRoute = {
  id: 0,
  title: '',
  relativeUrl: '',
  routeState: null,
  disabled: false,
}
