<div class="wrapper badge">
  <h6 class="badge--heading">RealtyBid VIP Investor Program</h6>
  <p class="badge--body-title-top">Rewarding Loyalty with Buying Advantages</p>
  <img src="/assets/images/program-bronze.svg" alt="bronze badge" class="badge--bronze badge--bronze__large badge-img" />
  <p class="badge--body-title-center">Become a Bronze Level VIP!</p>
  <p class="badge--body-text-center">
    When you make your first Investor purchase and also meet the minimum purchase price threshold, you`ll become a VIP Investor and unlock perks that will
    enhance your future buying experiences.
  </p>
  <p-divider styleClass="badge--divider"></p-divider>
  <a href="/help-center/investor-program" class="badge--vip-investor-program-link">Click here to learn more about our VIP Investor Program</a>
  <div class="badge--badges-wrapper badges-wrapper">
    <img src="/assets/images/program-premium-pro.svg" alt="premium-pro badge" class="badges-wrapper--premium-pro badge-img" />
    <img src="/assets/images/program-premium.svg" alt="premium badge" class="badges-wrapper--permium badge-img" />
    <img src="/assets/images/program-gold.svg" alt="gold badge" class="badges-wrapper--gold badge-img" />
    <img src="/assets/images/program-silver.svg" alt="silver badge" class="badges-wrapper--silver badge-img" />
    <img src="/assets/images/program-bronze.svg" alt="bronze badge" class="badges-wrapper--bronze badge-img" />
  </div>
</div>
