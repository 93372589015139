export interface WeissTier {
  tier_count: number;
  tier_min: number;
  tier_max: number;
  last_year_change: number;
  forecast: number;
  volatility: number;
  frac_rising: number;
  dispersion: number;
}

export interface WeissIndex {
  CBSA_code: number;
  county_code: number;
  metroname: string;
  countyname: string;
  medianValue: number;
  metroforcast: number;
  countyforcast: number;
  zipforcast: number;
  metroReport: MetroReport;
  resultCode: number;
  transactionGUID: string;
  zipIndex: number[];
  metroIndex: number[];
}

interface MetroReport {
  valuation: {
    tiers: {
      [key: number]: WeissTier;
    };
  };
  city_distance: {
    tiers: {
      [key: number]: WeissTier;
    };
  };
  square_feet: {
    tiers: {
      [key: number]: WeissTier;
    };
  };
  year_built: {
    tiers: {
      [key: number]: WeissTier;
    };
  };
}

export interface WeissTier {
  tier_count: number;
  tier_min: number;
  tier_max: number;
  last_year_change: number;
  forecast: number;
  volatility: number;
  frac_rising: number;
  dispersion: number;
}

export interface WeissLineIndices {
  pastIndex: number[];
  zipIndex: number[];
  metroIndex: number[];
}

export interface WeissLineChartResponse {
  Results: {
    forecast: number;
    url: string;
    indexLevelUsed: number;
    oneYearChange: number;
    addressAdjustment: string;
    lon: null;
    forecastRank: number;
    oneYearRank: number;
    houseRanking: number;
    pastSaleDate: number;
    use_code: string;
    bin: number;
    DistressedDiscount: number;
    DistressedDiscountOwnerOnly: number;
    DistressedNonOnly: number;
    EstimatedLTV: number;
    EstimatedsecondLTV: number;
    pastIndex: number[];
  };
  resultCode: number;
  customerID: number;
  message: string;
  transactionGUID: string;
}
export interface WeissLineIndices {
  pastIndex: number[];
  zipIndex: number[];
  metroIndex: number[];
}

export interface TierTableDataSection {
  tierOne: {
    lastYearChange: number;
    forecast: number;
    rising: number;
  };
  tierTwo: {
    lastYearChange: number;
    forecast: number;
    rising: number;
  };
  tierThree: {
    lastYearChange: number;
    forecast: number;
    rising: number;
  };
  tierFour: {
    lastYearChange: number;
    forecast: number;
    rising: number;
  };
}
export interface TierTableData {
  sqft: TierTableDataSection;
  valuation: TierTableDataSection;
  yrBuilt: TierTableDataSection;
}

export const nullTierTableData: TierTableData = {
  sqft: {
    tierOne: {
      lastYearChange: 0,
      forecast: 0,
      rising: 0,
    },
    tierTwo: {
      lastYearChange: 0,
      forecast: 0,
      rising: 0,
    },
    tierThree: {
      lastYearChange: 0,
      forecast: 0,
      rising: 0,
    },
    tierFour: {
      lastYearChange: 0,
      forecast: 0,
      rising: 0,
    },
  },
  valuation: {
    tierOne: {
      lastYearChange: 0,
      forecast: 0,
      rising: 0,
    },
    tierTwo: {
      lastYearChange: 0,
      forecast: 0,
      rising: 0,
    },
    tierThree: {
      lastYearChange: 0,
      forecast: 0,
      rising: 0,
    },
    tierFour: {
      lastYearChange: 0,
      forecast: 0,
      rising: 0,
    },
  },
  yrBuilt: {
    tierOne: {
      lastYearChange: 0,
      forecast: 0,
      rising: 0,
    },
    tierTwo: {
      lastYearChange: 0,
      forecast: 0,
      rising: 0,
    },
    tierThree: {
      lastYearChange: 0,
      forecast: 0,
      rising: 0,
    },
    tierFour: {
      lastYearChange: 0,
      forecast: 0,
      rising: 0,
    },
  },
};
