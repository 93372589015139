import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService, AuthState } from '$services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { InputErrorShowCondition } from '../input-error/input-error.component';
import { BehaviorSubject, catchError, of, tap } from 'rxjs';
import { Models } from '../../shared/models';
import { PasswordData } from '../password-change-form/password-change-form.component';

type VIEW = 'EMAIL' | 'EMAIL_SENT' | 'PASSWORD' | 'PASSWORD_RESET';

@UntilDestroy()
@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
})
export class ForgotPasswordFormComponent implements OnInit, OnDestroy {
  @Input() resetToken: string | null = null;

  constructor(private authService: AuthService, private fb: FormBuilder, private router: Router) {}

  currentView: VIEW = 'EMAIL';

  public errorCondition: InputErrorShowCondition = {
    touch: true,
    submit: true,
  };

  public sendEmailForm = this.fb.group({
    email: [
      null,
      {
        validators: [Validators.required, Validators.pattern(Models.ValidationPatterns.PASSWORD)],
      },
    ],
  });

  public waiting$ = new BehaviorSubject(false);
  public errorApi$ = new BehaviorSubject<any | null | undefined>(null);

  public ngOnInit() {
    if (this.resetToken) {
      this.currentView = 'PASSWORD';
    }
  }

  /**
   * Submit the form
   */
  public sendEmail() {
    this.sendEmailForm.patchValue(this.sendEmailForm.value);
    this.sendEmailForm.markAllAsTouched();
    if (this.sendEmailForm.invalid) return;
    this.waiting$.next(true);
    this.errorApi$.next(null);

    this.authService.sendPasswordResetEmail(this.sendEmailForm.value.email).subscribe({
      next: () => {
        this.currentView = 'EMAIL_SENT';
        this.waiting$.next(false);
      },
      error: () => {
        // Always simulate a success even if an error
        this.currentView = 'EMAIL_SENT';
        this.waiting$.next(false);
        /**
        error.errorMsg = 'Error sending recovery email.';

        this.errorApi$.next(error);
        this.waiting$.next(false);
         */
      },
    });
  }

  readonly changePasswordAction = (data: PasswordData) => {
    if (this.resetToken == null) return of(null);

    this.waiting$.next(true);
    this.errorApi$.next(null);

    return this.authService.resetPassword(this.resetToken, data.newPassword).pipe(
      tap(() => {
        this.currentView = 'PASSWORD_RESET';
        this.waiting$.next(false);
        this.authService.authState = AuthState.initial;
      }),
      catchError(error => {
        error.errorMsg = 'Error resetting password.';
        this.errorApi$.next(error);
        this.waiting$.next(false);
        throw error;
      }),
    );
  };

  public loginSuccess() {
    this.router.navigate(['/']);
  }

  public later() {
    this.router.navigate(['/login']);
  }

  ngOnDestroy() {}
}
