<div class="save-btn-container" *ngIf="{ saved: saved$ | async } as comp">
  <p-progressSpinner *ngIf="loading$ | async" [style]="{ height: '36px', width: '36px' }" class="spinner"></p-progressSpinner>
  <button
    pButton
    pRipple
    class="save-button"
    styleClass="test-style-class"
    [icon]="'pi ' + (comp.saved ? 'pi-heart-fill' : 'pi-heart')"
    [ngStyle]="{ color: comp.saved ? '#db3747' : '#57585b' }"
    (click)="updateStatus($event)"
    pTooltip="Save property"
    tooltipPosition="right"></button>
</div>
