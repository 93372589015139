import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output } from '@angular/core';
import { Models } from '$models';
import { BaseCovDropdown } from '../base-dropdown/base-cov-dropdown.component';

@Component({
  selector: 'app-cov-dropdown',
  templateUrl: './cov-dropdown.component.html',
  styleUrls: ['./cov-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CovDropdownComponent extends BaseCovDropdown {
  _options: Models.DropdownOption[] = [];
  selectedItems: string = '';
  groupedOptions: Models.DropdownGroupedOptions[] = [];

  @Input() title: string = '';
  @Input() formPropertyName = '';
  @Input() isMobile = false;
  @Input() set options(data: Models.DropdownGroupedOptions[]) {
    this.groupedOptions = [...data];
    this.updateAmount();
  }

  @Output() updateFormProperty = new EventEmitter();

  constructor() {
    super();
  }

  private updateAmount() {
    // In load, set default selected amount
    this.selectedAmount = this.groupedOptions.reduce((a, b) => a + b.options.reduce((a, b) => (b.selected ? a + 1 : a), 0), 0);
  }

  ngOnInit(): void {
    if (this.formPropertyName !== 'more') {
      this.selectedItems = 'All';
    }
  }

  selected(opt: Models.DropdownOption, groupIdx: number, optIdx: number) {
    const group = this.groupedOptions[groupIdx];
    const isNested = this.groupedOptions.length > 1;

    this.selectedItems = '';
    opt.selected = !opt.selected;
    this.deselectedOneWhileAllIsSelected(group.options, opt);

    if (opt.value === 'all') {
      if (opt.selected) {
        group.options.map(item => (item.selected = true));
        if (!isNested) {
          this.selectedItems = 'All';
        }
      } else {
        group.options.map(item => (item.selected = false));
      }
    }
    this.selectedAmount = !isNested ? this.getTotalSelected(group.options) : this.calculateTotalForNestedOptions();

    if (this.formPropertyName === 'more') {
      this.setFormPropertyNameForNestedGroup(group);
    }
    this.updateFormProperty.emit({ [this.formPropertyName]: this.getSelectedItems(group.options) });
  }

  setFormPropertyNameForNestedGroup(group: Models.DropdownGroupedOptions) {
    switch (group.subTitle) {
      case 'All Purchase Options': {
        this.formPropertyName = 'purchaseOption';
        break;
      }
      case 'Occupancy': {
        this.formPropertyName = 'occupancy';
        break;
      }
      default: {
        this.formPropertyName = 'additionalOptions';
      }
    }
  }

  getSelectedItems(array: Models.DropdownOption[]): string[] {
    return array.filter(item => item.selected).map(item => item.value);
  }

  getTotalSelected(array: Models.DropdownOption[]): number {
    return array.filter((item: Models.DropdownOption) => item?.selected && item.value !== 'all').length;
  }

  calculateTotalForNestedOptions(): number {
    const nestedArrays = this.groupedOptions.map(group => group.options);
    const flatten = [].concat.apply([], nestedArrays as any);
    return this.getTotalSelected(flatten);
  }

  deselectedOneWhileAllIsSelected(array: Models.DropdownOption[], option: Models.DropdownOption) {
    if (option.value === 'all') return;
    const allOption = array.find(item => item.selected && item.value === 'all');
    if (allOption) {
      array.splice(0, 1, { ...allOption, selected: false });
    }
  }

  splitWords(word: string): string[] {
    return word.split(' ');
  }

  /**
   * Close dropdown
   */
  public closeDropdown() {
    this.displayField('');
  }
}
