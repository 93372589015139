import { serializeCityName, serializeCountyName, serializeStateName } from './search-page-url-serializer.util';
import { stringToSlug } from './slug.util';

/**
 * Convert a google places response into an url
 * @param place
 * @returns
 */
export const googlePlacesToUrl = (place?: google.maps.places.PlaceResult | null): string | null => {
  if (!place) {
    return null;
  }
  // Extract location info from google palces
  const state = place.address_components?.filter(p => p.types.includes('administrative_area_level_1'))[0] ?? null;
  const county = place.address_components?.filter(p => p.types.includes('administrative_area_level_2'))[0] ?? null;
  const city = place.address_components?.filter(p => p.types.includes('locality'))[0] ?? null;
  const zip = place.address_components?.filter(p => p.types.includes('postal_code'))[0] ?? null;

  let url: string | null = null;

  if (state && county && city && zip) {
    url = `/${serializeStateName(state.short_name)}/${serializeCountyName(county.short_name)}/${serializeCityName(city.short_name)}/${zip.short_name}/`;
  } else if (state && county && city) {
    url = `/${serializeStateName(state.short_name)}/${serializeCountyName(county.short_name)}/${serializeCityName(city.short_name)}/`;
  } else if (state && county) {
    url = `/${serializeStateName(state.short_name)}/${serializeCountyName(county.short_name)}/`;
  } else if (state) {
    url = `/${serializeStateName(state.short_name)}/`;
  } else {
    url = '/';
  }

  return url ? stringToSlug(url) : null;
};
