<div class="add-edit-customer-form">
  <ng-container *ngIf="showSubTitle">
    <ng-container *ngIf="!showAddedUser && !showExistedUser && !isEdit">
      <p>
        The term customer means the person for whom you are placing bids. This is not to be considered a legal real estate term defining a client or customer
        agency relationship. The legal representation of the bidder is for you and the bidder to decide.
      </p>
      <h5 class="mt-4">New customer registration</h5>
    </ng-container>

    <ng-container *ngIf="showAddedUser && !isEdit">
      <div><span>The new customer has been added and can be managed from the </span><span style="font-weight: bold">My Customers</span> page</div>
      <h5 class="mt-4">Customer details</h5>
    </ng-container>

    <ng-container *ngIf="showExistedUser && !isEdit">
      <p-message severity="warn" class="g-0 mb-4" text="Customer already exists and can be managed from the My Customers page."></p-message>
      <h5 class="mt-4">Customer details</h5>
    </ng-container>
  </ng-container>

  <div>
    <form [formGroup]="form" [ngClass]="{ 'show-errors-on-touched': errorCondition.touch }">
      <ng-container *ngIf="!showAddedUser && !showExistedUser">
        <div>
          <ng-container *ngFor="let field of formFields">
            <p>
              <label *ngIf="field.label" [for]="field.formControlName || ''">
                {{ field.label || field.placeholder || '' }}
              </label>
              <ng-container [ngSwitch]="field.type">
                <ng-container *ngSwitchCase="'inputmask'">
                  <p-inputMask
                    [id]="field.formControlName || ''"
                    [formControlName]="field.formControlName || ''"
                    [mask]="field.mask || ''"
                    [slotChar]="field.slotChar || ' '"
                    [placeholder]="field.placeholder || ''"></p-inputMask>
                </ng-container>
                <ng-container *ngSwitchDefault>
                  <input
                    [formControlName]="field.formControlName || ''"
                    [placeholder]="field.placeholder || ''"
                    [type]="field.type || 'text'"
                    [id]="field.formControlName || ''"
                    pInputText />
                </ng-container>
              </ng-container>
              <app-input-error
                [controlName]="field.formControlName || ''"
                [friendlyName]="field.label || field.placeholder || ''"
                [errorCondition]="errorCondition"
                [anyErrorShowInvalid]="field.anyErrorShowInvalid || false"
                [customErrorMessage]="field.customErrorMessage || null"></app-input-error>
            </p>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="(showAddedUser || showExistedUser) && !isEdit">
        <div>
          <div>
            <span class="mr-5 label">Customer Name:</span>
            <span class="mr-5">{{ form.controls['FIRST_NAME'].value }}</span>
            <span>{{ form.controls['LAST_NAME'].value }}</span>
          </div>
          <div>
            <span class="mr-5 label">Customer ID:</span>
            <span>{{ form.controls['CUSTOMER_ID'].value }}</span>
          </div>
          <div>
            <span class="mr-5 label">Screen Name:</span>
            <span>RB{{ form.controls['CUSTOMER_ID'].value }}</span>
          </div>
          <div>
            <span class="mr-5 label">Phone Number:</span>
            <span>{{ form.controls['CELL_PHONE'].value | phoneNumber }}</span>
          </div>
        </div>
      </ng-container>
    </form>
  </div>
</div>
