import { SearchModels } from '../search.models';

/**
 * Is the search location distinct. Does not look at query params
 * @param a
 * @param b
 * @returns
 */
export const distinctLocation = (a: SearchModels.Location | null, b: SearchModels.Location | null) =>
  !!(
    a &&
    b &&
    a.address === b.address &&
    a.city === b.city &&
    a.county === b.county &&
    a.propertyId === b.propertyId &&
    a.state === b.state &&
    a.zip === b.zip
  );
