import { Observable } from 'rxjs';

enum BreakpointDirection {
  Up = 'Up',
  Down = 'Down',
}

export const enum CssBreakpoint {
  default = 0,
  sm = 576,
  md = 768,
  lg = 992,
  xl = 1200,
  xxl = 1400,
  xxxl = 1512,
  xxxxl = 1920,
}

/**
 * ------false------Mobile(576px)-------true-------->>>
 */
export function listenForSmallScreen(): Observable<boolean> {
  return listenForBreakpoint(CssBreakpoint.sm, BreakpointDirection.Up);
}

/**
 * <<<------true------Mobile(576px)-------false--------
 */
export function listenForSmallScreenOnly(): Observable<boolean> {
  return listenForBreakpoint(CssBreakpoint.sm, BreakpointDirection.Down);
}

/**
 * ---------false-----------Tablet(768px)-----------true-------------->>>
 */
export function listenForMediumScreen(): Observable<boolean> {
  return listenForBreakpoint(CssBreakpoint.md, BreakpointDirection.Up);
}

/**
 * <<<--------true-----------Tablet(768px)-----------false--------------
 */
export function listenForMediumScreenDown(): Observable<boolean> {
  return listenForBreakpoint(CssBreakpoint.md, BreakpointDirection.Down);
}

/**
 * --------------false---------------Desktop(992px)----------------true---------------->>>
 */
export function listenForLargeScreen(): Observable<boolean> {
  return listenForBreakpoint(CssBreakpoint.lg, BreakpointDirection.Up);
}

/**
 * <<<--------------true---------------Desktop(992px)----------------false----------------
 */
export function listenForLargeScreenDown(): Observable<boolean> {
  return listenForBreakpoint(CssBreakpoint.lg, BreakpointDirection.Down);
}

/**
 * -----------------false---------------Desktop(1400px)----------------true------------->>>
 */
export function listenForXXLScreenUp(): Observable<boolean> {
  return listenForBreakpoint(CssBreakpoint.xxl, BreakpointDirection.Up);
}

/**
 * -----------------false---------------Desktop(1512px)----------------true------------->>>
 */
export function listenForXXXLScreenUp(): Observable<boolean> {
  return listenForBreakpoint(CssBreakpoint.xxxl, BreakpointDirection.Up);
}

/**
 * -----------------false---------------Desktop(1920px)----------------true------------->>>
 */
export function listenForXXXXLScreenUp(): Observable<boolean> {
  return listenForBreakpoint(CssBreakpoint.xxxxl, BreakpointDirection.Up);
}

export function isLargeScreen(): boolean {
  return window.matchMedia(`(min-width: ${CssBreakpoint.lg / 16}em)`).matches;
}

export function listenForBreakpoint(breakpoint: CssBreakpoint, direction = BreakpointDirection.Down): Observable<boolean> {
  let maxminWidthText: string;
  if (direction === BreakpointDirection.Up) {
    maxminWidthText = 'min-width';
  } else {
    maxminWidthText = 'max-width';
  }

  const mediaQuery = `(${maxminWidthText}: ${breakpoint / 16}em)`;
  const media = window.matchMedia(mediaQuery);

  return new Observable(observer => {
    observer.next(media.matches);

    media.addEventListener('change', listener);
    observer.add(() => {
      media.removeEventListener('change', listener);
    });

    function listener(event: MediaQueryListEvent) {
      observer.next(event.matches);
    }
  });
}
