import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';

@Pipe({
  name: 'dayjsFormat',
})
export class DayjsFormatPipe implements PipeTransform {
  constructor() {
    dayjs.extend(timezone);
    dayjs.tz.setDefault('America/Chicago'); // set default timezone to CST
  }

  transform(value?: string, format?: string): string {
    if (!value) return '';
    return dayjs(value).format(format);
  }
}
