import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-beds-bath-dropdown',
  templateUrl: './beds-bath-dropdown.component.html',
  styleUrls: ['./beds-bath-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class BedsBathDropdownComponent {
  @Input() isMobile = false;
  @Input() numberOfBeds = null;
  @Input() numberOfBaths = null;
  @Input() bedsFormControl?: FormControl | null = new FormControl();
  @Input() bathsFormControl?: FormControl | null = new FormControl();

  public readonly options = [
    { label: 'Any', value: null },
    { label: '1+', value: 1 },
    { label: '2+', value: 2 },
    { label: '3+', value: 3 },
    { label: '4+', value: 4 },
  ];

  public isOpen = false;
}
