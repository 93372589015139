import { Injectable } from '@angular/core';
import { NtsStateManagementService } from '@ntersol/state-management';
import { Observable, map, filter, mergeMap, startWith } from 'rxjs';
import * as dayjs from 'dayjs';

import { ntsUIStoreCreator } from '@ntersol/state-management';
import { environment } from '$env';
import { Models } from '$models';
import { BiddingModels } from '../shared/models/bidding.model';
import { HttpClient } from '@angular/common/http';
import { removeNils } from '@ntersol/utils';

export const MOCKED_BUYER_AGENT = {
  Buyer_Agent_ID: 0,
  Company_Name: 'Realty Executives',
  Address1: '1200 N Main St',
  Address2: 'Ste 200',
  City: 'Seattle',
  State: 'WA',
  Postal_Code: '98101',
  Agent_Name: 'Reuben Loyd',
  Phone_1: '(222) 555-1212',
  Phone_1_Ext: '#10',
  Fax: '',
  Email: 'rl@mail.com',
};

export enum BiddingStoreKey {
  step = 'step',
  bidDeposit = 'bidDeposit',
  bidForData = 'bidForData',
  bidData = 'bidData',
  buyerAgent = 'buyerAgent',
  bidResult = 'bidResult',
  bidResultMessage = 'bidResultMessage',
}

export type BiddingStore = {
  [key in BiddingStoreKey]: any;
};

export const InitialState: BiddingStore = {
  [BiddingStoreKey.step]: 1,
  [BiddingStoreKey.bidForData]: null,
  [BiddingStoreKey.bidDeposit]: null,
  [BiddingStoreKey.bidData]: null,
  [BiddingStoreKey.buyerAgent]: null,
  [BiddingStoreKey.bidResult]: BiddingModels.BiddingResult.success,
  [BiddingStoreKey.bidResultMessage]: null,
};

@Injectable({
  providedIn: 'root',
})
export class BiddingStoreService {
  // Create a ui store creator instance with default state using interface model and options
  private uiStore = ntsUIStoreCreator<BiddingStore>(InitialState, { persistId: 'biddingStore' });

  private store = this.sm.createBaseStore({ apiUrlBase: environment.endpoints.apiUrl || '' });

  customers = this.store<Models.ListCustomersResponseCustomers>({
    apiUrl: `agent/listCustomers`,
    uniqueId: 'CUSTOMER_ID',
  });

  buyerAgent = this.store<Models.BuyerAgentRequest>({
    apiUrl: `user/current/buyeragent/details`,
    apiUrlOverride: {
      post: environment.endpoints.apiUrl + `user/current/buyeragent/add`,
      put: environment.endpoints.apiUrl + `user/current/buyeragent/details`,
      delete: environment.endpoints.apiUrl + `user/current/buyeragent/remove`,
    },
  });

  propertyDetails = this.store<Models.PropertyDetailsResponse>({
    apiUrl: `public/property`,
    autoLoad: false,
    map: {
      get: (r: Models.PropertyDetailsResponse) => (r ? removeNils(r) : r),
    },
  });

  public fees$ = this.propertyDetails.select$.pipe(
    map(d => d?.ITEM_ID),
    filter(d => !!d),
    mergeMap(id => this.http.get<Models.PropertyFeesResponse>(environment.endpoints.apiUrl + `public/property/${id}/fees`)),
  );

  disclosureStatus$ = this.propertyDetails.select$.pipe(
    map(d => d?.ITEM_ID),
    filter(d => !!d),
    mergeMap(id => this.http.get<Models.SuccessResponseMessage>(environment.endpoints.apiUrl + `bidding/disclosure/${id}/status`)),
    startWith(null),
  );

  currentBid$ = this.propertyDetails.select$.pipe(
    map(d => d?.ITEM_ID),
    filter(d => !!d),
    mergeMap(id => this.http.get<Models.PropertyCurrentBidResponse>(environment.endpoints.apiUrl + `public/property/${id}/currentbid`)),
    startWith(null),
  );

  occupancyTypes = this.store<BiddingModels.OccupancyType>({
    apiUrl: `public/types/purchaseprofile/occupancytype`,
    uniqueId: 'ID',
    map: {
      get: (result: BiddingModels.OccupancyType[]) =>
        result?.filter((value, i) => !(value.OCCUPANCYTYPE.includes('Agent') || value.OCCUPANCYTYPE.includes('Homebuyer'))),
    },
  });

  purchaseTypes = this.store<BiddingModels.PurchaseType>({
    apiUrl: `public/types/purchaseprofile/purchasetype`,
    uniqueId: 'ID',
  });

  purchaseProfiles = this.store<Models.PurchaseProfileListItem>({
    apiUrl: `contract/purchase-profile/list`,
    uniqueId: 'PURCHASEPROFILEID',
  });

  constructor(private sm: NtsStateManagementService, private http: HttpClient) {
    this.uiStore.update(InitialState);
  }

  getSelector(selector: BiddingStoreKey.bidForData): Observable<BiddingModels.PlaceBidForFormModel>;
  getSelector(selector: BiddingStoreKey.bidDeposit): Observable<Models.AddBidDepositRequest>;
  getSelector(selector: BiddingStoreKey.bidResult): Observable<BiddingModels.BiddingResult>;
  getSelector(selector: BiddingStoreKey.bidData): Observable<BiddingModels.BiddingModel>;
  getSelector(selector: BiddingStoreKey.bidResultMessage): Observable<unknown>;
  getSelector(selector: BiddingStoreKey): Observable<any> {
    return this.uiStore.select$(selector);
  }

  update(state: Partial<BiddingStore>) {
    this.uiStore.update(state);
  }

  reset() {
    this.uiStore.update(InitialState);
  }
}
