import { Injectable } from '@angular/core';
import { environment } from '$env';
import { Models } from '$models';

export interface WizardStore {
  currentStep: number;
  [key: string]: any;
}

export const InitialState: WizardStore = {
  currentStep: 0
};

@Injectable({
  providedIn: 'root',
})
export class ModalWizardStoreService {

  constructor() { }
}
