import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PropertyContingecies } from '../models/contingencies.model';
import { PropertyDisclosure } from '../models/state-disclosure.model';
import { environment } from '$env';

@Injectable()
export class PropertyDisclosuresService {
  readonly disclosures$ = new BehaviorSubject<PropertyDisclosure[]>([]);
  readonly additionalDisclosures$ = new BehaviorSubject<string | null>(null);
  readonly contingencies$ = new BehaviorSubject<string>('');

  fetchAll(propId: number) {
    this.fetchDisclosures(propId);
    this.fetchContingencies(propId);
  }

  private fetchDisclosures(propId: number) {
    this.http
      .get<PropertyDisclosure[]>(environment.endpoints.apiUrl + `public/property/${propId}/state-disclosures`)
      .subscribe(list => this.disclosures$.next(list));
  }

  private fetchContingencies(propId: number) {
    this.http.get<PropertyContingecies>(environment.endpoints.apiUrl + `public/property/${propId}/contingencies`).subscribe(data => {
      this.additionalDisclosures$.next(data.ADDITIONALDISCLOSURES ? data.ADDITIONALDISCLOSURES : null);
      this.contingencies$.next(data.CONTINGENCYSEC);
    });
  }

  constructor(private http: HttpClient) {}
}
