import { Injectable } from '@angular/core';
import { StorageI, storageFactory } from '../utils/storage.utils';

export enum StorageKeys {
  loginData = 'loginData',
}

const storagesKeys = [StorageKeys.loginData];

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private readonly storages = new Map<StorageKeys, any>();

  init() {
    for (const storageKey of storagesKeys) {
      this.storages.set(storageKey, storageFactory('localStorage', storageKey));
    }
  }

  getStorage<T>(storageKey: StorageKeys): StorageI<T> {
    return this.storages.get(storageKey);
  }
}
