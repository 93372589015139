import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faFacebookF, faLinkedinIn, faTwitter, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faBell, faHeart } from '@fortawesome/free-regular-svg-icons';
import {
  faAngleDown,
  faArrowDownShortWide,
  faArrowDownWideShort,
  faArrowLeft,
  faArrowUpFromBracket,
  faArrowUpShortWide,
  faBars,
  faCalendarDays,
  faCaretDown,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleXmark,
  faClock,
  faCog,
  faCogs,
  faCubes,
  faDollarSign,
  faDownload,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faGavel,
  faHandshakeSimple,
  faHome,
  faKey,
  faLocationDot,
  faMagnifyingGlass,
  faMapMarkerAlt,
  faMapPin,
  faMinus,
  faPencil,
  faPhone,
  faPlay,
  faPlus,
  faPortrait,
  faPowerOff,
  faRefresh,
  faScaleBalanced,
  faSearch,
  faShield,
  faSliders,
  faSpinner,
  faTrash,
  faTrashCan,
  faTriangleExclamation,
  faUpRightAndDownLeftFromCenter,
  faUpload,
  faUser,
  faUserGroup,
  faUserTie,
  faUsers,
  faX,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
// Solid icons
// Regular (thinner) icons
// import { faPowerOff } from '@fortawesome/free-regular-svg-icons';
// Corporate brand icons
// import { faPowerOff } from '@fortawesome/free-brands-svg-icons';

/**
 * Documentation: https://github.com/FortAwesome/angular-fontawesome/blob/master/docs/usage/icon-library.md
 *
 * To add a new icon:
 * 1. Go to https://fontawesome.com/icons and find the icon to use
 * 2. Import the icon in this file
 * 3. Register the icon in the constructor in this file
 * 4. Use the following component syntax: <fa-icon icon="power-off"></fa-icon> or <fa-icon [icon]="['fas', 'coffee']"></fa-icon>
 *
 * Only import the icons being used, otherwise unused icons will not be tree shaken out
 */

@NgModule({
  declarations: [],
  imports: [CommonModule, FontAwesomeModule],
  exports: [FontAwesomeModule],
})
export class IconsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faTrashCan,
      faUpload,
      faMinus,
      faGavel,
      faSearch,
      faPhone,
      faDownload,
      faEnvelope,
      faPowerOff,
      faPencil,
      faSpinner,
      faRefresh,
      faPlus,
      faExclamationTriangle,
      faCog,
      faCogs,
      faTrash,
      faUser,
      faUsers,
      faCaretDown,
      faBars,
      faHome,
      faCubes,
      faX,
      faHeart,
      faAngleDown,
      faBell,
      faTwitter,
      faXTwitter,
      faFacebookF,
      faLinkedinIn,
      faPlay,
      faSliders,
      faChevronLeft,
      faChevronRight,
      faKey,
      faShield,
      faCheckCircle,
      faCheck,
      faEye,
      faEyeSlash,
      faXmark,
      faCircleCheck,
      faArrowLeft,
      faMapMarkerAlt,
      faMapPin,
      faTriangleExclamation,
      faUpRightAndDownLeftFromCenter,
      faUserTie,
      faGavel,
      faChevronUp,
      faChevronDown,
      faCircleInfo,
      faCalendarDays,
      faLocationDot,
      faHandshakeSimple,
      faDollarSign,
      faMagnifyingGlass,
      faArrowUpFromBracket,
      faScaleBalanced,
      faPortrait,
      faArrowDownShortWide,
      faArrowDownWideShort,
      faArrowUpShortWide,
      faUserGroup,
      faCircleExclamation,
      faCircleXmark,
      faClock,
    );
  }
}
