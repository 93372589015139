<div class="property-features-inputs">
  <div class="max-width-179">
    <label class="input-label" [innerHTML]="label"></label>
    <input
      id="squareFeetValidation"
      class="p-inputtext"
      [formControl]="control"
      [type]="inputType"
      [placeholder]="''"
      (input)="patternInput()"
      (ngModelChange)="onChange($event)" />
    <div *ngIf="control.invalid && (control.dirty || control.touched)" class="error red mt-1 anim-fade-in small required">
      {{ errorMessage }}
    </div>
  </div>
</div>
