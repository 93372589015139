import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { map, mergeMap, of, switchMap, take, tap } from 'rxjs';
import { AuthService, DomService } from '../../../shared/services';
import { SearchStoreService, googlePlacesToLocationObject } from '../../../shared/stores';
import { ApiService } from '../../../shared/stores/api';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Models } from '../../../shared/models';
import { GoogleApiService } from '../../../shared/services/google-api.service';

@UntilDestroy()
@Component({
  selector: 'app-save-search-form',
  templateUrl: './save-search-form.component.html',
  styleUrls: ['./save-search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SaveSearchFormComponent implements OnInit {
  saveSearchForm = new FormControl('', [Validators.required, Validators.maxLength(40)]);
  public readonly loggedIn$ = this.auth.isLoggedIn$;

  @Input() previousUrl: string = '';
  @Output() close = new EventEmitter();
  isLoggedIn: boolean | undefined;
  pathName: string | undefined;

  savedSearches$ = this.apiGlobal.savedSearches.state$;
  public sendEmail = false;
  public sendText = false;

  constructor(
    private readonly searchStore: SearchStoreService,
    private readonly dom: DomService,
    private readonly apiGlobal: ApiService,
    private readonly auth: AuthService,
    private readonly router: Router,
    private readonly googleApi: GoogleApiService,
  ) {}

  ngOnInit(): void {
    this.searchStore.queryParamsSearch$
      .pipe(
        map(searchParams => searchParams?.term),
        take(1),
      )
      .subscribe(term => this.saveSearchForm?.patchValue(term));

    this.loggedIn$
      .pipe(
        take(1),
        tap(isLoggedIn => (this.isLoggedIn = isLoggedIn)),
      )
      .subscribe();

    this.pathName = this.previousUrl.split('?')[0];
  }

  submit() {
    if (this.saveSearchForm.invalid) {
      return;
    }

    if (!this.isLoggedIn) {
      const returnUrl = this.router.url !== '/' && this.router.url !== '/login' ? this.router.url.split('?')[0] : null;
      this.router.navigate(['/login'], { queryParams: { currentUrl: returnUrl, target: 'watchlist' } });
    } else {
      // Get current location of search
      this.searchStore.searchHistory$
        .pipe(
          take(1),
          switchMap(loc => {
            let path = this.dom?.window?.location?.pathname;
            if (this.dom?.window?.location?.pathname.includes('mobile/save-search')) {
              path = this.pathName;
            }
            let search = this.dom?.window?.location?.search.replace('&target=watchlist', '');
            const placeId = loc[0]?.value;
            const payload: Partial<Models.SavedSearch> = {
              SEARCHSTRING: path + '' + search,
              SEARCHNAME: this.saveSearchForm.value,
              SAVEDSEARCHID: Math.floor(Math.random() * 100000000), // API does not return unique ID, need one for store
              EMAIL_ACTIVE: this.sendEmail,
              TEXT_ACTIVE: this.sendText,
            };

            if (placeId) {
              return this.googleApi.getGooglePlaceDetails(placeId).pipe(
                switchMap(placeResult => {
                  const location = googlePlacesToLocationObject(placeResult);
                  const payload: Partial<Models.SavedSearch> = {
                    SEARCHSTRING: path + '' + search,
                    SEARCHNAME: this.saveSearchForm.value,
                    SAVEDSEARCHID: Math.floor(Math.random() * 100000000), // API does not return unique ID, need one for store
                    EMAIL_ACTIVE: this.sendEmail,
                    TEXT_ACTIVE: this.sendText,
                    CITY: location?.city,
                    STATE: location?.state,
                    ZIP: location?.zip,
                    ADDRESS: location?.propertyId,
                    COUNTY: location?.county,
                  };

                  return this.apiGlobal.savedSearches.post(payload);
                }),
              );
            }
            return this.apiGlobal.savedSearches.post(payload);
          }),
          // Force refresh store data to get unique ID for newly saved saved search
          mergeMap(() => this.apiGlobal.savedSearches.refresh()),
          // Check if all searches alerts are toggled on, if so, send a side effect to turn this most recent search's alerts on
          // By default it is off but it needs to be on if all searches are on but not on by default
          tap(() => {
            this.apiGlobal.savedSearches.selectAll$
              .pipe(
                // Check if all the searches in the store are on, both email and text
                map(searches => {
                  const allSearches = searches?.reduce((prev, current) => (!prev || !current.EMAIL_ACTIVE || !current.TEXT_ACTIVE ? false : true), true);
                  // If so return last item
                  if (allSearches && searches) {
                    return searches[searches.length - 1];
                  }
                  return null; // Otherwise null
                }),
                // If not null, update notification prefs to yes
                mergeMap(latest => (latest ? this.apiGlobal.saveSearchesNotificationChange({ ...latest, EMAIL_ACTIVE: true, TEXT_ACTIVE: true }) : of(null))),
                take(1),
              )
              .subscribe();
          }),
        )
        .subscribe(() => this.close.emit());
    }
  }
}
