import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Models } from '../models';
import { DomService } from './dom.service';

interface RouteHistory {
  /** The base/root url of the route */
  slug: string;
  /** The full path of the route */
  route: string;
  /** The same as route but in array representation */
  routeParts: string[];
  /** DateTime that the route was changed */
  dateTime: Date;
  /** Any query params */
  queryParams?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private static readonly localKey = 'routeHistory';
  private static readonly navigationHistoryLength = 100;
  private routeHistory: RouteHistory[] = [];

  // Injecting the Angular Router service
  constructor(private router: Router, private dom: DomService) {
    const routeHistory = this.dom.sessionStorage.getItem(NavigationService.localKey);
    if (routeHistory) {
      this.routeHistory = JSON.parse(routeHistory);
    }
  }

  /**
   * Processes a new route and adds it to the route history if it's different from the most recent route.
   * Also ensures that the route history doesn't exceed 100 items.
   *
   * @param {string} route - The new route string to be added to the route history. This string may include the path and query parameters.
   *
   * @example
   * navigationService.routeChange('/products/3?page=2');
   */
  public routeChange(route: string) {
    // Extract slug and query params from the route.
    // This assumes the slug is the first segment in the route and queryParams follow '?'.
    const [path, queryParams] = route.split('?');
    let routeParts = path.split('/').filter(segment => !!segment);
    if (routeParts.length === 0) {
      routeParts = ['/'];
    }
    const slug = path.split('/')[1] || Models.HomePageDummySlug;

    // Check if the most recent route is the same as the current route.
    if (this.routeHistory.length > 0 && this.routeHistory[0].route === path && this.routeHistory[0].queryParams === queryParams) {
      return; // If it is the same, exit without adding to the route history.
    }

    // Construct a new route entry.
    const newRouteEntry: RouteHistory = {
      slug: slug,
      route: path,
      routeParts: routeParts,
      dateTime: new Date(),
      queryParams: decodeURIComponent(queryParams) || undefined,
    };

    // Immutably add the new route to the front of routeHistory and, if necessary, drop the oldest route.
    this.routeHistory = [newRouteEntry, ...this.routeHistory.slice(0, NavigationService.navigationHistoryLength - 1)];
    this.dom.sessionStorage.setItem(NavigationService.localKey, JSON.stringify(this.routeHistory));
  }

  /**
   * Navigates to the route that precedes the last occurrence of a route with the same slug as the given route URL.
   * If no such route is found or the given route URL is the first in history, it navigates to the browser's previous route.
   *
   * @param {string} routeUrl - The route URL for which the previous route should be found and navigated to.
   */
  public goToPreviousRoute(routeUrl: string) {
    const previousRoute = this.findPreviousRoute(routeUrl);
    if (previousRoute) {
      this.router.navigate([previousRoute]);
    } else {
      this.dom.historyBack(); // Default go to previous route
    }
  }

  /**
   * Finds the route that precedes the last occurrence of a route with the same slug as the given route URL.
   *
   * For example, if the route history is ['/products/1', '/products/2', '/users/1', '/products/3']
   * and we call `findPreviousRoute('/products/3')`, this will return `/users/1`.
   *
   * @param {string} routeUrl - The route URL for which the previous route should be found.
   * @returns {string | null} - Returns the route that precedes the last occurrence of the route with the same slug.
   *                                         If no such route is found or the given route URL is the first in history,
   *                                         it returns null indicating to use the browser's back functionality.
   */
  private findPreviousRoute(routeUrl: string) {
    const slugToMatch = routeUrl.split('/')[1] || '';
    let foundMatchingSlug = false;
    for (let i = 0; i < this.routeHistory.length + 1; i++) {
      if (this.routeHistory[i] && this.routeHistory[i].slug === slugToMatch) {
        foundMatchingSlug = true;
      }
      // When a matching slug was found in the previous iterations and the current slug doesn't match
      // This is the route just before the last occurrence of the given slug
      else if (foundMatchingSlug && this.routeHistory[i]) {
        return this.routeHistory[i].route;
      }
    }
    return null;
  }

  /**
   * 1. Some pages can be reached from multiple plages in mobile view.
   * For example my-profile page can be reached either from the home page
   * or from the property search page. It means that back button "<" on the
   * my-profile page itself should dynamically navigates back to the page
   * where user previously was. So we can use this method like:
   *   public readonly backButtonNavigation = () => {
   *     this.navigationService.navigateToClosestRoute([Models.HomePageDummySlug, Models.SearchRouteSlug]);
   *   };
   * That way navigation will choose the closest navigation between provided slugs/paths
   *
   * 2. The other unique example is property-details page. It has path like:
   *    http://localhost:4200/houses-for-auction/california/kings-county/kettleman-city/93239/Parcel%20Id:%20038-138-001/736168/
   * There are 7 unique parts of the path [houses-for-auction, california, kings-county...etc]
   * When we on this page we have capability to go through the list of other properties by clicking
   * Prev or Next buttons on UI itself. It breaks back button "<" navigation if it uses
   * browser history navigation. But using "additionalCriteria" call back we can solve this issue:
   *   public readonly detailsBackButtonNavigation = () => {
   *    this.navigationService.navigateToClosestRoute([Models.SearchRouteSlug], historyObj => {
   *      return historyObj.routeParts.length <= 4;
   *    });
   * In provided example callback helps to skip all the routes with number of parts greater than 4,
   * so that it skips all the property-details links and goes right to the listings page
  };
   * @param segments - array of paths/slugs to find in the navigation history
   * @param additionalCriteria - additional function which helps to skip some records
   * in the navigation history
   */
  navigateToClosestRoute(segments: string[], additionalCriteria?: (historyObj: RouteHistory) => boolean) {
    const route = this.findClosestRouteAmong(segments, additionalCriteria);
    if (route) {
      if (route.queryParams) {
        const queryParams = this.convertStringQueryParamsToParamsType(route.queryParams);
        this.router.navigate(route.routeParts, { queryParams });
      } else {
        this.router.navigate(route.routeParts);
      }
    } else {
      this.dom.historyBack(); // Default go to previous route
    }
  }

  private convertStringQueryParamsToParamsType(queryParams: string): Params {
    return queryParams
      .split('&')
      .map(pair => pair.split('='))
      .reduce((acc, val) => {
        acc[val[0]] = val[1];
        return acc;
      }, {} as { [key: string]: string });
  }

  private findClosestRouteAmong(segments: string[], additionalCriteria?: (historyObj: RouteHistory) => boolean): RouteHistory | null {
    let minIdx = Number.POSITIVE_INFINITY;
    for (const segment of segments) {
      const idx = this.findSegmentIndexInHistory(segment, additionalCriteria);
      // idx + 1 because we are using slice(1) in the findSegmentIndexInHistory method
      minIdx = idx !== -1 ? Math.min(minIdx, idx + 1) : minIdx;
    }
    if (minIdx !== Number.POSITIVE_INFINITY) {
      return this.routeHistory[minIdx];
    }
    return null;
  }

  private findSegmentIndexInHistory(segment: string, additionalCriteria?: (historyObj: RouteHistory) => boolean): number {
    // slice(1) because we don't need to navigate to the page we are currently on
    return this.routeHistory.slice(1).findIndex(historyObj => {
      if (additionalCriteria && !additionalCriteria(historyObj)) {
        return false;
      }
      if (segment === Models.HomePageDummySlug) {
        return historyObj.slug === segment;
      }
      return historyObj.routeParts.includes(segment);
    });
  }
}
