<div class="container g-0" *ngIf="{ errorApi: errorApi$ | async, waiting: waiting$ | async } as comp">
  <div class="row flex-column align-items-center m-auto">
    <p-message severity="error" class="g-0 mb-3" [text]="comp.errorApi" *ngIf="comp.errorApi"></p-message>

    <div class="col g-0">
      <form [formGroup]="form" novalidate (ngSubmit)="signUp()" class="d-flex flex-column" [ngClass]="{ 'show-errors-on-submit': errorCondition.submit }">
        <div class="mb-4">
          <label for="FIRST_NAME">First Name<sup class="required">*</sup></label>
          <input id="FIRST_NAME" type="text" class="w-100" pInputText formControlName="FIRST_NAME" />
          <app-input-error
            controlName="FIRST_NAME"
            friendlyName="first name"
            [errorCondition]="errorCondition"
            [customErrorMessage]="'Please enter your first name'"></app-input-error>
        </div>

        <div class="mb-4">
          <label for="LAST_NAME">Last Name<sup class="required">*</sup></label>
          <input id="LAST_NAME" type="text" class="w-100" pInputText formControlName="LAST_NAME" />
          <app-input-error
            controlName="LAST_NAME"
            friendlyName="last name"
            [errorCondition]="errorCondition"
            [customErrorMessage]="'Please enter your last name'"></app-input-error>
        </div>

        <div class="mb-4">
          <label for="email">Email<sup class="required">*</sup></label>
          <div class="footnote text-secondary mb-1">Receive email alerts and easily recover passwords.</div>

          <input
            id="email"
            readonly
            (focus)="focus($event)"
            type="text"
            class="w-100"
            pInputText
            formControlName="EMAIL_ADDR"
            placeholder="e.g. johnwhite@gmail.com" />
          <app-input-error
            *ngIf="!form.get('EMAIL_ADDR')?.hasError('emailTaken')"
            controlName="EMAIL_ADDR"
            friendlyName="email address"
            [anyErrorShowInvalid]="true"
            [errorCondition]="errorCondition"></app-input-error>
          <small class="p-error" *ngIf="form.get('EMAIL_ADDR')?.hasError('emailTaken')">
            An account with this email already exists.
            <a [routerLink]="'/login/'"><button pButton class="p-button-link fw-normal p-0 d-inline-block" label="Login here"></button></a>
          </small>
        </div>

        <div class="mb-4">
          <label for="phone">Mobile Phone<sup class="required">*</sup></label>
          <div class="footnote text-secondary mb-1">Required in order to bid or sell properties.</div>
          <p-inputMask styleClass="w-100" mask="(999) 999-9999" formControlName="CELL_PHONE" placeholder="(999) 999-9999"></p-inputMask>
          <app-input-error
            controlName="CELL_PHONE"
            friendlyName="mobile phone number"
            [anyErrorShowInvalid]="true"
            [errorCondition]="errorCondition"></app-input-error>
        </div>

        <div class="mb-4">
          <label for="password">Password<sup class="required">*</sup></label>
          <app-password-strength-input
            [showHelpText]="true"
            [errorCondition]="{ dirty: true, submit: true }"
            [customErrorMessage]="'Please enter a valid password'"
            formControlName="PASSWORD_CODE"></app-password-strength-input>
        </div>

        <div class="mb-4">
          <label for="PASSWORD_CODE_CONFIRM">Confirm Password<sup class="required">*</sup></label>
          <p-password
            name="password"
            styleClass="w-100 p-input-icon-right"
            inputStyleClass="w-100"
            [toggleMask]="true"
            [feedback]="false"
            formControlName="PASSWORD_CODE_CONFIRM"></p-password>
          <app-input-error
            controlName="PASSWORD_CODE_CONFIRM"
            friendlyName=" "
            customErrorMessage="Passwords do not match"
            [anyErrorShowInvalid]="true"
            [errorCondition]="{ touch: true, submit: true }"></app-input-error>
        </div>

        <div class="mb-4">
          <label for="geographicInterests" class="small">For property purchases, what geographic areas are you most interested in? (up to 5 options)</label>
          <div class="geo-interests-chips d-flex flex-wrap my-1">
            <div class="chip small medium-bold px-2" *ngFor="let interest of geographicInterests; let i = index">
              {{ interest }}
              <span class="d-inline-block ms-2" (click)="removeGeographicInterest(i)"><fa-icon class="icon" icon="x" size="sm"></fa-icon></span>
            </div>
          </div>
          <app-location-search-input
            searchInputFieldPlaceholder="City, State, ZIP"
            [showSearchButton]="false"
            [showSavedSearches]="false"
            [showSearchHistory]="false"
            [clearSearchOnSelection]="true"
            [showAdvancedSearchDropdown]="false"
            [disabled]="hasMaxGeographicInterests"
            (selected)="onSelected($event)"></app-location-search-input>
          <div *ngIf="hasMaxGeographicInterests" class="footnote text-secondary mt-1">
            No more than {{ MAX_GEOGRAPHIC_INTERESTS }} geographic areas can be added.
          </div>
        </div>

        <div class="mb-4">
          <label class="checkbox-label">
            <p-checkbox formControlName="SEND_MARKETING_EMAILS" [binary]="true"></p-checkbox>
            <span class="small">Receive emails from RealtyBid about new properties for auction/for sale in your geographic areas of interest.</span>
          </label>
        </div>

        <div class="mb-4">
          <label class="checkbox-label">
            <p-checkbox formControlName="RECEIVE_TEXTS" [binary]="true"></p-checkbox>
            <span class="small"
              >Receive texts from RealtyBid about your property bidding status & new properties for auction/for sale in your geographic areas of interest.
              Recurring messages. Msg & Data rates may apply. Update your text preferences in your Profile at any time.</span
            >
          </label>
        </div>

        <div class="small mb-4">
          <label class="checkbox-label">
            <p-checkbox formControlName="AGREE_TO_TOC_AND_PP" [binary]="true"></p-checkbox>
            <span class="small">
              I agree to RealtyBid
              <a href="/terms-of-service" target="_blank" rel="noreferrer noreopen">Terms of Use</a>
              and
              <a href="/privacy-policy" target="_blank" rel="noreferrer noreopen">Privacy Policy</a>
            </span>
          </label>
          <app-input-error
            class="agree-error-msg"
            controlName="AGREE_TO_TOC_AND_PP"
            friendlyName=" "
            customErrorMessage="User must agree with Terms of Use and Privacy Policy before signing up."
            [errorCondition]="errorCondition"></app-input-error>
        </div>

        <div class="d-flex justify-content-md-end">
          <button
            pButton
            class="flex-grow-1 flex-md-grow-0"
            type="submit"
            iconPos="right"
            [disabled]="(!errorCondition.submit && form.invalid) || comp.waiting"
            [loading]="!!comp.waiting"
            label="Sign up"></button>
        </div>
      </form>
      <div class="mt-3 d-flex justify-content-center">
        <button pButton class="p-button-link primary-color fw-normal align-self-start p-0" (click)="maybeLater()" label="Maybe Later"></button>
      </div>
    </div>
  </div>
</div>
