export function runTasksAcrossEventLoopCycles(tasks: Array<() => void>, numberOfTasksPerCycle: number = 100) {
  let processedItems = 0;

  runTasks();

  function runTasks() {
    for (let i = processedItems; i < tasks.length; i++) {
      const task = tasks[i];

      task();
      processedItems++;

      if (canRunNextTasksPortion(processedItems, numberOfTasksPerCycle, tasks.length)) {
        setTimeout(() => {
          runTasks();
        });
        break;
      }
    }
  }
}

function canRunNextTasksPortion(processedItems: number, numberOfTasksPerCycle: number, totalTasks: number): boolean {
  return processedItems % numberOfTasksPerCycle === 0 && processedItems < totalTasks;
}
