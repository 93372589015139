import { AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { get, isEmpty } from 'lodash';
import { distinctUntilChanged } from 'rxjs';

import { Models } from '$models';
import { InputErrorShowCondition } from 'src/app/components/input-error/input-error.component';
import { FormGeneratorModel } from 'src/app/shared/models/form.models';

@UntilDestroy()
@Component({
  selector: 'app-add-edit-customer',
  templateUrl: './add-edit-customer.component.html',
  styleUrls: ['./add-edit-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddEditCustomerComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() showSubTitle = true;
  @Input() showAddedUser = false;
  @Input() showExistedUser = false;
  @Input() customer?: Models.ListCustomersResponseCustomers | null | undefined;
  @Input() inputStyles: Record<string, string> = {};

  @Output() formReady = new EventEmitter<any>();
  @Output() formValuesChanged = new EventEmitter<any>();

  form = this.fb.group({
    CUSTOMER_ID: [null],
    FIRST_NAME: [null, [Validators.required]],
    LAST_NAME: [null, [Validators.required]],
    EMAIL_ADDR: [null, [Validators.required, Validators.email]],
    CELL_PHONE: ['', [Validators.required]],
  });

  formFields: FormGeneratorModel<any>[] = [
    {
      label: 'First Name',
      placeholder: '',
      formControlName: 'FIRST_NAME',
      customErrorMessage: 'Required',
      class: 'col-12 col-sx-12 col-sm-12 col-md-6 col-lg-6',
    },
    {
      label: 'Last Name',
      placeholder: '',
      formControlName: 'LAST_NAME',
      customErrorMessage: 'Required',
      class: 'col-12 col-sx-12 col-sm-12 col-md-6 col-lg-6',
    },
    {
      label: 'Email',
      placeholder: 'e.g. johndoe@gmail.com',
      formControlName: 'EMAIL_ADDR',
      customErrorMessage: 'Required',
      class: 'col-12 col-sx-12 col-sm-12 col-md-6 col-lg-6',
    },
    {
      label: 'Mobile Phone',
      type: 'inputmask',
      placeholder: 'e.g. (555) 555-5555',
      formControlName: 'CELL_PHONE',
      mask: '(999) 999-9999',
      customErrorMessage: 'Required',
      slotChar: '_',
      class: 'col-12 col-sx-12 col-sm-12 col-md-6 col-lg-6',
    },
  ];

  errorCondition: InputErrorShowCondition = {
    touch: true,
    submit: true,
  };

  isEdit = false;

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.setFieldDefinitions();
    this.init();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customer'] && !changes['customer'].firstChange) {
      this.init();
    }
    if (changes['inputStyles'] && !changes['inputStyles'].firstChange) {
      this.setFieldDefinitions();
    }
  }

  ngAfterViewInit(): void {
    this.watchForFormValuesChanged();
    this.formReady.emit(this.form);
  }

  private init(): void {
    const customerID = get(this.customer, 'CUSTOMER_ID');
    this.isEdit = !!(this.customer && !isEmpty(customerID));
    if (this.isEdit) {
      // User can modify all fields except their email
      this.form?.get('EMAIL_ADDR')?.disable();
    }
    this.setFormValues();
  }

  private setFieldDefinitions(): void {
    this.formFields = this.formFields.map(field => {
      return {
        ...field,
        class: !isEmpty(this.inputStyles[field.formControlName]) ? this.inputStyles[field.formControlName] : 'col-12 col-sx-12 col-sm-12 col-md-6 col-lg-6',
      };
    });
  }

  private setFormValues(): void {
    if (this.customer) {
      this.form?.patchValue(this.customer);
    } else {
      this.form?.reset({}, { emitEvent: false });
    }
  }

  private watchForFormValuesChanged(): void {
    this.form?.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(values => this.formValuesChanged.emit(values));
  }
}
