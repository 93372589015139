export class DataUtils {
  /**
   * Return value or a result of called function
   * 
   * @param value   Original value or function to get a value
   * @returns T || defaultValue as T
   */
  static getValue<T>(value: T | (() => T) | undefined, defaultValue: T): T {
    if (typeof value === 'function') {
      const result = (value as (() => T))();
      if (typeof result === 'boolean') {
        return value as T;
      }
      return result || defaultValue;
    } else if (typeof value === 'boolean') {
      return value as T;
    }
    return (value || defaultValue) as T;
  }
}