import { Models } from '$models';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NavigationEnd, NavigationExtras, Router } from '@angular/router';
import { NtsStateManagementService } from '@ntersol/state-management';
import { NtsState } from '@ntersol/state-management/lib/state.models';
import { isEqual } from 'lodash';
import { BehaviorSubject, Observable, distinctUntilChanged, filter, map, of, shareReplay, skipWhile, startWith, switchMap, take, tap } from 'rxjs';
import { SearchModels, searchActions, searchReducer } from '../../../routes/houses/shared';
import { segmentsToLocation } from '../../../routes/houses/shared/utils';
import { removeQueryParams } from '../../utils/url.utils';
import { filtersChanged } from '../actions';

const searchStoreState: SearchModels.State = {
  searchHistory: [],
  resultsToDisplay: 20,
  fullView: null,
};
/** Restrict google places requests to these places types: https://developers.google.com/maps/documentation/places/web-service/supported_types */
export const maxApiResults = 500;

interface RouteChangeOptions {
  queryParams?: Partial<Models.SearchPageQueryParams>;
  /** If true, queryParams will be replaced instead of merged. Merged is default  */
  replaceQueryParams?: boolean;
  routerOptions?: NavigationExtras;
}
/**
 * Is type an action?
 * @param a
 * @returns
 */
export const isAction = <t, y>(a: unknown): a is NtsState.Action<t, y> => {
  if (
    a &&
    typeof a === 'object' &&
    !Array.isArray(a) &&
    Object.prototype.hasOwnProperty.call(a, 'type') &&
    Object.prototype.hasOwnProperty.call(a, 'payload')
  ) {
    return true;
  }
  return false;
};

@Injectable({
  providedIn: 'root',
})
export class SearchStoreService {
  readonly propertySearchForm = this.formBuilder.group({
    TYPE: [null],
    CATEGORYLIST: [null],
    MINBEDS: null,
    MINBATHS: null,
    MORE: this.formBuilder.control({}),
  });

  private static readonly QueryParamsFieldName = 'searchState';
  /** Map UI store */
  private store = this.sm.createUIStore<SearchModels.State>({ ...searchStoreState });
  /** The base slug of the search results/map route */
  public searchRouteSlug = Models.SearchRouteSlug;

  public searchHistory$ = this.store.select$('searchHistory').pipe(
    map(history => {
      return history.map(historyItem => {
        return historyItem && historyItem.label?.endsWith(', USA')
          ? {
              ...historyItem,
              label: historyItem.label.substring(0, historyItem.label.length - 5),
            }
          : historyItem;
      });
    }),
  );

  private readonly _stopUrlLocationChangeEvents$ = new BehaviorSubject(false);

  // UI State
  public resultsToDisplay$ = this.store.select$('resultsToDisplay');
  public fullView$ = this.store.select$('fullView');

  private readonly urlLocation$ = this.router.events.pipe(
    startWith(new NavigationEnd(0, this.location.path(), this.location.path())),
    filter(event => event instanceof NavigationEnd),
    skipWhile(() => this._stopUrlLocationChangeEvents$.value),
    map(event => decodeURIComponent((event as NavigationEnd).url)),
    distinctUntilChanged(),
    shareReplay(1),
  );

  public searchLocation$: Observable<SearchModels.Location> = this.urlLocation$.pipe(
    map(url => {
      return this.extractSegmentsFromUrl(url);
    }),
    filter(segments => {
      if (!segments) return false;
      return segments[0] === Models.SearchRouteSlug && !(segments[1] && (segments[1] === 'redirect' || segments[1] === 'mobile'));
    }),
    map(segments => {
      return segmentsToLocation(segments);
    }),
    distinctUntilChanged(isEqual),
    shareReplay(1),
  );

  public readonly hasLocationFilters$: Observable<boolean> = this.searchLocation$.pipe(map(location => Object.keys(location).length > 0));

  public readonly queryParams$ = this.urlLocation$.pipe(map(url => this.extractQueryParametersFromUrl(url)));
  public readonly queryParamsFilters$: Observable<Models.QueryParamsSearchFilters | undefined> = this.queryParams$.pipe(
    filter((allQueryParams: Partial<Models.SearchPageQueryParams | null>) => !!allQueryParams),
    map(allQueryParams => {
      if (allQueryParams?.filters) {
        return allQueryParams!.filters;
      }
      return this.propertySearchForm.value;
    }),
    distinctUntilChanged(isEqual),
  );

  public readonly queryParamsPagination$: Observable<Models.SearchPageQueryParams['pagination'] | undefined> = this.queryParams$.pipe(
    filter((allQueryParams: Partial<Models.SearchPageQueryParams | null>) => !!allQueryParams),
    map(allQueryParams => {
      return allQueryParams!.pagination;
    }),
    distinctUntilChanged(isEqual),
  );

  public readonly queryParamsMap$: Observable<Models.SearchPageQueryParams['map'] | undefined> = this.queryParams$.pipe(
    map(allQueryParams => {
      if (allQueryParams?.map) {
        return allQueryParams.map;
      }
      return;
    }),
    distinctUntilChanged(isEqual),
  );

  public readonly queryParamsSearch$: Observable<Models.SearchPageQueryParams['search'] | undefined> = this.queryParams$.pipe(
    map(allQueryParams => {
      if (allQueryParams?.search) {
        return allQueryParams.search;
      }
      return;
    }),
    distinctUntilChanged(isEqual),
  );

  public readonly queryParamsSortDirection$: Observable<Models.SearchPageQueryParams['sortDirection'] | undefined> = this.queryParams$.pipe(
    map(allQueryParams => {
      if (allQueryParams?.sortDirection) {
        return allQueryParams.sortDirection;
      }
      return;
    }),
    distinctUntilChanged(isEqual),
  );

  public readonly queryParamsContentView$: Observable<Models.SearchPageQueryParams['contentView'] | undefined> = this.queryParams$.pipe(
    map(allQueryParams => {
      if (allQueryParams?.contentView) {
        return allQueryParams.contentView;
      }
      return;
    }),
    distinctUntilChanged(isEqual),
  );

  public readonly queryParamsMobileListingsVisible$: Observable<Models.SearchPageQueryParams['mobileListingsVisible'] | undefined> = this.queryParams$.pipe(
    map(allQueryParams => {
      if (allQueryParams?.mobileListingsVisible) {
        return allQueryParams.mobileListingsVisible;
      }
      return;
    }),
    distinctUntilChanged(isEqual),
  );

  constructor(
    private readonly sm: NtsStateManagementService,
    private readonly location: Location,
    private readonly router: Router,
    private readonly formBuilder: FormBuilder,
  ) {
    // Do not put logic in here, use initialize method instead
  }

  /**
   * When service is started
   */
  public initialize(): Observable<void> {
    return this.sm.events$.pipe(
      tap(event => {
        if (filtersChanged.match(event)) {
          if (event.payload) {
            this.filtersChange(event.payload);
          }
        }
      }),
      switchMap(() => of()),
    );
  }

  public searchHistoryChange(newHistory: { label: string; value: string }) {
    this.searchHistory$.pipe(take(1)).subscribe(prevHistory => {
      if (prevHistory.some(history => history.value === newHistory.value)) {
        return;
      }

      let history = [newHistory, ...prevHistory].filter((_x, i) => i < 5);
      this.update({ searchHistory: history });
    });
  }

  /**
   * Change active search filters
   * @param filters
   */
  private filtersChange(filters: Models.QueryParamsSearchFilters) {
    this.mergeQueryParameters({ filters });
  }

  /**
   * Handle the pagination event for when a user changes the
   * @param page
   */
  public paginateChange(page: number) {
    this.mergeQueryParameters({ pagination: { page } });
  }

  public googleMapChange(mapData: Partial<Models.SearchPageQueryParams['map']>) {
    this.mergeQueryParameters({ map: mapData });
  }

  public sortChange(sortDirection: Models.SearchPageQueryParams['sortDirection']) {
    this.mergeQueryParameters({ sortDirection });
  }

  public contentViewChange(contentView: Models.SearchPageQueryParams['contentView']) {
    this.mergeQueryParameters({ contentView });
  }

  public searchChange(searchData: Models.SearchPageQueryParams['search']) {
    this.mergeQueryParameters({ search: searchData });
  }

  public mobileListingsVisibleChange(mobileListingsVisible: Models.SearchPageQueryParams['mobileListingsVisible'], mobile?: boolean) {
    this.mergeQueryParameters({ mobileListingsVisible }, mobile);
  }

  private mergeQueryParameters(queryParams: Partial<Models.SearchPageQueryParams>, mobile?: boolean): void {
    const currentQueryParamsFromRouter = this.extractQueryParametersFromUrl(this.router.url);
    let mergedQueryParams;
    if (!currentQueryParamsFromRouter) {
      mergedQueryParams = { ...queryParams };
    } else {
      mergedQueryParams = { ...currentQueryParamsFromRouter, ...queryParams };
    }

    if (mobile) {
      delete mergedQueryParams?.contentView;
    }

    this.routeChange(null, {
      queryParams: mergedQueryParams,
      replaceQueryParams: true,
      routerOptions: {
        replaceUrl: true,
      },
    });
  }

  private extractQueryParametersFromUrl(url: string): Partial<Models.SearchPageQueryParams> | null {
    const urlTree = this.router.parseUrl(url);
    if (urlTree.queryParamMap.keys.length === 0) {
      return null;
    }
    if (urlTree.queryParamMap.keys.some(key => key.startsWith('utm'))) {
      return null;
    }
    const queryParamsSerialized = urlTree.queryParams;
    const deserializedQueryParams: Partial<Models.SearchPageQueryParams> = JSON.parse(queryParamsSerialized[SearchStoreService.QueryParamsFieldName]);
    return deserializedQueryParams;
  }

  private extractSegmentsFromUrl(url: string): string[] {
    const urlWithoutQueryParams = removeQueryParams(url);
    const segments = urlWithoutQueryParams.split('/').filter(path => !!path);
    return segments;
  }

  /**
   * Make state changes to the map store
   * @param update - Supports both a partial and an action
   */
  public update(update: Partial<SearchModels.State> | NtsState.Action<SearchModels.State>) {
    // Convert any arguments to an action if not one already
    const action = isAction(update) ? update : searchActions.stateChange(update);
    // Send action to the reducer before updating state
    this.store.update(searchReducer(this.store.state$.value, action));
  }

  /**
   * Perform a route change for the map page. By default uses the location service to affect change route params without reloading component. Otherwise falls back to standard Angular Router
   * @param url
   * @param reloadRoute
   */
  public routeChange(
    /** URL to change route to, can be left nill to reload current page. IE in event of only filters or pagination change */
    urlSrc: string | null | undefined = this.location.path() + '/',
    options?: RouteChangeOptions,
  ) {
    if (!urlSrc) {
      urlSrc = this.location.path() + '/';
    }
    // Strip out any preexisting query params in the supplied url
    const url = decodeURIComponent(urlSrc?.split('?')[0]);
    let routerOptions: NavigationExtras = {};

    if (options?.replaceQueryParams && !options.queryParams) {
      throw new Error('If replaceQueryParams is set to true then queryParams must be provided');
    }

    if (!this.isHousesUrl(url)) {
      throw new Error(`Navigation can be made only to ${this.searchRouteSlug} path`);
    }

    if (options?.routerOptions) {
      routerOptions = { ...options.routerOptions };
    }

    if (options?.replaceQueryParams) {
      const queryParams = options.queryParams;
      this.router.navigate([url], {
        queryParams: {
          [SearchStoreService.QueryParamsFieldName]: JSON.stringify(queryParams),
        },
        ...routerOptions,
      });
      return;
    }

    if (options?.queryParams) {
      const currentQueryParams = this.extractQueryParametersFromUrl(this.router.url);
      const queryParams = { ...currentQueryParams, ...options.queryParams };
      this.router.navigate([url], {
        queryParams: {
          [SearchStoreService.QueryParamsFieldName]: JSON.stringify(queryParams),
        },
        ...routerOptions,
      });
      return;
    }

    this.router.navigate([url], routerOptions);
  }

  public turnOnLocationChangeEvents() {
    this._stopUrlLocationChangeEvents$.next(false);
  }

  public turnOffLocationChangeEvents() {
    this._stopUrlLocationChangeEvents$.next(true);
  }

  private isHousesUrl(url: string): boolean {
    const urlPaths = url?.split('/');
    const firstPath = urlPaths.filter(path => !!path)[0];
    return firstPath === this.searchRouteSlug;
  }
}
