<app-login-header *ngIf="_displayHeader"></app-login-header>
<div
  class="container-fluid"
  *ngIf="{ currentTab: currentTab$ | async, customMessage: customMessage$ | async, hasLoggedInOnce: hasLoggedInOnce$ | async } as componentData">
  <div class="row">
    <div class="col-12 col-lg-6 py-4 d-flex align-items-center justify-content-center">
      <div class="form-container">
        <p-tabView [activeIndex]="componentData.currentTab!" (activeIndexChange)="handleTabChanged($event)">
          <p-tabPanel header="Login">
            <ng-container *ngIf="componentData.currentTab === TAB.LOGIN">
              <ng-container *ngIf="componentData.customMessage; else noCustom">
                <h3 class="mt-4">Before you continue...</h3>
                <p [innerHtml]="componentData.customMessage"></p>
              </ng-container>
              <ng-template #noCustom>
                <div *ngIf="componentData.hasLoggedInOnce; else noLogin">
                  <h3 class="mt-4">{{ loginTabTitle }}</h3>
                  <div class="form-intro gray-400 mt-1 mb-4">{{ loginTabSubTitle }}</div>
                </div>
              </ng-template>
              <ng-template #noLogin>
                <div class="d-none d-md-block">
                  <h3 class="mt-4">Please sign in</h3>
                  <div class="form-intro gray-400 mt-1 mb-4">Please enter your details below to get started.</div>
                </div>
              </ng-template>
              <app-login-form
                [userName]="signupLogin"
                [showLogoutMessage]="!componentData.customMessage && !!navigationState?.['triggeredByUser']"></app-login-form>
            </ng-container>
          </p-tabPanel>
          <p-tabPanel header="Sign up">
            <ng-container *ngIf="componentData.currentTab === TAB.SIGN_UP">
              <div class="d-none d-md-block">
                <h3 class="mt-4">{{ singupTabTitle }}</h3>
                <div class="form-intro gray-400 mt-1 mb-4">{{ signupTabSubTitle }}</div>
              </div>
              <app-sign-up-form (complete)="handleTabChanged(TAB.LOGIN, $event)"></app-sign-up-form>
            </ng-container>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
    <div class="col-lg-6 d-none d-lg-flex side-image"></div>
  </div>
</div>
