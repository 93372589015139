import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-base-dropdown',
  template: ``,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseCovDropdown {
  @Input() public selectedDropDownName = '';
  public selectedAmount: number = 0;

  @Output() openDropdown = new EventEmitter<string>();
  constructor() {}

  displayField(fieldName: string) {
    const closeOrOpen = this.selectedDropDownName === fieldName ? '' : fieldName;
    this.openDropdown.emit(closeOrOpen);
  }
}
