import { stateLabelValues } from '../models';

export function getLongStateName(stateAbbreviation: typeof stateLabelValues[0]['value']): typeof stateLabelValues[0]['label'] | null {
  const stateProperties = stateLabelValues.find(stateProperties => stateProperties.value === stateAbbreviation);
  if (!stateProperties) {
    return null;
    // throw new Error(`There is no state with the abbreviation ${stateAbbreviation}`);
  }
  const stateLongName = stateProperties.label;
  return stateLongName;
}

export function getStateAbbreviation(stateLongName: typeof stateLabelValues[0]['label']): typeof stateLabelValues[0]['value'] | null {
  const stateProperties = stateLabelValues.find(stateProperties => stateProperties.label.toLowerCase() === stateLongName.toLowerCase());
  if (!stateProperties) {
    return null;
    // throw new Error(`There is no state with the long name ${stateLongName}`);
  }
  const stateAbbreviation = stateProperties.value;
  return stateAbbreviation;
}
