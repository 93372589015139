<ng-container
  *ngIf="{
    auctionTypes: auctionTypes$ | async,
    propertyTypes: propertyTypes$ | async,
    largeScreenDownObserver: largeScreenDownObserver$ | async
  } as componentData">
  <div class="search-filters-container" [class.mobile]="componentData.largeScreenDownObserver">
    <form [formGroup]="propertySearchForm">
      <div [ngClass]="{ 'flex desktop': !componentData.largeScreenDownObserver }">
        <div class="me-2 flex-1" *ngIf="!componentData.largeScreenDownObserver">
          <app-property-search-bar [enableSmallDropDownOnHighResolutionScreen]="true" class="property-search"></app-property-search-bar>
        </div>
        <div class="dropdowns">
          <!-- AUCTION TYPES-->
          <div class="auction-type">
            <p-multiSelect
              [options]="componentData.auctionTypes || []"
              [filter]="false"
              [showHeader]="true"
              formControlName="CATEGORYLIST"
              [placeholder]="auctionTypeLabel"
              scrollHeight="100%"
              showClear="true"
              styleClass="me-lg-3 filter-select">
              <ng-template pTemplate="header">
                <span class="auction-type-label">All</span>
              </ng-template>
              <ng-template let-value pTemplate="selectedItems">
                <ng-container
                  *ngIf="
                    value === null || (value?.length && value[0] === null) || value.length === componentData.auctionTypes?.length;
                    else notAllAuctionTypes
                  ">
                  {{ auctionTypeLabel }} <span class="filter-selected">(All)</span>
                </ng-container>
                <ng-container *ngIf="value?.length === 1 && value[0] !== null">
                  <span class="filter-selected">{{ propertyType(componentData.auctionTypes!, value[0])?.label }}</span>
                </ng-container>
                <ng-template #notAllAuctionTypes>
                  <span>
                    {{ auctionTypeLabel }} <span class="filter-selected">({{ value?.length }})</span>
                  </span>
                </ng-template>
              </ng-template>
            </p-multiSelect>
          </div>

          <!-- PROPERTY TYPES -->
          <div class="property-type">
            <p-multiSelect
              [options]="componentData.propertyTypes || []"
              [filter]="false"
              [showHeader]="true"
              formControlName="TYPE"
              scrollHeight="100%"
              styleClass="me-lg-3 filter-select"
              [placeholder]="propertyTypeLabel"
              showClear="true">
              <ng-template pTemplate="header">
                <span class="auction-type-label">All</span>
              </ng-template>
              <ng-template let-value pTemplate="selectedItems">
                <ng-container
                  *ngIf="
                    value === null || (value?.length && value[0] === null) || value.length === componentData.propertyTypes?.length;
                    else notAllPropertyTypes
                  ">
                  {{ propertyTypeLabel }} <span class="filter-selected">(All)</span>
                </ng-container>
                <ng-container *ngIf="value?.length === 1 && value[0] !== null">
                  <span class="filter-selected">{{ propertyType(componentData.propertyTypes!, value[0])?.label }}</span>
                </ng-container>
                <ng-template #notAllPropertyTypes>
                  <span>
                    {{ propertyTypeLabel }} <span class="filter-selected">({{ value?.length }})</span>
                  </span>
                </ng-template>
                &nbsp;
              </ng-template>
            </p-multiSelect>
          </div>

          <app-beds-bath-dropdown
            [numberOfBeds]="bedsFormControl.value"
            [numberOfBaths]="bathsFormControl.value"
            [bedsFormControl]="bedsFormControl"
            [bathsFormControl]="bathsFormControl"
            [isMobile]="!!componentData.largeScreenDownObserver"></app-beds-bath-dropdown>

          <!-- ADDITIONAL FILTERS -->
          <app-more formControlName="MORE" [isMobile]="!!componentData.largeScreenDownObserver"></app-more>

          <button
            *ngIf="!componentData.largeScreenDownObserver"
            [class.show]="hasFilters$ | async"
            pButton
            type="button"
            icon="pi pi-times"
            class="p-button-link gray-500 clear-filters mr-15"
            label="Clear Filters"
            title="Clear Filters"
            (click)="clearForm()"></button>
        </div>

        <div class="d-block d-lg-none mobile-button-filters" *ngIf="componentData.largeScreenDownObserver">
          <div class="buttons-container">
            <div class="save-clear-mobile">
              <button
                pButton
                type="button"
                class="apply-filters p-button-sm"
                label="Apply filters"
                title="Apply filters"
                (click)="applyFiltersForMobile()"></button>
            </div>
          </div>
        </div>

        <div class="d-lg-block ms-auto text-end" *ngIf="!componentData.largeScreenDownObserver">
          <button pButton type="button" class="p-button-sm p-button-secondary me-2" (click)="op.toggle($event)">
            Save Search <fa-icon class="ml-15" icon="angle-down"></fa-icon>
          </button>
          <p-overlayPanel #op [showCloseIcon]="false">
            <ng-template pTemplate>
              <app-save-search-form (close)="op.hide()"></app-save-search-form>
            </ng-template>
          </p-overlayPanel>
        </div>
      </div>
    </form>
  </div>
</ng-container>
