import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AgentTermsComponent } from './agent-terms/agent-terms.component';
import { InvestorTermsComponent } from './investor-terms/investor-terms.component';
import { UserTermsOhioComponent } from './user-terms-ohio/user-terms-ohio.component';
import { UserTermsComponent } from './user-terms/user-terms.component';
import { AgreeTermsComponent } from './agree-terms/agree-terms.component';

@NgModule({
  declarations: [UserTermsComponent, InvestorTermsComponent, AgentTermsComponent, UserTermsOhioComponent, AgreeTermsComponent],
  exports: [UserTermsComponent, InvestorTermsComponent, AgentTermsComponent, UserTermsOhioComponent, AgreeTermsComponent],
  imports: [RouterModule, CommonModule],
})
export class TermsOfServicePartsModule {}
