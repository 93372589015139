import { Component, Input } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { Models } from '../../../shared/models';

interface FooterLink {
  label?: string;
  externalLink?: string;
  routerLink?: string;
  icon?: IconProp;
}

@Component({
  selector: 'app-footer',
  styleUrls: ['./footer.component.scss'],
  templateUrl: './footer.component.html',
})
export class FooterComponent {
  @Input() isHousesPage = false;

  readonly searchRouteSlug = Models.SearchRouteSlug;

  footerLinks: FooterLink[] = [
    {
      label: 'About Us',
      routerLink: '/about-us/',
    },
    {
      label: 'Careers',
      externalLink: 'https://covius.wd1.myworkdayjobs.com/CoviusCareers',
    },
    {
      label: 'Help Center',
      routerLink: '/help-center/',
    },
    {
      label: 'Contact Us',
      routerLink: '/contact-us/',
    },
  ];
  icons: FooterLink[] = [
    {
      icon: ['fab', 'facebook-f'],
      externalLink: 'https://www.facebook.com/RealtyBid',
    },
    {
      icon: ['fab', 'x-twitter'],
      externalLink: 'https://twitter.com/RealtyBid',
    },
    {
      icon: ['fab', 'linkedin-in'],
      externalLink: 'https://www.linkedin.com/company/realtybid.com',
    },
  ];

  public thisYear = new Date().getFullYear();
  constructor() {}
}
