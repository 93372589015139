import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Models } from '$models';
import { BehaviorSubject, map } from 'rxjs';

@Component({
  selector: 'app-auction-type-indicator',
  templateUrl: './auction-type-indicator.component.html',
  styleUrls: ['./auction-type-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuctionTypeIndicatorComponent implements OnInit, OnChanges {
  @Input() categoryType?: Models.PropertyCategoryType = 0;

  public categoryType$ = new BehaviorSubject(0);

  public description$ = this.categoryType$.pipe(map(cat => Models.AUCTION_TYPE_DESCRIPTION_BY_CAT[String(cat)]));
  public color$ = this.categoryType$.pipe(
    map(cat => Models.AUCTION_TYPE_COLOR_BY_CAT[String(cat)]),
    map(cat => (cat ? cat : '#00796B')),
  );

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['categoryType'] && this.categoryType) {
      this.categoryType$.next(this.categoryType || 0);
    }
  }
}
