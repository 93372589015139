<div class="desktop-buy-sell-list" [ngClass]="!userType ? 'not-signed-in' : ''">
  <div *ngIf="isLoggedIn">
    <div class="list d-flex flex-column gap-2">
      <div class="list-item" *ngFor="let item of items">
        <h6 class="mb-2">{{ item.label }}</h6>
        <div class="flex nav-item-container mb-2" *ngFor="let menuItem of item.menuItems">
          <a
            *ngIf="menuItem.routerLink"
            class="nav-item p-1 w-100"
            [routerLink]="menuItem.routerLink ? [menuItem.routerLink] : null"
            [state]="menuItem.state"
            (click)="menuItem.command ? menuItem.command() : null">
            {{ menuItem.label }}
          </a>
          <a
            *ngIf="menuItem.externalLink"
            class="nav-item p-1 w-100"
            (click)="redirect(menuItem.externalLink, menuItem.command)"
            target="_blank"
            rel="noreferrer noreopen">
            {{ menuItem.label }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isBidder()" class="footnote mt-25">
    <hr />
    <div>You are currently registered as a {{ getUserTypeName(userType) }}.</div>
    <div>
      To change your account type,
      <span *ngIf="isClient()">contact <br /><a class="button-link" href="mailto:customerservice@realtybid.com">customerservice@realtybid.com</a></span>
      <span *ngIf="isBidder()">go to <br /><a class="button-link" routerLink="/my-profile/">Account Details</a></span>
    </div>
  </div>
  <div *ngIf="!isLoggedIn" class="login-signup-container">
    <p><span class="login-signup pointer" routerLink="/login/">Login or Sign-up to access RealtyBid.com features.</span></p>
  </div>
</div>
