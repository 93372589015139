<ng-container
  *ngIf="{
    isHomePage: isHomePage$ | async,
    largeScreenDownObserver: largeScreenDownObserver$ | async,
    footerVisible: footerVisible$ | async
  } as componentData">
  <app-header id="sticky-app-header" class="sticky-top" [isHomePage]="componentData.isHomePage" [class.home-page]="componentData.isHomePage"> </app-header>
  <main class="flex-1" [class.home-page-main]="componentData.isHomePage"><router-outlet></router-outlet></main>
  <ng-container *ngIf="(!componentData.largeScreenDownObserver || componentData.isHomePage) && componentData.footerVisible">
    <app-footer [class.home-spacing]="componentData.isHomePage"></app-footer>
  </ng-container>
  <app-mobile-navigation *ngIf="componentData.isHomePage" class="d-block d-lg-none"></app-mobile-navigation>
</ng-container>
