import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { NtsStateManagementModule } from '@ntersol/state-management';
import { ModalWizardStoreService } from './shared/store/modal-wizard.store';
import { ModalWizardService } from './shared/services/modal-wizard.service';

import { ModalWizardStepContentDirective } from './shared/directives/modal-wizard-step-content.directive';
import { ModalWizardStepComponent } from './components/modal-wizard-step/modal-wizard-step.component';
import { ModalWizardComponent } from './modal-wizard.component';
import { ModalWizardStepContentBaseComponent } from './components/modal-wizard-step/modal-wizard-step-content-base.component';

@NgModule({
  imports: [CommonModule, NtsStateManagementModule, ButtonModule],
  declarations: [ModalWizardStepContentDirective, ModalWizardStepContentBaseComponent, ModalWizardStepComponent, ModalWizardComponent],
  providers: [ModalWizardStoreService, ModalWizardService],
  exports: [ModalWizardStepContentBaseComponent, ModalWizardStepComponent, ModalWizardComponent],
})
export class ModalWizardModule {}
