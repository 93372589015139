import { Component, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged } from 'rxjs';
import { InputErrorShowCondition } from 'src/app/components/input-error/input-error.component';
import { FormGeneratorModel } from 'src/app/shared/models/form.models';
import { BiddingModels, RequiredKeys } from '../../shared/models/bidding.model';

const authorizeNetPaymentForm: Record<RequiredKeys<BiddingModels.AuthorizeNetPaymentCardModel>, any> = {
  fullname: [null, [Validators.required, Validators.maxLength(100)]],
  cardNumber: [null, Validators.required],
  month: [null, [Validators.required, Validators.pattern('0[1-9]|1[0-2]')]],
  year: [null, [Validators.required]],
  cardCode: [null, [Validators.required]],
  zip: [null, [Validators.required, Validators.maxLength(8)]],
};

@UntilDestroy()
@Component({
  selector: 'app-bid-deposit',
  templateUrl: './bid-deposit.component.html',
  styleUrls: ['./bid-deposit.component.scss'],
})
export class BidDepositComponent implements OnInit, AfterViewInit {
  @Output() formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() valuesChanged: EventEmitter<{ [key: string]: any }> = new EventEmitter<{ [key: string]: any }>();

  form = this.fb.group(authorizeNetPaymentForm);

  depositFormFeilds: FormGeneratorModel<keyof BiddingModels.AuthorizeNetPaymentCardModel>[] = [
    {
      label: 'Full Name (on the card)',
      placeholder: 'e.g. John Doe',
      formControlName: 'fullname',
      customErrorMessage: 'Required',
    },
    {
      label: 'Card Number',
      type: 'inputmask',
      placeholder: 'e.g. 1234 1234 1234 1234',
      formControlName: 'cardNumber',
      mask: '9999 9999 9999 9999',
      customErrorMessage: 'Required',
      slotChar: ' ',
    },
    {
      label: 'Exp. Month',
      type: 'inputmask',
      placeholder: 'MM',
      formControlName: 'month',
      mask: '99',
      slotChar: ' ',
      customErrorMessage: 'Required',
      class: 'col-6',
    },
    {
      label: 'Exp. Year',
      type: 'inputmask',
      placeholder: 'YYYY',
      formControlName: 'year',
      mask: '9999',
      slotChar: ' ',
      customErrorMessage: 'Required',
      class: 'col-6',
    },
    {
      label: 'CVV ',
      type: 'inputmask',
      placeholder: 'e.g. 123',
      formControlName: 'cardCode',
      mask: '999',
      slotChar: ' ',
      customErrorMessage: 'Required',
      class: 'col-6',
    },
    {
      label: 'ZIP Code / Postal Code',
      type: 'inputmask',
      placeholder: 'e.g. 11111',
      formControlName: 'zip',
      mask: '99999',
      slotChar: ' ',
      customErrorMessage: 'Required',
      class: 'col-6',
    },
  ];

  errorCondition: InputErrorShowCondition = {
    touch: true,
    submit: true,
  };

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.watchForFormValuesChanged();
  }

  ngAfterViewInit(): void {
    this.formReady.emit(this.form);
  }

  private watchForFormValuesChanged(): void {
    this.form?.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(values => this.valuesChanged.emit(values));
  }
}
