<ng-container
  *ngIf="{
    currentUserLocation: currentUserLocationGooglePlaceId$ | async,
    searchHistory: searchHistory$ | async,
    savedSearches: savedSearches$ | async,
    googleResults: googleResults$ | async,
    canSubmit: canSubmit$ | async,
    isLargeScreen: largeScreenObserver$ | async,
    isLessThanLargeScreen: largeScreenDownObserver$ | async,
    isXXXXLScreen: xxxxlScreenUpObserver$ | async,
    searchInputFieldPlaceholder: searchInputFieldPlaceholder$ | async
  } as componentData">
  <div class="search-wrapper" tabindex="-1" (clickOutside)="clickOutsideHandler()">
    <div class="search-controls h-100">
      <form (ngSubmit)="onSubmit(); $event.preventDefault()" class="form p-inputgroup h-100" [ngClass]="{ 'with-button': showSearchButton }">
        <div style="position: relative">
          <ng-container *ngIf="componentData.isLessThanLargeScreen; else searchField">
            <span class="mobile-search-container p-input-icon-right d-block">
              <i class="pi pi-search" (click)="onSubmit(); $event.preventDefault()"></i>
              <ng-template [ngTemplateOutlet]="searchField"></ng-template>
            </span>
          </ng-container>
          <button
            *ngIf="showSearchButton"
            class="d-none d-lg-inline-flex button p-button-lg search-button"
            type="submit"
            aria-label="Search"
            pButton
            icon="pi pi-search"
            [disabled]="disabled || !componentData.canSubmit"></button>
          <div *ngIf="isDropdownVisible" (mouseover)="dropdownMouseoverHandler($event)" class="dropdown-wrapper card card-body">
            <!-- No Results Found -->
            <ng-container *ngIf="showAdvancedSearchError">
              <div class="group group-5">
                <ul>
                  <li #li>No Results Found</li>
                </ul>
              </div>
            </ng-container>

            <!-- User location -->
            <ng-container *ngIf="showCurrentUserGeolocation">
              <div class="group group-1" *ngIf="componentData.currentUserLocation">
                <ul>
                  <li
                    #li
                    (click)="onDropdownListElementClick()"
                    [attr.data-key]="componentData.currentUserLocation.value"
                    [attr.data-type]="searchRequestType.CURRENT_LOCATION"
                    [attr.data-description]="componentData.currentUserLocation.addressDescription"
                    class="search-result">
                    <a><fa-icon icon="map-pin"></fa-icon> {{ componentData.currentUserLocation.label }}</a>
                  </li>
                </ul>
              </div>
            </ng-container>

            <!-- Google search locations -->
            <ng-container *ngIf="componentData.googleResults?.length">
              <div class="group group-3">
                <span class="subtitle">Search Locations</span>
                <ul>
                  <li
                    #li
                    (click)="onDropdownListElementClick()"
                    [attr.data-key]="data.value"
                    [attr.data-type]="searchRequestType.GOOGLE_RESULT"
                    [attr.data-description]="data.addressDescription"
                    *ngFor="let data of componentData.googleResults">
                    <fa-icon icon="map-marker-alt"></fa-icon> {{ data.label }}
                  </li>
                </ul>
              </div>
            </ng-container>

            <!-- Search History -->
            <ng-container *ngIf="componentData.searchHistory && showSearchHistory">
              <div class="group group-4" *ngIf="componentData.searchHistory.length">
                <span class="subtitle">Search History</span>
                <ul>
                  <li
                    #li
                    (click)="onDropdownListElementClick(data.label)"
                    [attr.data-key]="data.value"
                    [attr.data-type]="searchRequestType.HISTORY"
                    [attr.data-url]="data.value"
                    *ngFor="let data of componentData.searchHistory">
                    <fa-icon icon="map-marker-alt"></fa-icon> {{ data.label }}
                  </li>
                </ul>
              </div>
            </ng-container>

            <!-- Saved Searches -->
            <ng-container *ngIf="showSavedSearches">
              <ng-container *ngIf="componentData.savedSearches">
                <div class="group group-5" *ngIf="componentData.savedSearches?.length">
                  <span class="subtitle">Saved Searches</span>
                  <ul>
                    <li
                      #li
                      (click)="onDropdownListElementClick()"
                      [attr.data-key]="data.value"
                      [attr.data-type]="searchRequestType.SAVED_SEARCH"
                      [attr.data-url]="data.url"
                      *ngFor="let data of componentData.savedSearches">
                      <fa-icon icon="map-marker-alt"></fa-icon> {{ data.label }}
                    </li>
                  </ul>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <div class="advancedSearchContainer" *ngIf="showAdvancedSearchDropdown">
          <ng-container *ngIf="enableSmallDropDownOnHighResolutionScreen">
            <app-icon-dropdown
              *ngIf="!componentData.isXXXXLScreen && !componentData.isLessThanLargeScreen"
              [options]="advancedSearchOptions || []"
              [formControl]="selectedValueControl">
            </app-icon-dropdown>
            <p-dropdown
              *ngIf="componentData.isXXXXLScreen"
              [autoDisplayFirst]="false"
              placeholder="Advanced Search"
              [options]="advancedSearchOptions || []"
              [id]="'AdvancedSearch'"
              [formControl]="selectedValueControl"
              [showClear]="false"
              [showTransitionOptions]="'100ms'"
              [hideTransitionOptions]="'50ms'"
              (onClear)="resetPlaceholder()">
              <ng-template pTemplate="selectedItem">
                <div>
                  <span>Advanced Search</span>
                </div>
              </ng-template>
              <ng-template let-option pTemplate="item">
                <div>
                  <span>{{ option.label }}</span>
                </div>
              </ng-template>
            </p-dropdown>
            <div *ngIf="componentData.isLessThanLargeScreen" class="mobile-advancedsearch">
              <p-button
                styleClass="mobile-advancedsearch--btn"
                icon="pi pi-chevron-down"
                [routerLink]="advancedSearchMobileRoutingPath.length > 0 ? advancedSearchMobileRoutingPath : ['.', 'mobile', 'advanced-search-options']">
              </p-button>
            </div>
          </ng-container>
          <ng-container *ngIf="!enableSmallDropDownOnHighResolutionScreen">
            <p-dropdown
              *ngIf="componentData.isLargeScreen"
              [autoDisplayFirst]="false"
              placeholder="Advanced Search"
              [options]="advancedSearchOptions || []"
              [id]="'AdvancedSearch'"
              [formControl]="selectedValueControl"
              [showClear]="false"
              [showTransitionOptions]="'100ms'"
              [hideTransitionOptions]="'50ms'"
              (onClear)="resetPlaceholder()">
              <ng-template pTemplate="selectedItem">
                <div>
                  <span>Advanced Search</span>
                </div>
              </ng-template>
              <ng-template let-option pTemplate="item">
                <div>
                  <span>{{ option.label }}</span>
                </div>
              </ng-template>
            </p-dropdown>
            <div *ngIf="componentData.isLessThanLargeScreen" class="mobile-advancedsearch">
              <p-button
                styleClass="mobile-advancedsearch--btn"
                icon="pi pi-chevron-down"
                [routerLink]="advancedSearchMobileRoutingPath.length > 0 ? advancedSearchMobileRoutingPath : ['.', 'mobile', 'advanced-search-options']">
              </p-button>
            </div>
          </ng-container>
        </div>
      </form>
    </div>
  </div>

  <ng-template #searchField>
    <input
      type="text"
      pInputText
      [placeholder]="componentData.searchInputFieldPlaceholder"
      [formControl]="searchControl"
      (click)="inputFieldClickHandler($event)"
      (focus)="inputFieldFocusHandler($event)"
      (keydown)="dropdownKeyboardNavigationHandler($event); inputFieldStopCursorShiftHandler($event)"
      class="input-element" />
  </ng-template>
</ng-container>
