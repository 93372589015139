import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function matchValue(control2Name: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const parentForm = control.parent;

    if (control.value !== parentForm?.get(control2Name)?.value) {
      return { matchValue: true };
    }
    return null;
  };
}

/**
 * Validator should be used on the FormGroup level to compare 2 child FormControls.
 * For example:
 *  new FormGroup({
 *    test1: FormControl(),
 *    test2: FormControl(),
 *  }, { validators: matchControls('test1', 'test2')})
 */
export function matchControls(control1Name: string, control2Name: string): (control: AbstractControl) => ValidationErrors | null {
  return (control: AbstractControl) => {
    const control1 = control.get(control1Name);
    const control2 = control.get(control2Name);

    return control1 && control2 && control1.value !== control2.value
      ? {
          matchValue: true,
        }
      : null;
  };
}
