export type YesNo = 'Y' | 'N' | 'U' | null;

enum ZeroOneBoolean {
  TRUE = 1,
  FALSE = 0,
}

export class PropertyBase {
  IS_AGENT: boolean = false; // internal, not part of the request
  IS_INVESTOR: boolean = false; // internal, not part of the request
  CAN_EDIT_DATE: ZeroOneBoolean = ZeroOneBoolean.TRUE;
  /** Dupe of ITEM_ID I think */
  PROPERTY_ID: number | null = null;
  ITEM_ID: number | null = null;

  /**
   * AUCTION TIMELINE
   */
  FINANCING: YesNo = 'Y';
  AUCTION_DAY_COUNT: number = 5;
  AUCTION_START_DATE: string | null = null;

  /**
   * PROPERTY INFORMATiON
   */
  ASSET_NUMBER: string | null = null;
  ADDRESS1: string | null = null;
  ADDRESS2: string | null = null;
  CITY: string | null = null;
  COUNTY: string | null = null; // not full_county_name
  STATE_CODE: string | null = null;
  POSTAL_CODE: string | null = null;
  SUBDIVISION: string | null = null;
  OCCUPANCY: string | null = 'Unknown';
  YARDSIGN: YesNo = null; // only agent

  /**
   * PROPERTY FEATURES
   */
  TYPE: number | null = null;
  BEDROOM_COUNT: number | null = null;
  BATH_COUNT: number | null = null;
  HALF_BATH_COUNT: number | null = null;
  SQ_FT: number | null = null;
  ACRES: string | null = null;
  YEAR_BUILT: number | null = null;
  FIREPLACE: YesNo | ' ' = 'U';
  GARAGE_SIZE: YesNo | string = '';
  HEAT_TYPE: string | null = 'Unknown';
  BASEMENT: YesNo | ' ' = 'U';
  OCCUPANCY_STATUS: string | null = 'U';

  /**
   * PROPERTY DESCRIPTION
   */
  DESCRIPTION: string | null = null;

  /**
   * PRICING/FEE
   */
  LIST_PRICE: number | null = null;
  RESERVE_PRICE_AMT: number | null = null;
  BUYER_BROKER_FEE: string | null = null;
  LISTING_AGENT_FEE: string | null = null; // ONLY INVESTOR

  /**
   * LISTING AGENT - INVESTOR ONLY
   */
  HAS_LISTING_AGENT: YesNo = 'N'; // ! NOT PART OF THE ENDPOINT - USED INTERNALLY
  LISTING_AGENCY: string | null = null;
  LISTING_AGENT_FIRST_NAME: string | null = null;
  LISTING_AGENT_LAST_NAME: string | null = null;
  LISTING_AGENT_EMAIL_ADDRESS: string | null = null;
  LISTING_AGENT_PHONE: string | null = null;
  LISTING_AGENT_ADDRESS1: string | null = null;
  LISTING_AGENT_CITY: string | null = null;
  LISTING_AGENT_STATE_CODE: string | null = null;
  LISTING_AGENT_POSTAL_CODE: string | null = null;
  LISTING_AGENT_LICENSE_NUMBER: string | null = null;
  LISTING_AGENT_LICENSE_STATE: string | null = null;

  /**
   * CLOSING AGENT - INVESTOR ONLY
   */
  HAS_CLOSER_AGENCY: YesNo = 'N'; // ! NOT PART OF THE ENDPOINT - USED INTERNALLY
  CLOSER_AGENT_AGENCY: string | null = null;
  CLOSER_AGENT_FIRST_NAME: string | null = null;
  CLOSER_AGENT_LAST_NAME: string | null = null;
  CLOSER_AGENT_EMAIL_ADDRESS: string | null = null;
  CLOSER_AGENT_POSTAL_CODE: string | null = null;
  CLOSER_AGENT_PHONE: string | null = null;
  CLOSER_AGENT_ADDRESS1: string | null = null;
  CLOSER_AGENT_CITY: string | null = null;
  CLOSER_AGENT_STATE_CODE: string | null = null;

  /**
   *  INSPECTION CONTENGENCIES
   */
  HAS_FINANCING_CONTINGENCY: YesNo = 'N';
  HAS_AN_INSPECTION_CONTINGENCY: boolean = false; // ! NOT PART OF THE ENDPOINT - USED INTERNALLY
  CLOSER_AGENT_ID: number | null = null; // ! NOT PART OF THE ADD/EDIT ENDPOINT - USED INTERNALLY
  LISTING_AGENT_ID: number | null = null; // ! NOT PART OF THE ADD/EDIT ENDPOINT - USED INTERNALLY
  INSPECTIONS: string | null = 'N';

  /**
   *  CLOSING CONTENGENCIES - AGENT ONLY
   */
  CLOSINGCOST: string | null = null;

  /**
   *  ADDITIONAL CONTENGENCIES - AGENT ONLY
   */
  CONTINGENCIES: string | null = null; // "0" - means NO

  /**
   *  PHOTOS/VIDEOS/DOCUMENTS
   */
  WEBSITE_LINK: string | null = null;

  // OTHER1: string | null = null;
  OTHER2: string | null = null;
  OTHER3: string | null = null;
  OTHER4: string | null = null;

  constructor() {
    const startDate = new Date();
    // startDate.setDate(startDate.getDate() + 1);
    this.AUCTION_START_DATE = startDate.toString();
  }
}

export interface PropertyAgent {
  ID: string | null;
  AGENCY_NAME: string;
  NAME: string;
  EMAIL_ADDRESS: string;
  POSTAL_CODE: string;
  PHONE: string;
  ADDRESS: string;
  CITY: string;
  STATE_CODE: string;
  LICENSE_STATE?: string;
  LICENSE_NUMBER?: string;
  AGENT_ID?: string;
}
