import { KeyValue } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy, Input, SimpleChanges, OnDestroy, OnChanges, Output, EventEmitter, NgZone } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as dayjs from 'dayjs';
import { BehaviorSubject, Observable, takeUntil, tap } from 'rxjs';

interface DateParts {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}
type DatePartsKeys = keyof DateParts;
type SegmentLabels = { [k in DatePartsKeys]: string };

@UntilDestroy()
@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountdownTimerComponent implements OnChanges, OnInit, OnDestroy {
  @Input() auctionDate?: string | Date | null = null;
  @Input() segmentLabel: 'short' | 'long' = 'short';
  @Output() timeout = new EventEmitter<boolean>(false);

  private readonly _countdownParts$ = new BehaviorSubject<DateParts>({} as DateParts);
  readonly countdownParts$ = this._countdownParts$.asObservable();

  private animationFrameRequestId?: number;
  private readonly refreshRate: number = 60;

  public segmentLabels!: SegmentLabels;
  readonly segmentLabelsShort: SegmentLabels = { days: 'd', hours: 'h', minutes: 'm', seconds: 's' };
  readonly segmentLabelsLong: SegmentLabels = { days: 'days', hours: 'hours', minutes: 'minutes', seconds: 'seconds' };

  constructor(private readonly ngZone: NgZone) {}

  ngOnInit(): void {
    this.updateSegmentLabels();
    this.emitTimeoutWhenCountdownGoesToZeros().subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['auctionDate']) {
      if (this.animationFrameRequestId) {
        cancelAnimationFrame(this.animationFrameRequestId);
      }

      this.runAnimationEveryMs(this.refreshRate, this.updateCountdownDateCallback);
    }
    if (changes['segmentLabel']) {
      this.updateSegmentLabels();
    }
  }

  ngOnDestroy() {
    if (this.animationFrameRequestId !== undefined) {
      cancelAnimationFrame(this.animationFrameRequestId);
    }
  }

  private emitTimeoutWhenCountdownGoesToZeros(): Observable<DateParts> {
    return this.countdownParts$.pipe(
      // once we goes to all zeroes we don't need this observable anymore
      // even before this component is destroyed
      takeUntil(this.timeout),
      // component can be destroyed before going to zeros so we should
      // support unsubscriptions for this case as well
      untilDestroyed(this),
      tap(({ days, hours, minutes, seconds }) => {
        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
          this.timeout.emit(true);
        }
      }),
    );
  }

  private updateSegmentLabels() {
    this.segmentLabels = this.segmentLabel === 'long' ? this.segmentLabelsLong : this.segmentLabelsShort;
  }

  /**
   * Runs beforeEveryPaintCallback callback every refreshRateInMs milliseconds right before browser paint process.
   * refreshRateInMs better to set much less than 1000 otherwise countdown timer gets updates on the screen with
   * interval greater than second, so that user will see that seconds part sometimes gets new data
   * faster than clock second and following update would be slower than clock second.
   * @param refreshRateInMs - delay time in ms (to prevent run callback before every frame paint process)
   * @param beforeEveryPaintCallback callback should return condition when to finish animation
   */
  private runAnimationEveryMs(refreshRateInMs: number, beforeEveryPaintCallback: () => boolean): void {
    let prevTime = performance.now();

    const animationCallback = (time: number) => {
      const msCurrentTimePassed = time - prevTime;

      if (msCurrentTimePassed > refreshRateInMs) {
        prevTime = time;

        if (beforeEveryPaintCallback.call(this)) {
          return;
        }
      }

      this.ngZone.runOutsideAngular(() => {
        this.animationFrameRequestId = requestAnimationFrame.call(this, animationCallback);
      });
    };

    this.ngZone.runOutsideAngular(() => {
      this.animationFrameRequestId = requestAnimationFrame.call(this, animationCallback);
    });
  }

  /**
   * Updates countdown timer data.
   * @returns true if auction date either is not set or it's on the past
   * what means we should stop update timer
   */
  private updateCountdownDateCallback(): boolean {
    const auctionDate = dayjs(this.auctionDate);
    const now = dayjs();

    if (!this.auctionDate || auctionDate.isBefore(now)) {
      this._countdownParts$.next({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      return true;
    }

    const diffInSecondsBetweenDates = auctionDate.diff(now, 'second');

    const days = Math.floor(diffInSecondsBetweenDates / 60 / 60 / 24);
    const hours = Math.floor(diffInSecondsBetweenDates / 60 / 60) - days * 24;
    const minutes = Math.floor(diffInSecondsBetweenDates / 60) - days * 24 * 60 - hours * 60;
    const seconds = diffInSecondsBetweenDates - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

    this._countdownParts$.next({ days, hours, minutes, seconds });

    return false;
  }

  trackByFn(index: number, item: KeyValue<keyof DateParts, number>): string {
    return item.key;
  }
}
