import { Directive, Output, EventEmitter, HostListener, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appUpload]',
})
export class UploadDirective {
  @Output() onFileDropped = new EventEmitter<FileList>();

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.setStyle('opacity', 0.8);
    this.setStyle('border', '4px dashed #3570e6');
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.setStyle('opacity', 1);
    this.setStyle('border', '2px dashed #3570e6');
  }

  @HostListener('drop', ['$event']) public ondrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.setStyle('opacity', 1);
    this.setStyle('border', '2px dashed #3570e6');

    if (!event.dataTransfer) {
      return;
    }

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.onFileDropped.emit(files);
    }
  }

  constructor(private readonly element: ElementRef, private readonly renderer2: Renderer2) {}

  private setStyle(property: string, value: string | number): void {
    this.renderer2.setStyle(this.element.nativeElement, property, value);
  }
}
