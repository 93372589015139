import { Pipe, PipeTransform } from '@angular/core';

 /**
  * Cast super type into type using generics
  * Return Type obtained by optional @param type OR assignment type.
  * 
  * Usage:
  * 
  * <input
  *   type="checkbox"
  *   *ngIf="event.end"
  *   [(ngModel)]="(event | cast: Event).acknowledged"
  *   [disabled]="(event | cast: Event).acknowledged" />
  */

 @Pipe({ name: 'cast' })
 export class CastPipe implements PipeTransform {
    // eslint-disable-next-line no-unused-vars
    transform<T>(input: unknown, type: T | undefined): T {
      return input as unknown as T;
    }
 }