import { Component, ChangeDetectionStrategy, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, combineLatest, Observable } from 'rxjs';
import { removeNils } from '@ntersol/utils';

import { Models } from '$models';
import { SettingsService } from '$settings';
import { BiddingModels } from '../../shared/models/bidding.model';
import { BiddingStoreService, BiddingStoreKey } from '../../store/bidding.store';

@UntilDestroy()
@Component({
  selector: 'app-confirm-bid',
  template: `
    <ng-container *ngIf="{ fees: fees$ | async } as feesData">
      <div class="confirm-bid" *ngIf="feesData.fees">
        <app-bid-summary [bidAmount]="(bidData$ | async)?.BID_AMT" [bidAssistAmount]="(bidData$ | async)?.BID_ASSIST_AMT"></app-bid-summary>
        <div class="section bid-notes">
          <p>
            <strong>Note:</strong> You are about to place a legally binding bid for this property. Failure to perform on any contract is subject to penalties
            outlined in the terms for bidding. Erroneous bids should be reported immediately to our Customer Service Department at customerservice@realtybid.com
          </p>
          <p>
            <ng-container *ngIf="!feesData.fees.FEE || feesData.fees.FEE === 'NONE'"> <strong>Transaction Fee:</strong><span> NONE</span> </ng-container>
            <ng-container *ngIf="!!feesData.fees.FEE && feesData.fees.FEE !== 'NONE'">
              <strong>Transaction Fee:</strong> By placing this bid, you are agreeing to payment of the Internet Transaction Fee of
              <strong>{{ feesData.fees.FEE }}</strong>
            </ng-container>
          </p>
          <p><strong>Minimum Deposit:</strong> {{ property?.DEPOSIT }}</p>
        </div>
      </div>
    </ng-container>
  `,
  styles: [
    `
      .confirm-bid .section {
        margin-top: 1.5rem;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmBidComponent implements OnInit {
  @Output() bidPayloadChanged: EventEmitter<BiddingModels.BidddingRequest | null> = new EventEmitter<BiddingModels.BidddingRequest | null>();

  public fees$: Observable<Models.PropertyFeesResponse | null> = this.biddingStore.fees$;
  public deposit$ = this.biddingStore;

  bidData$ = this.biddingStore.getSelector(BiddingStoreKey.bidData);

  private user: Models.LoginResponse | null = null;
  public property: Models.PropertyDetailsResponse | null = null;
  private bidForData: BiddingModels.PlaceBidForFormModel | null = null;
  private biddingData: BiddingModels.BiddingModel | null = null;

  constructor(private settings: SettingsService, private biddingStore: BiddingStoreService) {}

  ngOnInit(): void {
    this.watchForBidDataChanged();
  }

  private buildBiddingPayload(): BiddingModels.BidddingRequest | null {
    if (!this.user || !this.property || !this.biddingData) {
      return null;
    }
    const payload: BiddingModels.BidddingRequest = {
      ...this.biddingData,
      ITEM_ID: this.property.ITEM_ID,
      CUSTOMER_ID: this.bidForData?.CUSTOMER_ID,
      /**
       * Make sure that default value will be in payload, if no profile is selected
       */
      PURCHASEPROFILEID: this.biddingData?.PURCHASEPROFILEID || 0,
    };
    return removeNils(payload);
  }

  private watchForBidDataChanged() {
    combineLatest([this.settings.user$, this.biddingStore.propertyDetails.state$, this.biddingStore.getSelector(BiddingStoreKey.bidForData), this.bidData$])
      .pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe({
        next: ([user, property, bidForData, biddingData]) => {
          this.user = user;
          this.property = property.data;
          this.bidForData = bidForData;
          this.biddingData = biddingData;
          // Broadcast payload change event if data are ready
          if (this.user && this.property && this.biddingData) {
            const payload = this.buildBiddingPayload();
            if (payload) {
              this.bidPayloadChanged.emit(payload);
            }
          }
        },
        error: () => {
          this.user = null;
          this.property = null;
          this.bidForData = null;
          this.biddingData = null;
          // Set it to Null letting know that bidding has "failed"
          this.bidPayloadChanged.emit(null);
        },
      });
  }
}
