import { ActivatedRoute, Router } from '@angular/router';

export const redirectToReturnUrlParam = (route: ActivatedRoute, router: Router, defaultRoute: string = '/') => {
  // get return url from route parameters or default to '/'
  let returnUrl = route.snapshot.queryParams['returnUrl'] || defaultRoute;
  if (returnUrl === '/login/') {
    returnUrl = defaultRoute;
  }
  router.navigate([returnUrl]);
};
