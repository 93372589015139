import { SearchModels } from '../../../../shared/stores';
import { toTitleCase } from './text.util';

/**
 * Convert the location in the route params to a search term
 * @param params
 * @returns
 */
export const searchLocationToSearchTerm = (loc?: SearchModels.Location | null): string | null => {
  // const loc = params as SearchModels.Location;
  if (!loc) {
    return null;
  }
  let term: string | null = null;
  // ZIP Code
  if (loc.zip && loc.state && loc.city) {
    term = `${loc.city}, ${loc.state.toUpperCase()} ${loc.zip}`;
    // City
  } else if (loc.state && loc.city) {
    term = `${loc.city}, ${loc.state.toUpperCase()}`;
    // County
  } else if (loc.state && loc.county) {
    term = `${loc.county}, ${loc.state.toUpperCase()}`;
    // State
  } else if (loc.state) {
    term = `${loc.state.toUpperCase()}`;
  }

  return term ? toTitleCase(term) : null;
};

/**
 * Is the search location a US State
 * @param loc
 */
const isState = (loc?: SearchModels.Location | null) => {
  // Clone and remove query params
  const location = { ...loc };
  return location && Object.keys(location).length === 1 && !!location.state;
};

/**
 * Extract the correct places ID from the search response
 * @param results
 * @param loc
 * @returns
 */
export const searchLocationToPlacesId = (results: google.maps.places.AutocompletePrediction[], loc: SearchModels.Location | null) => {
  const firstResultId = results?.[0]?.place_id;
  // If this is a US State (IE Texas), make sure to return the state level result
  if (isState(loc)) {
    const statePlaceId = results.find(r => r.types.includes('administrative_area_level_1'))?.place_id;
    return statePlaceId ?? firstResultId;
  }
  return firstResultId;
};
