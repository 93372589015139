interface StateViewport {
  name: string;
  abbreviation: string;
  viewPort: google.maps.LatLngBoundsLiteral;
}

export const statesViewport: StateViewport[] = [
  {
    name: 'alabama',
    abbreviation: 'al',
    viewPort: { south: 30.144425, west: -88.4732269, north: 35.008028, east: -84.888246 },
  },
  {
    name: 'alaska',
    abbreviation: 'ak',
    viewPort: { south: 49.79676704326808, west: -171, north: 72, east: -131.09765625 },
  },
  {
    name: 'arkansas',
    abbreviation: 'ar',
    viewPort: { south: 33.00428206756398, west: -94.61785899197491, north: 36.49974906092542, east: -89.64099703456495 },
  },
  {
    name: 'arizona',
    abbreviation: 'az',
    viewPort: { south: 31.33234477848764, west: -114.8163228550557, north: 37.00372505965424, east: -109.045171916046 },
  },
  {
    name: 'california',
    abbreviation: 'ca',
    viewPort: { south: 32.52883196043871, west: -124.4820030490848, north: 42.00950300443976, east: -114.1312110028529 },
  },
  {
    name: 'colorado',
    abbreviation: 'co',
    viewPort: { south: 36.992449, west: -109.060204, north: 41.0034439, east: -102.041522 },
  },
  {
    name: 'connecticut',
    abbreviation: 'ct',
    viewPort: { south: 36.85614889298437, west: -77.7452999742829, north: 45.83897616045527, east: -67.7697140367829 },
  },
  {
    name: 'delaware',
    abbreviation: 'de',
    viewPort: { south: 34.34518719665614, west: -80.3743869948213, north: 43.64556058336274, east: -70.3988010573213 },
  },
  {
    name: 'district of columbia',
    abbreviation: 'dc',
    viewPort: { south: 38.79164502621412, west: -77.11975901361396, north: 38.99584500374451, east: -76.90939297542634 },
  },
  {
    name: 'florida',
    abbreviation: 'fl',
    viewPort: { south: 22.8285924, west: -89.8042682, north: 32.3607674, east: -77.8049338 },
  },
  {
    name: 'georgia',
    abbreviation: 'ga',
    viewPort: { south: 30.35575693442233, west: -85.60516493123394, north: 35.00130301500388, east: -80.78295998100835 },
  },
  {
    name: 'hawaii',
    abbreviation: 'hi',
    viewPort: { south: 18.55, west: -160.53, north: 22.37, east: -154.48 },
  },
  {
    name: 'idaho',
    abbreviation: 'id',
    viewPort: { south: 41.98818193370447, west: -117.24302696742, north: 49.00084608942538, east: -111.0434949886877 },
  },
  {
    name: 'illinois',
    abbreviation: 'il',
    viewPort: { south: 36.9702980460126, west: -91.51307901663446, north: 42.50848094838776, east: -87.01993496220028 },
  },
  {
    name: 'indiana',
    abbreviation: 'in',
    viewPort: { south: 37.77167200487875, west: -88.09789199026922, north: 41.76136800286847, east: -84.78453506641252 },
  },
  {
    name: 'iowa',
    abbreviation: 'ia',
    viewPort: { south: 40.3754400188, west: -96.63948492461773, north: 43.50119605320161, east: -90.1400609349535 },
  },
  {
    name: 'kansas',
    abbreviation: 'ks',
    viewPort: { south: 36.99301593983594, west: -102.0517688242823, north: 40.00316596776067, east: -94.58838702801843 },
  },
  {
    name: 'kentucky',
    abbreviation: 'ky',
    viewPort: { south: 36.4970579515975, west: -89.57120303316137, north: 39.14773205652311, east: -81.96478807948522 },
  },
  {
    name: 'louisiana',
    abbreviation: 'la',
    viewPort: { south: 28.85512707038328, west: -94.04335203188151, north: 33.01962011001316, east: -88.75838798242145 },
  },
  {
    name: 'maine',
    abbreviation: 'me',
    viewPort: { south: 42.917126, west: -71.0839278, north: 47.459833, east: -66.885444 },
  },
  {
    name: 'maryland',
    abbreviation: 'md',
    viewPort: { south: 37.88660501609504, west: -79.4876510639216, north: 39.7230370307816, east: -74.98628191214425 },
  },
  {
    name: 'massachusetts',
    abbreviation: 'ma',
    viewPort: { south: 41.18705303854487, west: -73.50821007655624, north: 42.88677799555013, east: -69.85886097535136 },
  },
  {
    name: 'michigan',
    abbreviation: 'mi',
    viewPort: { south: 41.6961179803949, west: -90.4183919308845, north: 48.30561094582951, east: -82.12280500730516 },
  },
  {
    name: 'minnesota',
    abbreviation: 'mn',
    viewPort: { south: 43.49936096179093, west: -97.23909299996531, north: 49.38447202078465, east: -89.48381217787039 },
  },
  {
    name: 'mississippi',
    abbreviation: 'ms',
    viewPort: { south: 30.13984496143205, west: -91.65500906197607, north: 34.99609900841086, east: -88.09788796587989 },
  },
  {
    name: 'missouri',
    abbreviation: 'mo',
    viewPort: { south: 35.99568492252557, west: -95.77470400212587, north: 40.61363997509282, east: -89.09896800110899 },
  },
  {
    name: 'montana',
    abbreviation: 'mt',
    viewPort: { south: 44.35791500658805, west: -116.0491529136954, north: 49.00111167030684, east: -104.0396939878996 },
  },
  {
    name: 'nebraska',
    abbreviation: 'ne',
    viewPort: { south: 39.99993201807688, west: -104.0535139593889, north: 43.00170702349276, east: -95.30829000605853 },
  },
  {
    name: 'nevada',
    abbreviation: 'nv',
    viewPort: { south: 35.001857, west: -120.0064729, north: 42.001842, east: -114.039461 },
  },
  {
    name: 'new hampshire',
    abbreviation: 'nh',
    viewPort: { south: 42.69704196007883, west: -72.55712395870282, north: 45.30577773954853, east: -70.57509397395282 },
  },
  {
    name: 'new jersey',
    abbreviation: 'nj',
    viewPort: { south: 38.78865697747964, west: -75.56358608752295, north: 41.35760698838924, east: -73.88505995655244 },
  },
  {
    name: 'new mexico',
    abbreviation: 'nm',
    viewPort: { south: 31.33216180712133, west: -109.0504310076264, north: 37.00023295164024, east: -103.0020429667444 },
  },
  {
    name: 'new york',
    abbreviation: 'ny',
    viewPort: { south: 40.4765780006847, west: -79.76259006912822, north: 45.01586101827584, east: -71.77749103789378 },
  },
  {
    name: 'north carolina',
    abbreviation: 'nc',
    viewPort: { south: 33.75287800466285, west: -84.32182095701926, north: 36.58813706643755, east: -75.40011901176226 },
  },
  {
    name: 'north dakota',
    abbreviation: 'nd',
    viewPort: { south: 45.93507193820341, west: -104.0490120237419, north: 49.00055849725752, east: -96.5544110772682 },
  },
  {
    name: 'ohio',
    abbreviation: 'oh',
    viewPort: { south: 38.40342287692288, west: -84.8203050709824, north: 42.32337404096916, east: -80.51870503609469 },
  },
  {
    name: 'oklahoma',
    abbreviation: 'ok',
    viewPort: { south: 33.61578697960584, west: -103.0024129458349, north: 37.00231205981126, east: -94.43101393697654 },
  },
  {
    name: 'oregon',
    abbreviation: 'or',
    viewPort: { south: 41.99208194682079, west: -124.7035410212307, north: 46.29909902539971, east: -116.4632619690902 },
  },
  {
    name: 'pennsylvania',
    abbreviation: 'pa',
    viewPort: { south: 39.71979900602428, west: -80.51984850253436, north: 42.51416576510771, east: -74.68956094709924 },
  },
  {
    name: 'rhode island',
    abbreviation: 'ri',
    viewPort: { south: 41.07937124543846, west: -71.9586615298175, north: 42.200058015545885, east: -70.71171328763 },
  },
  {
    name: 'south carolina',
    abbreviation: 'sc',
    viewPort: { south: 31.9959539649131, west: -83.35392799812386, north: 35.21548492898592, east: -78.49930092531599 },
  },
  {
    name: 'south dakota',
    abbreviation: 'sd',
    viewPort: { south: 42.47968601024274, west: -104.0578790692265, north: 45.94537703063709, east: -96.43647197161877 },
  },
  {
    name: 'tennessee',
    abbreviation: 'tn',
    viewPort: { south: 34.98292391961682, west: -90.31049105785706, north: 36.67825503602104, east: -81.64690001182882 },
  },
  {
    name: 'texas',
    abbreviation: 'tx',
    viewPort: { south: 25.83711645856708, west: -106.6456460547471, north: 36.50112613904738, east: -93.50803894473373 },
  },
  {
    name: 'utah',
    abbreviation: 'ut',
    viewPort: { south: 36.99765682253983, west: -114.0528850475383, north: 42.00170194719211, east: -109.0415719854012 },
  },
  {
    name: 'vermont',
    abbreviation: 'vt',
    viewPort: { south: 42.72693297349642, west: -73.43790497922724, north: 45.01665799286757, east: -71.46504701039785 },
  },
  {
    name: 'virginia',
    abbreviation: 'va',
    viewPort: { south: 36.54085192914776, west: -83.67539507062487, north: 39.4660119830118, east: -75.16643504475577 },
  },
  {
    name: 'washington',
    abbreviation: 'wa',
    viewPort: { south: 45.54354101516995, west: -124.8489739457119, north: 49.00249453041775, east: -116.916071080042 },
  },
  {
    name: 'west virginia',
    abbreviation: 'wv',
    viewPort: { south: 37.2015399411757, west: -82.64459104447006, north: 40.63880098741526, east: -77.71951897317682 },
  },
  {
    name: 'wisconsin',
    abbreviation: 'wi',
    viewPort: { south: 42.49171994804539, west: -92.88943307713289, north: 47.30982195141704, east: -86.2495480246244 },
  },
  {
    name: 'wyoming',
    abbreviation: 'wy',
    viewPort: { south: 40.99477203798426, west: -111.0545580542501, north: 45.00581503797179, east: -104.0522449418437 },
  },
];
