import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Observable, take, timer } from 'rxjs';
import { matchControls } from '../../shared/validators';
import { listenForLargeScreen } from '../../shared/utils';

export interface PasswordData {
  newPassword: string;
  oldPassword?: string;
}

@Component({
  selector: 'app-password-change-form',
  templateUrl: './password-change-form.component.html',
  styleUrls: ['./password-change-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordChangeFormComponent implements OnInit, OnChanges {
  @Input() waiting: boolean = false;
  @Input() showOldPasswordField = false;
  @Input() onFormSubmitAction!: (data: PasswordData) => Observable<unknown>;

  validatePasswordMatch = (control: AbstractControl): { [key: string]: any } | null => {
    const password = this.passwordForm?.get('password')?.value as string;
    const passwordConfirm = control.value as string;

    if (password !== passwordConfirm) {
      return { passwordMatch: true };
    }

    return null;
  };

  public readonly newPasswordControl = new FormControl('', [Validators.required]);
  public readonly confirmPasswordControl = new FormControl('', [Validators.required, this.validatePasswordMatch]);
  public readonly oldPasswordControl = new FormControl('');
  public readonly largeScreenObserver$ = listenForLargeScreen();
  passwordErrorCondition: { touch?: boolean; dirty?: boolean; submit?: boolean } = {};

  passwordForm = this.fb.group(
    {
      password: this.newPasswordControl,
      confirmPassword: this.confirmPasswordControl,
      oldPassword: this.oldPasswordControl,
    },
    { validators: matchControls('password', 'confirmPassword') },
  );
  constructor(private fb: FormBuilder, private readonly cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showOldPasswordField']?.currentValue === true) {
      this.makeOldPasswordControlToBeRequired();
    }
  }

  ngOnInit() {
    timer(100)
      .pipe(take(1))
      .subscribe(() => this.passwordForm.reset());
    this.setAutocompleteOff();
  }

  submitForm() {
    if (!this.passwordForm.valid) {
      this.passwordForm.get('oldPassword')?.markAsDirty();
      this.passwordForm.get('oldPassword')?.markAsTouched();
      this.passwordErrorCondition = {
        touch: true,
        dirty: true,
        submit: true,
      };
      this.passwordForm.get('confirmPassword')?.markAsDirty();
      this.passwordForm.get('confirmPassword')?.markAsTouched();
      return;
    }

    this.onFormSubmitAction(this.prepareOutputData())
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.passwordForm.reset();
        },
        error: err => {
          this.passwordForm.get('oldPassword')?.setErrors({
            oldPassword: 'Please enter a valid password',
          });
          this.cd.markForCheck();
        },
      });
  }

  private makeOldPasswordControlToBeRequired() {
    this.passwordForm.get('oldPassword')?.setValidators(Validators.required);
  }

  private prepareOutputData(): PasswordData {
    return {
      newPassword: this.newPasswordControl.value,
      ...(this.showOldPasswordField
        ? {
            oldPassword: this.oldPasswordControl.value,
          }
        : {}),
    };
  }

  private setAutocompleteOff() {
    document.getElementsByName('password').forEach(element => {
      const input = element.querySelector('input');
      if (input) {
        input.setAttribute('autocomplete', 'off');
      }
    });
  }
}
