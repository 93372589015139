import { Component, Input, OnInit } from '@angular/core';
import { Observable, debounceTime, share } from 'rxjs';
import { scriptLoad$ } from '@ntersol/utils';
import { AuthorizenetConfig, PaymentData, SecureData, DispatchDataResponse } from './authorize-net';

declare const window: any;

/**
 * "Broker" component for Authorize.net API.
 * Coluld be extended to support other endpoints or to implement specific business logic.
 */
@Component({
  selector: 'app-authorizenet',
  template: ``,
})
export class AuthorizenetComponent implements OnInit {
  @Input() config?: AuthorizenetConfig;
  @Input() isDevMode = false;

  billingAddressOptions: any;
  urlAction: any;
  apiLoginID: any;
  clientKey: any;

  ngOnInit(): void {
    try {
      this.apiLoginID = this.getUndefined('apiLoginID', this.config?.apiLoginID);
      this.clientKey = this.getUndefined('clientKey', this.config?.clientKey);

      /** Loads authorize.net script. Returns boolean to indicate loaded status. */
      scriptLoad$([`https://js${this.isDevMode ? 'test' : ''}.authorize.net/v1/Accept.js`])
        .pipe(
          debounceTime(1), // Ensure script has time to initialize
          share(),
        )
        .subscribe();
    } catch (error) {
      console.error(`Authorize Config Error: "${error}"`);
    }
  }

  dispatchData(paymentData: PaymentData): Observable<DispatchDataResponse | null> {
    const payload: SecureData = {
      cardData: paymentData.cardData,
      authData: {
        clientKey: this.clientKey,
        apiLoginID: this.apiLoginID,
      },
    };

    return new Observable<DispatchDataResponse | null>(obs => {
      if (window) {
        window.Accept.dispatchData(payload, (response: DispatchDataResponse) => {
          if (response.messages.resultCode === 'Ok') {
            obs.next(response);
            obs.complete();
          } else {
            obs.error(response);
          }
        });
      } else {
        obs.next(null);
      }
    });
  }

  private getUndefined = (reference: string, data: any) => {
    if (data && data !== null) {
      return data;
    }
    throw new Error(`Data ${reference} is Undefined or Null`);
  };
}
