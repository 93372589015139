import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

/**
 * Notifies Angular that the supplied input is safe for bypassing the DOM sanitizer
 * ONLY USE THIS WITH TRUSTWORTHY SOURCES
 * Usage: {{ value | safe: 'html' }}
 */
@Pipe({
  name: 'safe',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(protected _sanitizer: DomSanitizer) {}

  public transform(value: string | null | undefined, type: string = 'html'): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    if (!value) return '';
    switch (type) {
      case 'html':
        return this._sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this._sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this._sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this._sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this._sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        throw new Error(`Invalid safe type specified: ${type}`);
    }
  }
}
