import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-number-of-steppers',
  templateUrl: './number-of-steppers.component.html',
  styleUrls: ['./number-of-steppers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberOfSteppersComponent {
  @Input() requiredBedAsterisk = '';
  @Input() requiredBathAsterisk = '';
  @Input() formControlBedCount!: FormControl;
  @Input() formControlBathCount!: FormControl;
  @Input() formControlHalfBathCount!: FormControl;

  validateBedCount(event: any) {
    this.formControlBedCount.patchValue(event.value);
    this.formControlBedCount.markAsDirty();
  }

  validateBathCount(event: any) {
    this.formControlBathCount.patchValue(event.value);
    this.formControlBathCount.markAsDirty();
  }
}
