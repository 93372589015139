export enum AuctionTimelineStates {
  AUCTION_NOT_YET_STARTED = 'Auction not yet started',
  AUCTION_COMING_SOON = 'Auction coming soon',
  ACTIVE_AUCTION = 'Active Auction',
  POST_AUCTION = 'Post Auction',
  AUCTION_HAS_ENDED_AS_SOLD = 'Sold',
  AUCTION_HAS_ENDED_AS_RETURNED = 'Returned',
  // Outlier states for Ohio case
  AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_CANCELLED = 'Auction for Online Foreclosure Sales - Ohio - Has been cancelled',
  AUCTION_FOR_ONLINE_FORECLOSURE_SALES_HAS_BEEN_POSTPONED = 'Auction for Online Foreclosure Sales - Ohio - Has been postponed',
}

export interface AuctionStatus {
  auctionStatus: AuctionTimelineStates;
}
