export enum VipLevel {
  'NOLEVEL',
  'BRONZE',
  'SILVER',
  'GOLD',
  'PREMIUM',
  'PREMIUMPRO',
}

export const vipLevelAssetsMapping = {
  [VipLevel.BRONZE]: {
    path: '/assets/images/program-bronze.svg',
    alt: 'bronze badge',
    color: '#9C7146',
  },
  [VipLevel.SILVER]: {
    path: '/assets/images/program-silver.svg',
    alt: 'silver badge',
    color: '#808083',
  },
  [VipLevel.GOLD]: {
    path: '/assets/images/program-gold.svg',
    alt: 'gold badge',
    color: '#9B8C64',
  },
  [VipLevel.PREMIUM]: {
    path: '/assets/images/program-premium.svg',
    alt: 'premium badge',
    color: '#1C5380',
  },
  [VipLevel.PREMIUMPRO]: {
    path: '/assets/images/program-premium-pro.svg',
    alt: 'premium-pro badge',
    color: '#BE2326',
  },
};
