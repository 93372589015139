import { Models } from '$models';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SettingsService } from '../../shared/stores';
import { map } from 'rxjs';

@Component({
  selector: 'app-mobile-navigation',
  templateUrl: './mobile-navigation.component.html',
  styleUrls: ['./mobile-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileNavigationComponent {
  searchRouteSlug = Models.SearchRouteSlug;
  buySellTitle$ = this.settings.user$.pipe(map(data => (data?.UserType === 'Client' ? 'Client Portal' : 'Buy / Sell')));

  constructor(private settings: SettingsService) {}
}
