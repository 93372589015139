import { Component, Input, OnInit } from '@angular/core';
import { catchError, map as rxMap, of} from 'rxjs';

import { UiStateService } from '$ui';
import { createStoreKey } from '$utils';
import { Models } from '$models';
import { SettingsService } from '$settings';
import { ModalWizardModels } from 'src/app/components/modal-wizard/shared/models/modal-wizard.model';
import { BiddingStoreService } from '../../store/bidding.store';
import { BiddingService } from '../../shared/services/bidding.service';

@Component({
  selector: 'app-bid-terms-of-service-step-content',
  template: `
    <div class="bid-terms-of-service-step-content">
      <app-bid-terms-of-service [user]="user$ | async" [property]="propertyDetails$ | async"></app-bid-terms-of-service>
    </div>
  `
})
export class BidTermsOfServiceStepContentComponent implements ModalWizardModels.StepContent, OnInit {

  @Input() stepId = 'bid-terms-of-service-step';

  user: Models.LoginResponse | null = null;
  propertyDetails: Models.PropertyDetailsResponse | null = null;

  user$ = this.settings.user$.pipe(
    rxMap(user => {
      this.user = user;
      return user;
    }),
    catchError(() => {
      this.user = null;
      return of(null);
    })
  );

  propertyDetails$ = this.biddingStore.propertyDetails.state$.pipe(
    rxMap(state => {
      this.propertyDetails = state.data;
      return state.data;
    }),
    catchError(() => {
      this.propertyDetails = null;
      return of(null);
    })
  );

  constructor(
    private settings: SettingsService,
    private biddingStore: BiddingStoreService,
    private bidding: BiddingService,
    private ui: UiStateService
  ) { }

  ngOnInit(): void { }

  canEntry(args: ModalWizardModels.StepValidationArgs) {
    return true;
  }

  canExit(args: ModalWizardModels.StepValidationArgs) {
    // Update UI state with terms of service that was agreed by user
    if (this.user && this.propertyDetails) {
      this.bidding.acceptDisclosure({ ITEM_ID: this.propertyDetails.ITEM_ID }).subscribe();
    }
    return true;
  }
}
