import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Models } from '$models';

@Component({
  selector: 'app-buyer-agent-info',
  template: `
    <div class="buyer-agent-info">
      <h5 *ngIf="displayTitle">Buyer Agent</h5>
      <h6>{{ agent?.COMPANY_NAME }}</h6>
      <p>{{ agent?.COMPANY_ADDRESS1 }} {{ agent?.COMPANY_ADDRESS2 }}</p>
      <p>{{ agent?.COMPANY_CITY }}, {{ agent?.COMPANY_STATE }} {{ agent?.COMPANY_POSTAL_CODE }}</p><br/>
      <h6>{{ agent?.CONTACT_FIRST_NAME }} {{ agent?.CONTACT_LAST_NAME }}</h6>
      <p>{{ agent?.MOBILE }}</p>
      <p>{{ agent?.EMAIL }}</p>
    </div>
  `,
  styles: [
    `
      .buyer-agent-info p {
        margin-bottom: 0;
      }
      .buyer-agent-info h6 {
        margin-bottom: 0;
        font-weight: 500;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuyerAgentInfoComponent { 

  @Input() agent: Models.BuyerAgentRequest | null | undefined = null;
  @Input() displayTitle = true;

  constructor() { }
}
