<div class="property-options">
  <label class="input-label" for="closing-agency">{{ label }} <sup class="required" *ngIf="required">*</sup></label>
  <div class="max-width-400">
    <p-dropdown
      [formControl]="control"
      optionValue="value"
      [options]="options"
      optionLabel="label"
      placeholder="Select..."
      (onChange)="selectOption.emit($event.value)">
    </p-dropdown>
  </div>
</div>
