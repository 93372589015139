<app-modal-wizard
  *ngIf="stepDefinitions.length"
  [config]="wizardConfig"
  (onStepChanged)="onStepChanged($event)"
  (onCompleted)="close()"
  (onCloseClick)="close()">
  <app-modal-wizard-step
    *ngFor="let stepDefinition of stepDefinitions"
    [stepId]="stepDefinition.stepId"
    [title]="stepDefinition?.title"
    [description]="stepDefinition?.description"
    [state]="stepDefinition?.state"
    [status]="stepDefinition?.status"
    [component]="stepDefinition?.component"
    [componentInputs]="stepDefinition?.componentInputs"
    [componentOutputs]="stepDefinition?.componentOutputs"
    [parentStep]="stepDefinition?.parentStep"
    [parentStepId]="stepDefinition?.parentStepId"
    [showHeader]="stepDefinition?.showHeader"
    [showNext]="stepDefinition?.showNext"
    [showPrev]="stepDefinition?.showPrev"
    [prevText]="stepDefinition?.prevText"
    [nextText]="stepDefinition?.nextText"
    [completeText]="stepDefinition?.completeText"
    [footerTopContent]="stepDefinition?.footerTopContent"
    [footerBottomContent]="stepDefinition?.footerBottomContent"
    [isFooterStickedToBottom]="stepDefinition?.isFooterStickedToBottom"
    [canEnter]="stepDefinition?.canEnter"
    [canExit]="stepDefinition?.canExit">
  </app-modal-wizard-step>
</app-modal-wizard>
