import { mergeDeepRight } from 'ramda';
import { environment as defaults } from './environment.defaults';

/**
 * Dev env config with local override
 */
export const environment = mergeDeepRight(defaults, {
  endpoints: {
    apiUrl: 'https://apiuat.realtybid.com/',
    photoPath: 'https://photosuat.realtybid.com/',
    downloadSearchResult: 'https://appuat.realtybid.com/downloadSearchResults.cfm',
  },
  clientPortalLinks: {
    dailyTasks: 'https://clientportal.uat.internal.realtybid.com/#/dailytasks',
    programManagers: 'https://clientportal.uat.internal.realtybid.com/#/pm',
    manageProperties: 'https://clientportal.uat.internal.realtybid.com/#/manageproperties',
    allDocuments: 'https://clientportal.uat.internal.realtybid.com/#/AllDocuments/',
    charts: 'https://clientportal.uat.internal.realtybid.com/#/charts',
  },
  reportLinks: {
    auctionPerformance: 'https://appuat.realtybid.com/activityReport.cfm',
    contractStatus: 'https://appuat.realtybid.com/contractStatusReport.cfm',
    bidding: 'https://appuat.realtybid.com/biddingReport.cfm',
  },
  licenses: {
    googleMapsKey: 'AIzaSyDN_EuLyvnvODkvgKA2f9E6tr6mmcyOAQc',
    googleAnalyticsKey: 'G-2YHFJV8941',
    googleTagKey: 'GTM-M85D469B',

    weissWidgetsApiKey: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo5ODQyfQ._xs5NnkA6xfAYlsPs602lrigSKtNPN0Ixssh891NMQM',
    recaptchaKey: '6LdtYawnAAAAAAIRY6G36-QnKgnYuj_pXM5B7VY9',
    cookiebotKey: 'f29c8432-8ee9-4f55-9ce3-76a6d5b626a4',
    newRelic: {
      agentID: '1120250964',
      applicationID: '1120250964',
    },
  },
  settings: {
    enableCookiebot: false,
  },
});
