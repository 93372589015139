<ng-container
  *ngIf="{
    sectionsState: sectionsState$ | async,
    listenForMediumScreen: listenForMediumScreen$ | async
  } as comp">
  <nav id="wizard-nav" [ngClass]="(locationClasses$ | async) ?? ''">
    <ng-container *ngFor="let section of comp.sectionsState; let i = index">
      <button
        class="wizard-nav {{ section?.sectionId }}"
        [ngClass]="{ 'active': section?.active }"
        [routerLink]="[routerLinkBase, section.sectionId, section.routeStart]"
        [disabled]="(!section.completed && !section.active && !section.started && linear) || !comp.listenForMediumScreen">
        <span class="wizard-label">
          {{ section.title }}
        </span>
        <span class="wizard-label-circle"></span>
        <ng-container *ngIf="!hideMenuCheckMark" style="display: flex">
          <fa-icon icon="check" class="green" *ngIf="section?.completed"></fa-icon>
        </ng-container>
      </button>
    </ng-container>
  </nav>
</ng-container>
