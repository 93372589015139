import { Injectable } from '@angular/core';
import { NtsStateManagementService } from '@ntersol/state-management';
import { BehaviorSubject, Observable, debounceTime, distinctUntilChanged, filter, forkJoin, iif, map, switchMap, take, tap } from 'rxjs';
import { HousesApiStoreService, SearchType } from '../../routes/houses/shared';
import { SearchStoreService, filtersChanged } from '../../shared/stores';
import { ApiService } from '../../shared/stores/api';
import { listenForLargeScreenDown } from '../../shared/utils';

export interface DisplayValue {
  label: string | undefined;
  value: number | undefined;
}
export interface SelectedOptions {
  selectedAuctionTypes: (number | undefined)[];
  selectedPropertyTypes: (number | undefined)[];
}

@Injectable({
  providedIn: 'root',
})
export class ToolbarService {
  readonly propertySearchForm = this.searchStore.propertySearchForm;

  readonly auctionTypes$: Observable<DisplayValue[]> = this.api.salesTypes.selectAll$.pipe(
    filter(types => !!types),
    map(types => (types || []).map(type => ({ label: type.CATEGORYNAME, value: type.CATEGORYID }))),
  );

  readonly propertyTypes$: Observable<DisplayValue[]> = this.api.propertyType.selectAll$.pipe(
    filter(types => !!types),
    map(list => list?.map(type => ({ label: type.TYPE_CAT_DESC, value: type.PROP_TYPE_CAT_ID })) || []),
  );

  public readonly largeScreenDownObserver$ = listenForLargeScreenDown();

  private readonly _filtersCanBeApplied$ = new BehaviorSubject(false);
  public readonly filtersCanBeApplied$ = this._filtersCanBeApplied$.asObservable();

  constructor(
    private readonly api: ApiService,
    private readonly searchStore: SearchStoreService,
    private readonly sm: NtsStateManagementService,
    private readonly housesApiStoreService: HousesApiStoreService,
  ) {}

  dispatchFilterChanges() {
    return this.largeScreenDownObserver$.pipe(
      distinctUntilChanged(),
      switchMap(isScreenSizeLessThanLarge => {
        return iif(() => isScreenSizeLessThanLarge, this.getPropertyFiltersMobileObserver(), this.getPropertyFiltersDesktopObserver());
      }),
      tap(() => {
        this.housesApiStoreService.stopSurroundedArea();
        this.housesApiStoreService.setSearchType(SearchType.filter);
        this.sm.dispatch(filtersChanged(this.propertySearchForm.value));
      }),
    );
  }

  getSelectedOptions() {
    return forkJoin({
      queryParamsFilters: this.searchStore.queryParamsFilters$.pipe(take(1)),
      auctionTypes: this.auctionTypes$.pipe(take(1)),
      propertyTypes: this.propertyTypes$.pipe(take(1)),
    }).pipe(
      tap(response => {
        const queryParamsFilters = response.queryParamsFilters;
        const hasParams = Object.values(queryParamsFilters!)
          .filter(Boolean)
          .filter(value => Object.keys(value).length > 0);
        if (hasParams.length > 0) {
          this.propertySearchForm.reset(undefined, { emitEvent: false });
          this.propertySearchForm.patchValue(response.queryParamsFilters!);
        } else {
          this.propertySearchForm.patchValue({
            CATEGORYLIST: response.auctionTypes.map(type => type.value),
            TYPE: response.propertyTypes.map(type => type.value),
          });
        }
      }),
    );
  }

  allowApplyFilter() {
    this.housesApiStoreService.stopSurroundedArea();
    this._filtersCanBeApplied$.next(true);
  }

  private getPropertyFiltersMobileObserver(): Observable<unknown> {
    return this.filtersCanBeApplied$.pipe(filter(canBeApplied => canBeApplied));
  }

  private getPropertyFiltersDesktopObserver(): Observable<unknown> {
    return this.propertySearchForm.valueChanges.pipe(debounceTime(500));
  }
}
