export interface StorageI<T> {
  saveData(data: T): void;
  getData(): T;
  removeData(): void;
  hasData(): boolean;
  clearAll(): void;
}

type StorageType = 'sessionStorage' | 'localStorage';

export function storageFactory<T>(type: StorageType, key: string): StorageI<T> {
  const StorageClass = getStorage(type);
  return new StorageClass(key);
}

function getStorage(type: StorageType): new <T>(key: string) => StorageI<T> {
  if (type === 'sessionStorage') {
    return SessionStorage;
  } else if (type === 'localStorage') {
    return LocalStorage;
  }
  throw new Error('Unknown type of storage');
}

class SessionStorage<T> implements StorageI<T> {
  private readonly key: string;

  constructor(key: string) {
    this.key = key;
  }

  saveData(data: any): void {
    sessionStorage.setItem(this.key, JSON.stringify(data));
  }

  getData(): any {
    const serializedData = sessionStorage.getItem(this.key);
    if (!serializedData) {
      return;
    }
    return JSON.parse(serializedData);
  }

  removeData(): void {
    sessionStorage.removeItem(this.key);
  }

  hasData(): boolean {
    return !!sessionStorage.getItem(this.key);
  }

  clearAll(): void {
    sessionStorage.clear();
  }
}

export class LocalStorage<T> implements StorageI<T> {
  private readonly key: string;

  constructor(key: string) {
    this.key = key;
  }

  saveData(data: any): void {
    localStorage.setItem(this.key, JSON.stringify(data));
  }

  getData(): any {
    const serializedData = localStorage.getItem(this.key);
    if (!serializedData) {
      return;
    }
    return JSON.parse(serializedData);
  }

  removeData(): void {
    localStorage.removeItem(this.key);
  }

  hasData(): boolean {
    return !!localStorage.getItem(this.key);
  }

  clearAll(): void {
    localStorage.clear();
  }
}
