import { Component, ChangeDetectionStrategy, Input, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { BehaviorSubject, map, take, timer } from 'rxjs';
import { Models } from '$models';
import { missingImageUrl } from '../../routes/houses/shared/utils';
import { environment } from '$env';

const missingImagesPlaceholder = [
  {
    previewImageSrc: missingImageUrl,
    alt: '',
    index: 0,
  },
];

@Component({
  selector: 'app-galleria',
  templateUrl: './galleria.component.html',
  styleUrls: ['./galleria.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class GalleriaComponent {
  @Input() property: Models.PropertySearchResponse | Models.PropertyDetailsResponse | null = null;
  @Input() size: 'sm' | 'lg' = 'sm';
  @Input() preload = false;
  @Input() fitImage = false;
  @Input() showIndicators = true;

  public imagesSrc$ = new BehaviorSubject<Models.PhotoPaths[] | null>(null);
  public showCarousel$ = this.imagesSrc$.pipe(map(images => !!((images?.length || 0) > 1)));
  public imageErrors: boolean[] = [];
  public missingImageUrl = missingImageUrl;
  /** Have all images been preloaded yet */
  private imagesPreloaded = false;
  public activeIndex = 0;

  ngOnChanges(changes: SimpleChanges) {
    if (this.preload) {
      this.preloadImages();
    }
    /***/
    if (changes['property']) {
      // this.showAuctionCountdown$.next(this.property?.AUCTION_START_DATE ? this.shouldShowAuctionCountdown(this.property.AUCTION_START_DATE) : false);
      // Update url when property updates
      // this.href = propertyToSearchPageUrl(this.searchStore.searchRouteSlug, this.property);
      this.updateGalleryImages();
    }
  }

  onActiveIndexChange(activeIndex: number) {
    this.activeIndex = activeIndex;
  }

  /**
   * Preload all carousel images on mouse over to avoid a FOUC
   * @param imagesSrc
   * @returns
   */
  public preloadImages() {
    if (!this.property || this.imagesPreloaded) {
      return;
    }

    // Remove first image since it's already been loaded
    // Load images in memory
    this.getImageUrls()
      ?.slice(1)
      .forEach(imgSrc => {
        const img = new Image();
        img.src = imgSrc.previewImageSrc;
      });
    this.imagesPreloaded = true; // Only preload the first time
  }

  /**
   * Update galleria images
   *
   * The galleria does not update images on input changes so this is necessary to force that
   */
  private updateGalleryImages() {
    this.imagesSrc$.next(null);
    // this.imagesSrc$.next(missingImagesPlaceholder);
    timer(0, 10)
      .pipe(take(1))
      .subscribe(() => this.imagesSrc$.next(this.getImageUrls()));
  }

  /**
   * Generate image urls
   * @param size
   * @returns
   */
  private getImageUrls(size: 'sm' | 'lg' = this.size) {
    if (this.property?.PHOTOS?.length) {
      return this.property?.PHOTOS?.map((p, i) => ({
        previewImageSrc: environment.endpoints.photoPath + (size === 'lg' ? 'large' : 'small') + p?.IMAGE_PATH,
        alt: p.IMAGE_DESCRIPTION || 'home for auction.',
        index: i,
      }));
    }
    return missingImagesPlaceholder;
  }
}
