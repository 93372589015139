import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SearchModels } from '../../shared/stores';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageHeaderComponent {
  @Input() heading: string | null = null;
  @Input() description: string | null = null;
  @Input() breadCrumbs?: SearchModels.BreadCrumb[] | null = null;
  @Input() actionsMobileClasses?: string[] | any = [];
}
