import { Models } from '$models';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ntsUIStoreCreator } from '@ntersol/state-management';

interface SettingsStore {
  user: Models.LoginResponse | null;
  userDetails: Models.UserDetailsResponse | null;
}

const initialState: SettingsStore = {
  user: null,
  userDetails: null,
};

@Injectable({ providedIn: 'root' })
export class SettingsService {
  // Create a ui store creator instance with default state using interface model and options
  private store = ntsUIStoreCreator<SettingsStore>(initialState, { persistId: 'settingsStore' });

  public user$ = this.store.select$('user');
  public userDetails$ = this.store.select$('userDetails');

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {}

  public update(state: Partial<SettingsStore>) {
    this.store.update(state);
  }
}
