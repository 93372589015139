import { Component, ElementRef, Input, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';
import { filter, map, mergeMap, take, tap } from 'rxjs';
import { getNavLinksForUserType } from './navigation-items';
import { VipLevel, vipLevelAssetsMapping } from '../../../shared/models/assets.model';
import { AuthService, AuthState } from '../../../shared/services';
import { ApiService } from '../../../shared/stores/api';
import { SettingsService } from '../../../shared/stores/settings/settings.service';
import { listenForLargeScreen } from '../../../shared/utils';

import { Models } from '$models';
import { OverlayPanel } from 'primeng/overlaypanel';

@UntilDestroy()
@Component({
  selector: 'app-nav',
  styleUrls: ['./nav.component.scss'],
  templateUrl: './nav.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class NavComponent {
  @Input() isHomePage: boolean | null = false;
  @ViewChild(OverlayPanel) op!: OverlayPanel;
  @ViewChild('profile', { static: false }) profile!: ElementRef;
  /** Turn the username into title case */
  public readonly userName$ = this.auth.userName$;
  public readonly user$ = this.settingsService.user$;
  public readonly loggedIn$ = this.auth.isLoggedIn$;
  public readonly userTypes = Models.UserType;

  public filterVisible = false;
  public readonly userType$ = this.auth.userType$;
  public readonly buySellDropdownItems$ = this.auth.userType$.pipe(map(userType => getNavLinksForUserType(userType)));
  public readonly searchRouteSlug = Models.SearchRouteSlug;

  public isLarge = false;
  public readonly largeScreenObserver$ = listenForLargeScreen().pipe(tap(isLarge => (this.isLarge = isLarge)));

  public readonly vipLevelAssetData = this.auth.isLoggedIn$.pipe(
    take(1),
    filter(x => !!x),
    mergeMap(() =>
      this.api.viplevels.select$.pipe(
        filter(state => !!state),
        map(state => state!.VIPLEVEL),
        map((vipLevel: VipLevel) => {
          if (vipLevel === VipLevel.NOLEVEL) {
            return null;
          }
          return vipLevelAssetsMapping[vipLevel];
        }),
      ),
    ),
  );

  constructor(
    private readonly router: Router,
    private readonly auth: AuthService,
    private readonly settingsService: SettingsService,
    private readonly api: ApiService,
    private readonly renderer: Renderer2,
  ) {}

  logOut() {
    this.auth.logOut(AuthState.loggedOut, { triggeredByUser: true });
  }

  login() {
    const returnUrl = this.router.url !== '/' && this.router.url !== '/login' ? this.router.url.split('?')[0] : null;
    this.router.navigate(['/login'], { queryParams: { currentUrl: returnUrl } });
  }

  navigateToWatchList() {
    this.auth.isLoggedIn$.pipe(take(1)).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.router.navigate(['/saved-properties']);
      } else {
        this.router.navigate(['/login'], { queryParams: { currentUrl: '/saved-properties', target: 'watchlist' } });
      }
    });
  }

  navigateToAlertsNotifications() {
    this.auth.isLoggedIn$.pipe(take(1)).subscribe(isLoggedIn => {
      if (isLoggedIn) {
        this.router.navigate(['/my-profile/alerts-notifications']);
      } else {
        this.router.navigate(['/login'], { queryParams: { currentUrl: '/my-profile/alerts-notifications', target: 'alerts-notifications' } });
      }
    });
  }

  navigateToSearchHouses() {
    this.router.navigate(['/' + this.searchRouteSlug]);
  }

  showProfileSettings(event: any) {
    this.op.toggle(event);
    if (!this.isLarge) {
      this.router.navigate(['/mobile/user-profile']);
    }
  }
}
