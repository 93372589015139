import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NtsFormsModule } from '@ntersol/forms';

import { DialogModule } from 'primeng/dialog';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SidebarModule } from 'primeng/sidebar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { ConfirmationService, MessageService } from 'primeng/api';
import { IconsModule } from './shared/icons/icons.module';
import { FontsModule } from './shared/fonts/fonts.module';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ButtonModule } from 'primeng/button';
import { CarouselModule } from 'primeng/carousel';
import { CardModule } from 'primeng/card';
import { GalleriaModule } from 'primeng/galleria';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DividerModule } from 'primeng/divider';
import { PasswordModule } from 'primeng/password';
import { MessageModule } from 'primeng/message';
import { SkeletonModule } from 'primeng/skeleton';
import { InputMaskModule } from 'primeng/inputmask';
import { PanelModule } from 'primeng/panel';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { AccordionModule } from 'primeng/accordion';
import { InputSwitchModule } from 'primeng/inputswitch';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ListboxModule } from 'primeng/listbox';
import { InputNumberModule } from 'primeng/inputnumber';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';

const MODULES = [
  // Angular
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  NtsFormsModule,
  // Font-awesome icons
  FontsModule,
  // Prime NG UI Lib
  SlideMenuModule,
  MenubarModule,
  MenuModule,
  SidebarModule,
  DialogModule,
  ConfirmDialogModule,
  DynamicDialogModule,
  OverlayPanelModule,
  IconsModule,
  InputTextModule,
  InputMaskModule,
  InputNumberModule,
  SelectButtonModule,
  ToggleButtonModule,
  ButtonModule,
  CarouselModule,
  CardModule,
  GalleriaModule,
  ProgressSpinnerModule,
  DividerModule,
  PasswordModule,
  MessageModule,
  MessagesModule,
  SkeletonModule,
  PanelModule,
  TableModule,
  TabViewModule,
  AccordionModule,
  InputSwitchModule,
  DropdownModule,
  CheckboxModule,
  RadioButtonModule,
  ListboxModule,
  ToastModule,
];

@NgModule({
  imports: [CommonModule, ...MODULES],
  providers: [ConfirmationService, DialogService, MessageService],
  exports: MODULES,
  declarations: [],
})
export class VendorModule {}
