import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService, AuthState } from '../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}
  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) {
    // If a token
    if (this.auth.authState && this.auth.authState === AuthState.loggedIn) {
      return true; // logged in and has apiUrl so set true
    }
    const parsedUrl = this.router.parseUrl(this.router.url);
    const segments =
      parsedUrl.root.children.hasOwnProperty('primary') && parsedUrl.root.children['primary'].hasOwnProperty('segments')
        ? parsedUrl.root.children['primary']['segments']
        : [];
    const isLoginComponent = segments.findIndex(segment => segment.path === 'login') !== -1;

    if (!isLoginComponent) {
      this.auth.logOut(AuthState.loggedOut, { currentUrl: _state.url });
    }

    return false;
  }
}
