<nav *ngIf="{ isDesktop: listenForSmallScreen() | async, activeRoute: featureNavService.activeRoute$ | async } as componentData">
  <ng-container *ngFor="let route of routes; index as index">
    <button
      *ngIf="
        !(
          (route.isSubmitRoute && mode === 'edit' && componentData.isDesktop) ||
          (componentData.isDesktop && route.id === 0 && mode === 'edit') ||
          (!componentData.isDesktop && index === 0) ||
          (route.isSubmitRoute && !componentData.isDesktop)
        )
      "
      class="nav-item d-block w-100"
      [ngClass]="{
        'active': mode !== 'add' && route.id === componentData.activeRoute?.id,
        'modify': mode === 'edit' && !componentData.isDesktop
      }"
      [disabled]="route.disabled"
      (click)="canNavigate(route)">
      {{ route.title }}
      <ng-container
        *ngIf="
          showVisited &&
          ((mode === 'add' && componentData.isDesktop) ||
            (!componentData.isDesktop && mode === 'add') ||
            (!componentData.isDesktop && mode === 'edit' && route.isSaved))
        ">
        <fa-icon icon="check" class="green" *ngIf="route.visited"></fa-icon>
      </ng-container>
    </button>
  </ng-container>
</nav>
