<div id="file-uploader-container">
  <div appUpload class="drop-zone" (onFileDropped)="handleDroppedFiles($event)">
    <div class="mb-3"><img width="98px" height="82px" src="./assets/images/file-upload-cloud.png" alt="file upload cloud icon" /></div>
    <h3 *appShowOnScreenSize="'desktop'" class="prompt">
      Drag here or <a class="browse-link" href="javascript:void(0)" (click)="fileInput.click()">browse</a> to upload
    </h3>
    <h3 *appShowOnScreenSize="'mobile'" class="prompt"><a class="browse-link" href="javascript:void(0)" (click)="fileInput.click()">Browse</a> to upload</h3>
    <input type="file" #fileInput multiple (change)="handleInputFiles($event)" class="d-none" />
    <p class="supported-files-msg">{{ supportedFilesLabel }}</p>
  </div>
  <!-- Files list Container -->
  <div>
    <div class="error-container mt-4">
      <p-messages key="file-size-error"></p-messages>
      <p-messages key="file-type-error"></p-messages>
    </div>
    <app-loading-spinner [fullScreen]="false" [loading]="isLoading"></app-loading-spinner>
    <div *ngIf="files && files.length > 0" class="d-none d-lg-flex justify-content-end">
      <a class="browse-link" href="javascript:void(0)" (click)="handleRemoveAll()">Remove All</a>
    </div>
    <div class="files-list">
      <div class="file-row" *ngFor="let file of files; trackBy: trackByFn">
        <div *ngIf="canSetMainFile">
          <p-radioButton
            [inputId]="file.fileId.toString()"
            name="file.fileId"
            [value]="file.fileId"
            [formControl]="mainFileControl"
            pTooltip="Click to set as main photo"
            tooltipPosition="right"></p-radioButton>
        </div>
        <div class="file-thumbnail" [class.ml-5]="!canSetMainFile">
          <img [src]="file.thumbnailUrl || createThumbnail(file?.file!)" width="50" />
        </div>
        <div class="file-name" [ngStyle]="{ 'max-width': file?.category ? '16rem' : '30rem' }" [title]="file?.fileName">
          {{ truncateFilename(file?.fileName!, 40) }}
        </div>
        <div *ngIf="file?.category" class="file-category">{{ file?.category }}</div>
        <div *ngIf="!file?.category" class="file-size d-none d-lg-block">{{ returnFileSize(file.file?.size || file.savedFileSize) }}</div>
        <div class="file-delete" (click)="deleteFile(file.fileId)"><i class="pi pi-times"></i></div>
      </div>
    </div>
  </div>
</div>
