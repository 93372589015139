import { Component, EventEmitter, Output, OnInit, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, distinctUntilChanged, filter, map as rxMap, of, switchMap } from 'rxjs';
import { forEach, get, isNumber } from 'lodash';

import { SettingsService } from '$settings';
import { Models } from '$models';

import { FormGeneratorModel } from 'src/app/shared/models/form.models';
import { BiddingModels, RequiredKeys } from '../../shared/models/bidding.model';
import { BiddingStoreKey, BiddingStoreService } from '../../store/bidding.store';
import { BiddingService } from '../../shared/services/bidding.service';
import { NotificationService } from '$services';

const FORM_TITLE = 'Active Action Form';
const PROPERTY_ADDRESS_LABEL = 'Property Address';
const CURRENT_BID_LABEL = 'Current Bid';
const OPENING_BID_LABEL = 'Opening Bid';
const BID_AMT_PLACEHOLDER = 'Your Bid Amount';
const BID_AMT_HINT = 'Note: Bid Increment of {{increment}}';
const OWNERSHIP_TYPE_PLACEHOLDER = 'Ownership Information';
const PURCHASE_TYPE_PLACEHOLDER = 'Purchase Type';
const BID_ASSIST_AMT_PLACEHOLDER = 'Set BidAssist (optional)';
const BID_ASSIST_AMT_HINT =
  'You can bid automatically, using only as much of the BidAssist amount needed to maintain the top bid position, up to the maximum amount.';
const BID_ASSIST_CARRYOVER_PLACEHOLDER =
  'Would you like to carry your BidAssist into Post Auction negotiations if you are the High Bidder at the end of the auction and the reserve price has not been met?';
const PURCHASEPROFILEID_PLACEHOLDER = 'Purchase Profile (optional)';
const BUYER_AGENT_PLACEHOLDER = 'Buyer Agent (optional)';
const BID_NOW = 'Bid Now';
const BIDDING_FOR = 'Bidding For';

const formModel: Record<RequiredKeys<BiddingModels.BiddingModel>, any> = {
  BID_AMT: [null, Validators.required],
  OCCUPANCY_TYPE: ['O', Validators.required],
  PURCHASE_TYPE: ['C', Validators.required],
  BID_ASSIST_AMT: null,
  BID_ASSIST_CARRYOVER: 'Y',
  PURCHASEPROFILEID: null,
};

const BIDDING_DEFAULT_PURCHASE_TYPE_DATA: Record<RequiredKeys<BiddingModels.BiddingModel>, any> = {
  OCCUPANCY_TYPE: 'O',
  PURCHASE_TYPE: 'C',
  PURCHASEPROFILEID: null,
};

export const BIDDING_DEFAULT_DATA: Record<RequiredKeys<BiddingModels.BiddingModel>, any> = {
  BID_AMT: null,
  BID_ASSIST_AMT: null,
  BID_ASSIST_CARRYOVER: 'N',
  ...BIDDING_DEFAULT_PURCHASE_TYPE_DATA,
};

@UntilDestroy()
@Component({
  selector: 'app-place-bid-form',
  templateUrl: './place-bid-form.component.html',
  styleUrls: ['./place-bid-form.component.scss'],
})
export class PlaceBidFormComponent implements OnInit, AfterViewInit {
  @Output() formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() formValuesChanged: EventEmitter<Record<RequiredKeys<Models.AddAgentContactRequest>, any>> = new EventEmitter<
    Record<RequiredKeys<Models.AddAgentContactRequest>, any>
  >();
  @Output() buyerAgentClick: EventEmitter<BiddingModels.BuyerAgentClickEvent> = new EventEmitter<BiddingModels.BuyerAgentClickEvent>();
  @Output() buyerAgentRemoveClick: EventEmitter<BiddingModels.BuyerAgentClickEvent> = new EventEmitter<BiddingModels.BuyerAgentClickEvent>();

  readonly PROPERTY_ADDRESS_LABEL = PROPERTY_ADDRESS_LABEL;
  readonly OPENING_BID_LABEL = OPENING_BID_LABEL;
  readonly CURRENT_BID_LABEL = CURRENT_BID_LABEL;
  readonly BID_AMT_PLACEHOLDER = BID_AMT_PLACEHOLDER;
  readonly OWNERSHIP_TYPE_PLACEHOLDER = OWNERSHIP_TYPE_PLACEHOLDER;
  readonly PURCHASE_TYPE_PLACEHOLDER = PURCHASE_TYPE_PLACEHOLDER;
  readonly BID_ASSIST_AMT_PLACEHOLDER = BID_ASSIST_AMT_PLACEHOLDER;
  readonly BID_ASSIST_AMOUNT_HINT = BID_ASSIST_AMT_HINT;
  readonly BID_ASSIST_CARRYOVER_PLACEHOLDER = BID_ASSIST_CARRYOVER_PLACEHOLDER;
  readonly PURCHASEPROFILEID_PLACEHOLDER = PURCHASEPROFILEID_PLACEHOLDER;
  readonly BUYER_AGENT_PLACEHOLDER = BUYER_AGENT_PLACEHOLDER;
  readonly BID_NOW = BID_NOW;
  readonly BIDDING_FOR = BIDDING_FOR;
  readonly requiredAsterisk = '<sup class="required">*</sup>';

  title = FORM_TITLE;
  form = this.fb.group(formModel);
  formFields: FormGeneratorModel<keyof BiddingModels.BiddingModel | 'RADIO'>[] | null = null;
  bidOptions: Record<string, string>[] = [];
  bidAssistOptions: Record<string, string>[] = [];
  bidAmountHint = '';

  user: Models.LoginResponse | null = null;
  property: Models.PropertyDetailsResponse | null = null;
  currentBid: Models.PropertyCurrentBidResponse | null = null;
  currentBidAmt: number = 0;
  buyerAgent: Models.BuyerAgentRequest | null = null;
  biddingData: BiddingModels.BiddingModel | null = null;
  selectedPurchaseProfile: Models.PurchaseProfileViewResponse | null = null;

  isNewBuyerAgent = false;

  readonly propertyCategoryType = Models.PropertyCategoryType;

  user$ = this.settings.user$.pipe(
    rxMap(user => {
      this.user = user;
      return user;
    }),
    catchError(() => {
      this.user = null;
      return of(null);
    }),
  );

  propertyDetails$ = this.biddingStore.propertyDetails.state$.pipe(
    rxMap(state => {
      this.property = state.data;
      return this.property;
    }),
    catchError(() => {
      this.property = null;
      return of(null);
    }),
  );

  biddingFor$ = this.biddingStore.getSelector(BiddingStoreKey.bidForData).pipe(
    switchMap((model: BiddingModels.PlaceBidForFormModel) => {
      if (this.isUserAgent && model) {
        if (model.Bid_Target === BiddingModels.BidTargets.existingCustomer) {
          const customerId = model.CUSTOMER_ID || 0;
          return this.biddingStore.customers
            .selectOne$(customerId)
            .pipe(rxMap(customer => `${customer?.FIRST_NAME} ${customer?.LAST_NAME} (${customer?.HANDLE_ID})`));
        } else if (model.Bid_Target === BiddingModels.BidTargets.newCustomer) {
          return of(`${model?.FIRST_NAME} ${model?.LAST_NAME} (NEW)`);
        } else {
          return of('Myself');
        }
      }
      return of(null);
    }),
  );

  currentBid$ = this.biddingStore.currentBid$.pipe(
    rxMap(res => {
      this.currentBid = res;
      if (this.currentBid || this.property) {
        const isOpeningBid = this.currentBid?.LEADERBIDDER === BID_NOW;
        this.setBidOptions(isOpeningBid);
        this.setBidAssistOptions(this.bidOptions, get(this.bidOptions, '[0].value', 0));
      }
      return res;
    }),
    catchError(error => {
      if ([0, 404].includes(error.status) && this.property) {
        const bidAmt = this.getBidAmount();
        this.currentBid = this.property
          ? {
              LEADERBIDDER: this.BID_NOW,
              CURRENT_BID: bidAmt,
              BID_INCREMENT: 0,
            }
          : null;
        if (this.currentBid) {
          const isOpeningBid = this.currentBid?.LEADERBIDDER === BID_NOW;
          this.setBidOptions(isOpeningBid);
          this.setBidAssistOptions(this.bidOptions, get(this.bidOptions, '[0].value', 0));
        }
        return of(this.currentBid);
      }
      return of(null);
    }),
  );

  buyerAgent$ = this.biddingStore.buyerAgent.state$.pipe(
    filter(state => !state.loading && !state.modifying),
    rxMap(state => {
      const buyerAgent = get(state, 'data[0]');
      this.buyerAgent = buyerAgent;
      return buyerAgent;
    }),
    catchError(error => {
      this.buyerAgent = null;
      console.error(error.MESSAGE || error.message);
      this.notification.showToast({
        styleClass: 'p-toast-message-error',
        closable: true,
        summary: 'Error',
        detail: 'Could not get Buyer Agent data.',
      });
      return of(null);
    }),
  );

  occupancyTypes$ = this.biddingStore.occupancyTypes.state$.pipe(
    rxMap(state => {
      const res: Record<string, any>[] = [];
      forEach(state?.entities as Record<string | number, BiddingModels.OccupancyType>, entity => {
        res.push({
          label: entity.OCCUPANCYTYPE,
          value: entity.ID,
        });
      });
      return res;
    }),
  );

  purchaseTypes$ = this.biddingStore.purchaseTypes.state$.pipe(
    rxMap(state => {
      const res: Record<string, any>[] = [];
      forEach(state?.entities as Record<string | number, BiddingModels.PurchaseType>, entity => {
        res.push({
          label: entity.PURCHASETYPE,
          value: entity.ID,
        });
      });
      return res;
    }),
  );

  purchaseProfiles$ = this.biddingStore.purchaseProfiles.state$.pipe(
    rxMap(state => {
      const res: Record<string, any>[] = [];
      forEach(state?.entities as Record<string | number, Models.PurchaseProfileListItem>, entity => {
        res.push({
          label: entity?.PURCHASEPROFILENAME,
          value: entity?.PURCHASEPROFILEID,
        });
      });
      return res;
    }),
  );

  biddingData$ = this.biddingStore.getSelector(BiddingStoreKey.bidData).pipe(
    rxMap(biddingData => {
      this.biddingData = biddingData;
      this.setFormModel();
      return biddingData;
    }),
    catchError(() => {
      this.biddingData = null;
      return of(null);
    }),
  );

  yesNoOptions: Record<any, any>[] = [
    { label: 'Yes', value: 'Y' },
    { label: 'No', value: 'N' },
  ];

  get isPostAuction(): boolean {
    return BiddingService.isPostAuction(this.property);
  }

  get isUserAgent(): boolean {
    return BiddingService.isUserAgent(this.user);
  }

  get isUserBidder(): boolean {
    return BiddingService.isUserBidder(this.user);
  }

  get isUserClient(): boolean {
    return BiddingService.isUserClient(this.user);
  }

  get isUserInvestor(): boolean {
    return BiddingService.isUserInvestor(this.user);
  }

  private currentBidAmtValue: number = 0;

  constructor(
    public bidding: BiddingService,
    private notification: NotificationService,
    private fb: FormBuilder,
    private biddingStore: BiddingStoreService,
    private settings: SettingsService,
  ) {}

  ngOnInit(): void {
    this.watchForFormValuesChanged();
  }

  ngAfterViewInit(): void {
    // If user is investor, set default for Occupancy Type radio button to I for Investor
    if (this.isUserInvestor) {
      this.form.get('OCCUPANCY_TYPE')?.setValue('I');
    }

    this.formReady.emit(this.form);
  }

  onBuyerAgentClick(e: MouseEvent): void {
    e.preventDefault();
    this.buyerAgentClick.next({
      agent: this.buyerAgent as Models.BuyerAgentRequest,
    });
  }

  onBuyerAgentRemoveClick(e: MouseEvent): void {
    e.preventDefault();
    this.buyerAgentRemoveClick.next({
      agent: this.buyerAgent as Models.BuyerAgentRequest,
    });
  }

  getBidAmount(): number {
    return this.property?.CURRENT_ONLINE_AUCTION_BID_INFORMATION?.BIDAMT || 0;
  }

  private setFormModel(): void {
    this.form.patchValue(this.biddingData || { ...BIDDING_DEFAULT_DATA }, { emitEvent: false });
  }

  /**
   * Set "Bid Amount" options
   *
   * @param isOpeningBid   True if no bids have been done yet (optional, by default is True)
   */
  private setBidOptions(isOpeningBid = true): void {
    this.bidOptions = [];

    if (!this.property || !this.currentBid) {
      return;
    }
    const bids = this.bidding.getBidOptions(this.currentBid?.CURRENT_BID || this.getBidAmount(), this.currentBid?.BID_INCREMENT, isOpeningBid);

    // Create bid options based on property sale bid
    this.bidOptions = bids.bids.map(
      option =>
        ({
          label: this.bidding.formatMoneyValue(option),
          value: option,
        } as Record<any, any>),
    );

    this.bidAmountHint = BID_AMT_HINT.replace('{{increment}}', this.bidding.formatMoneyValue(bids.increment));
  }

  /**
   * Set "Bid Assist Amount" options
   *
   * @param bidOptions
   * @param currentBid
   */
  private setBidAssistOptions(bidOptions: Record<any, any>[], currentBid: number): void {
    this.bidAssistOptions = [];

    if (!this.property || !this.currentBid) {
      return;
    }
    // Create bid options based on property sale bid
    bidOptions.forEach(option => {
      if (option['value'] > currentBid) {
        this.bidAssistOptions.push(option);
      }
    });
  }

  private formValuesChangedHandler(values: Record<RequiredKeys<BiddingModels.BiddingModel>, any>): void {
    const bidAmt = this.form.get('BID_AMT')?.value;
    const bidAssistAmt = this.form.get('BID_ASSIST_AMT')?.value;

    // Update "Bid Assist Amount" options based on current bid amount
    if (isNumber(bidAmt) && bidAmt !== this.currentBidAmtValue) {
      this.currentBidAmtValue = bidAmt;
      this.setBidAssistOptions(this.bidOptions, this.currentBidAmtValue);
    }

    /**
     * Bid amount assist value must be grater than bid amount. If
     * it isn't the case, set bid amount assist to Null to let user
     * select greater value.
     */
    if (isNumber(bidAmt) && isNumber(bidAssistAmt) && bidAmt >= bidAssistAmt) {
      this.form.get('BID_ASSIST_AMT')?.setValue(null);
    }
    this.formValuesChanged.emit(values);
  }

  private watchForFormValuesChanged(): void {
    this.form?.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(this.formValuesChangedHandler.bind(this));
  }
}
