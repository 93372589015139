import { Pipe, PipeTransform } from '@angular/core';
import { Models } from '$models';

@Pipe({ name: 'matchesCategoryTypes' })
export class MatchesCategoryTypesPipe implements PipeTransform {
  transform(categoryId?: number, categoryTypes?: (keyof typeof Models.PropertyCategoryType)[]): boolean {
    let matchesCategoryTypes = false;

    if (!categoryId || !categoryTypes) {
      return matchesCategoryTypes;
    }

    for (const categoryType of categoryTypes) {
      if (categoryId == Models.PropertyCategoryType[categoryType]) {
        matchesCategoryTypes = true;
        break;
      }
    }

    return matchesCategoryTypes;
  }
}
