import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NtsStateManagementService } from '@ntersol/state-management';
import { iif, map, tap } from 'rxjs';
import { Models } from '$models';
import { environment } from '$env';
import { Country } from '../../models/countries.models';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private store = this.sm.createBaseStore({ apiUrlBase: environment.endpoints.apiUrl + '' });

  public getCountries = this.store<Country>({
    uniqueId: 'value',
    apiUrlBase: '/',
    apiUrl: 'assets/data/countries.json',
    map: {
      get: (data: { data: Array<Country> }) => {
        return data.data;
      },
    },
  });

  public propertyType = this.store<Models.PropertyTypeResponse>({
    uniqueId: 'PROP_TYPE_CAT_ID',
    apiUrl: 'public/types/propertyType',
  });

  public salesTypes = this.store<Models.PropertySaleTypeResponse>({
    uniqueId: 'CATEGORYID',
    apiUrl: 'public/types/sale',
    map: {
      // Sort response based on display order
      get: (cats: Models.PropertySaleTypeResponse[]) => cats.sort((a, b) => (a.DISPLAYORDER ?? 0) - (b.DISPLAYORDER ?? 0)),
    },
  });

  public alertTypes = this.store<Models.GetAlertTypesResponse>({
    apiUrl: 'user/current/alert/getalerttypes',
    uniqueId: 'ALERT_TYPE_ID',
  });

  public getAuctionLicenses = (stateCode: string) =>
    this.http.get<Array<Models.AuctionLicenseResponse>>(environment.endpoints.apiUrl + `public/property/auctionlicense/${stateCode}`);

  public auctionTimeLineDates = (duration: 5 | 7 | 10) =>
    this.http.get<Models.AuctionTimeLineDates>(environment.endpoints.apiUrl + `property/getCalendarDates/${duration}`);

  public holidayDates = this.store<Models.HolidayCalendarResponse>({
    apiUrl: 'public/property/getHolidayCalendar',
    uniqueId: 'HOLIDAY_DATE',
  });

  public closingCostTypes = this.store<Models.ClosingCostType>({
    apiUrl: 'public/types/closingcosttype',
    uniqueId: 'ID',
  });

  /** Saved properties/watched properties */
  public watched = this.store<Models.WatchedProperties>({
    apiUrl: 'user/current/property/watched/list',
    uniqueId: 'ITEM_ID',
  });

  /** Delete a watched property */
  public watchedPropertiesDelete = (id: number) =>
    this.http.post(environment.endpoints.apiUrl + 'user/current/property/watched/delete', { ITEM_ID: id }).pipe(tap(() => this.watched.refresh().subscribe())); // Refresh watched props in store

  public savedSearches = this.store<Models.SavedSearch>({
    apiUrl: 'user/current/property/saved-searches/list',
    apiUrlOverride: {
      post: environment.endpoints.apiUrl + 'user/current/property/saved-searches/add',
      put: environment.endpoints.apiUrl + 'user/current/property/saved-searches/edit',
    },
    uniqueId: 'SAVEDSEARCHID',
  });

  public alerts = this.store<Models.GetAllAlertsResponse>({
    apiUrl: 'user/current/alert/getall',
    uniqueId: 'ALERT_ID',
    apiUrlOverride: {
      post: environment.endpoints.apiUrl + 'user/current/alert/add',
      put: environment.endpoints.apiUrl + 'user/current/alert/update',
    },
  });

  public viplevels = this.store<Models.GetVipLevelsResponse>({
    apiUrl: 'investor/viplevels',
  });

  /**
   * Make advanced search for things like item ID, parcel #, case # etc
   * @param term
   * @returns
   */
  public advancedSearch$ = (term: string | null) =>
    this.http
      .get<Models.PropertyLookup[] | { message: string; ITEM_ID: string }>(environment.endpoints.apiUrl + `public/search/${term}/lookup`)
      .pipe(map(results => (Array.isArray(results) && results.length ? results : [])));

  /**
   * Delete a saved search
   * @param id
   * @returns
   */
  public savedSearchesDelete = (id: number) =>
    this.http
      .post(environment.endpoints.apiUrl + 'user/current/property/saved-searches/delete', {
        savedSearchID: id,
      })
      .pipe(tap(() => this.savedSearches.refresh().subscribe()));

  /** Update a saved searches notification settings */
  public saveSearchesNotificationChange = (search: Partial<Models.SavedSearch>) =>
    this.http
      .put(environment.endpoints.apiUrl + 'user/current/property/saved-searches/notification', search)
      .pipe(tap(() => this.savedSearches.refresh().subscribe()));

  constructor(private sm: NtsStateManagementService, public http: HttpClient) {}

  /** Update an auction notification settings */
  saveAuctionAlertsNotificationChange = (alert: Partial<Models.UpdateAlertRequest>) => {
    const observable = iif(
      () => !alert.ALERT_ID,
      this.http.post(environment.endpoints.apiUrl + 'user/current/alert/add', alert),
      this.http.put(environment.endpoints.apiUrl + 'user/current/alert/update', alert),
    );

    return observable.pipe(tap(() => this.alerts.refresh().subscribe()));
  };
}
