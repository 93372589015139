<form #myForm="ngForm" [formGroup]="form" [ngClass]="{ 'show-errors-on-touched': errorCondition.touch, 'show-errors-on-submit': errorCondition.submit }">
  <p-password
    name="password"
    class="show-dirty-error"
    styleClass="w-100 p-input-icon-right"
    inputStyleClass="w-100"
    [toggleMask]="true"
    [feedback]="false"
    [ariaLabelledBy]="labelId"
    formControlName="password"></p-password>
  <app-input-error
    #inputError
    class="error"
    controlName="password"
    friendlyName="password"
    [customErrorMessage]="customErrorMessage"
    [errorCondition]="errorCondition"
    [ngClass]="{ 'with-help': (inputError.controlState$ | async)?.showError }">
  </app-input-error>
  <div *ngIf="showHelpText" class="footnote text-secondary mt-1">
    At least 8 characters in length and at least 1 upper case, 1 number and 1 special character.
  </div>
  <ng-container *ngIf="validationClasses$ | async as classes">
    <div *ngIf="showStrengthFeedback" class="validations flex mt-3 ms-1">
      <div class="char-length d-flex mb-2" [ngClass]="classes['invalidCharacterLength']">
        <fa-icon icon="circle-check"></fa-icon>
        <span>At least 8 characters in length</span>
      </div>
      <div class="one-number d-flex mb-2" [ngClass]="classes['missingNumber']">
        <fa-icon icon="circle-check"></fa-icon>
        <span>At least 1 number</span>
      </div>
      <div class="upper-lower d-flex mb-2" [ngClass]="classes['missingUpperOrLowerCaseLetter']">
        <fa-icon icon="circle-check"></fa-icon>
        <span>At least 1 upper case</span>
      </div>
      <div class="upper-lower d-flex" [ngClass]="classes['specialCharacter']">
        <fa-icon icon="circle-check"></fa-icon>
        <span>At least 1 special character</span>
      </div>
    </div>
  </ng-container>
</form>
