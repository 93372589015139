<ng-container *ngIf="{ listenForLargeScreen: listenForLargeScreen$ | async } as componentData">
  <ng-container *ngIf="isHomePage || componentData.listenForLargeScreen">
    <ng-container *ngIf="{ loggedIn: loggedIn$ | async } as comp">
      <header>
        <nav class="nav">
          <a class="brand pl-2 me-3" [routerLink]="['/']">
            <img *ngIf="componentData.listenForLargeScreen" class="logo" src="./assets/images/realty-bid-logo-full.svg" alt="logo" />
            <img *ngIf="!componentData.listenForLargeScreen" class="logo" src="./assets/images/realty-bid-logo-sm.svg" alt="logo" />
          </a>
          <div class="nav-content">
            <app-nav class="nav-component" [isHomePage]="isHomePage"></app-nav>
          </div>
        </nav>
      </header>
    </ng-container>
  </ng-container>
</ng-container>
