<div class="mt-4 p-3">
  <h4>Investor Terms</h4>

  <p><b>Additional Terms for Upgrading to Investor Account</b></p>

  <p>
    The following terms and conditions apply to all Investors attempting to bid or sell Properties on RealtyBid.com. Please take a moment to read this section
    thoroughly before upgrading to an Investor Account.
  </p>

  <p><b>Additional User Terms Related to Buying or Selling a Property on RealtyBid as an Investor:</b></p>

  <p>As an Investor on RealtyBid, each user agrees to the following:</p>

  <p>
    1. During the timeframe the Property is on RealtyBid.com, I agree to place all offers I receive on the Site. No offer will be considered outside the
    RealtyBid process.
  </p>

  <p>
    2. I agree that an internet transaction fee will be charged to the winning buyer of any property I have placed in auction on RealtyBid. This internet
    transaction fee will be paid to RealtyBid at closing. The Purchase Agreement will include an Internet Transaction Fee Addendum specifying the amount to be
    paid by the buyer and shall be signed by the buyer and made a part of the contract. I agree that if I do not get the RealtyBid fee addendum signed by the
    buyer, I will be responsible for payment of any internet transaction or buyer’s premium fee for any property that I have listed and sold through RealtyBid.
  </p>

  <div>
    3. When posting a Property on the Site for auction , I agree that:
    <ol>
      <li>
        if I request to have the Property withdrawn from the Site prior to the full duration of the online auction, a withdrawal penalty could be assessed
        (unless prior approval has been granted by RealtyBid management);
      </li>
      <li>I will set a Reserve price equal to a fair value and minimum amount I would accept to sell the Property;</li>
      <li>
        a starting bid amount that is less than my Reserve price will be generated by the RealtyBid system and that this starting bid amount will not be
        determined by me;
      </li>
      <li>I agree to post as much useful information about the Property as possible, and I will provide any required disclosures for the Property;</li>
      <li>
        I agree that the Buyer Agent commission input into the RealtyBid system will be the same as that offered in the MLS, or if not, the MLS listing will be
        used to determine the commission paid to a Buyer Agent (RealtyBid encourages the payment of commission by Sellers to Buyer Agents placing bids for their
        clients on RealtyBid, as this practice draws more potential bidders for Properties offered on the Site);
      </li>
      <li>I agree that I will not artificially inflate or influence the bids on a particular Property;</li>
      <li>
        I agree that RealtyBid is a marketing vehicle to assist me in generating offer activity for a Property and that it does not represent me or any bidder
        as an agent;
      </li>
      <li>I agree that RealtyBid has given no guarantee as to its ability to obtain any price for a Property;</li>
      <li>
        I understand that I can monitor all my listings and their bidding activity through my account profile by clicking the link to the activity report; and
      </li>
      <li>
        I agree to and entrust RealtyBid to utilize the RealtyBid investor contract and addenda for the sales transactions of my Properties sold on
        RealtyBid.com (<a href="assets/pdfs/COMPLETE.RB.Purchase.Agreement.pdf" target="_blank" alt="Click here" rel="noreferrer noreopen">Click Here</a> to
        view a sample of this RealtyBid investor contract and addenda).
      </li>
    </ol>
  </div>
</div>
