import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ApiService } from '../../../../shared/stores/api';
import { Observable, filter, map } from 'rxjs';
import { Models } from '../../../../shared/models';
import { Statistics } from '../../components/program-badge/program-badge.component';
import { ThemeData, VipLevelBannerService } from '../../shared/services/vip-level-banner.service';
import { VipLevel } from '../../../../shared/models/assets.model';
import { MobileHeaderLevel3TypeAccessor } from '../../../../components/mobile-navigation/headers/app-mobile-header-level-3.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-program-badge-wrapper',
  templateUrl: './program-badge-wrapper.component.html',
  styleUrls: ['./program-badge-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgramBadgeWrapperComponent implements MobileHeaderLevel3TypeAccessor {
  readonly headerTitle = 'Account Details';
  readonly closeButtonAction = () => {
    this.router.navigate(['/', 'my-profile', 'account-details']);
  };

  public readonly VipLevel = VipLevel;
  private vipLevelState: Observable<Models.GetVipLevelsResponse> = this.api.viplevels.select$.pipe(
    filter(state => !!state),
  ) as Observable<Models.GetVipLevelsResponse>;

  public vipLevelStatistics$: Observable<Statistics> = this.vipLevelState.pipe(
    filter(state => state.VIPLEVEL !== this.VipLevel.NOLEVEL),
    map(state => {
      return {
        numberPropertiesPurchased: state.NUMBERPROPERTIESPURCHASED,
        averagePurchasePrice: state.AVERAGEPURCHASEPRICE,
        numberPropsBidOn: state.NUMBERPROPSBIDON,
        percentOfValue: state.PERCENTOFVALUE,
      };
    }),
  );

  public badgeThemeData: Observable<ThemeData> = this.vipLevelState.pipe(
    filter(state => state.VIPLEVEL !== this.VipLevel.NOLEVEL),
    map(state => {
      const uiData = this.vipLevelBannerService.getVipLevelUiData(state.VIPLEVEL);
      uiData.vipStateText = this.replacePlaceholderWithNumberOfPropertiesLeft(uiData.vipStateText, state.PROPERTIESTONEXTLEVEL);
      return uiData;
    }),
  );

  public vipLevel: Observable<VipLevel> = this.vipLevelState.pipe(map(state => state.VIPLEVEL));

  constructor(private readonly api: ApiService, private readonly vipLevelBannerService: VipLevelBannerService, private readonly router: Router) {}

  private replacePlaceholderWithNumberOfPropertiesLeft(vipStateText: string, propertiesToNextLevel: number): string {
    return vipStateText.replace(/\{O\}/g, propertiesToNextLevel.toString());
  }
}
