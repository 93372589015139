import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { Models } from '../../shared/models';

@Component({
  selector: 'app-state-license',
  templateUrl: './state-license.component.html',
  styleUrls: ['./state-license.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StateLicenseComponent implements OnInit {
  @Input() license!: Models.AuctionLicenseResponse; // Asserted in OnInit

  ngOnInit() {
    if (!this.license) {
      throw new Error('license input field should be provided');
    }
  }
}
