import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, map, of, startWith } from 'rxjs';

@Component({
  selector: 'app-saved-search-form',
  templateUrl: './saved-search-form.component.html',
  styleUrls: ['./saved-search-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedSearchFormComponent {
  messageLength$: Observable<number> = of(40);
  control = new FormControl();

  @Input() set saveSearchForm(control: FormControl) {
    this.control = control;
    this.messageLength$ = control.valueChanges.pipe(
      startWith(control.value || ''),
      map(value => 40 - value.length),
    );
  }

  @Input() isEdit = false;
  @Output() submitForm = new EventEmitter();

  submitValue() {
    this.submitForm.emit();
  }
}
