import { Component } from '@angular/core';
import { environment } from '$env';
import { Location } from '@angular/common';

@Component({
  selector: 'app-login-header',
  styleUrls: ['./login-header.component.scss'],
  templateUrl: './login-header.component.html',
})
export class LoginHeaderComponent {
  public appName = environment.properties.appName;

  constructor(private readonly location: Location) {}

  public close() {
    this.location.back();
  }
}
