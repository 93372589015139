<!-- *ngIf="componentData.vipLevel !== VipLevel.NOLEVEL && componentData.theme && componentData.vipStatistic" -->
<ng-container
  *ngIf="{
    theme: badgeThemeData | async,
    vipStatistic: vipLevelStatistics$ | async,
    vipLevel: vipLevel | async
  } as componentData">
  <div class="wrapper container">
    <app-mobile-header-level-3 [title]="headerTitle" [action]="closeButtonAction"></app-mobile-header-level-3>
    <ng-container *ngIf="componentData.vipLevel !== VipLevel.NOLEVEL && componentData.theme && componentData.vipStatistic">
      <div class="d-flex justify-content-center">
        <app-program-badge
          [vipLevelsStatistics]="componentData.vipStatistic"
          [mainColor]="componentData.theme.mainColor"
          [vipStateBackgroundColor]="componentData.theme.vipStateBackgroundColor"
          [currentBadgePath]="componentData.theme.currentBadgePath"
          [altImageText]="componentData.theme.altImageText"
          [headerText]="componentData.theme.headerText"
          [vipStateText]="componentData.theme.vipStateText">
        </app-program-badge>
      </div>
    </ng-container>
  </div>
</ng-container>
