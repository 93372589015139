import { capitalizeFirstLetterOfEachWord, replaceAllDoubleSpacesToSingleSpace, replaceDashesToSpaces, replaceSpacesToDashes } from '../../../utils';
import { getLongStateName, getStateAbbreviation } from '../../../utils/us-states-converter.utils';

/**
 * Example:
 *  @Input 'SC'
 *  @returns 'south-carolina'
 */
export function serializeStateName(stateAbbreviation?: string | null): string {
  const abbreviationInUpperCase = (stateAbbreviation ?? '').toUpperCase();
  const longStateName = getLongStateName(abbreviationInUpperCase) || stateAbbreviation;
  const serializedLongStateName = replaceSpacesToDashes((longStateName ?? '').toLowerCase());
  return serializedLongStateName;
}

/**
 * Example:
 *  @Input 'south-carolina'
 *  @returns 'SC'
 */
export function deserializeStateName(urlSegmentStateName?: string): string {
  const abbreviation = getStateAbbreviation(replaceDashesToSpaces(urlSegmentStateName ?? '')) || urlSegmentStateName;
  return abbreviation ?? '';
}

/**
 * We use county names which have format `${County Name} County`.
 * For example: 'Washington County'.
 * So it should be always at least 2 words. Otherwise an error will be
 * raised. We are using 2 APIs where county name is a filter.
 * Google Places API and this application Backend API.
 * Google Places API uses the format described above, so that if Backend API
 * returns something different, then there wouldn't be match what leads
 * to inconsistent search results
 *
 * Example:
 *  @Input 'Washington County'
 *  @returns 'washington-county'
 */
export function serializeCountyName(countyName?: string): string {
  return replaceSpacesToDashes(replaceAllDoubleSpacesToSingleSpace((countyName ?? '').toLowerCase()));
}

/**
 * Example:
 *  @Input 'washington-county'
 *  @returns 'Washington County'
 */
export function deserializeCountyName(urlSegmentCountyName?: string): string {
  const countyName = replaceDashesToSpaces(urlSegmentCountyName ?? '');
  return capitalizeFirstLetterOfEachWord(countyName);
}

/**
 * Example:
 *  @Input 'Fort Worth'
 *  @returns 'fort-worth'
 */
export function serializeCityName(cityName?: string): string {
  return replaceSpacesToDashes(replaceAllDoubleSpacesToSingleSpace((cityName ?? '').toLowerCase()));
}

/**
 * Example:
 *  @Input 'fort-worth'
 *  @returns 'Fort Worth'
 */
export function deserializeCityName(urlSegmentCityName?: string): string {
  const cityName = replaceDashesToSpaces(urlSegmentCityName ?? '');
  return capitalizeFirstLetterOfEachWord(cityName);
}
