<div class="bed-bath-container" [ngClass]="{ mobile: isMobile }" (clickOutside)="isOpen = false">
  <div class="input" (click)="isOpen = !isOpen">
    <div>
      <span class="selected-amount">({{ numberOfBeds ? numberOfBeds + '+' : 'All' }}) </span>
      <span class="title ml-5 mr-5">Beds &</span>
      <span class="selected-amount">({{ numberOfBaths ? numberOfBaths + '+' : 'All' }}) </span>
      <span class="title ml-5 mr-5">Baths</span>
    </div>
    <fa-icon class="ml-15" [class]="{ flip: isOpen }" icon="angle-down"></fa-icon>
  </div>

  <div class="options-container" *ngIf="isOpen">
    <div class="options pb-3">
      <p class="ml-5">Beds</p>
      <p-selectButton *ngIf="bedsFormControl" [options]="options" [formControl]="bedsFormControl"></p-selectButton>
      <p class="ml-5">Baths</p>
      <p-selectButton *ngIf="bathsFormControl" [options]="options" [formControl]="bathsFormControl"></p-selectButton>
    </div>
  </div>
</div>
