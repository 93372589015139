import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

export interface InputRadioOption {
  label: string;
  value: string | boolean;
}
@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InputRadioComponent,
      multi: true,
    },
  ],
})
export class InputRadioComponent implements ControlValueAccessor {
  @Input() options!: InputRadioOption[];

  disabled = false;

  onChanged?: (value: string | boolean) => void;
  onTouched: () => void = () => {};
  value: string | boolean = '';

  writeValue(value: string | boolean) {
    this.value = value;
  }

  registerOnChange(fn: (value: string | boolean) => void) {
    this.onChanged = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onRadioInputChange(option: InputRadioOption): void {
    this.writeValue(option.value);
    if (this.onChanged) {
      this.onChanged(option.value);
    }
    this.onTouched();
  }
}
