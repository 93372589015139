import { environment } from '$env';
import { Models } from '$models';

export function getNavLinksForUserType(userType: string | null): Models.MenuItemGroup[] {
  switch (userType) {
    case Models.UserType.Client: {
      return clientItems;
    }
    case Models.UserType.Agent: {
      return agentItems;
    }
    case Models.UserType.Investor: {
      return investorItems;
    }
    case Models.UserType.Bidder: {
      return bidderItems;
    }
    default: {
      return [];
    }
  }
}

const baseBiddingMenuItems: Models.MenuItem[] = [
  {
    label: 'Current Bids',
    routerLink: '/current-bids/',
  },
  {
    label: 'Bid Deposit Status',
    routerLink: '/bid-deposit-status/',
  },
  {
    label: 'Saved Properties',
    routerLink: '/saved-properties/',
  },
  {
    label: 'Saved Searches',
    routerLink: '/my-profile/alerts-notifications/',
    routerMobile: '/mobile/save-search/saved/',
  },
];

const baseListingMenuItems: Models.MenuItem[] = [
  {
    label: 'My Listings',
    routerLink: '/my-listings/',
  },
  {
    label: 'Add New Property',
    routerLink: '/my-listings/redirect/',
    routerMobile: '/my-listings/detail/',
    state: { mode: 'add' },
  },
];

const bidderItems: Models.MenuItemGroup[] = [
  {
    label: 'Bidding',
    menuItems: [
      ...baseBiddingMenuItems,
      {
        label: 'Purchased Properties',
        routerLink: '/purchased-properties/',
      },
    ],
  },
  {
    label: 'Reports',
    menuItems: [
      {
        label: 'Bidding Report',
        externalLink: environment.reportLinks.bidding,
      },
    ],
  },
];

const investorItems: Models.MenuItemGroup[] = [
  {
    label: 'Bidding',
    menuItems: [...baseBiddingMenuItems],
  },
  {
    label: 'Manage Listings',
    menuItems: [...baseListingMenuItems],
  },
  {
    label: 'Purchasing',
    menuItems: [
      {
        label: 'Purchased Properties',
        routerLink: '/purchased-properties/',
      },
      {
        label: 'Purchase Profiles',
        routerLink: '/purchase-profile/',
      },
    ],
  },
  {
    label: 'Reports',
    menuItems: [
      {
        label: 'Auction Performance Report',
        externalLink: environment.reportLinks.auctionPerformance,
      },
      {
        label: 'Bidding Report',
        externalLink: environment.reportLinks.bidding,
      },
      {
        label: 'Contract Status Report',
        externalLink: environment.reportLinks.contractStatus,
      },
    ],
  },
];

const agentItems: Models.MenuItemGroup[] = [
  {
    label: 'Bidding',
    menuItems: [...baseBiddingMenuItems],
  },
  {
    label: 'Manage Listings',
    menuItems: [
      ...baseListingMenuItems,
      {
        label: 'Verify REO Properties',
        routerLink: '/verify-reo/',
      },
    ],
  },
  {
    label: 'Purchasing',
    menuItems: [
      {
        label: 'Purchased Properties',
        routerLink: '/purchased-properties/',
      },
    ],
  },
  {
    label: 'Customers',
    menuItems: [
      {
        label: 'My Customers',
        routerLink: '/my-customers/',
      },
      {
        label: 'Add New Customer',
        routerLink: '/my-customers/add-edit',
      },
    ],
  },
  {
    label: 'Reports',
    menuItems: [
      {
        label: 'Auction Performance Report',
        externalLink: environment.reportLinks.auctionPerformance,
      },
      {
        label: 'Bidding Report',
        externalLink: environment.reportLinks.bidding,
      },
      {
        label: 'Contract Status Report',
        externalLink: environment.reportLinks.contractStatus,
      },
    ],
  },
];

const clientItems: Models.MenuItemGroup[] = [
  {
    label: '',
    menuItems: [
      {
        label: 'Daily Tasks',
        externalLink: environment.clientPortalLinks.dailyTasks,
      },
      {
        label: 'Program Managers',
        externalLink: environment.clientPortalLinks.programManagers,
      },
      {
        label: 'Manage Properties',
        externalLink: environment.clientPortalLinks.manageProperties,
      },
      {
        label: 'All Documents',
        externalLink: environment.clientPortalLinks.allDocuments,
      },
      {
        label: 'Inventory Dashboard',
        externalLink: environment.clientPortalLinks.charts,
      },
    ],
  },
  {
    label: 'Reports',
    menuItems: [
      {
        label: 'Auction Performance Report',
        externalLink: environment.reportLinks.auctionPerformance,
      },
      {
        label: 'Contract Status Report',
        externalLink: environment.reportLinks.contractStatus,
      },
    ],
  },
];
