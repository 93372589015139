import { Directive, Output, EventEmitter, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, fromEvent, merge, skip } from 'rxjs';

/**
 * Emits an event if the user clicks outside the attached DOM element
 * USAGE: <div (clickOutside)="someFunction()">
 */
@UntilDestroy()
@Directive({
  selector: '[clickOutside]',
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  @Output('clickOutside') onClickOutside = new EventEmitter<MouseEvent>();

  constructor(private ref: ElementRef) {}
  ngOnInit(): void {
    merge(fromEvent(document, 'mousedown'), fromEvent(document, 'touchstart'))
      .pipe(
        filter(event => !this.ref.nativeElement.contains(event.target)),
        untilDestroyed(this),
      )
      .subscribe(() => this.onClickOutside.emit());
  }
  ngOnDestroy(): void {}
}
