import {
  Component,
  Input,
  Output,
  EventEmitter,
  ContentChildren,
  QueryList,
  OnChanges,
  AfterContentInit,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of, isObservable } from 'rxjs';

import { DataUtils } from '$utils';
import { ModalWizardConstants } from './shared/models/modal-wizard.constants';
import { ModalWizardModels } from './shared/models/modal-wizard.model';
import { ModalWizardService } from './shared/services/modal-wizard.service';
import { ModalWizardStep } from './shared/directives/modal-wizard-step.directive';
import { ModalWizardStepComponent } from './components/modal-wizard-step/modal-wizard-step.component';
import { BiddingModels } from '../modals/bidding/shared/models/bidding.model';

@UntilDestroy()
@Component({
  selector: 'app-modal-wizard',
  templateUrl: './modal-wizard.component.html',
  styleUrls: ['./modal-wizard.component.scss'],
})
export class ModalWizardComponent implements OnChanges, AfterContentInit {
  @ViewChild('card') cardBlock!: ElementRef;

  @Input() config: ModalWizardModels.WizardConfig = {};

  @Output() onStepChanged: EventEmitter<ModalWizardModels.StepChangedArgs> = new EventEmitter<ModalWizardModels.StepChangedArgs>();
  @Output() onCompleted: EventEmitter<any> = new EventEmitter<any>();
  @Output() onReset: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCloseClick: EventEmitter<any> = new EventEmitter<any>();

  @ContentChildren(ModalWizardStepComponent) wizardSteps: QueryList<ModalWizardStepComponent> = new QueryList<ModalWizardStepComponent>();

  activeStepIndex: number = -1;

  private _isCompleted = false;

  /**
   * Return a list of visible steps
   */
  get steps(): Array<ModalWizardStepComponent> {
    //return this._steps.filter(step => !DataUtils.getValue<boolean>(step.isHidden) && !DataUtils.getValue<boolean>(step.isDisabled));
    return this.wizardSteps.filter(step => !step.isHidden && !step.isDisabled && !step.parentStep);
  }

  get isCompleted(): boolean {
    return this._isCompleted;
  }

  get isPrevHidden(): boolean {
    return !DataUtils.getValue<boolean>(this.activeStep?.showPrev, true); // || !this.hasPrevStep;
  }

  get isPrevDisabled(): boolean {
    return false; // !DataUtils.getValue<boolean>(this.activeStep.isValid); //!this.hasPrevStep ||
  }

  get isNextHidden(): boolean {
    return !DataUtils.getValue<boolean>(this.activeStep?.showNext, true); // || !this.hasNextStep &&
  }

  get isNextDisabled(): boolean {
    return false; // !DataUtils.getValue<boolean>(this.activeStep.isValid); //!this.hasNextStep ||
  }

  get activeStep(): ModalWizardStepComponent | null {
    return this.wizardSteps.find(step => step.isActive) || null;
  }

  set activeStep(step: ModalWizardStepComponent | null) {
    if (step && step !== this.activeStep && !DataUtils.getValue<boolean>(step.isDisabled, false)) {
      const prevStep = this.activeStep;

      this.activeStepIndex = step.index;

      // Current step anchor > Remove other classes and add done class
      if (this.activeStep) {
        const initialStatus =
          this.activeStep.initialStatus === ModalWizardModels.StepStatus.active ? ModalWizardModels.StepStatus.untouched : this.activeStep.initialStatus;
        this.activeStep.status = initialStatus || ModalWizardModels.StepStatus.untouched;
      }

      // Trigger "stepChanged" event
      this.broadcastActiveStepChange({
        step,
        previousStep: prevStep as ModalWizardStep,
        nextStep: this.getNextStep()?.step as ModalWizardStep,
        direction: this.getStepDirection(step.index) as ModalWizardModels.StepDirection,
      });

      step.status = ModalWizardModels.StepStatus.active;

      // Set the buttons based on the step
      //this._setButtons(selectedStep.index);

      this.setHeaderTitle(step?.title || null);
      this.setHeaderVisibility(step?.showHeader);
    }
  }

  get hasNextStep(): boolean {
    if (this.activeStep?.parentStep) {
      return true;
    }
    const nextStep = this.getNextStep();
    return !!nextStep;
  }

  get hasPrevStep(): boolean {
    if (this.activeStep?.parentStep) {
      return true;
    }
    const prevStep = this.getPrevStep();
    return !!prevStep;
  }

  get activeStepPreviousLabel(): string {
    return DataUtils.getValue<string>(this.activeStep?.prevText, ModalWizardConstants.PREV_TEXT);
  }

  get activeStepNextLabel(): string {
    return DataUtils.getValue<string>(this.activeStep?.nextText, ModalWizardConstants.NEXT_TEXT);
  }

  get activeStepDoneLabel(): string {
    return DataUtils.getValue<string>(this.activeStep?.completeText, ModalWizardConstants.COMPLETE_TEXT);
  }

  get activeStepFooterTopContent(): string {
    return this.activeStep?.footerTopContent || '';
  }

  get activeStepFooterBottomContent(): string {
    return this.activeStep?.footerBottomContent || '';
  }

  get hasFooterTopContent(): boolean {
    return !!this.activeStepFooterTopContent.length;
  }

  get hasFooterBottomContent(): boolean {
    return !!this.activeStepFooterBottomContent.length;
  }

  constructor(public sanitizer: DomSanitizer, private wizardService: ModalWizardService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] && !changes['config'].firstChange) {
      this.init();
      this.backupStepStates();
    }
  }

  ngAfterContentInit(): void {
    this.restoreScrollPositionToTop();

    // Init steps
    this.init();

    this.reindexWizardStepsOnStepsChange();

    this.backupStepStates();

    // Add event listeners
    this.watchForReset();
    this.watchForPrevStep();
    this.watchForNextStep();
    this.watchForLastStep();
    this.watchForShowStep();
    this.watchForStepChanged();
  }

  getPrevStep(stepId?: string): ModalWizardModels.StepSearchResult | null {
    return this.getPrevOrNextStep(false);
  }

  getNextStep(stepId?: string): ModalWizardModels.StepSearchResult | null {
    return this.getPrevOrNextStep();
  }

  getStepDirection(selectedStepIndex: number): ModalWizardModels.StepDirection | null {
    return this.activeStepIndex >= 0 && this.activeStepIndex != selectedStepIndex
      ? this.activeStepIndex < selectedStepIndex
        ? ModalWizardModels.StepDirection.forward
        : ModalWizardModels.StepDirection.backward
      : null;
  }

  goToStep(step: ModalWizardStepComponent | string, direction?: ModalWizardModels.StepDirection): void {
    if (!this.isCompleted) {
      // if step represented by step ID
      if (typeof step === 'string') {
        step = this.getStepById(step)?.step as ModalWizardStepComponent;
      }
      this.showStep(step, direction);
    }
  }

  onCloseButtonClick() {
    if (this.activeStep?.stepId === BiddingModels.BidStepIds.manageBuyerAgent) {
      this.previous();
    } else {
      this.onCloseClick.emit();
    }
  }

  reset(): void {
    // Reset all elements and classes
    this.activeStep = null;
    this.restoreStepStates();

    // TODO: reset state associated with wizard
    //...

    this.init();

    // Trigger "onReset" event
    this.onReset.emit();
  }

  next(): void {
    if (this.hasNextStep) {
      const step = this.activeStep?.parentStepId ? this.getStepById(this.activeStep.parentStepId)?.step : this.getNextStep()?.step;
      let nextStep = (step || this.activeStep) as ModalWizardStepComponent;
      this.goToStep(nextStep, ModalWizardModels.StepDirection.forward);
    }
  }

  previous(): void {
    if (this.hasPrevStep) {
      const step = this.activeStep?.parentStepId ? this.getStepById(this.activeStep.parentStepId)?.step : this.getPrevStep()?.step;
      let prevStep = (step || this.activeStep) as ModalWizardStepComponent;
      this.goToStep(prevStep, ModalWizardModels.StepDirection.backward);
    }
  }

  complete(): void {
    this.onCompleted.emit();
    this._isCompleted = true;
  }

  /**
   * Update current modal window header title
   *
   * @param {string} [value]   Text for title
   */
  setHeaderTitle(value: string | null): void {
    const modalTitleSection = document.getElementsByClassName(ModalWizardConstants.MODAL_HEADER_TITLE_SECTION_CLASS_SELECTOR);
    if (!modalTitleSection?.length) {
      return;
    }
    modalTitleSection[0].innerHTML = value || '';
  }

  /**
   * Update current modal window header visibility
   *
   * @param {boolean} [isVisible]   Flag whether to display or hide modal window header
   */
  setHeaderVisibility(isVisible = true): void {
    const modalTitleSection = document.getElementsByClassName(ModalWizardConstants.MODAL_HEADER_SECTION_CLASS_SELECTOR);
    if (!modalTitleSection?.length) {
      return;
    }
    if (isVisible) {
      modalTitleSection[0].classList.remove('d-none');
    } else {
      modalTitleSection[0].classList.add('d-none');
    }
  }

  /**
   * Update current modal window header visibility
   *
   * @param {boolean} [isStick]   If True, stick footer to the bottom
   */
  setFooterStickPosition(isStick = true): void {
    const elements = document.getElementsByClassName(ModalWizardConstants.MODAL_BODY_SECTION_CLASS_SELECTOR);
    if (!elements.length) {
      return;
    }
    if (isStick) {
      elements[0].classList.remove('unsticky-footer');
    } else {
      elements[0].classList.add('unsticky-footer');
    }
    //
  }

  private init() {
    // set config
    let defaultConfig = this.wizardService.getDefaultConfig();
    this.config = Object.assign(defaultConfig, this.config);

    // set step states
    this.initSteps();

    const selectedStep = this.wizardSteps.toArray()[this.config.selected || 0];

    // Show the initial step
    this.goToStep(selectedStep);
  }

  private restoreScrollPositionToTop() {
    this.onStepChanged.pipe(untilDestroyed(this)).subscribe(data => {
      (this.cardBlock.nativeElement as HTMLDivElement).scrollTo({ top: 0 });
    });
  }

  private initSteps() {
    this.wizardSteps.forEach((step, index) => {
      step.index = index;
      step.status = step.status || ModalWizardModels.StepStatus.untouched;
      step.state = step.state || ModalWizardModels.StepState.normal;
    });
    const selected = this.config?.selected || 0;
    // Mark previous steps of the active step as done or mark current step as active
    this.wizardSteps.forEach(step => {
      if (step.state != ModalWizardModels.StepState.disabled && step.state != ModalWizardModels.StepState.hidden && !step?.parentStep) {
        step.status = step.index < selected ? ModalWizardModels.StepStatus.done : step.status;
      }
    });
  }

  private reindexWizardStepsOnStepsChange(): void {
    this.observeNumberOfStepsChanges()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.reindexWizardSteps();
      });
  }

  private reindexWizardSteps(): void {
    this.wizardSteps.forEach((step, index) => {
      step.index = index;
    });
  }

  private observeNumberOfStepsChanges(): Observable<ModalWizardStepComponent> {
    return this.wizardSteps.changes.pipe(untilDestroyed(this));
  }

  private showStep(selectedStep: ModalWizardStepComponent, direction?: ModalWizardModels.StepDirection) {
    // If step not found, skip
    if (!selectedStep || selectedStep.index >= this.steps.length) {
      return;
    }

    // If current step is requested again, skip
    if (selectedStep == this.activeStep) {
      return;
    }

    // If it is a disabled or hidden step, skip
    /*if (selectedStep.state == ModalWizardModels.StepState.disabled || selectedStep.state == ModalWizardModels.StepState.hidden) {
      return;
    }*/

    return this.isStepChangeValid(selectedStep, direction, this.activeStep?.canExit)
      .toPromise()
      .then((isValid: boolean | undefined) => {
        if (isValid) {
          return this.isStepChangeValid(selectedStep, direction, selectedStep?.canEnter).toPromise();
        } else {
          this.wizardService.validationEmitter$.emit();
        }
        return of(isValid).toPromise();
      })
      .then((isValid: boolean | undefined) => {
        if (isValid) {
          // Load step content
          this.loadStepContent(selectedStep);
        }
      });
  }

  private loadStepContent(selectedStep: ModalWizardStepComponent) {
    // Update the current/active step
    this.activeStep = selectedStep;
  }

  private isStepChangeValid(
    selectedStep: ModalWizardStep,
    direction?: ModalWizardModels.StepDirection,
    condition?: boolean | ((args: ModalWizardModels.StepValidationArgs) => boolean) | ((args: ModalWizardModels.StepValidationArgs) => Observable<boolean>),
  ): Observable<boolean> {
    if (typeof condition === typeof true) {
      return of(<boolean>condition);
    } else if (condition instanceof Function) {
      direction = direction || (this.getStepDirection(selectedStep.index) as ModalWizardModels.StepDirection);
      let result = condition({ fromStep: this.activeStep as ModalWizardStep, toStep: selectedStep, direction, wizardRef: this });

      if (isObservable(result)) {
        return result;
      } else if (typeof result === typeof true) {
        return of(result);
      } else {
        return of(false);
      }
    }

    return of(true);
  }

  private backupStepStates() {
    this.wizardSteps.forEach(step => {
      step.initialStatus = step.status;
      step.initialState = step.state;
    });
  }

  private restoreStepStates() {
    this.wizardSteps.forEach(step => {
      step.status = step.initialStatus;
      step.state = step.initialState;
    });
  }

  private getStepById(stepId: string): ModalWizardModels.StepSearchResult | null {
    let stepIndex = -1;
    const step = this.wizardSteps.toArray().find((step, index) => {
      if (step.stepId === stepId) {
        stepIndex = index;
        return true;
      }
      return false;
    });
    return step
      ? {
          step,
          stepIndex,
        }
      : null;
  }

  /**
   * Return next/previous visible step
   *
   * @param {boolean} [isNext]   True if next visible Step is requested (default)
   */
  private getPrevOrNextStep(isNext = true): ModalWizardModels.StepSearchResult | null {
    const delta = isNext ? 1 : -1;
    let step: ModalWizardStep | null = null;
    let stepIndex: number = -1;

    // Looping forward/backward until next/prev visible step
    for (let i = this.activeStepIndex + delta; isNext ? i < this.wizardSteps.length : i >= 0; isNext ? i++ : i--) {
      const s = this.wizardSteps.get(i);
      if (s && !s.isActive && !s.isDisabled && !s.isHidden && !s.parentStepId) {
        step = s;
        stepIndex = i;
        break;
      }
    }
    return step
      ? {
          step,
          stepIndex,
        }
      : null;
  }

  private broadcastActiveStepChange(args: ModalWizardModels.StepChangedArgs) {
    // Trigger "stepChanged" event
    this.onStepChanged.emit(args);
    this.wizardService.stepChanged(args);
  }

  private watchForReset() {
    this.wizardService.resetWizard$.pipe(untilDestroyed(this)).subscribe(() => this.reset());
  }

  private watchForNextStep() {
    this.wizardService.showNextStep$.pipe(untilDestroyed(this)).subscribe(() => this.next());
  }

  private watchForPrevStep() {
    this.wizardService.showPrevStep$.pipe(untilDestroyed(this)).subscribe(() => this.previous());
  }

  private watchForLastStep() {
    this.wizardService.showLastStep$.pipe(untilDestroyed(this)).subscribe(() => this.complete());
  }

  private watchForShowStep() {
    this.wizardService.showStep$.pipe(untilDestroyed(this)).subscribe(step => this.showStep(step as ModalWizardStepComponent));
  }

  private watchForStepChanged() {
    this.wizardService.stepChangedArgs$.pipe(untilDestroyed(this)).subscribe(argsStep => this.showStep(argsStep?.step as ModalWizardStepComponent));
  }
}
