<div class="modal-wizard-card card">
  <div class="header">
    <h4 class="p-dialog-title title"></h4>
    <button (click)="onCloseButtonClick()" type="button" pButton icon="pi pi-times" class="p-button-rounded p-button-text p-button-lg close-button"></button>
  </div>
  <div #card class="card-block">
    <ng-content></ng-content>
  </div>
  <div class="content-footer card-footer d-flex flex-column" [hidden]="isCompleted">
    <div
      class="extra-content top-content d-flex justify-content-end"
      [innerHtml]="sanitizer.bypassSecurityTrustHtml(activeStepFooterTopContent)"
      [hidden]="!hasFooterTopContent"></div>
    <div class="footer-buttons d-flex justify-content-end" [hidden]="isCompleted">
      <button type="button" class="btn btn-outline-primary me-3" (click)="previous()" [disabled]="isPrevDisabled" [hidden]="isPrevHidden">
        {{ activeStepPreviousLabel }}
      </button>
      <button type="button" class="btn btn-primary" (click)="next()" [disabled]="isNextDisabled" [hidden]="isNextHidden">
        {{ activeStepNextLabel }}
      </button>
      <button type="button" class="btn btn-primary" (click)="complete()" [disabled]="isNextDisabled" [hidden]="!isNextHidden">
        {{ activeStepDoneLabel }}
      </button>
    </div>
    <div
      class="extra-content bottom-content d-flex justify-content-end"
      [innerHtml]="sanitizer.bypassSecurityTrustHtml(activeStepFooterBottomContent)"
      [hidden]="!hasFooterBottomContent"></div>
  </div>
</div>
