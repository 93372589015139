<h4 class="mb-2">{{ isEdit ? 'Update name' : 'Name your search' }}</h4>
<form>
  <div class="mb-3">
    <input #input class="p-inputtext p-component w-100" [formControl]="control" (focus)="input.select()" />
    <small class="hint-message">
      <span> Max character limit 40 </span>
      <span> {{ messageLength$ | async }}/40 </span>
    </small>
  </div>
  <div class="text-end">
    <button pButton type="submit" class="apply-filters p-button-sm" [label]="isEdit ? 'Update name' : 'Save search'" (click)="submitValue()"></button>
  </div>
</form>
