<div>
  <h4>Agent Terms</h4>

  <p class="mb-4">
    The following terms and conditions apply to all real estate agents attempting to bid or sell Properties on RealtyBid.com. Please take a moment to read this
    section thoroughly before upgrading to an Agent Account.
  </p>

  <p>
    <b>Additional User Terms Related to Buying or Selling a Property on RealtyBid as an Agent:</b>
  </p>

  <p>As a Listing Agent representing a Seller on RealtyBid, each user agrees to the following:</p>

  <p>
    1. During the timeframe the Property is on RealtyBid.com, I agree to place all offers I receive on the Site. No offer will be considered outside the
    RealtyBid process.
  </p>

  <div>
    <p>2. When bidding on a Property, I agree that:</p>
    <ol>
      <li>
        If I am a winning bidder on behalf of another buyer, I agree that if I do not get the RealtyBid fee addendum signed by the buyer, I will be responsible
        for payment of any internet transaction or buyer’s premium fee and
      </li>
      <li>
        I will only place a bid on a Property on behalf of a potential buyer with full authorization from the potential buyer and I understand that if my buyer
        is the winning buyer and fails to complete the contract execution process, I will not be entitled to a return of my Bid Deposit or Ohio Bid Deposit; and
      </li>
      <li>
        If accepting payment as a licensed real estate agent, I am licensed in the state where the Property is located and fully authorized to bid on the
        Property on RealtyBid.com
      </li>
    </ol>
  </div>

  <div>
    <p>3. When posting a Property on the Site for auction, I agree that:</p>
    <ol>
      <li>
        If I request to have the Property withdrawn from the Site prior to the full duration of the online auction, a withdrawal penalty could be assessed
        (unless prior approval has been granted by RealtyBid management);
      </li>
      <li>I will set a Reserve price equal to a fair value and minimum amount I would accept to sell the Property;</li>
      <li>
        a starting bid amount that is less than my Reserve price will be generated by the RealtyBid system and that this starting bid amount will not be
        determined by me;
      </li>
      <li>I agree to post as much useful information about the Property as possible, and I will provide any required disclosures for the Property;</li>
      <li>
        I agree that the Buyer Agent commission input into the RealtyBid system will be the same as that offered in the MLS, or if not, the MLS listing will be
        used to determine the commission paid to a Buyer Agent (RealtyBid encourages the payment of commission by Sellers to Buyer Agents placing bids for their
        clients on RealtyBid, as this practice draws more potential bidders for Properties offered on the Site);
      </li>
      <li>I agree that I will not artificially inflate or influence the bids on a particular Property;</li>
      <li>
        I agree that RealtyBid is a marketing vehicle to assist me in generating offer activity for a Property and that it does not represent me or any bidder
        as an agent;
      </li>
      <li>I agree that RealtyBid has given no guarantee as to its ability to obtain any price for a Property;</li>
      <li>
        I understand that I can monitor all my listings and all bidding activity through my account profile by clicking the link to the activity report; and
      </li>
      <li>
        I am licensed in the state where the property is located and fully authorized to represent the sale of the property as the listing agent and understand
        that It is recommended by RealtyBid that any Listing Agent posting a Property on RealtyBid should have a listing agreement addendum agreed upon and
        executed by the Property owner before placing a listing on RealtyBid (The addendum acknowledges that the Property owner wants the Property to be
        marketed on RealtyBid and helps protect the Listing Agent and RealtyBid.com from a Property owner attempting to increase the Reserve price after the
        auction bidding begins).
      </li>
    </ol>
  </div>
</div>
