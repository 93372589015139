import { environment } from '$env';
import { SearchStoreService, SettingsService, propertyInfoToLabel } from '$stores';
import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  InjectionToken,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Observable,
  ReplaySubject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  iif,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { HousesApiStoreService, SearchType } from '../../../routes/houses/shared';
import { Models } from '../../../shared/models';
import { GoogleApiService } from '../../../shared/services/google-api.service';
import { SessionStorageService, StorageKeys } from '../../../shared/services/session-storage.service';
import { ApiService } from '../../../shared/stores/api';
import { GeolocationService } from '../../../shared/stores/search/services/geolocation.service';
import { propertyToUrl } from '../../../shared/stores/search/utils/property-to-url.util';
import { listenForLargeScreen, listenForLargeScreenDown, listenForXXXLScreenUp, listenForXXXXLScreenUp } from '../../../shared/utils';
import { LocationSearchInputService } from '../location-search-input.service';

interface ListItem {
  value: string;
  label: string;
  url?: string;
  addressDescription?: string;
}

interface Options {
  minNumberOfCharactersToActivateSearch: number;
}

export const SearchOptionsToken = new InjectionToken<Options>('Options for search component');

export enum SearchRequestType {
  CURRENT_LOCATION = 'current location',
  SAVED_SEARCH = 'saved search',
  GOOGLE_RESULT = 'google result',
  DIRECT_MATCHES = 'direct matches',
  HISTORY = 'history',
  NO_SELECTION = 'no selection',
}

export interface Selection {
  type: SearchRequestType;
  payload?: {
    key: string;
    url?: string;
    description?: string;
    searchTerm?: string;
  };
  advanced?: SearchOptions;
}

type SearchOptions = 'itemID' | 'trusteeNumber' | 'trusteeNumber' | 'caseNumber' | 'parcelID' | 'default';
export interface AdvancedSearchOptions {
  advanced: SearchOptions;
}

const defaultOptions: Options = {
  minNumberOfCharactersToActivateSearch: 2,
};

@UntilDestroy()
@Component({
  selector: 'app-location-search-input',
  templateUrl: './location-search-input.component.html',
  styleUrls: ['./location-search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LocationSearchInputComponent implements OnChanges, OnInit {
  /**
   * ----------------------------------------------------
   * Input fields
   * ----------------------------------------------------
   */
  @Input() searchTerm: string = '';
  @Input() searchInputFieldPlaceholder: string = '';
  @Input() clearSearchOnSelection: boolean = false;
  @Input() showSearchButton: boolean = true;
  @Input() showCurrentUserGeolocation: boolean = true;
  @Input() showSavedSearches: boolean = true;
  @Input() showSearchHistory: boolean = true;
  @Input() disabled: boolean = false;
  @Input() showAdvancedSearchDropdown: boolean = false;
  @Input() advancedSearchMobileRoutingPath: string[] = [];
  @Input() enableSmallDropDownOnHighResolutionScreen = false;

  originalPlaceholder: string = '';

  /**
   * ----------------------------------------------------
   * Output fields
   * ----------------------------------------------------
   */
  @Output() selected = new EventEmitter<Selection>();

  /**
   * ----------------------------------------------------
   * Template reference variables and functionality
   * ----------------------------------------------------
   */

  // collection of all <li> tags elements. Helps to determine which
  // <li> element is active to be able to use keyboard and mouse navigation
  private readonly _liElements$ = new ReplaySubject<QueryList<ElementRef>>(1);
  private readonly liElements$ = this._liElements$.asObservable();
  @ViewChildren('li') set liElements(list: QueryList<ElementRef>) {
    this._liElements$.next(list);
  }

  get liElements(): QueryList<ElementRef> {
    let resultList: QueryList<ElementRef>;
    this.liElements$.pipe(take(1)).subscribe(list => {
      resultList = list;
    });
    return resultList!;
  }

  /**
   * ----------------------------------------------------
   * Variables
   * ----------------------------------------------------
   */
  public readonly searchControl = new FormControl('');
  public isDropdownVisible = false;
  public readonly largeScreenObserver$ = listenForLargeScreen();
  public readonly largeScreenDownObserver$ = listenForLargeScreenDown();
  public readonly xxxlScreenUpObserver$ = listenForXXXLScreenUp();
  public readonly xxxxlScreenUpObserver$ = listenForXXXXLScreenUp();
  // is used to send to the @Output variable data with
  // specified type.
  public readonly searchRequestType = SearchRequestType;

  private mostExpectedResultFromClosedDropdown?: Selection;
  // Initiall set firstIndex to 0 because if user denies to
  // share current location then we can take zero element
  // from search locations
  private firstIndex = 0;

  // Prevents user to submit a form (disables search button)
  // while api requests are processing
  private readonly _canSubmit$ = new ReplaySubject<boolean>(1);
  public readonly canSubmit$ = this._canSubmit$.asObservable().pipe(distinctUntilChanged());
  get canSubmit(): boolean {
    let result = false;
    this._canSubmit$.subscribe(canSearchBeSubmitted => {
      result = canSearchBeSubmitted;
    });
    return result;
  }

  // Observes user typing in the search bar.
  private readonly searchControlObserver$ = this.searchControl.valueChanges.pipe(
    debounceTime(300),
    map(term => term.trim()),
    shareReplay(1),
    untilDestroyed(this),
    tap(term => {
      localStorage.setItem('searchTerm', term);
    }),
  );

  private _dropdownActiveElementIdx = -1;

  showAdvancedSearchError: boolean = false;

  // This setter helps to add/remove HTML "active" class
  // on either hovered or keyboard navigated <li> element
  private set dropdownActiveElementIdx(newIndex: number) {
    if (!this.isDropdownVisible && this._dropdownActiveElementIdx !== -1) {
      this._dropdownActiveElementIdx = -1;
      return;
    }

    if (!this.isDropdownVisible) {
      return;
    }

    if (this._dropdownActiveElementIdx !== -1) {
      this.toggleActiveElement(this._dropdownActiveElementIdx, 'remove');
    }
    this._dropdownActiveElementIdx = newIndex;
    this.toggleActiveElement(newIndex, 'add');
  }

  private get dropdownActiveElementIdx() {
    return this._dropdownActiveElementIdx;
  }

  private toggleActiveElement(elementIdx: number, action: 'add' | 'remove'): void {
    const element = this.liElements.get(elementIdx);
    if (!element) {
      return;
    }
    if (action === 'add') {
      this.renderer.addClass(element?.nativeElement, 'active');
    } else if (action === 'remove') {
      this.renderer.removeClass(element?.nativeElement, 'active');
    }
  }

  advancedSearchOptions = this.locationSearchInputService.advancedSearchOptions;

  selectedAdvancedSearchOption: SearchOptions = 'default';
  public readonly selectedValueControl = this.locationSearchInputService.selectedValueControl;

  // This component can be set using Options object.
  // Currently the only option is minumum characters length
  // before google (and local) search can be activated
  private readonly options: Options;

  // Gets current user location from browser API and then
  // based on gotten coordinates (lat/lng) requests Google API
  // for an additional information
  public currentUserLocationGooglePlaceId$: Observable<ListItem | undefined> = this.geolocationService.currentUserLocationCoordinates$.pipe(
    switchMap((coordinates: GeolocationCoordinates) => {
      return this.googleApiService.getGoogleReverseGeocodeResults({
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      });
    }),
    map((response: google.maps.GeocoderResponse) => response.results),
    map((results: google.maps.GeocoderResult[]) => this.extractTheMostPromisingGeocoderResult(results)),
    map((bestResult: google.maps.GeocoderResult | undefined) => ({
      value: bestResult?.place_id || '1',
      label: 'Use Current Location',
      addressDescription: bestResult?.formatted_address.replace(/, USA$/, ''),
    })),
    tap(() => {
      // if user allows to share current location then set firstIndex as 1
      // because when user hits enter we should take first element from the
      // search locations restuls. By setting this variable to 1, we are
      // skipping "Use Current Location" option when submit search form
      this.firstIndex = 1;
      this.dropdownActiveElementIdx = -1;
    }),
    catchError(error => {
      this.firstIndex = 0;
      return of(undefined);
    }),
    shareReplay(1),
  );

  public googleResults$: Observable<ListItem[]> = this.searchControlObserver$.pipe(
    filter(() => this.isDefaultSearch()),
    tap(() => {
      this._canSubmit$.next(false);
    }),
    switchMap((term: string) => {
      return iif(
        () => term.length < this.options.minNumberOfCharactersToActivateSearch,
        of([]),
        this.googleApiService.getGoogleAutocompletePlaces(term).pipe(
          map((predictions: google.maps.places.AutocompletePrediction[]) => {
            return predictions.map(prediction => ({
              value: prediction.place_id,
              label: prediction.description.replace(/, USA$/, ''),
              addressDescription: prediction.description.replace(/, USA$/, ''),
            }));
          }),
        ),
      );
    }),
    tap(googleResults => {
      if (googleResults.length > 0) {
        const firstMatch = googleResults[0];
        this.mostExpectedResultFromClosedDropdown = this.buildSelectionPayload(
          SearchRequestType.GOOGLE_RESULT,
          firstMatch.value,
          undefined,
          firstMatch.addressDescription,
          this.searchControl.value,
        );
      }
      this.dropdownActiveElementIdx = -1;
      this._canSubmit$.next(true);
    }),
  );

  public searchHistory$: Observable<ListItem[]> = this.searchStoreService.searchHistory$;

  public savedSearches$: Observable<ListItem[]> = this.settingsService.user$.pipe(
    filter((loginResponse: Models.LoginResponse | null) => !!loginResponse),
    switchMap(() => this.globalApiService.savedSearches.selectAll$),
    filter(savedSearches => !!savedSearches),
    map(savedSearches =>
      savedSearches!.map(search => ({
        value: search.SAVEDSEARCHID.toString(),
        label: search.SEARCHNAME,
        url: search.SEARCHSTRING,
      })),
    ),
    shareReplay(1),
  );

  searchInputFieldPlaceholder$ = combineLatest([
    this.selectedValueControl.valueChanges.pipe(startWith(this.selectedValueControl.value)),
    this.largeScreenObserver$,
  ]).pipe(
    map(([selected, isLargeScreen]) => {
      if (this.isOnSignUp()) {
        selected = 'default';
      }
      return this.onAdvancedSearchDropdownChange(selected, isLargeScreen);
    }),
  );

  private readonly locationStorage = this.sessionStorageService.getStorage<string>(StorageKeys.searchSurroundingLocation);

  constructor(
    private readonly geolocationService: GeolocationService,
    private readonly googleApiService: GoogleApiService,
    private readonly globalApiService: ApiService,
    private readonly searchStoreService: SearchStoreService,
    private readonly settingsService: SettingsService,
    private readonly renderer: Renderer2,
    private readonly http: HttpClient,
    private readonly locationSearchInputService: LocationSearchInputService,
    private readonly housesApiStoreService: HousesApiStoreService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly location: Location,
    @Optional() @Inject(SearchOptionsToken) private injectedOptions: Options,
  ) {
    this.options = this.injectedOptions ?? defaultOptions;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['searchTerm']) {
      this.searchControl.patchValue(this.searchTerm);
    }
  }

  ngOnInit() {
    this._canSubmit$.next(true);
  }

  get totalNumberOfListElements() {
    return this.liElements?.length || 0;
  }

  public inputFieldStopCursorShiftHandler(event: KeyboardEvent) {
    if (['ArrowDown', 'ArrowUp'].includes(event.code)) {
      event.preventDefault();
    }
  }

  public inputFieldClickHandler(event: MouseEvent) {
    const hasAdvancedSearch: boolean = this.selectedAdvancedSearchOption !== 'default';
    if (hasAdvancedSearch) {
      this.isDropdownVisible = false;
      return;
    }

    if (!this.isDropdownVisible && event.target instanceof HTMLInputElement) {
      this.isDropdownVisible = true;
    }
  }

  public inputFieldFocusHandler(event: FocusEvent) {
    const hasAdvancedSearch: boolean = this.selectedAdvancedSearchOption !== 'default';
    if (hasAdvancedSearch) {
      this.isDropdownVisible = false;
      return;
    }

    this.isDropdownVisible = true;
    if (!this.searchControl.value || this.searchControl.value.length < this.options.minNumberOfCharactersToActivateSearch) {
      return;
    }
    this.searchControl.updateValueAndValidity();
  }

  public clickOutsideHandler() {
    this.isDropdownVisible = false;
    this.dropdownActiveElementIdx = -1;
  }

  public dropdownMouseoverHandler(event: Event) {
    if (!(event.target instanceof HTMLLIElement)) {
      throw new Error('Target element has to be HTMLLIElement instance');
    }
    const hoveredKey = event.target.dataset['key'];
    const hoveredType = event.target.dataset['type'] as SearchRequestType;

    if (hoveredKey === undefined || hoveredType === undefined) {
      const hasAdvancedSearch: boolean = this.selectedAdvancedSearchOption !== 'default';
      if (!hasAdvancedSearch) {
        throw new Error('dataset "key" or "type" variable must be set on <li> element');
      }
      return;
    }

    this.dropdownActiveElementIdx = this.findIndexOfHoveredElement(hoveredKey, hoveredType);
  }

  public dropdownKeyboardNavigationHandler(event: KeyboardEvent) {
    const hasAdvancedSearch: boolean = this.selectedAdvancedSearchOption !== 'default';
    if (hasAdvancedSearch) {
      return;
    }

    if (!this.isDropdownVisible) {
      return;
    }
    switch (event.code) {
      case 'ArrowDown':
        // If bottom has been reached then go to the top
        this.dropdownActiveElementIdx = (this.dropdownActiveElementIdx + 1) % this.totalNumberOfListElements;
        break;
      case 'ArrowUp':
        // If top has been reached then go to the bottom
        this.dropdownActiveElementIdx = this.dropdownActiveElementIdx <= 0 ? this.totalNumberOfListElements - 1 : this.dropdownActiveElementIdx - 1;
        break;
      default:
        break;
    }
  }

  onSubmit() {
    if (!this.canSubmit) {
      return;
    }

    const term = this.searchControl.value;
    const hasAdvancedSearch: boolean = this.selectedAdvancedSearchOption !== 'default';

    // No element either hovered or navigated using keyboard
    if (this.dropdownActiveElementIdx === -1) {
      if (term.length < this.options.minNumberOfCharactersToActivateSearch) {
        this.emitSelected({ type: SearchRequestType.NO_SELECTION });
        this.isDropdownVisible = false;
        return;
      }

      if (hasAdvancedSearch) {
        // Get the listing from the lookup endpoint
        // this.globalApiService
        //   .advancedSearch$(term)

        const params = new HttpParams({
          fromObject: {
            [this.selectedValueControl.value]: term,
          },
        });

        this.http
          .get<Models.PropertySearchResponse[]>(environment.endpoints.apiUrl + `public/search/listings`, { params })
          .pipe(
            tap(() => {
              this._canSubmit$.next(false);
            }),
            map((lookupDataProperties: Models.PropertySearchResponse[]) => {
              const checkResults = lookupDataProperties;
              if (checkResults.hasOwnProperty('message')) {
                this._canSubmit$.next(true);
                return [];
              }
              return lookupDataProperties.map((lookupData: Models.PropertySearchResponse) => ({
                value: term,
                label: propertyInfoToLabel(lookupData),
                url: propertyToUrl(lookupData)!,
              }));
            }),
          )
          .subscribe(data => {
            if (data.length === 0) {
              this.showAdvancedSearchError = true;
              this._canSubmit$.next(true);
              this.isDropdownVisible = true;
              return;
            }
            const payload = {
              type: SearchRequestType.DIRECT_MATCHES,
              payload: { key: term, url: data[0].url },
              advanced: this.selectedAdvancedSearchOption,
            };
            this.emitSelected(payload);
          });

        return;
      }

      // If user clicks on magnifying glass button right away without focusing
      // on <input> element and <input> element has something more than 2
      // characters length, then take first result like we do by calling
      // emitElementWithIndex(this.firstIndex)
      if (!this.isDropdownVisible && !!this.mostExpectedResultFromClosedDropdown) {
        this.emitSelected(this.mostExpectedResultFromClosedDropdown);
        return;
      }

      // Get element number "1" because in the dropdown list it will
      // be with the highest priority (not "0" index because it will be
      // current location of the user).
      // For example if user has both Direct Matches and Search Locations,
      // Direct Matches is always go first therefore index number "1"
      // returns what expected
      this.emitElementWithIndex(this.firstIndex);
      return;
    }
    // If user hovered over (or navigated using keyboard) dropdown list element
    // then on hit "enter", functionality chooses currently highlighted element.
    this.emitElementWithIndex(this.dropdownActiveElementIdx);
    return;
  }

  public onDropdownListElementClick(value?: string) {
    if (value) {
      this.locationStorage.saveData(value);
    }
    this.emitElementWithIndex(this.dropdownActiveElementIdx);
  }

  // Utils
  private findIndexOfHoveredElement(key: string, type: SearchRequestType) {
    if (!this.liElements) {
      throw new Error('li elements are not available');
    }
    let targetIndex = -1;

    this.liElements.find((elementRef: ElementRef, index: number) => {
      const element = elementRef.nativeElement as HTMLLIElement;
      const elementKey = element.getAttribute('data-key');
      const elementType = element.getAttribute('data-type');
      const isFound = elementKey === key && elementType === type;
      if (isFound) {
        targetIndex = index;
      }
      return isFound;
    });

    return targetIndex;
  }

  private extractTheMostPromisingGeocoderResult(results: google.maps.GeocoderResult[]): google.maps.GeocoderResult | undefined {
    return (
      results.find(r => r.types.includes('locality')) ||
      results.find(r => r.types.includes('postal_code')) ||
      results.find(r => r.types.includes('administrative_area_level_2')) ||
      results.find(r => r.types.includes('administrative_area_level_1')) ||
      // if no precise places were found above, then search
      // for the country
      results.find(r => r.types.includes('country'))
    );
  }

  private emitElementWithIndex(index: number, hasAdvancedSearch?: boolean) {
    const elementDataset = this.liElements.get(index)?.nativeElement.dataset;
    const elementKey = elementDataset['key'] as string;
    const elementType = elementDataset['type'] as SearchRequestType;
    const elementUrl = elementDataset['url'] as string;
    const searchTerm = this.searchControl.value;
    const elementAddressDescription = elementDataset['description'] as string;

    this.emitSelected(this.buildSelectionPayload(elementType, elementKey, elementUrl, elementAddressDescription, searchTerm, hasAdvancedSearch));
    if (this.clearSearchOnSelection) {
      this.searchControl.reset('');
    }
    this.isDropdownVisible = false;
    this.dropdownActiveElementIdx = -1;
  }

  private buildSelectionPayload(
    type: SearchRequestType,
    key: string,
    url?: string,
    description?: string,
    searchTerm?: string,
    hasAdvancedSearch?: boolean,
  ): Selection {
    let result: Selection = {
      type,
      payload: {
        key,
      },
    };

    if (hasAdvancedSearch) {
      result.advanced = this.selectedAdvancedSearchOption;
    }

    if (!!url) {
      result.payload = { ...result.payload!, url };
    }

    if (!!description) {
      result.payload = { ...result.payload!, description };
    }

    if (!!searchTerm) {
      result.payload = { ...result.payload!, searchTerm };
    }

    return result;
  }

  onAdvancedSearchDropdownChange(value: any, isDesktop: boolean) {
    this.selectedAdvancedSearchOption = value;

    let placeholderTemp = '';

    switch (value) {
      case 'default':
        placeholderTemp = this.searchInputFieldPlaceholder;
        this.isDropdownVisible = true;
        break;
      case 'itemID':
        placeholderTemp = `Property Item ID`;
        break;
      case 'trusteeNumber':
        placeholderTemp = `Property Trustee #`;
        break;
      case 'caseNumber':
        placeholderTemp = `Property Case #`;
        break;
      case 'parcelID':
        placeholderTemp = `Property Parcel #`;
        break;
    }
    this.isDropdownVisible = false;
    const desktopPrefix = isDesktop ? 'Enter a ' : '';
    return `${desktopPrefix}${placeholderTemp}`;
  }

  resetPlaceholder() {
    this.selectedValueControl.setValue('default');
  }

  private isDefaultSearch(): boolean {
    return this.selectedAdvancedSearchOption === 'default';
  }

  private emitSelected(selection: Selection) {
    const description = selection.payload?.description;
    if (description) {
      this.locationStorage.saveData(description);
    }
    this.housesApiStoreService.stopSurroundedArea(true);
    this.housesApiStoreService.setSearchType(SearchType.location);
    this.selected.emit(selection);
  }

  private isOnSignUp() {
    return this.location.path().includes('/sign-up') ? true : false;
  }
}
