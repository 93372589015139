import { Injectable } from '@angular/core';
import { StorageI, storageFactory } from '../utils/storage.utils';

export enum StorageKeys {
  // if user on property details page and there are Next/Prev buttons
  // and user clicks browser's refresh button, data of the search results
  // will be taken from the session storage. This is the purpose of this
  // particular storage
  searchResults = 'searchResults',
  searchPageLocation = 'searchPageLocation',
  searchSurroundingLocation = 'searchPageLocation',
}

const storagesKeys = [StorageKeys.searchResults, StorageKeys.searchPageLocation];

@Injectable({
  providedIn: 'root',
})
export class SessionStorageService {
  private readonly storages = new Map<StorageKeys, any>();
  constructor() {}

  init() {
    for (const storageKey of storagesKeys) {
      this.storages.set(storageKey, storageFactory('sessionStorage', storageKey));
    }
  }

  getStorage<T>(storageKey: StorageKeys): StorageI<T> {
    return this.storages.get(storageKey);
  }
}
