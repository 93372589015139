import { Models } from '$models';

export function getAuctionsToShowOnThePage(auctions: Models.PropertySearchResponse[], page: number, numberOfResultsToDisplay: number) {
  if (auctions.length <= numberOfResultsToDisplay && page && page > 1) {
    page = 1;
  }
  const startIndex = Math.max(page - 1, 0) * numberOfResultsToDisplay;
  return auctions.slice(startIndex, startIndex + numberOfResultsToDisplay);
}

export function getPaginationDataParameters(
  page: number,
  auctionsPerPage: number,
  total: number,
): {
  startItemIndex: number;
  endItemIndex: number;
  hasPrev: boolean;
  hasNext: boolean;
  numberOfAuctionsOnPage: number;
} {
  const expectedPageAuctionsNumber = page * auctionsPerPage;

  // Variables to be returned:
  const numberOfAuctionsOnPage = total < expectedPageAuctionsNumber ? auctionsPerPage - (expectedPageAuctionsNumber - total) : auctionsPerPage;
  const startItemIndex = Math.min(auctionsPerPage * (page - 1) + 1, total);
  const endItemIndex = Math.min(startItemIndex + auctionsPerPage - 1, total);
  const hasPrev = page > 1;
  const hasNext = page < Math.ceil(total / auctionsPerPage);

  return { startItemIndex, endItemIndex, hasPrev, hasNext, numberOfAuctionsOnPage };
}

export function getLastPageNumber(total: number, itemsPerPage: number): number {
  return Math.ceil(total / itemsPerPage);
}
