<div class="wrapper">
  <div class="button-wrapper">
    <p-button styleClass="advancedsearch-button" icon="pi pi-chevron-down" (click)="toggleDropdown()"></p-button>
  </div>
  <div class="dropdown-wrapper">
    <p-dropdown
      [options]="options"
      styleClass="advancedsearch-dropdown"
      [showClear]="false"
      [showTransitionOptions]="'100ms'"
      [hideTransitionOptions]="'50ms'"
      [formControl]="localFormControl"></p-dropdown>
  </div>
</div>
