<div class="bidding-result d-flex flex-column">

  <ng-container *ngIf="(result$ | async) === biddingResult.success; else bidFailedHeader">
    <div>
      <div class="result-message d-flex justify-content-center">
        <h1 class="success">{{ message$ | async }}</h1>
      </div>
      <div class="result-icon d-flex justify-content-center align-items-center">
        <fa-icon icon="check-circle" class="success"></fa-icon>
      </div>
    </div>
  </ng-container>

  <div class="bidding-summary pt-2">
    <app-bid-summary 
      [property]="property$ | async" 
      [bidAmount]="(bidData$ | async)?.BID_AMT" 
      [bidAssistAmount]="(bidData$ | async)?.BID_ASSIST_AMT"
    ></app-bid-summary>
  </div>
</div>

<ng-template #bidFailedHeader>
  <div class="result-message error d-flex flex-column align-items-center">
    <h3 class="text-center failed">Unsuccessful Bid</h3>
    <p class="text-center truncated-to-3-lines failed mt-3" title="{{ (message$ | async) }}">{{ message$ | async }}</p>
  </div>
</ng-template>
