<div class="row mt-3">
  <h2>{{ license.STATE_NAME }}</h2>
  <div class="col-12 col-lg-4" *ngIf="license.BROKER_CONTACT_NAME">
    <h3 class="h5"><strong>Broker</strong></h3>
    <p>
      {{ license.BROKER_CONTACT_NAME }}<br />
      License #: {{ license.BROKER_LICENSE_NUMBER }}
    </p>
    <p>
      {{ license.BROKER_COMPANY_NAME }}<br />
      License #: {{ license.BROKER_COMPANY_LICENSE_NUMBER }}
    </p>
  </div>
  <p-divider
    *ngIf="license.BROKER_CONTACT_NAME && (license.AUCTIONEER_NAME || license.AUCTION_COMPANY_NAME)"
    layout="vertical"
    class="col g-0 flex-grow-0 d-none d-xxl-flex"></p-divider>
  <div class="col-12 col-lg-4" *ngIf="license.AUCTIONEER_NAME || license.AUCTION_COMPANY_NAME">
    <h3 class="h5"><strong>Auctioneer</strong></h3>
    <p *ngIf="license.AUCTIONEER_NAME">
      {{ license.AUCTIONEER_NAME }}<br />
      License #: {{ license.AUCTIONEER_LICENSE_NUMBER }}
    </p>
    <p *ngIf="license.AUCTION_COMPANY_NAME">
      {{ license.AUCTION_COMPANY_NAME }}<br />
      License #: {{ license.AUCTION_COMPANY_LICENSE_NUMBER }}
    </p>
  </div>
  <p-divider
    *ngIf="
      (license.AUCTIONEER_NAME || license.BROKER_CONTACT_NAME) &&
      license.PRIVATE_SELLING_OFFICER_NAME &&
      license.PRIVATE_SELLING_OFFICER_AUCTIONEER_LICENSE_NUMBER
    "
    layout="vertical"
    class="col g-0 flex-grow-0 d-none d-xxl-flex"></p-divider>
  <div class="col-12 col-lg-3" *ngIf="license.PRIVATE_SELLING_OFFICER_NAME && license.PRIVATE_SELLING_OFFICER_AUCTIONEER_LICENSE_NUMBER">
    <h3 class="h5"><strong>Private Selling Officer</strong></h3>
    <p class="mb-0">{{ license.PRIVATE_SELLING_OFFICER_NAME }}</p>
    <p *ngIf="license.PRIVATE_SELLING_OFFICER_CITY && license.PRIVATE_SELLING_OFFICER_STATE">
      {{ license.PRIVATE_SELLING_OFFICER_CITY }}, {{ license.PRIVATE_SELLING_OFFICER_STATE }}
    </p>
    <p class="mb-0"><span>Auctioneer License #: </span>{{ license.PRIVATE_SELLING_OFFICER_AUCTIONEER_LICENSE_NUMBER }}</p>
    <p class="mb-0"><span>Salesperson License #: </span>{{ license.PRIVATE_SELLING_OFFICER_BROKER_LICENSE_NUMBER }}</p>
  </div>
</div>
