import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { AuthService, DomService } from '$services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { matchValue, phoneUSValidator } from '../../shared/validators';
import { redirectToReturnUrlParam } from '../../shared/utils';
import { InputErrorShowCondition } from '../input-error/input-error.component';
import { BehaviorSubject, debounceTime } from 'rxjs';
import { Models } from '$models';
import { Selection } from '../property-search-bar/location-search-input/location-search-input.component';

@UntilDestroy()
@Component({
  selector: 'app-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styleUrls: ['./sign-up-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpFormComponent implements OnInit {
  @Output() complete = new EventEmitter<string>();
  constructor(
    public dialogService: DialogService,
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dom: DomService,
  ) {}

  private readonly showErrorsOnSubmit = true;
  public errorCondition: InputErrorShowCondition = {
    touch: !this.showErrorsOnSubmit,
    submit: this.showErrorsOnSubmit,
  };
  public form = this.fb.group({
    FIRST_NAME: [null, [Validators.required]],
    LAST_NAME: [null, [Validators.required]],
    EMAIL_ADDR: [null, [Validators.required, Validators.pattern(Models.ValidationPatterns.PASSWORD)]],
    CELL_PHONE: [null, [Validators.required, phoneUSValidator]],
    PASSWORD_CODE: [null, [Validators.required]],
    PASSWORD_CODE_CONFIRM: [null, [Validators.required, matchValue('PASSWORD_CODE')]],
    COMMENTS: [[]],
    SEND_MARKETING_EMAILS: [true],
    RECEIVE_TEXTS: [true],
    AGREE_TO_TOC_AND_PP: [false, [Validators.requiredTrue]],
  });

  public MAX_GEOGRAPHIC_INTERESTS = 5;
  public geoInterests: { [key: string]: string } = {};
  public hasMaxGeographicInterests = false;

  public get geographicInterests() {
    return this.form.controls['COMMENTS'].value as string[];
  }

  public waiting$ = new BehaviorSubject(false);
  public errorApi$ = new BehaviorSubject<string | null>(null);

  public ngOnInit() {
    this.updateConfirmPasswordValidationOnPasswordControlChange(this.form.get('PASSWORD_CODE')!, this.form.get('PASSWORD_CODE_CONFIRM')!);
    this.setAutocompleteOff();
  }

  /** Prevents autofill  */
  focus(e: FocusEvent) {
    (e.target as HTMLInputElement)?.removeAttribute('readonly');
  }

  /**
   * Submit the form
   */
  public signUp() {
    this.form.patchValue(this.form.value);
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.waiting$.next(true);
    this.errorApi$.next(null);

    const values = this.form.value as Models.BidderRequest;
    values.CELL_PHONE = values?.CELL_PHONE?.replace(/\D/g, '');
    values.SEND_MARKETING_EMAILS = values.SEND_MARKETING_EMAILS === true || values.SEND_MARKETING_EMAILS === 'Y' ? 'Y' : 'N';
    values.RECEIVE_TEXTS = values.RECEIVE_TEXTS === true || values.RECEIVE_TEXTS === 'Y' ? 'Y' : 'N';
    this.authService.signUp(values).subscribe({
      next: () => {
        this.complete.emit(values.EMAIL_ADDR);

        // Save Geographic Interests to localStorage
        this.dom.localStorage?.setItem(Models.LocalStorageKeys.GeoInterest, JSON.stringify(this.geoInterests));
        // Save marketing email flag to localStorage until geograpic interests are added as saved searches on sign up
        this.dom.localStorage?.setItem(Models.LocalStorageKeys.MarketingEmail, values.SEND_MARKETING_EMAILS == 'Y' ? 'Y' : 'N');
        this.dom.localStorage?.setItem(Models.LocalStorageKeys.TextMessages, values.RECEIVE_TEXTS == 'Y' ? 'Y' : 'N');

        this.waiting$.next(false);
        this.form.reset();
      },
      error: () => {
        this.dom.window?.scrollTo(0, 0);
        this.errorApi$.next(
          'This email address is already in use as part of an existing account within the RealtyBid system. Please Login or reset your password if you believe you are already registered with this email address.',
        );
        this.waiting$.next(false);
      },
    });
  }

  maybeLater() {
    this.redirectToReturnUrl();
  }

  public onSelected(event: Selection) {
    this.addGeographicInterest(event.payload?.description!, event.payload?.key);
  }

  private addGeographicInterest(name: string, placeId: string = '') {
    if (this.hasMaxGeographicInterests) {
      return;
    }

    const interests = this.geographicInterests;
    if (interests.includes(name)) {
      return;
    }
    interests.push(name);
    this.geoInterests[placeId] = name; // Add to regions object
    this.form?.controls['COMMENTS']?.setValue(interests);
    this.updateHasMaxGeographicInterests();
  }
  private redirectToReturnUrl() {
    redirectToReturnUrlParam(this.route, this.router);
  }

  public removeGeographicInterest(index: number) {
    const interests = this.geographicInterests;
    interests.splice(index, 1);
    const placeIds = Object.keys(this.geoInterests);
    delete this.geoInterests[placeIds[index]];
    this.form.controls['COMMENTS'].setValue(interests);
    this.updateHasMaxGeographicInterests();
  }

  private updateHasMaxGeographicInterests() {
    this.hasMaxGeographicInterests = this.geographicInterests.length >= this.MAX_GEOGRAPHIC_INTERESTS;
  }

  private updateConfirmPasswordValidationOnPasswordControlChange(passwordControl: AbstractControl, confirmPasswordControl: AbstractControl): void {
    if (passwordControl) {
      passwordControl.valueChanges.pipe(debounceTime(150), untilDestroyed(this)).subscribe(password => {
        if (confirmPasswordControl.value === password) {
          confirmPasswordControl.updateValueAndValidity();
        }
      });
    }
  }

  private setAutocompleteOff() {
    document.getElementsByName('password').forEach(element => {
      const input = element.querySelector('input');
      if (input) {
        input.setAttribute('autocomplete', 'off');
      }
    });
  }
}
