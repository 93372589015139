import { Input, Output, EventEmitter, HostBinding, Directive, Type, ComponentRef } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalWizardConstants } from '../models/modal-wizard.constants';
import { ModalWizardModels } from '../models/modal-wizard.model';

@Directive()
export abstract class ModalWizardStep {

    @Input() stepId: string = 'some-step';
    @Input() title?: string;
    @Input() description?: string;
    @Input() state?: ModalWizardModels.StepState;
    @Input() status?: ModalWizardModels.StepStatus;
    @Input() parentStep?: ModalWizardStep;
    @Input() parentStepId?: string;
    @Input() component?: Type<any>;
    @Input() componentInputs?: ModalWizardModels.StepComponentInputs;
    @Input() componentOutputs?: ModalWizardModels.StepComponentOutputSubscribers;
    @Input() showHeader?: boolean = true;
    @Input() showNext?: boolean | (() => boolean) = true;
    @Input() showPrev?: boolean | (() => boolean) = true;
    @Input() prevText?: string | (() => string) = ModalWizardConstants.PREV_TEXT;
    @Input() nextText?: string | (() => string) = ModalWizardConstants.NEXT_TEXT;
    @Input() completeText?: string | (() => string) = ModalWizardConstants.COMPLETE_TEXT;
    @Input() footerTopContent?: string;
    @Input() footerBottomContent?: string;
    @Input() isFooterStickedToBottom?: boolean = true;
    @Input() canEnter: boolean | ((args: ModalWizardModels.StepValidationArgs) => boolean) | ((args: ModalWizardModels.StepValidationArgs) => Observable<boolean>) | undefined = true;
    @Input() canExit: boolean | ((args: ModalWizardModels.StepValidationArgs) => boolean) | ((args: ModalWizardModels.StepValidationArgs) => Observable<boolean>) | undefined = true;

    @Output() onComplete: EventEmitter<any> = new EventEmitter<any>();

    index: number = 0;
    initialState?: ModalWizardModels.StepState;
    componentRef: ComponentRef<any> | null = null;
    initialStatus?: ModalWizardModels.StepStatus;

    @HostBinding('hidden')
    get hidden(): boolean {
      return this.status != ModalWizardModels.StepStatus.active || this.state == ModalWizardModels.StepState.hidden;
    }

    get isActive(): boolean {
      return this.status == ModalWizardModels.StepStatus.active;
    }

    get isHidden(): boolean {
      return this.state == ModalWizardModels.StepState.hidden;
    }
  
    get isDisabled(): boolean {
      return this.state == ModalWizardModels.StepState.disabled;
    }
}