import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-property-input-details',
  templateUrl: './property-input-details.component.html',
  styleUrls: ['./property-input-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PropertyInputDetailsComponent {
  @Input() label!: string;
  @Input() errorMessage!: string;
  @Input() control!: FormControl;
  @Input() inputType: 'text' | 'number' = 'number';
  @Input() pattern!: string;
  @Output() inputChange = new EventEmitter<any>();

  onChange(event: any) {
    this.inputChange.emit(event);
  }

  patternInput() {
    if (this.pattern) {
      const regex = new RegExp(`[${this.pattern}]`, 'g');
      this.control.setValue(this.control.value.replace(regex, ''));
    }
  }
}
