import { Component, ComponentFactoryResolver, forwardRef, OnInit, OnDestroy, ViewChild, ChangeDetectionStrategy } from '@angular/core';
import { Subscription } from 'rxjs';
import { merge, forEach, has } from 'lodash';
import { ModalWizardStep } from '../../shared/directives/modal-wizard-step.directive';
import { ModalWizardModels } from '../../shared/models/modal-wizard.model';
import { ModalWizardStepContentDirective } from '../../shared/directives/modal-wizard-step-content.directive';

@Component({
  selector: 'app-modal-wizard-step',
  template: `
    <div>
      <ng-content></ng-content>
      <ng-template modalWizardStepContent></ng-template>
    </div>
  `,
  providers: [{ provide: ModalWizardStep, useExisting: forwardRef(() => ModalWizardStepComponent) }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModalWizardStepComponent extends ModalWizardStep implements OnInit, OnDestroy {
  @ViewChild(ModalWizardStepContentDirective, { static: true })
  stepContent: ModalWizardStepContentDirective | null = null;

  private static subscribeToComponentOutputs(componentOutputs: ModalWizardModels.StepComponentOutputSubscribers, componentInstance: any): Subscription[] {
    const subscriptions = [] as Subscription[];
    forEach(componentOutputs, (outputCallback: (outputEvent: any) => void, key: string) => {
      const componentOutput = componentInstance[key];
      if (componentOutput) {
        subscriptions.push(componentOutput.subscribe({ next: outputCallback }));
      }
    });
    return subscriptions;
  }

  private readonly subscriptions = new Subscription();

  constructor() {
    super();
  }

  ngOnInit() {
    this.loadComponent();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  loadComponent() {
    if (!(this.component && this.stepContent)) {
      return;
    }
    const inputs = this.componentInputs;

    this.stepContent.viewContainerRef.clear();
    this.componentRef = this.stepContent.viewContainerRef.createComponent(this.component);

    if (inputs) {
      merge(this.componentRef.instance, inputs);
    }
    // Sync step Id with component instance if there aren't equal
    if (this.stepId && has(this.componentRef.instance, 'stepId') && this.stepId !== this.componentRef.instance.stepId) {
      merge(this.componentRef.instance, { stepId: this.stepId });
    }

    this.subscribeToComponentOutputs(this.componentRef.instance);
  }

  /**
   * Subscribe to component outputs and pass along the
   * event where the component configuration is defined.
   *
   * @private
   */
  private subscribeToComponentOutputs(componentInstance: any) {
    if (!this.componentOutputs) {
      return;
    }
    const subscriptions = ModalWizardStepComponent.subscribeToComponentOutputs(this.componentOutputs, componentInstance);
    subscriptions.forEach(subscription => this.subscriptions.add(subscription));
  }
}
