import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AuthService, AuthState } from '../services';
import { makeStateKey, TransferState } from '@angular/platform-browser';

export const isNode = typeof process !== 'undefined' && process.versions != null && process.versions.node != null;

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  /**
   * Append bearer token to auth settings
   * @param settings
   */
  constructor(private auth: AuthService, private transferState: TransferState) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Handle transfer state between node and the browser
    // If is a browser and the request is GET
    if (!isNode && req.method === 'GET') {
      // Create a state key using the url
      const key = makeStateKey(req.url);
      // Look for a response for that state key in the transfer service
      const response = this.transferState.get(key, null);
      // If found return it from the store instead of making an http call
      if (response) {
        return of(new HttpResponse({ body: response, status: 200 }));
      }
    }

    // Clone request, add headers
    let cloneReq: Observable<HttpEvent<any>>;
    if (req.headers.has('Authorization')) {
      cloneReq = next.handle(req.clone());
    } else {
      cloneReq = next.handle(req.clone({ withCredentials: true }));
    }
    // Return request, handle errors globally here
    return cloneReq.pipe(
      tap(event => {
        // If SSR is on and this is on the server/node and this is a successful get request,
        // store the result in transfer state to pass to the browser
        if (isNode && event instanceof HttpResponse && (event.status === 200 || event.status === 202)) {
          this.transferState.set(makeStateKey(req.url), event.body);
        }
        /**
        // API responses are malformed on auth failures
        if ('SUCCESS' in event && (event as any).body?.SUCCESS === false) {
          this.sessionEnd();
        }
         */
      }),
      catchError(error => {
        console.error(error);
        // If unauthorized or forbidden error, end session.
        // For others, allow UI to display corresponding errors.
        if (error.status === 401 || error.status === 403 /*|| error.status === 502 || error.status === 500*/) {
          this.sessionEnd();
        }
        const err = error.error || error;

        // API responses are malformed on auth failures
        if (
          // error?.body?.SUCCESS === false ||
          // error?.error?.SUCCESS === false ||
          error?.error?.MESSAGE === 'Signature verification failed: Token expired' ||
          error?.body?.MESSAGE === 'Signature verification failed: Token expired'
        ) {
          //  || error?.error?.SUCCESS === false
          this.sessionEnd();
        }
        // Catch and rethrow error
        return throwError(error);
      }),
    );
  }

  /**
   * End the user's session based on auth failure
   */
  private sessionEnd() {
    this.auth.logOut(AuthState.sessionExpired);
  }
}
