import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dropdown } from 'primeng/dropdown';
/**
 * Usage example:
 *
  <app-list-select
    [formControl]="test"
    [options]="[
      { label: 'Property Address', value: 'default' },
      { label: 'Property Item ID', value: 'itemID' },
      { label: 'Property Trustee #', value: 'trusteeNumber' },
      { label: 'Property Case #', value: 'caseNumber' },
      { label: 'Property Parcel #', value: 'parcelID' }
    ]"
    (clickOption)="handleClick($event)"
    ></app-list-select>
 */
@UntilDestroy()
@Component({
  selector: 'app-list-select',
  templateUrl: './list-select.component.html',
  styleUrls: ['./list-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ListSelectComponent),
      multi: true,
    },
  ],
})
export class ListSelectComponent implements OnInit, ControlValueAccessor {
  @Input() options!: Dropdown['options'];
  @Output() clickOption = new EventEmitter();

  onChange?: (value: any) => void;
  onTouched: () => void = () => {};
  readonly localFormControl = new FormControl('');

  ngOnInit() {
    if (!this.options) {
      throw new Error('Required input field "options" is not set');
    }

    this.localFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (!!this.onChange) {
        this.onChange(value);
      }
    });
  }

  writeValue(value: any) {
    this.localFormControl.setValue(value);
  }

  registerOnChange(onChange: (value: any) => void) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  selectOption(value: string) {
    this.writeValue(value);
    setTimeout(() => {
      this.clickOption.emit(true);
    }, 100);
  }
}
