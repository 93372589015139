import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppMobileHeaderDirective } from './app-mobile-header.directive';

export interface MobileHeaderLevel1TypeAccessor {
  headerTitle: string;
  backButtonNavigation: () => void;
}

@Component({
  selector: 'app-mobile-header-level-1',
  template: `
    <div class="wrapper">
      <p-button class="action-button" styleClass="mobile-header-level-1 p-button-text" icon="pi pi-chevron-left" (onClick)="click()"></p-button>
      <p class="h1 title">{{ title }}</p>
      <button
        [ngClass]="{
          hidden: !actionRight
        }"
        class="hidden action-button-right mobile-header-level-1"
        (click)="clickRight($event)"
      >
        <fa-icon *ngIf="iconRight" [icon]="iconRight"></fa-icon>
      </button>
    </div>
  `,
  styles: [
    `
      .wrapper {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        padding-block: 1.265625rem;
      }
      .hidden {
        opacity: 0;
        visibility: hidden;
      }
      .action-button,
      .action-button-right {
        min-width: 40px;
      }
      .action-button {
        grid-column: 1 / 2;
      }
      .title {
        grid-column: 2 / 3;
        margin: 0;
        width: 100%;
        text-align: center;
        font-size: 1.875rem;
        color: #1f2225;
        font-family: 'Inter', sans-serif;
        line-height: 38px;
        letter-spacing: -0.2px;
      }
      .action-button-right {
        grid-column: 3 / 4;
        justify-self: end;
        background: transparent;
        border: 0;
        margin-right: 0.5rem;
      }
      fa-icon {
        font-size: 18px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMobileHeaderLevel1Component extends AppMobileHeaderDirective {}
