import { environment } from '$env';
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { scriptLoad$ } from '@ntersol/utils';
import {
  BehaviorSubject,
  Observable,
  bindCallback,
  combineLatest,
  distinctUntilChanged,
  filter,
  from,
  map,
  of,
  shareReplay,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import { stateLabelValues } from '../models';
import { statesViewport } from '../models/states-viewport.model';
import { SearchStoreService } from '../stores';

@Injectable({
  providedIn: 'root',
})
export class GoogleApiService {
  private readonly minAutoCompleteChars = 2;
  private readonly country = 'us';

  private readonly renderer: Renderer2;

  viewPort$ = new BehaviorSubject<google.maps.LatLngBoundsLiteral | undefined>(undefined);

  public scriptsLoaded$ = scriptLoad$(['https://maps.googleapis.com/maps/api/js?key=' + environment.licenses.googleMapsKey + '&libraries=places']).pipe(
    shareReplay(1),
  ); // Ensure script has time to initialize before notifying subscribers

  /** After script load, initialize the google autocomplete service */
  public autoCompleteService$ = this.scriptsLoaded$.pipe(
    filter(x => x),
    map(() => new google.maps.places.AutocompleteService()),
    shareReplay(1),
  );

  /** After script load, initialize the google places service */
  private placesService$ = this.scriptsLoaded$.pipe(
    filter(x => x),
    map(() => new google.maps.places.PlacesService(this.renderer.createElement('div'))),
    shareReplay(1),
  );

  /** After script load, initialize the street view service */
  public streetViewService$ = this.scriptsLoaded$.pipe(
    filter(x => x),
    map(() => new google.maps.StreetViewService()),
    shareReplay(1),
  );

  /** After script load, initialize the geocode service */
  public geocodeService$ = this.scriptsLoaded$.pipe(
    filter(x => x),
    map(() => new google.maps.Geocoder()),
    shareReplay(1),
  );

  constructor(
    private readonly rendererFactory: RendererFactory2,

    private readonly searchStoreService: SearchStoreService,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  /**
   * Reverse geocode lookup, supply lat/long to get a google places result
   * @param location - coordinates in Lat/Lng
   * @returns
   */
  getGoogleReverseGeocodeResults(location: google.maps.LatLngLiteral): Observable<google.maps.GeocoderResponse> {
    return this.geocodeService$.pipe(
      switchMap((geocodeService: google.maps.Geocoder) => {
        return from(
          geocodeService.geocode({
            location,
            region: this.country,
          }),
        );
      }),
    );
  }

  /**
   * Return a list of google places results from a search string
   * @param term
   * @param minChars
   */
  getGoogleAutocompletePlaces(term: string): Observable<google.maps.places.AutocompletePrediction[]> {
    term = term.trim();
    if (term.length < this.minAutoCompleteChars) {
      return of([]);
    }
    return this.autoCompleteService$.pipe(
      switchMap((service: google.maps.places.AutocompleteService) => {
        let requestObject: google.maps.places.AutocompletionRequest = {
          input: term,
          componentRestrictions: { country: this.country },
        };

        if (term.length === 2) {
          const stateData = stateLabelValues.find(state => state.value.toUpperCase() === term.toUpperCase());
          if (stateData?.value === 'PR') {
            requestObject = { ...requestObject, input: stateData.label, componentRestrictions: { country: null } };
          } else if (stateData) {
            requestObject = { ...requestObject, input: stateData.label, types: ['administrative_area_level_1'] };
          }
        }
        return service.getPlacePredictions(requestObject);
      }),
      map((response: google.maps.places.AutocompleteResponse) => {
        return response.predictions;
      }),
      take(1),
    );
  }

  getGooglePlaceDetails(placeId: string): Observable<google.maps.places.PlaceResult> {
    const errorStatues = [
      google.maps.places.PlacesServiceStatus.INVALID_REQUEST,
      google.maps.places.PlacesServiceStatus.NOT_FOUND,
      google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT,
      google.maps.places.PlacesServiceStatus.REQUEST_DENIED,
      google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR,
      google.maps.places.PlacesServiceStatus.ZERO_RESULTS,
    ];

    return this.placesService$.pipe(
      switchMap((googlePlacesService: google.maps.places.PlacesService) => {
        return new Observable<google.maps.places.PlaceResult>(subscriber => {
          googlePlacesService.getDetails({ placeId }, (placeResult, responseStatus) => {
            if (errorStatues.includes(responseStatus)) {
              subscriber.error(responseStatus);
              return;
            }

            if (responseStatus === google.maps.places.PlacesServiceStatus.OK) {
              subscriber.next(placeResult!);
            }
          });
        });
      }),
    );
  }

  getViewportFromQuery(query: string, isState?: boolean | null) {
    const errorStatues = [
      google.maps.places.PlacesServiceStatus.INVALID_REQUEST,
      google.maps.places.PlacesServiceStatus.NOT_FOUND,
      google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT,
      google.maps.places.PlacesServiceStatus.REQUEST_DENIED,
      google.maps.places.PlacesServiceStatus.UNKNOWN_ERROR,
      google.maps.places.PlacesServiceStatus.ZERO_RESULTS,
    ];

    const placeId$ = this.searchStoreService.queryParams$.pipe(
      map(location => location?.search?.placeId),
      distinctUntilChanged(),
    );

    return combineLatest([this.placesService$, placeId$]).pipe(
      switchMap(([googlePlacesService, placeId]) => {
        const findPlaceFromQuery$ = bindCallback(googlePlacesService.findPlaceFromQuery);
        const getDetails$ = bindCallback(googlePlacesService.getDetails);

        let placesSearch$ = findPlaceFromQuery$({ fields: ['geometry.viewport'], query }).pipe(
          map(([placeResult, responseStatus]) => {
            const results = placeResult ? placeResult[0] : null;
            return { results, responseStatus };
          }),
        );

        if (placeId) {
          placesSearch$ = getDetails$({ placeId }).pipe(
            map(([placeResult, responseStatus]) => {
              const results = placeResult || null;
              return { results, responseStatus };
            }),
          );
        }

        return placesSearch$.pipe(
          map(({ results, responseStatus }) => {
            if (errorStatues.includes(responseStatus)) {
              throwError(() => responseStatus);
              return;
            }

            if (results) {
              return this.handleGoogleResults(responseStatus, results, isState, query);
            }
            return;
          }),
        );
      }),
    );
  }

  private handleGoogleResults(
    responseStatus: google.maps.places.PlacesServiceStatus,
    placeResult: google.maps.places.PlaceResult | null,
    isState: boolean | null | undefined,
    query: string,
  ) {
    if (responseStatus === google.maps.places.PlacesServiceStatus.OK && placeResult) {
      let viewPort = placeResult.geometry?.viewport?.toJSON();
      /**
       * Google maps api is not returning the correct coordinates to states.
       * We are setting the coordinates when we search spacifically for states.
       */
      if (isState) {
        for (const state of statesViewport) {
          if (query.toLowerCase() === state.name || query.toLowerCase() === state.abbreviation) {
            viewPort = state.viewPort;
          }
        }
      }
      this.viewPort$.next(viewPort);
      return viewPort;
    }
    return;
  }
}
