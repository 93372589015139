import { Component, Input, OnChanges, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { Models } from '$models';
import { PropertyDisclosuresService } from '$services';
import { BiddingService } from '../../shared/services/bidding.service';
import { TermsAgreeViewType } from 'src/app/components/terms-of-service-parts/agree-terms/agree-terms.component';

@Component({
  selector: 'app-bid-terms-of-service',
  templateUrl: './bid-terms-of-service.component.html',
  styleUrls: ['./bid-terms-of-service.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PropertyDisclosuresService]
})
export class BidTermsOfServiceComponent implements OnChanges {

  @Input() user: Models.LoginResponse | null = null;
  @Input() property: Models.PropertyDetailsResponse | null = null;

  TermsAgreeViewType = TermsAgreeViewType;
  disclosures$ = this.disclosures.disclosures$.asObservable();

  get isUserAgent(): boolean {
    return BiddingService.isUserAgent(this.user);
  }

  get isUserBidder(): boolean {
    return BiddingService.isUserBidder(this.user);
  }

  get isUserInvestor(): boolean {
    return BiddingService.isUserInvestor(this.user);
  } 

  get isOhio(): boolean {
    return BiddingService.isOhio(this.property);
  } 

  constructor(
    private disclosures: PropertyDisclosuresService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['property'] && this.property) {
      this.disclosures.fetchAll(this.property.ITEM_ID);
    }
  }
}
