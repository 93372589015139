import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '$env';
import { AuthService } from '../../../shared/services';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, filter, map, mergeMap, take } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from '../../../shared/stores/api';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Location } from '@angular/common';

@UntilDestroy()
@Component({
  selector: 'app-property-save-button',
  templateUrl: './property-save-button.component.html',
  styleUrls: ['./property-save-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PropertySaveButtonComponent implements OnInit, OnChanges {
  @Input() ITEM_ID?: number | null = null;

  public ITEM_ID$ = new BehaviorSubject<number | null>(null);

  // Saved search is a function of auth and checking if this prop is in the saved props list
  public saved$ = this.auth.isLoggedIn$.pipe(
    filter(x => !!x),
    mergeMap(() => combineLatest([this.apiGlobal.watched.selectAll$.pipe(filter(x => !!x)), this.ITEM_ID$.pipe(distinctUntilChanged())])),
    map(([saved, ITEM_ID]) => {
      return !!saved?.find(s => s.ITEM_ID === ITEM_ID);
    }), // See if watched id matches prop id
  );

  public loading$ = new BehaviorSubject(false);

  constructor(private http: HttpClient, private auth: AuthService, private router: Router, private apiGlobal: ApiService, private location: Location) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['ITEM_ID']) {
      this.ITEM_ID$.next(changes['ITEM_ID'].currentValue);
    }
  }

  /**
   * Add/delete saved search from endpoint
   * @param e
   */
  public updateStatus(e: MouseEvent) {
    e.preventDefault();
    e.stopPropagation();
    this.loading$.next(true);

    this.auth.isLoggedIn$.pipe(take(1)).subscribe(isLoggedIn => {
      if (!isLoggedIn) {
        this.router.navigate(['/login'], { queryParams: { currentUrl: this.location.path(), target: 'watchlist' } });
        this.loading$.next(false);
      } else {
        this.saved$.pipe(take(1)).subscribe(isSaved => {
          if (!isSaved) {
            this.http
              .post(environment.endpoints.apiUrl + '/user/current/property/watched/add', {
                ITEM_ID: this.ITEM_ID,
              })
              .pipe(mergeMap(() => this.apiGlobal.watched.refresh()))
              .subscribe(() => this.loading$.next(false));
          } else {
            this.apiGlobal
              .watchedPropertiesDelete(this.ITEM_ID || 0)
              .pipe(mergeMap(() => this.apiGlobal.watched.refresh()))
              .subscribe(() => this.loading$.next(false));
          }
        });
      }
    });
  }
}
