import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SearchModels } from '../../shared/stores';
import { unclearSegment } from '../../shared/stores/search/utils/property-to-url.util';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbsComponent {
  @Input() breadcrumbPrepend?: SearchModels.BreadCrumb | null = null;
  @Input() breadCrumbs?: SearchModels.BreadCrumb[] | null = null;

  unclear(segment: string) {
    return unclearSegment(segment);
  }
}
