import { Models } from '$models';
import { Directive } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs';
import { AuthService, DomService } from '../../../shared/services';

@UntilDestroy()
@Directive()
export abstract class BaseBuySellList {
  userType: string | null = '';

  userType$ = this.auth.userType$;

  constructor(protected auth: AuthService, protected dom: DomService) {
    this.userType$
      .pipe(
        tap(userType => (this.userType = userType)),
        untilDestroyed(this),
      )
      .subscribe();
  }

  isClient(): boolean {
    return this.userType === Models.UserType.Client;
  }

  isBidder(): boolean {
    return this.userType === Models.UserType.Bidder;
  }

  getUserTypeName(userType: Models.UserType | string | null) {
    return Models.USER_TYPE_NAME?.[userType as Models.UserType] ?? '';
  }
}
