<div class="bid-term-of-service">
  <app-user-terms></app-user-terms>
  <app-agree-terms [view]="isUserBidder && !isOhio ? TermsAgreeViewType.NORMAL : TermsAgreeViewType.INTRO"></app-agree-terms>

  <ng-container *ngIf="!isUserBidder || (isUserBidder && isOhio)">
    <ng-container *ngIf="!isUserBidder"> <br /><br /> </ng-container>
    <app-investor-terms *ngIf="isUserInvestor"></app-investor-terms>
    <app-agent-terms *ngIf="isUserAgent"></app-agent-terms>
    <app-agree-terms *ngIf="!isUserBidder" [isExtraCondition]="true" [view]="TermsAgreeViewType.ACTION"></app-agree-terms>
    <ng-container *ngIf="isOhio">
      <br /><br />
      <app-user-terms-ohio *ngIf="isOhio"></app-user-terms-ohio>
      <app-agree-terms [isExtraCondition]="true" [view]="TermsAgreeViewType.INTRO"></app-agree-terms>
      <app-agree-terms [view]="TermsAgreeViewType.ACTION"></app-agree-terms>
    </ng-container>
  </ng-container>

  <div class="separator"></div>

  <!-- PROPERTY DISCLOSURES -->
  <div class="disclosures-wrapper">
    <ng-container *ngIf="disclosures$ | async as disclosures">
      <h6 class="disclosures-title">Property Disclosures:</h6>
      <a target="_blank" *ngFor="let document of disclosures" class="d-block" [href]="document.DISCLOSUREPATH" rel="noreferrer noreopen">
        {{ document.DISCLOSURENAME }}
      </a>
    </ng-container>
  </div>
</div>
