<div class="stepper-container">
  <div class="stepper">
    <div>
      Number of Bedrooms<sup class="required">{{ requiredBedAsterisk }}</sup>
    </div>
    <div class="input-container">
      <p-inputNumber
        id="NumBeds"
        class="bed-bath-count"
        [step]="1"
        [showButtons]="true"
        [min]="0"
        [max]="20"
        placeholder="0"
        [formControl]="formControlBedCount"
        (onInput)="validateBedCount($event)"
        buttonLayout="horizontal"
        decrementButtonClass="p-button-danger"
        incrementButtonClass="p-button-success"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        spinnerMode="horizontal"></p-inputNumber>
      <div *ngIf="formControlBedCount.invalid && (formControlBedCount.dirty || formControlBedCount.touched)" class="required-error red mt-1 anim-fade-in small">
        Number of Bedrooms is required
      </div>
    </div>
  </div>

  <div class="stepper">
    <div>
      Number of Baths<sup class="required">{{ requiredBathAsterisk }}</sup>
    </div>
    <div class="input-container">
      <p-inputNumber
        id="NumBaths"
        class="bed-bath-count"
        [step]="1"
        [showButtons]="true"
        [min]="0"
        [max]="20"
        placeholder="0"
        [formControl]="formControlBathCount"
        (onInput)="validateBathCount($event)"
        buttonLayout="horizontal"
        decrementButtonClass="p-button-danger"
        incrementButtonClass="p-button-success"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        spinnerMode="horizontal"></p-inputNumber>
      <div
        *ngIf="formControlBathCount.invalid && (formControlBathCount.dirty || formControlBathCount.touched)"
        class="required-error red mt-1 anim-fade-in small">
        Number of Baths is required
      </div>
    </div>
  </div>

  <div class="stepper">
    <div>Number of Half Baths</div>
    <div class="input-container">
      <p-inputNumber
        class="bed-bath-count"
        [step]="1"
        [showButtons]="true"
        [min]="0"
        [max]="20"
        placeholder="0"
        [formControl]="formControlHalfBathCount"
        buttonLayout="horizontal"
        decrementButtonClass="p-button-danger"
        incrementButtonClass="p-button-success"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        spinnerMode="horizontal"></p-inputNumber>
    </div>
  </div>
</div>
