import { Type } from '@angular/core';
import { Observable } from 'rxjs';
import { ModalWizardComponent } from '../../modal-wizard.component';
import { ModalWizardStep } from '../directives/modal-wizard-step.directive';

export module ModalWizardModels {
  export enum StepState {
    normal = 'normal',
    disabled = 'disabled',
    error = 'error',
    hidden = 'hidden',
  }

  export enum StepStatus {
    untouched = 'untouched',
    done = 'done',
    active = 'active',
  }

  export enum StepDirection {
    forward = 'forward',
    backward = 'backward',
  }

  export interface WizardConfig {
    selected?: number; // Initial selected step, 0 = first step
  }

  export interface StepValidationArgs {
    direction?: StepDirection;
    fromStep?: ModalWizardStep;
    toStep?: ModalWizardStep;
    wizardRef: ModalWizardComponent;
  }

  export interface StepChangedArgs {
    step: ModalWizardStep;
    previousStep: ModalWizardStep;
    nextStep: ModalWizardStep;
    direction: StepDirection;
  }

  export interface StepDefinition {
    stepId: string;
    title?: string;
    description?: string;
    state?: StepState;
    status?: StepStatus;
    component: Type<any>;
    componentInputs?: ModalWizardModels.StepComponentInputs;
    componentOutputs?: ModalWizardModels.StepComponentOutputSubscribers;
    parentStep?: ModalWizardStep;
    parentStepId?: string;
    showHeader?: boolean;
    showNext?: boolean | (() => boolean);
    showPrev?: boolean | (() => boolean);
    prevText?: string | (() => string);
    nextText?: string | (() => string);
    nextDisable?: boolean | (() => boolean);
    completeText?: string | (() => string);
    footerTopContent?: string;
    footerBottomContent?: string;
    isFooterStickedToBottom?: boolean;
    canEnter?: boolean | ((args: StepValidationArgs) => boolean) | ((args: StepValidationArgs) => Observable<boolean>);
    canExit?: boolean | ((args: StepValidationArgs) => boolean) | ((args: StepValidationArgs) => Observable<boolean>);
  }

  export interface StepContent {
    stepId: string;
    canEnter?:
      | boolean
      | ((args: ModalWizardModels.StepValidationArgs) => boolean | unknown)
      | ((args: ModalWizardModels.StepValidationArgs) => Observable<boolean | unknown>)
      | unknown;
    canExit?:
      | boolean
      | ((args: ModalWizardModels.StepValidationArgs) => boolean | unknown)
      | ((args: ModalWizardModels.StepValidationArgs) => Observable<boolean | unknown>)
      | unknown;
  }

  export interface StepSearchResult {
    step: ModalWizardStep;
    stepIndex: number;
  }

  export interface StepComponentInputs {
    [inputName: string]: any;
  }

  export interface StepComponentOutputSubscribers {
    [outputName: string]: (outputEvent: any) => void;
  }
}
