import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AppMobileHeaderDirective } from './app-mobile-header.directive';

export interface MobileHeaderLevel2TypeAccessor {
  headerTitle: string;
  backButtonNavigation: () => void;
}

@Component({
  selector: 'app-mobile-header-level-2',
  template: `
    <div class="wrapper">
      <p-button class="action-button" styleClass="mobile-header-level-2 p-button-text" icon="pi pi-chevron-left" (onClick)="click()"></p-button>
      <p class="h5 title">{{ title }}</p>
      <button
        [ngClass]="{
          hidden: !actionRight
        }"
        class="hidden action-button-right mobile-header-level-2"
        (click)="clickRight($event)"
      >
        <fa-icon *ngIf="iconRight" [icon]="iconRight"></fa-icon>
      </button>
    </div>
  `,
  styles: [
    `
      .wrapper {
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        padding-block: 1.265625rem;
      }
      .hidden {
        opacity: 0;
        visibility: hidden;
      }
      .action-button,
      .action-button-right {
        min-width: 40px;
      }
      .action-button {
        grid-column: 1 / 2;
      }
      .title {
        grid-column: 2 / 3;
        margin: 0;
        width: 100%;
        text-align: center;
        font-size: 18px;
      }
      .action-button-right {
        grid-column: 3 / 4;
        justify-self: end;
        background: transparent;
        border: 0;
        margin-right: 0.5rem;
      }
      fa-icon {
        font-size: 18px;
      }
      ::ng-deep button fa-icon svg {
        width: 18px;
        height: 22px !important;
      }
      ::ng-deep .wrapper .mobile-header-level-2 .action-button-right .pi {
        font-size: 1.25rem;
      }
      ::ng-deep .wrapper .mobile-header-level-2 {
        color: #737578;
      }
      ::ng-deep .wrapper .mobile-header-level-2:focused,
      ::ng-deep .wrapper .mobile-header-level-2:hover {
        color: #737578;
      }
      ::ng-deep .wrapper .mobile-header-level-2.p-button.p-button-text:enabled:hover,
      ::ng-deep .wrapper .mobile-header-level-2.p-button.p-button-text:enabled:active {
        color: #737578;
        background: none;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppMobileHeaderLevel2Component extends AppMobileHeaderDirective {}
