import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, ContentChildren, QueryList } from '@angular/core';
import { PrimeTemplate } from 'primeng/api';
import { Carousel } from 'primeng/carousel';
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

@Component({
  selector: 'app-carousel',
  template: ` <p-carousel [value]="value" [showIndicators]="showIndicators" [responsiveOptions]="responsiveOptions">
    <!-- Pass through templates to p-carousel -->
    <ng-container *ngFor="let template of templates; let i = index">
      <ng-template [pTemplate]="template.name" let-item>
        <ng-template *ngTemplateOutlet="template.template; context: { $implicit: item }"></ng-template>
      </ng-template>
    </ng-container>
  </p-carousel>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarouselComponent implements OnInit {
  @Input() value: any[] = [];
  @Input() showIndicators = false;
  @Input() responsiveOptions: Carousel['responsiveOptions'] = [];

  @ViewChild(Carousel, { static: true }) carousel!: Carousel;
  @ContentChildren(PrimeTemplate) templates!: QueryList<any>;

  constructor() {}

  ngOnInit(): void {
    this.setupCustomTouchHandling();
  }

  ngOnDestroy() {
    clearAllBodyScrollLocks();
  }

  /**
   * Override touch events from Carousel to detect and handle scrolling better.
   * Try to detect if user is scrolling and either scroll or move the carousel but not both.
   * Adapted from: https://github.com/thebird/Swipe/blob/master/swipe.js
   * Note: Would not work with vertical carousel.
   */
  private setupCustomTouchHandling() {
    let isScrolling: boolean | null = null;
    const onTouchStart = this.carousel.onTouchStart.bind(this.carousel);
    this.carousel.onTouchStart = (e: TouchEvent) => {
      onTouchStart(e);
      isScrolling = null;
    };
    this.carousel.onTouchMove = e => {
      var touch = e.touches[0];

      // measure change in x and y
      const delta = {
        x: touch.pageX - this.carousel.startPos.x,
        y: touch.pageY - this.carousel.startPos.y,
      };

      // determine if scrolling test has run - one time test
      if (isScrolling == null) {
        isScrolling = !!(isScrolling || Math.abs(delta.x) < Math.abs(delta.y));
      }

      // if user is not trying to scroll vertically
      if (!isScrolling) {
        // prevent native scrolling
        e.preventDefault();
        // Not enough to cancel scroll event. Need to disable body scroll for iOS
        disableBodyScroll(this.carousel.el.nativeElement);
      }
    };

    const onTouchEnd = this.carousel.onTouchEnd.bind(this.carousel);
    this.carousel.onTouchEnd = e => {
      if (isScrolling) return;
      else enableBodyScroll(this.carousel.el.nativeElement);
      onTouchEnd(e);
    };
  }
}
