import { isBrowser } from '../../../utils';
import { SearchModels } from '../search.models';
import {
  deserializeCityName,
  deserializeCountyName,
  deserializeStateName,
  serializeCityName,
  serializeCountyName,
  serializeStateName,
} from './search-page-url-serializer.util';

export enum HousesSegments {
  STATE,
  COUNTY,
  CITY,
  ZIP,
  ADDRESS,
  PROPERTYID,
}

export function segmentsToLocation(segments: string[]): SearchModels.Location {
  let result = {} as SearchModels.Location;
  // removes "houses" segment
  segments.shift();
  for (let i = 0; i < segments.length; i++) {
    const segment = segments[i];
    if (!segment) {
      break;
    }
    switch (i) {
      case HousesSegments.STATE:
        result = { state: deserializeSegment(segment, i) };
        break;
      case HousesSegments.COUNTY:
        result = { ...result, county: deserializeSegment(segment, i) };
        break;
      case HousesSegments.CITY:
        result = { ...result, city: deserializeSegment(segment, i) };
        break;
      case HousesSegments.ZIP:
        result = { ...result, zip: segment };
        break;
      case HousesSegments.ADDRESS:
        result = { ...result, address: segment };
        break;
      case HousesSegments.PROPERTYID:
        result = { ...result, propertyId: segment };
        break;
    }
  }
  return result;
}

export function locationToUrlPath(location: SearchModels.Location): string {
  if (!location || Object.keys(location).length === 0) return '';
  let path = '';
  if (!location.hasOwnProperty('state')) return path;
  path += '/' + serializeStateName(location.state!);
  if (!location.hasOwnProperty('county')) return path;
  path += '/' + serializeCountyName(location.county!);
  if (!location.hasOwnProperty('city')) return path;
  path += '/' + serializeCityName(location.city!);
  return path;
}

export function locationToPathList(location: SearchModels.Location): string[] {
  if (!location || Object.keys(location).length === 0) return [];
  const pathList: string[] = [];
  if (!location.hasOwnProperty('state')) return pathList;
  pathList.push(serializeStateName(location.state!));
  if (!location.hasOwnProperty('county')) return pathList;
  pathList.push(serializeStateName(location.county!));
  if (!location.hasOwnProperty('city')) return pathList;
  pathList.push(serializeStateName(location.city!));
  return pathList;
}

export function deserializeSegment(urlSegment: string, index: number): string {
  switch (index) {
    case HousesSegments.STATE:
      return deserializeStateName(urlSegment).toUpperCase();
    case HousesSegments.COUNTY:
      return deserializeCountyName(urlSegment);
    case HousesSegments.CITY:
      return deserializeCityName(urlSegment);
    default:
      return urlSegment;
  }
}

export function serializeSegment(path: string, index: number): string {
  switch (index) {
    case HousesSegments.STATE:
      return serializeStateName(path);
    case HousesSegments.COUNTY:
      return serializeCountyName(path);
    case HousesSegments.CITY:
      return serializeCityName(path);
    default:
      return path;
  }
}

/**
 * Get the query params from the string
 * @param url
 * @returns
 */
export const queryParamsGet = (url?: string | null) => {
  if (!isBrowser || !url) {
    return {};
  }
  const path = url.includes('?') ? url.split('?')[1] : url;

  const params = new URLSearchParams(path);

  let result = {} as any;

  // Get arrays from params
  params.forEach((value, key) => {
    if (result[key]) {
      const current = result[key];
      if (Array.isArray(current)) {
        result[key] = current.concat(value);
      } else {
        result[key] = [current, value];
      }
    } else {
      result[key] = value;
    }
  });

  return result;
};
