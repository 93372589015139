/// <reference types="@types/gtag.js" />
import { environment } from '$env';
import { Injectable } from '@angular/core';
import { DomService } from './dom.service';

declare global {
  interface Window {
    dataLayer: any[];
    gtag: Gtag.Gtag;
  }
}

/**
 * Google Analytics Wrapper
 *
 * - Adds type safety and Node.js support
 * - Install definitions: `npm install --save-dev @types/gtag.js`
 */
@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  /** Google analytics account ID */
  public gaId: string | null = null;
  private loaded = false;

  constructor(private dom: DomService) {
    // this.load(environment.licenses.googleAnalyticsKey);
    this.loadGTM(environment.licenses.googleTagKey);
  }

  /**
   * Load and initialize google analytics dynamically. This can be useful when using different tracking scripts per environment
   *
   * The declaration in the index.html file is preferable
   * ```
   * <script>
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'X-XXXXXXXX');
    </script>
   * ```
   *
   * @param gaId
   */
  public load(gaId: string) {
    if (!window?.gtag) {
      window.dataLayer = window.dataLayer || [];
      window.gtag = () => window.dataLayer.push(arguments);

      const script = document.createElement('script');

      script.onload = () => {
        gtag = function () {
          window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
        window.gtag('config', gaId, { cookie_flags: 'SameSite=None;Secure' });
      };

      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${gaId}`;
      document.head.appendChild(script);
    }
  }

  public loadGTM(gtmId: string) {
    if (!window?.gtag) {
      window.dataLayer = window.dataLayer || [];
      window.gtag = () => {
        window.dataLayer.push(arguments);
      };
      const script = document.createElement('script');

      const scriptContent = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${gtmId}');`;
      script.innerText = scriptContent;
      script.onload = () => {
        gtag = function () {
          window.dataLayer.push(arguments);
        };
        window.gtag('js', new Date());
        window.gtag('config', gtmId, { cookie_flags: 'SameSite=None;Secure' });
      };
      document.head.appendChild(script);

      const noscript = document.createElement('noscript');
      const content = `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
      noscript.innerHTML = content;
      document.body.appendChild(noscript);
    }
  }

  /**
   * Pass data to google analytics
   * - https://developers.google.com/tag-platform/gtagjs/reference#event
   * - https://developers.google.com/analytics/devguides/collection/gtagjs/events
   *
   * @example
   * this.analytics.gtag('event', 'sign_up');
   *
   * @param args
   * @returns
   */
  public gtag(command: 'config', targetId: string, config?: Gtag.ControlParams | Gtag.EventParams | Gtag.ConfigParams | Gtag.CustomParams): void;
  public gtag(command: 'set', targetId: string, config: Gtag.CustomParams | boolean | string): void;
  public gtag(command: 'set', config: Gtag.CustomParams): void;
  public gtag(command: 'js', config: Date): void;
  public gtag(command: 'event', eventName: Gtag.EventNames | (string & {}), eventParams?: Gtag.ControlParams | Gtag.EventParams | Gtag.CustomParams): void;
  public gtag(command: 'get', targetId: string, fieldName: Gtag.FieldNames | string, callback?: (field: string | Gtag.CustomParams | undefined) => any): void;
  public gtag(command: 'consent', consentArg: Gtag.ConsentArg | string, consentParams: Gtag.ConsentParams): void;
  public gtag(arg: any, arg2: any, arg3?: any) {
    this.dom?.window?.gtag(arg, arg2, arg3);
  }

  /**
   * Identify the user to GA with unique ID. Typically done on login or registration events.
   *
   * Do not use any personally identifiable user information like email or phone number
   *
   * @example
   * this.analytics.identify('XXXXX-XXXXX-XXXXX-XXXXX');
   *
   * @param uniqueId
   */
  public identify(uniqueId: string | number | null) {
    this.gtag('set', {
      user_id: uniqueId,
    });
  }

  public customEvent(event: any) {
    this.dom?.window?.dataLayer.push(event);
  }
}
