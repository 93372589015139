import { Models } from '../../../models';

/**
 * Returns the address in a human readable label
 *
 * @param {Property} property - The property object
 * @returns {string} - The address in a human readable label format
 */
export const propertyInfoToLabel = (prop?: Models.PropertySearchResponse | Models.PropertyDetailsResponse | Models.PropertyLookup | null) => {
  if (!prop) {
    return '';
  }
  return `${prop.ADDRESS1} ${prop.CITY}, ${prop.STATE} ${prop.POSTAL_CODE}`;
};
