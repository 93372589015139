import { Component, ViewChild, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, combineLatest, filter, map, of, take, tap } from 'rxjs';
import { get, isBoolean } from 'lodash';

import { SettingsService } from '$settings';
import { Models } from '$models';
import { ModalWizardConstants } from '../../modal-wizard/shared/models/modal-wizard.constants';
import { ModalWizardModels } from '../../modal-wizard/shared/models/modal-wizard.model';
import { BiddingStoreKey, BiddingStoreService } from './store/bidding.store';
import { BiddingService } from './shared/services/bidding.service';

import { ModalWizardService } from '../../modal-wizard/shared/services/modal-wizard.service';
import { ModalWizardComponent } from '../../modal-wizard/modal-wizard.component';
import { BidTermsOfServiceStepContentComponent } from './steps/bid-terms-of-service-step-content/bid-terms-of-service-step-content.component';
import { BidDepositStepContentComponent } from './steps/bid-deposit-step-content/bid-deposit-step-content.component';
import { PlaceBidForStepContentComponent } from './steps/place-bid-for-step-content/place-bid-for-step-content.component';
import { PlaceBidFormStepContentComponent } from './steps/place-bid-form-step-content/place-bid-form-step-content.component';
import { BuyerAgentStepContentComponent } from './steps/buyer-agent-step-content/buyer-agent-step-content.component';
import { BidConfirmStepContentComponent } from './steps/bid-confirm-step-content/bid-confirm-step-content.component';
import { BidResultStepContentComponent } from './steps/bid-result-step-content/bid-result-step-content.component';
import { BiddingModels } from './shared/models/bidding.model';
import { ApiService } from '../../../routes/bid-deposit-status/shared/stores/api.service';

@UntilDestroy()
@Component({
  selector: 'app-bidding-modal',
  templateUrl: './bidding.component.html',
  styleUrls: ['./bidding.component.scss'],
})
export class BiddingModalComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild(ModalWizardComponent, { static: false }) modalWizardComponent: ModalWizardComponent | null = null;

  private readonly currentCustomerId = 0;

  wizardConfig: ModalWizardModels.WizardConfig = {
    selected: 0,
  };
  stepDefinitions: ModalWizardModels.StepDefinition[] = [];
  user?: Models.LoginResponse;
  property?: Models.PropertyDetailsResponse;
  depositStatus?: Models.BidDepositStatus;
  isDisclosureAccepted: boolean | null | undefined = null;

  private readonly customersStatusHash = new Map<number, string>();

  get isPostAuction(): boolean {
    return BiddingService.isPostAuction(this.property);
  }

  get hasNoDeposit(): boolean {
    return BiddingService.hasNoDeposit(this.depositStatus);
  }

  constructor(
    public ref: DynamicDialogRef,
    public dialogConfig: DynamicDialogConfig,
    private settings: SettingsService,
    private biddingStore: BiddingStoreService,
    private wizardService: ModalWizardService,
    private readonly apiService: ApiService,
  ) {
    // Initialize store with default values
    this.biddingStore.reset();
  }

  ngOnInit() {
    this.runAgentCardInfoObserver();
  }

  ngAfterViewInit(): void {
    this.watchForInputDataChanged();
  }

  ngOnDestroy(): void {
    this.biddingStore.reset();
  }

  onStepChanged(args: ModalWizardModels.StepChangedArgs): void {
    // Update dialog title based on current step settings
    this.modalWizardComponent?.setHeaderTitle(args?.step?.title || '');
    // Update dialog header visibility based on current step settings
    this.modalWizardComponent?.setHeaderVisibility(args?.step?.showHeader);
    // Update dialog footer position based on current step settings
    this.modalWizardComponent?.setFooterStickPosition(args?.step?.isFooterStickedToBottom);
  }

  /**
   * Close modal
   */
  close(): void {
    this.ref.close();
  }

  private getStepDefinitions(): ModalWizardModels.StepDefinition[] {
    const steps: ModalWizardModels.StepDefinition[] = [];
    /**
     * This option should be displayed only for action
     * for property that was selected first time. After
     * that it shouldn't be displayed for bidder.
     */
    if (!this.isDisclosureAccepted) {
      steps.push({
        stepId: BiddingModels.BidStepIds.termsOfUse,
        title: 'Terms of Service',
        component: BidTermsOfServiceStepContentComponent,
        showPrev: false,
        nextText: 'Agree',
        isFooterStickedToBottom: false,
        canEnter: (args: ModalWizardModels.StepValidationArgs) => {
          return this.wizardService.validateStep('entry', args) as boolean;
        },
        canExit: (args: ModalWizardModels.StepValidationArgs) => {
          return this.wizardService.validateStep('exit', args) as boolean;
        },
      });
    }

    /**
     * Those steps should be displayed for any role
     */
    const finalSteps: ModalWizardModels.StepDefinition[] = [
      {
        stepId: BiddingModels.BidStepIds.confirmBid,
        title: 'Confirm Bid',
        component: BidConfirmStepContentComponent,
        nextText: 'Bid Now',
        canEnter: (args: ModalWizardModels.StepValidationArgs) => {
          return this.wizardService.validateStep('entry', args) as boolean;
        },
        canExit: (args: ModalWizardModels.StepValidationArgs) => {
          return this.wizardService.validateStep('exit', args) as boolean;
        },
      },
      {
        stepId: BiddingModels.BidStepIds.bidResult,
        component: BidResultStepContentComponent,
        showHeader: false,
        showPrev: false,
        showNext: false,
        completeText: 'Close',
      },
    ];

    /**
     * ACTIVE ACTION - NO DEPOSIT: all roles with no deposit
     */
    if (this.hasNoDeposit && this.user?.UserType !== Models.UserType.Agent) {
      steps.push(this.createBidDepositStep('Bid Deposit', 'Confirm'));
    }

    /**
     * Active Auction - active/no deposit: agent, agent-new customer, agent-exisitng customer
     */
    if (this.user?.UserType === Models.UserType.Agent) {
      steps.push({
        stepId: BiddingModels.BidStepIds.placeBidFor,
        title: 'Who Are You Placing a Bid For?',
        component: PlaceBidForStepContentComponent,
        showPrev: false,
        canEnter: (args: ModalWizardModels.StepValidationArgs) => {
          return this.wizardService.validateStep('entry', args) as boolean;
        },
        canExit: (args: ModalWizardModels.StepValidationArgs) => {
          return this.wizardService.validateStep('exit', args) as boolean;
        },
      });
    }

    steps.push({
      stepId: BiddingModels.BidStepIds.placeBidForm,
      title: this.isPostAuction ? 'Post Auction Form' : 'Active Auction Form',
      component: PlaceBidFormStepContentComponent,
      showPrev: this.user?.UserType === Models.UserType.Agent,
      nextText: 'Submit Bid',
      canEnter: (args: ModalWizardModels.StepValidationArgs) => {
        return this.wizardService.validateStep('entry', args) as boolean;
      },
      canExit: (args: ModalWizardModels.StepValidationArgs) => {
        return this.wizardService.validateStep('exit', args) as boolean;
      },
    });

    /**
     * Active Auction - no/active deposit: bidder, investor, investor purchase profile
     */
    if ([Models.UserType.Bidder, Models.UserType.Investor].includes(this.user?.UserType as Models.UserType)) {
      steps.push({
        stepId: BiddingModels.BidStepIds.manageBuyerAgent,
        title: 'Buyer Agent Form',
        component: BuyerAgentStepContentComponent,
        parentStepId: BiddingModels.BidStepIds.placeBidForm,
        prevText: 'Back',
        nextText: 'Add Agent',
        canEnter: (args: ModalWizardModels.StepValidationArgs) => {
          return this.wizardService.validateStep('entry', args) as boolean;
        },
        canExit: (args: ModalWizardModels.StepValidationArgs) => {
          return this.wizardService.validateStep('exit', args) as boolean;
        },
      });
    }

    return [...steps, ...finalSteps];
  }

  /**
   * Make sure that all necessary data are ready
   * before starting configuring wizard steps.
   */
  private watchForInputDataChanged() {
    combineLatest([
      this.settings.user$,
      this.biddingStore.propertyDetails.state$,
      this.apiService.bidDepositStatus(this.currentCustomerId),
      this.biddingStore.disclosureStatus$,
    ])
      .pipe(
        untilDestroyed(this),
        map(([user, property, depositStatus, disclosureStatus]) => {
          this.user = user as Models.LoginResponse;
          this.property = property.data || undefined;
          this.depositStatus = depositStatus as Models.BidDepositStatus;
          const disclosureAcceptedStatusMessage = get(disclosureStatus, 'MESSAGE');
          // Set it to null to avoid building step definitions if status isn't obtained yet
          this.isDisclosureAccepted = disclosureAcceptedStatusMessage ? disclosureAcceptedStatusMessage.includes('TRUE') : null;
        }),
      )
      .subscribe(() => {
        if (this.user && this.property && this.depositStatus && isBoolean(this.isDisclosureAccepted)) {
          this.stepDefinitions = this.getStepDefinitions();
          this.modalWizardComponent?.reset();
        }
      });
  }

  private runAgentCardInfoObserver(): void {
    this.isAgent()
      .pipe(
        filter(isAgent => isAgent),
        take(1),
      )
      .subscribe(() => {
        this.requestCardInfoForInactiveDepositCustomerStatus();
      });
  }

  private requestCardInfoForInactiveDepositCustomerStatus(): void {
    this.biddingStore
      .getSelector(BiddingStoreKey.bidForData)
      .pipe(
        filter(bidData => !!bidData),
        untilDestroyed(this),
      )
      .subscribe(bidData => {
        const bidTarget = bidData.Bid_Target;
        if (this.bidDepositInFlow()) {
          this.removeBidDepositStepFromTheFlow();
        }

        const orderNumberOfPlaceBidForPage = this.stepDefinitions.findIndex(step => step.stepId === BiddingModels.BidStepIds.placeBidFor);
        if (orderNumberOfPlaceBidForPage === -1) {
          return;
        }

        if (bidTarget === BiddingModels.BidTargets.newCustomer) {
          this.addBidDepositStepAfterIndex(orderNumberOfPlaceBidForPage);
        } else if (bidTarget === BiddingModels.BidTargets.existingCustomer && bidData.CUSTOMER_ID) {
          this.addDepositeStepOnCustomerInactiveStatus(+bidData.CUSTOMER_ID, orderNumberOfPlaceBidForPage);
        } else if (bidTarget === BiddingModels.BidTargets.selfCustomer) {
          this.addDepositeStepOnCustomerInactiveStatus(this.currentCustomerId, orderNumberOfPlaceBidForPage);
        }
      });
  }

  private isActiveCustomerStatus(customerId: number): Observable<boolean> {
    const status = this.getSavedCustomerStatus(customerId);
    if (status !== '') {
      return of(status === 'Active');
    }
    return this.apiService.bidDepositStatus(customerId).pipe(
      tap(response => {
        this.customersStatusHash.set(customerId, response.HOLDACTIVE === 1 ? 'Active' : 'Inactive');
      }),
      map(response => {
        return response.HOLDACTIVE === 1;
      }),
    );
  }

  private addBidDepositStepAfterIndex(idx: number): void {
    this.stepDefinitions = [
      ...this.stepDefinitions.slice(0, idx + 1),
      this.createBidDepositStep('Bid Deposit', 'Confirm'),
      ...this.stepDefinitions.slice(idx + 1),
    ];
  }

  private getSavedCustomerStatus(customerId: number): string {
    if (this.customersStatusHash.has(customerId)) {
      return this.customersStatusHash.get(customerId)!;
    }
    return '';
  }

  private bidDepositInFlow(): boolean {
    return this.getStepIndexInFlow(BiddingModels.BidStepIds.bidDeposit) !== -1;
  }

  private getStepIndexInFlow(stepId: BiddingModels.BidStepIds): number {
    return this.stepDefinitions.findIndex(step => step.stepId === stepId) || -1;
  }

  private getBidDepositIndexInFlow(): number {
    return this.getStepIndexInFlow(BiddingModels.BidStepIds.bidDeposit);
  }

  private removeBidDepositStepFromTheFlow(): boolean {
    const indexInFlow = this.getBidDepositIndexInFlow();
    if (indexInFlow === -1) {
      return false;
    }
    this.stepDefinitions.splice(indexInFlow, 1);
    this.stepDefinitions = [...this.stepDefinitions];
    return true;
  }

  private createBidDepositStep(title?: string, nextText?: string) {
    return {
      stepId: BiddingModels.BidStepIds.bidDeposit,
      title: title,
      component: BidDepositStepContentComponent,
      showPrev: false,
      nextText: nextText,
      footerBottomContent: ModalWizardConstants.BID_DEPOSIT_STEP_FOOTER_CONTENT,
      isFooterStickedToBottom: false,
      canEnter: (args: ModalWizardModels.StepValidationArgs) => {
        return this.wizardService.validateStep('entry', args) as boolean;
      },
      canExit: (args: ModalWizardModels.StepValidationArgs) => {
        return this.wizardService.validateStep('exit', args) as boolean;
      },
    };
  }

  private addDepositeStepOnCustomerInactiveStatus(customerId: number, insertAfter: number) {
    this.isActiveCustomerStatus(customerId)
      .pipe(take(1))
      .subscribe(isActive => {
        if (!isActive) {
          this.addBidDepositStepAfterIndex(insertAfter);
        }
      });
  }

  private isAgent(): Observable<boolean> {
    return this.settings.user$.pipe(map(user => user?.UserType === Models.UserType.Agent));
  }
}
