<div class="buyer-agent-form">
  <form 
    [formGroup]="form" 
    [ngClass]="{ 'show-errors-on-touched': errorCondition.touch }" 
  >
    <div class="mt-1">
      <div class="p-fluid row">
        <ng-container *ngFor="let field of buyerAgentFormFeilds">
          <ng-container [ngSwitch]="field.type">
            <ng-container *ngSwitchCase="'dropdown'">
              <div class="col" [ngClass]="field.class ?? 'col-12'">
                <label *ngIf="field.label" [for]="field.formControlName ?? ''">
                  {{ field.label || field.placeholder || '' }}
                </label> 
                <p-dropdown
                  [options]="field?.options ?? []"
                  [id]="field.formControlName"
                  [formControlName]="field.formControlName ?? ''"
                  [placeholder]="field.placeholder ?? ''" 
                  [showTransitionOptions]="'100ms'"
                  [hideTransitionOptions]="'50ms'"
                ></p-dropdown>
                <app-input-error 
                  [controlName]="field.formControlName ?? ''" 
                  [friendlyName]="field.label ?? field.placeholder ?? ''"
                  [errorCondition]="errorCondition"
                  [anyErrorShowInvalid]="field.anyErrorShowInvalid ?? false"
                  [customErrorMessage]="field.customErrorMessage ?? null"
                ></app-input-error>
              </div>
            </ng-container>
            <ng-container *ngSwitchCase="'inputmask'">
              <div class="col" [ngClass]="field.class ?? 'col-12'">
                <label *ngIf="field.label" [for]="field.formControlName ?? ''">
                  {{ field.label || field.placeholder || '' }}
                </label> 
                <p-inputMask
                  [id]="field.formControlName ?? ''"
                  [formControlName]="field.formControlName ?? ''" 
                  [mask]="field.mask ?? ''" 
                  [slotChar]="field.slotChar ?? ' '"
                  [placeholder]="field.placeholder ?? ''"
                ></p-inputMask>
                <app-input-error 
                  [controlName]="field.formControlName ?? ''" 
                  [friendlyName]="field.label ?? field.placeholder ?? ''"
                  [errorCondition]="errorCondition"
                  [anyErrorShowInvalid]="field.anyErrorShowInvalid ?? false"
                  [customErrorMessage]="field.customErrorMessage ?? null"
                ></app-input-error>
              </div>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <div class="col" [ngClass]="field.class ?? 'col-12'">
                <label *ngIf="field.label" [for]="field.formControlName ?? ''">
                  {{ field.label || '' }}
                </label> 
                <input 
                  [formControlName]="field.formControlName ?? ''" 
                  [placeholder]="field.placeholder ?? ''" 
                  [type]="field.type ?? 'text'" 
                  [id]="field.formControlName ?? ''" 
                  pInputText />
                <app-input-error 
                  [controlName]="field.formControlName ?? ''" 
                  [friendlyName]="field.label ?? field.placeholder ?? ''" 
                  [errorCondition]="errorCondition"
                  [anyErrorShowInvalid]="field.anyErrorShowInvalid ?? false"
                  [customErrorMessage]="field.customErrorMessage ?? null"
                ></app-input-error>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </form>
</div>