import { exhaustMap, Observable, Subject, takeUntil, timer, ReplaySubject, switchMap, tap, finalize } from 'rxjs';

type CancelPollingSignaller = () => void;

export const options = {
  boundaries: {
    lowerLimitInMinutes: 20,
    upperLimitInMinutes: 50,
  },
  pollingCyclesInSeconds: {
    fastCycle: 3,
    middleCycle: 10,
    slowCycle: 50,
  },
};

export class Poller {
  /**
   * @param cycleInSeconds - runs every N seconds observable from the "whatToPoll" parameter
   * @param whatToPoll - Observable which is going to be subscribed to every polling cycle
   * @param cancelCallback - user callback function, if returns true then polling stops.
   * @returns function by calling which you can cancel polling (to be used in ngOnDestroy hook)
   */
  public static poll(cycleInSeconds: number, whatToPoll: Observable<void>, cancelCallback: () => boolean = () => false): CancelPollingSignaller {
    const _cancelNotifier$ = new Subject();
    const cancelNotifier$ = _cancelNotifier$.asObservable();

    timer(0, cycleInSeconds * 1000)
      .pipe(
        takeUntil(cancelNotifier$),
        exhaustMap(() => whatToPoll),
        tap(() => {
          if (cancelCallback()) {
            _cancelNotifier$.next(true);
          }
        }),
      )
      .subscribe();

    return () => {
      _cancelNotifier$.next(true);
    };
  }

  /**
   * Smart poll is using floating polling interval which depends on how far from the anchorDate parameter
   * current date and time is. For example in a context of countdown timer:
   *  - if we are more than 50 minutes before countdown timer goes to 00:00:00 then polling
   *    cycle will be 60sec
   *  - if we are less than 50 minutes but greater than 20 minutes before countdown timer goes to zeroes
   *    then polling cycle will be 20sec.
   *  - if we are less than 20 minutes before countdown timer goes to zeroes then polling cycle
   *    will be 3sec.
   * These parameters are defined by "options" object on the top of this module/file.
   * @param whatToPoll - Observable which is going to be subscribed to every polling cycle
   * @param anchorDate - target date (can be auction end date). Is used to calculate polling cycle time.
   * @param cancelCallback - user callback function, if returns true then polling stops.
   * @returns function by calling which you can cancel polling (to be used in ngOnDestroy hook)
   */
  public static smartPoll(whatToPoll: Observable<void>, anchorDate: Date, cancelCallback: () => boolean = () => false): CancelPollingSignaller {
    const _cancelNotifier$ = new Subject();
    const cancelNotifier$ = _cancelNotifier$.asObservable();

    const _cycle$ = new ReplaySubject<number>(1);
    const cycle$ = _cycle$.asObservable();

    let prevCycle: number;

    _cycle$.next(Poller.calculateCycle(anchorDate));

    cycle$
      .pipe(
        tap((cycle: number) => {
          prevCycle = cycle;
        }),
        switchMap((cycle: number) => timer(0, cycle)),
        takeUntil(cancelNotifier$),
        tap(() => {
          const currentCycle = Poller.calculateCycle(anchorDate);
          if (currentCycle !== prevCycle) {
            _cycle$.next(currentCycle);
          }
          if (cancelCallback()) {
            _cancelNotifier$.next(true);
          }
        }),
        switchMap(() => whatToPoll),
      )
      .subscribe();

    return () => {
      _cancelNotifier$.next(true);
    };
  }

  private static calculateCycle(anchorDate: Date): number {
    const datesDiffInMinutes = Math.abs(anchorDate.getTime() - Date.now()) / 1000 / 60;
    if (datesDiffInMinutes < options.boundaries.lowerLimitInMinutes) {
      return options.pollingCyclesInSeconds.fastCycle * 1000;
    } else if (datesDiffInMinutes < options.boundaries.upperLimitInMinutes) {
      return options.pollingCyclesInSeconds.middleCycle * 1000;
    } else {
      return options.pollingCyclesInSeconds.slowCycle * 1000;
    }
  }
}
