import { Component, ChangeDetectionStrategy, OnInit, AfterViewInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, distinctUntilChanged, map as rxMap, of, ReplaySubject, take, filter } from 'rxjs';
import { forEach } from 'lodash';
import { removeNils } from '@ntersol/utils';

import { Models } from '$models';
import { NotificationService } from '$services';
import { BiddingModels } from '../../shared/models/bidding.model';
import { BiddingStoreService } from '../../store/bidding.store';
import { BiddingService } from '../../shared/services/bidding.service';
import { InputErrorShowCondition } from 'src/app/components/input-error/input-error.component';
import { ModalWizardService } from '../../../../modal-wizard/shared/services/modal-wizard.service';
import { AddEditCustomerComponent } from '../../../../add-edit-customer/add-edit-customer.component';

const bidForExistingCustomerForm: Record<string, any> = {
  CUSTOMER_ID: [null, Validators.required],
};

const bidTarget: Record<'RADIO', BiddingModels.BidTargets | null> = {
  RADIO: null,
};

@UntilDestroy()
@Component({
  selector: 'app-place-bid-for-form',
  templateUrl: './place-bid-for-form.component.html',
  styleUrls: ['./place-bid-for-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlaceBidForFormComponent implements OnInit, AfterViewInit {
  private readonly _customerFormComponent$ = new ReplaySubject<AddEditCustomerComponent>();
  private readonly customerFormComponent$ = this._customerFormComponent$.asObservable().pipe(filter(component => !!component));

  @ViewChild(AddEditCustomerComponent) set customerFormComponent(component: AddEditCustomerComponent) {
    if (component) {
      this._customerFormComponent$.next(component);
    }
  }

  @Output() formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() formValuesChanged: EventEmitter<BiddingModels.PlaceBidForFormModel | null> = new EventEmitter<BiddingModels.PlaceBidForFormModel | null>();
  @Output() formValidityChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  bidTargets = BiddingModels.BidTargets;

  // Options for "Existing Customers" dropdown list
  customers$ = this.biddingStore.customers.state$.pipe(
    rxMap(state => {
      const res: Record<string, any>[] = [];
      forEach(state?.entities as Record<string | number, Models.ListCustomersResponseCustomers>, entity => {
        res.push({
          label: `${entity.FIRST_NAME} ${entity.LAST_NAME}`,
          value: entity.CUSTOMER_ID,
        });
      });
      return res;
    }),
    catchError(() => {
      this.notification.showToast({
        styleClass: 'p-toast-message-error',
        closable: true,
        summary: 'Error',
        detail: 'Could not load list of customers.',
      });
      return of([]);
    }),
  );

  form = this.fb.group({
    bidTarget: this.fb.group(bidTarget),
    [BiddingModels.BidTargets.existingCustomer]: this.fb.group(bidForExistingCustomerForm),
    [BiddingModels.BidTargets.newCustomer]: [],
  });

  editCustomerForm: FormGroup | null = null;

  addEditCustomerFormInputStyles = {
    'FIRST_NAME': 'col-12',
    'LAST_NAME': 'col-12',
    'EMAIL_ADDR': 'col-12',
    'CELL_PHONE': 'col-12',
  };

  errorCondition: InputErrorShowCondition = {
    touch: true,
  };

  get isExistingCustomerSelected(): boolean {
    return !!(this.form.get('bidTarget.RADIO')?.value === BiddingModels.BidTargets.existingCustomer);
  }

  get isNewCustomerSelected(): boolean {
    return !!(this.form.get('bidTarget.RADIO')?.value === BiddingModels.BidTargets.newCustomer);
  }

  get isSelfCustomerSelected(): boolean {
    return !!(this.form.get('bidTarget.RADIO')?.value === BiddingModels.BidTargets.selfCustomer);
  }

  constructor(
    private fb: FormBuilder,
    private notification: NotificationService,
    private biddingStore: BiddingStoreService,
    private readonly wizardService: ModalWizardService,
  ) {}

  ngOnInit(): void {
    this.watchForFormValuesChanged();
    // Select default "bid for" section
    this.form.get('bidTarget.RADIO')?.setValue(BiddingModels.BidTargets.existingCustomer);

    this.wizardService.validationEmitter$.pipe(untilDestroyed(this)).subscribe(() => {
      if (this.isExistingCustomerSelected) {
        this.form.markAllAsTouched();
      } else if (this.isNewCustomerSelected) {
        this.customerFormComponent$.pipe(take(1)).subscribe((component: AddEditCustomerComponent) => {
          component.form.markAllAsTouched();
        });
      }
    });
  }

  ngAfterViewInit(): void {
    this.watchForBidTargetChanged();
    this.setupControlsAccessibility();
    this.formReady.emit(this.form);
  }

  onEditCustomerFormReady(form: FormGroup): void {
    this.editCustomerForm = form;
  }

  onEditCustomerFormValuesChanged(values: BiddingModels.PlaceBidForFormModel | null): void {
    // Transform values into a general format for futher use
    const validValues = {
      [BiddingModels.BidTargets.newCustomer]: values,
      bidTarget: {
        RADIO: BiddingModels.BidTargets.newCustomer,
      },
    };
    this.onFormValuesChanged(validValues);
  }

  private setupControlsAccessibility(resetValues = true) {
    // Disable all inputs but 'RADIO's
    BiddingService.disableFormControls(this.form.controls, ['RADIO'], resetValues);
    this.editCustomerForm && BiddingService.disableFormControls(this.editCustomerForm.controls, ['RADIO'], resetValues);

    // Enable inputs based on selected "bid for" type
    if (this.isExistingCustomerSelected) {
      BiddingService.enableFormGroupControls(this.form.controls, ['CUSTOMER_ID']);
    } else if (this.isNewCustomerSelected && this.editCustomerForm) {
      BiddingService.enableFormGroupControls(this.editCustomerForm.controls, ['FIRST_NAME', 'LAST_NAME', 'EMAIL_ADDR', 'CELL_PHONE']);
    }
  }

  private onFormValuesChanged(values: any): void {
    const model = removeNils(this.formValuesToModel(values));

    // Broadcast validity changed event
    if (this.isNewCustomerSelected) {
      this.formValidityChanged.emit(this.editCustomerForm?.valid);
    } else {
      // Since there's no form for "I am bidding for myself"
      // so passing true, otherwise passing form.valid value
      this.formValidityChanged.emit(this.form?.valid ?? true);
    }

    // Broadcast change event
    this.formValuesChanged.emit({
      ...model,
    });
  }

  /**
   * Convert form values into model
   *
   * @param values  Form values
   * @returns PlaceBidForFormModel
   */
  private formValuesToModel(values: any): BiddingModels.PlaceBidForFormModel {
    let model: BiddingModels.PlaceBidForFormModel = {};
    Object.keys(BiddingModels.BidTargets).forEach(key => (model = { ...model, ...(values[key] || {}) }));
    return { ...model, Bid_Target: values?.bidTarget?.RADIO || BiddingModels.BidTargets.existingCustomer };
  }

  private watchForBidTargetChanged() {
    this.form
      .get('bidTarget.RADIO')
      ?.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged())
      .subscribe(() => this.setupControlsAccessibility());
  }

  private watchForFormValuesChanged(): void {
    this.form?.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(this.onFormValuesChanged.bind(this));
  }
}
