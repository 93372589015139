<div class="dialog-content">
  <div class="flex-center mt-2">
    <span class="icon me-3"><fa-icon icon="circle-info" size="2x"></fa-icon></span>
    <p class="mb-0">Your session is about to time out. Do you want to extend your current session?</p>
  </div>
</div>

<div class="p-dialog-footer p-widget-content">
  <button
    type="button"
    pButton
    class="p-button me-4"
    [ngClass]="(breakPoint$ | async) ? 'p-button-text' : 'p-button-outlined'"
    aria-label="Close"
    (click)="onLogoutClick()"
    icon="fas fa-power-off"
    label="Log Out"></button>
  <button type="button" pButton (click)="onExtendSessionClick()" icon="fas fa-angle-double-right" iconPos="right" label="Extend Session"></button>
</div>
