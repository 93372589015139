import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

export interface Statistics {
  numberPropertiesPurchased: number;
  averagePurchasePrice: number;
  numberPropsBidOn: number;
  percentOfValue: number;
}

/**
 * Dumb component with the only responsibility to render input data within styled template
 */
@Component({
  selector: 'app-program-badge',
  templateUrl: './program-badge.component.html',
  styleUrls: ['./program-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgramBadgeComponent {
  @Input() mainColor: string = '';
  @Input() vipStateBackgroundColor = '';
  @Input() currentBadgePath: string = '';
  @Input() altImageText: string = '';
  @Input() headerText: string = '';
  @Input() vipStateText: string = '';
  @Input() vipLevelsStatistics: Statistics | null = null;

  public currentYear: string = new Date().getFullYear().toString();
}
