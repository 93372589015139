import { Component, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, distinctUntilChanged, filter, map, startWith, switchMap } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { listenForLargeScreenDown } from '../../../shared/utils';

@UntilDestroy()
@Component({
  selector: 'app-layout-main',
  templateUrl: './layout-main.component.html',
  styleUrls: ['./layout-main.component.scss'],
})
export class LayoutMainComponent implements OnDestroy {
  private readonly _footerVisible$ = new BehaviorSubject(true);
  public readonly footerVisible$ = this._footerVisible$.asObservable();

  private checkCurrentRoute$: Observable<string> = this.router.events.pipe(
    filter(event => event instanceof NavigationEnd),
    map(() => this.router.url),
    startWith(this.router.url),
  );

  // Determine if current route is the homepage
  public isHomePage$: Observable<boolean> = this.checkCurrentRoute$.pipe(
    map(url => url === '/'),
    distinctUntilChanged(),
  );

  public readonly largeScreenDownObserver$ = listenForLargeScreenDown();

  constructor(private readonly router: Router) {
    this.syncFooterState();
  }

  ngOnDestroy() {
    // this.layoutMainService.showFooter();
  }

  private syncFooterState() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        switchMap(() => this.largeScreenDownObserver$),
        untilDestroyed(this),
      )
      .subscribe((isNarrowerThanLargeScreen: boolean) => {
        let showFooter = null;

        this.traverseRouterState(this.router.routerState.snapshot.root, snapshot => {
          if (snapshot.data.hasOwnProperty('showLayoutFooterForDesktop') && !isNarrowerThanLargeScreen) {
            showFooter = snapshot.data['showLayoutFooterForDesktop'];
          } else {
            return;
          }
        });

        if (showFooter === true) {
          this._footerVisible$.next(true);
        } else if (showFooter === false) {
          this._footerVisible$.next(false);
        }
      });
  }

  private traverseRouterState(activatedRouteSnapshot: ActivatedRouteSnapshot, callback: (snapshot: ActivatedRouteSnapshot) => void) {
    callback.call(this, activatedRouteSnapshot);
    if (!activatedRouteSnapshot.children.length) {
      return;
    }

    for (const snapshot of activatedRouteSnapshot.children) {
      this.traverseRouterState.call(this, snapshot, callback);
    }
  }
}
