<div class="input-radio-container">
  <div
    *ngFor="let option of options"
    class="input-radio"
    [class.is-check]="value === option.value"
    [ngClass]="{ 'is-disabled': disabled }"
    (click)="onRadioInputChange(option)">
    <input [disabled]="disabled" type="radio" name="radio-group" [value]="option.value" [(ngModel)]="value" />
    <div class="radio-checkmark"></div>
    <label for="radio-group">{{ option.label }}</label>
  </div>
</div>
