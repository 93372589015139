import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, OnInit, OnChanges, AfterViewInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { removeNils } from '@ntersol/utils';
import { distinctUntilChanged } from 'rxjs';
import { get } from 'lodash';

import { Models } from '$models';
import { states } from '../../../../../shared/data/states.data';
import { RequiredKeys } from '../../shared/models/bidding.model';
import { phoneUSValidator } from 'src/app/shared/validators';
import { InputErrorShowCondition } from 'src/app/components/input-error/input-error.component';
import { FormGeneratorModel } from 'src/app/shared/models/form.models';

const statesOptions = [{ label: '-- Please Select --', value: null, disabled: true, styleClass: 'disabled' }, ...states.map(s => ({ label: s, value: s }))];

const buyerAgentForm: Record<RequiredKeys<Models.BuyerAgentRequest>, any> = {
  COMPANY_NAME: [null, Validators.required],
  COMPANY_ADDRESS1: [null, [Validators.required]],
  COMPANY_ADDRESS2: null,
  COMPANY_CITY: [null, [Validators.required]],
  COMPANY_STATE: [null, [Validators.required]],
  COMPANY_POSTAL_CODE: [null, [Validators.required]],
  CONTACT_FIRST_NAME: [null, Validators.required],
  CONTACT_LAST_NAME: [null, Validators.required],
  MOBILE: [null, [Validators.required, phoneUSValidator]],
  COMPANY_FAX: null,
  EMAIL: [null, [Validators.required, Validators.email]],
};

@UntilDestroy()
@Component({
  selector: 'app-buyer-agent-form',
  templateUrl: './buyer-agent-form.component.html',
  styleUrls: ['./buyer-agent-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuyerAgentFormComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() agent: Models.BuyerAgentRequest | null | undefined;

  @Output() formReady: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() formValuesChanged: EventEmitter<Record<RequiredKeys<Models.BuyerAgentRequest>, any> | null> = new EventEmitter<Record<
    RequiredKeys<Models.BuyerAgentRequest>,
    any
  > | null>();

  buyerAgentFormFeilds: FormGeneratorModel<keyof Models.BuyerAgentRequest>[] = [
    {
      label: 'Company Name',
      placeholder: '',
      formControlName: 'COMPANY_NAME',
      customErrorMessage: 'Required',
    },
    {
      label: 'Address 1',
      placeholder: 'e.g. 1000 Main St',
      formControlName: 'COMPANY_ADDRESS1',
      customErrorMessage: 'Required',
    },
    {
      label: 'Address 2 (optional)',
      placeholder: 'e.g. Ste 5',
      formControlName: 'COMPANY_ADDRESS2',
    },
    {
      label: 'City',
      placeholder: '',
      formControlName: 'COMPANY_CITY',
      customErrorMessage: 'Required',
    },
    {
      label: 'State',
      placeholder: 'State',
      formControlName: 'COMPANY_STATE',
      type: 'dropdown',
      options: statesOptions,
      customErrorMessage: 'Required',
      //  class: 'col-6'
    },
    {
      label: 'ZIP Code / Postal Code',
      type: 'inputmask',
      formControlName: 'COMPANY_POSTAL_CODE',
      mask: '99999',
      placeholder: 'e.g. 11111',
      // class: 'col-6',
      customErrorMessage: 'Required',
    },
    {
      label: 'Agent First Name',
      placeholder: '',
      formControlName: 'CONTACT_FIRST_NAME',
      customErrorMessage: 'Required',
    },
    {
      label: 'Agent Last Name',
      placeholder: '',
      formControlName: 'CONTACT_LAST_NAME',
      customErrorMessage: 'Required',
    },
    {
      label: 'Agent Phone',
      type: 'inputmask',
      placeholder: 'e.g. +1 (555) 555-5555',
      formControlName: 'MOBILE',
      mask: '+1 (999) 999-9999',
      slotChar: '_',
      // class: 'col-6'
    },
    {
      label: 'Agent Fax (optional)',
      type: 'inputmask',
      placeholder: 'e.g. + 1 (555) 555-5555',
      mask: '+1 (999) 999-9999',
      slotChar: '_',
      formControlName: 'COMPANY_FAX',
      // class: 'col-6'
    },
    {
      label: 'Agent Email',
      placeholder: 'e.g. johndoe@gmail.com',
      formControlName: 'EMAIL',
    },
  ];

  form = this.fb.group(buyerAgentForm);

  errorCondition: InputErrorShowCondition = {
    touch: true,
  };

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.setFormValues();
    this.watchForFormValuesChanged();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['agent'] && !changes['agent'].firstChange) {
      this.setFormValues();
    }
  }

  ngAfterViewInit(): void {
    this.formReady.emit(this.form);
  }

  private setFormValues(): void {
    if (!this.agent) {
      this.form.reset();
    } else {
      this.form.patchValue(this.agent, { emitEvent: false });
    }
  }

  private onFormValuesChanged(values: Record<RequiredKeys<Models.BuyerAgentRequest>, any>): void {
    const model = removeNils(values);
    // Broadcast change event
    this.formValuesChanged.emit(
      model
        ? {
            ...model,
            ADDRESS1: `${get(model, 'COMPANY_ADDRESS1')}` + (get(model, 'COMPANY_ADDRESS2') ? ` ${get(model, 'COMPANY_ADDRESS2')}` : ''),
            CITY: get(model, 'COMPANY_CITY'),
            STATE: get(model, 'COMPANY_STATE'),
            POSTAL_CODE: get(model, 'COMPANY_POSTAL_CODE'),
          }
        : model,
    );
  }

  private watchForFormValuesChanged(): void {
    this.form.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(this.onFormValuesChanged.bind(this));
  }
}
