<div class="container g-0" *ngIf="{ errorApi: errorApi$ | async, waiting: waiting$ | async } as comp">
  <div class="row flex-column align-items-center m-auto">
    <p-message severity="error" class="g-0" [text]="comp.errorApi.errorMsg" *ngIf="comp.errorApi"> </p-message>

    <ng-container *ngIf="currentView === 'EMAIL'">
      <div class="col g-0">
        <form
          [formGroup]="sendEmailForm"
          novalidate
          (ngSubmit)="sendEmail()"
          class="d-flex flex-column"
          [ngClass]="{ 'show-errors-on-touched': errorCondition.touch, 'show-errors-on-submit': errorCondition.submit }">
          <h3>Forgot your password?</h3>
          <div class="gray-400 mt-1 mb-4">Enter your email to reset it.</div>
          <div class="mb-4">
            <label for="email">Email</label>
            <input id="email" type="text" class="w-100" pInputText formControlName="email" placeholder="e.g. johnwhite@gmail.com" />
            <app-input-error controlName="email" friendlyName="email address" [anyErrorShowInvalid]="true" [errorCondition]="errorCondition"></app-input-error>
          </div>

          <div class="d-flex justify-content-end">
            <button
              pButton
              pRipple
              type="submit"
              iconPos="right"
              [disabled]="comp.waiting"
              [loading]="!!comp.waiting"
              label="Send email with recovery link"></button>
          </div>
        </form>
        <div class="small medium-bold text-secondary d-flex flex-column mt-4 g-0">
          <div>
            Remember your password?
            <a routerLink="/login/" rel="noreferrer noreopen" queryParamsHandling="merge"
              ><button pButton class="p-button-link primary-color p-0" label="Log in"></button
            ></a>
          </div>
          <div>
            Don't have an account?
            <a routerLink="/sign-up/" rel="noreferrer noreopen" queryParamsHandling="merge"
              ><button pButton class="p-button-link primary-color p-0" label="Sign up here"></button
            ></a>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentView === 'EMAIL_SENT'">
      <h3>Recovery email sent</h3>
      <div class="gray-400 mt-1 mb-4">
        Didn't receive the email? Check to make sure the email address you used matches the email on your account, look in your spam folder, or request another
        email below.
      </div>

      <div class="mb-4">
        <label for="email">Email</label>
        <input id="email" type="text" class="w-100" pInputText [value]="sendEmailForm.get('email')?.value" [disabled]="!!comp.waiting" />
      </div>

      <div class="d-flex justify-content-end">
        <button
          pButton
          pRipple
          type="submit"
          iconPos="right"
          [disabled]="comp.waiting"
          [loading]="!!comp.waiting"
          (click)="sendEmail()"
          label="Send again"></button>
      </div>
      <div class="small medium-bold text-secondary d-flex flex-column mt-4 g-0">
        <div>
          Don't have an account?
          <a routerLink="/sign-up/" rel="noreferrer noreopen" queryParamsHandling="merge"
            ><button pButton class="p-button-link primary-color p-0" label="Sign up here"></button
          ></a>
        </div>
        <div>
          <a routerLink="/login/" rel="noreferrer noreopen" queryParamsHandling="merge"
            ><button pButton class="p-button-link primary-color p-0" label="Let's do this later"></button
          ></a>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="currentView === 'PASSWORD'">
      <h3>Create new password</h3>
      <div class="gray-400 mt-1 mb-4">Choose a password that will be encrypted and secure for your account.</div>
      <app-password-change-form [onFormSubmitAction]="changePasswordAction" [waiting]="!!comp.waiting"> </app-password-change-form>
    </ng-container>
    <ng-container *ngIf="currentView === 'PASSWORD_RESET'">
      <h3>Congratulations!</h3>
      <div class="gray-400 mt-1 mb-4">Your password has been successfully changed. Log in to get started.</div>
      <app-login-form [showForgotPassword]="false"></app-login-form>
    </ng-container>
  </div>
</div>
