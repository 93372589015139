import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, map, startWith, Subject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-more',
  templateUrl: './more.component.html',
  styleUrls: ['./more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MoreComponent),
      multi: true,
    },
  ],
})
export class MoreComponent implements OnInit, ControlValueAccessor {
  @Input() isMobile = false;
  @Input() clearForm!: Subject<boolean>;
  @Output() onFormChange: any = new EventEmitter<any>();

  onChange?: (value: any) => void;
  onTouched: () => void = () => {};

  public isOpen = false;
  numberOfCheckedGroups = 0;

  public readonly OCCUPANCY: FormArray = this.fb.array([null, null, null]);
  public readonly FINANCING: FormArray = this.fb.array([null, null]);

  moreFormGroup = this.fb.group({
    OCCUPANCY: this.OCCUPANCY,
    FINANCING: this.FINANCING,
    RESERVEMET: false,
    ENDINGTODAY: false,
    NOBUYERPREMIUMS: false,
    POSTAUCTIONS: false,
  });

  readonly numberOfSelected$ = this.moreFormGroup.valueChanges.pipe(
    debounceTime(50),
    map(formGroupValue => {
      return this.extractNumberOfSelectedFromFormGroupValue(formGroupValue);
    }),
    startWith(0),
  );

  constructor(private readonly fb: FormBuilder) {}

  ngOnInit() {
    this.FINANCING.at(0)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(val => (val ? this.FINANCING.at(1).setValue(false) : null));

    this.FINANCING.at(1)
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe(val => (val ? this.FINANCING.at(0).setValue(false) : null));

    this.moreFormGroup.valueChanges.pipe(debounceTime(50), untilDestroyed(this)).subscribe(val => {
      if (this.onChange) {
        this.onChange(val);
      }
    });
  }

  // ControlValueAccessor methods implementation
  writeValue(value: any) {
    if (!value || Object.values(value).length === 0) {
      return;
    }
    this.moreFormGroup.patchValue(value);
  }

  registerOnChange(onChange: (value: any) => void) {
    // Use this saved this.onChange and apply every time
    // local formcontrol makes changes by passing through
    // the mapping
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void) {
    this.onTouched = onTouched;
  }

  private extractNumberOfSelectedFromFormGroupValue(formGroupValue: FormGroup['value']): number {
    return (
      Number(formGroupValue['ENDINGTODAY']) +
      Number(formGroupValue['NOBUYERPREMIUMS']) +
      Number(formGroupValue['POSTAUCTIONS']) +
      Number(formGroupValue['RESERVEMET']) +
      Number(formGroupValue['FINANCING'][0]) +
      Number(formGroupValue['FINANCING'][1]) +
      (formGroupValue['OCCUPANCY'][0] === null ? 0 : Number(formGroupValue['OCCUPANCY'][0].length)) +
      (formGroupValue['OCCUPANCY'][1] === null ? 0 : Number(formGroupValue['OCCUPANCY'][1].length)) +
      (formGroupValue['OCCUPANCY'][2] === null ? 0 : Number(formGroupValue['OCCUPANCY'][2].length))
    );
  }
}
