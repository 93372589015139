import { EnvironmentConfig } from './environment.model';

/**
 * Global defaults for environment configs
 */
export const environment: EnvironmentConfig = {
  appID: 'realtybid',
  /** Use production bundling */
  production: false,
  properties: {
    /** Name of application */
    appName: 'RealtyBid',
  },
  settings: {
    /** Enable service worker functionality */
    enableServiceWorker: true,
    /** Is this app going to communicate with other domains or instances of itself for multiscreen usage?
     * If so, whitelist domains in the domains.listenTo property */
    enableAppComms: true,
    /** Should lazy loaded routes be preloaded on app instantiation? If false will be loaded on demand */
    preloadRoutes: false,
    /** Should data that is written to localstorage (such as app settings and store state) be obfuscated? */
    obfuscate: true,
    enableCookiebot: true,
  },
  domains: {
    /** If App Comms is enabled, whitelist domains to accept messages from here */
    listenTo: <string[]>['http://localhost:4200'],
  },
  endpoints: {
    /** Location to get environment and config settings */
    envConfig: 'http://localhost:4200/assets/mock-data/env-settings.json',
    /** Location of API if not getting that from envConfig */
    apiUrl: 'https://apidev.realtybid.com/',
    /** Auth/Login endpoint */
    authLogin: 'login/login',
    /** Refresh token endpoint */
    authTokenRefresh: 'session/sessioninfo',
    /** Api version endpoint. If not null then the app will request an update when the version changes */
    versionPath: <string | null>null, // '/version',
    /** Log front-end errors to here. Used by error.intercepter */
    errorPath: <string | null>null, // 'apiwebapp/log',
    photoPath: 'https://photosdev.realtybid.com/',
    downloadSearchResult: 'https://appdev.realtybid.com/downloadSearchResults.cfm',
  },
  state: {
    /** Which UI store properties to not write to localstorage. IE do not persist confidential/personal information */
    uiStoreBlacklist: <string[]>[],
  },
  licenses: {
    googleMapsKey: 'AIzaSyDUuitcVNRTSX3mrKofVE4CsaW8RSKYkb0',
    googleAnalyticsKey: 'G-2YHFJV8941',
    googleTagKey: 'GTM-M85D469B',
    weissWidgetsApiKey: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo5ODQyfQ._xs5NnkA6xfAYlsPs602lrigSKtNPN0Ixssh891NMQM',
    recaptchaKey: '6LcaGqkhAAAAALKnQKIRxfB7I7cln5cKK9X_rx5Q',
    cookiebotKey: 'f29c8432-8ee9-4f55-9ce3-76a6d5b626a4',
    authorizeNet: {
      apiLoginId: '3bkfqVc86yLF',
      clientKey: '89yueukx9xDPaKA8MMVanxfH6GGn5N7467fdpxz38K5R6Y42WYNXA7LW8y7V4XXU',
    },
  },
  clientPortalLinks: {
    dailyTasks: 'https://clientportal.dev.internal.realtybid.com/#/dailytasks',
    programManagers: 'https://clientportal.dev.internal.realtybid.com/#/pm',
    manageProperties: 'https://clientportal.dev.internal.realtybid.com/#/manageproperties',
    allDocuments: 'https://clientportal.dev.internal.realtybid.com/#/AllDocuments/',
    charts: 'https://clientportal.dev.internal.realtybid.com/#/charts',
  },
  reportLinks: {
    auctionPerformance: 'https://appdev.realtybid.com/activityReport.cfm',
    contractStatus: 'https://appdev.realtybid.com/contractStatusReport.cfm',
    bidding: 'https://appdev.realtybid.com/biddingReport.cfm',
  },
};
