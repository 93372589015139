import { SearchModels } from '..';

/**
 * Convert a string or slug into title case
 * @param phrase
 * @returns
 */
export const toTitleCase = (phrase?: string | null) => {
  return (
    String(phrase || '')
      // .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .split('-')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  );
};

/**
 * Convert search results to the properly calculated number
 * @param searchResults
 * @returns
 */
export const searchResultsToDisplay = (searchResults: SearchModels.SearchResults | null) => {
  if (!searchResults) {
    return ``;
  }
  let currentCount = searchResults.page === 0 ? 1 : searchResults?.page * searchResults?.resultsPerPage - searchResults.resultsPerPage + 1;

  let total = searchResults.total < searchResults.resultsPerPage ? searchResults.total : searchResults.resultsPerPage * (searchResults.page + 1);
  let resultsPerPage = total < searchResults.total ? total - searchResults.list.length : searchResults.total;
  if (resultsPerPage === 0) {
    resultsPerPage = searchResults.resultsPerPage;
  }

  return searchResults.total === 0 ? '' : `Showing ${currentCount} - ${resultsPerPage} of ${searchResults.total} Properties`;
};
