import { Injectable } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalsManagementService {

  private modals = new Map<DynamicDialogRef, Subscription>();

  constructor() { }

  register(modal: DynamicDialogRef): void {
    if (!this.isRegistered(modal)) {
      const sub = modal.onClose.subscribe(() => this.unregister(modal, false));
      this.modals.set(modal, sub);
    }
  }

  unregister(modal: DynamicDialogRef, closeIt = true): void {
    if (this.isRegistered(modal)) {
      this.modals.get(modal)?.unsubscribe();
      if (closeIt) {
        modal.close();
      }
    }
  }

  unregisterAll(): void {
    this.modals.forEach((sub, modal) => {
      this.unregister(modal);
    });
  }

  private isRegistered(modal: DynamicDialogRef): boolean {
    return this.modals.has(modal);
  }
}
