<div>
  <h3 class="mb-5 mb-lg-4 text-center text-lg-start header-mobile-style">Property Features</h3>
  <app-property-options
    label="Property Type"
    [required]="true"
    [control]="formControlType"
    [options]="(propertyTypes$ | async) || []"
    (selectOption)="typeSelected($event)">
  </app-property-options>

  <hr />

  <!-- Number steppers -->
  <app-number-of-steppers
    [requiredBedAsterisk]="requiredBedAsterisk"
    [requiredBathAsterisk]="requiredBathAsterisk"
    [formControlBedCount]="formControlBedCount"
    [formControlBathCount]="formControlBathCount"
    [formControlHalfBathCount]="formControlHalfBathCount">
  </app-number-of-steppers>

  <hr />

  <div class="property-features-inputs-container">
    <app-property-input-details [label]="sqftText" [control]="formControlSqFt" errorMessage="Square Feet is required"></app-property-input-details>
    <div class="lot-size-container">
      <app-property-input-details
        [inputType]="'text'"
        [label]="lotSizeText"
        [control]="formControlLotSizeValue"
        [pattern]="'^0-9.,'"
        (inputChange)="changeLotSizeValue($event)"
        errorMessage="Lot Size is required"></app-property-input-details>

      <app-input-radio
        class="lot-size-radio"
        [formControl]="formControlLotSizeOption"
        [options]="LOT_SIZE_OPTIONS"
        (ngModelChange)="changeLotSizeOption()"></app-input-radio>
    </div>
    <app-property-input-details [label]="yearBuiltText" [control]="formControlYearBuilt" errorMessage="Year Built is required"></app-property-input-details>
  </div>

  <hr class="d-none d-lg-block" />

  <div class="property-options-container">
    <nts-form-field [formControl]="formControlFirePlace" label="Fireplace" [placeholder]="'Fireplace'" type="radio" [options]="FIREPLACE_OPTIONS">
    </nts-form-field>

    <hr class="d-none d-lg-block" />

    <div class="property-type-dropdowns-container">
      <app-property-options
        label="Garage Size"
        [control]="formControlGarageSize"
        [options]="GARAGE_SIZE_OPTIONS"
        (selectOption)="typeSelected($event.value)"></app-property-options>
      <app-property-options
        label="Heat Type"
        [control]="formControlHeatType"
        [options]="HEAT_TYPE"
        (selectOption)="typeSelected($event.value)"></app-property-options>
    </div>

    <hr class="d-none d-lg-block" />

    <nts-form-field [formControl]="formControlBasement" label="Basement" [placeholder]="'Basement'" type="radio" [options]="BASEMENT_OPTIONS"> </nts-form-field>

    <hr *ngIf="showOccupancySection" class="d-none d-lg-block" />
    <nts-form-field
      *ngIf="showOccupancySection"
      [formControl]="formControlOccupancy"
      label="Occupancy"
      [placeholder]="'Occupancy'"
      type="radio"
      [options]="OCCUPANCY_OPTIONS"></nts-form-field>
  </div>
</div>
