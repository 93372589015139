<ng-container
  *ngIf="{
    asset: vipLevelAssetData | async,
    user: user$ | async,
    userName: userName$ | async,
    userType: userType$ | async,
    loggedIn: loggedIn$ | async,
    buySellDropdownItems: buySellDropdownItems$ | async,
    isLargeScreen: largeScreenObserver$ | async
  } as componentData">
  <div #nav id="nav" [class]="{ 'search-page': !isHomePage }">
    <div class="nav-options">
      <a class="nav-menu-link main-menu buy-sell-item d-none d-lg-block">
        <span>{{ componentData.userType === userTypes.Client ? 'Client Portal' : 'Buy / Sell' }}</span>
        <fa-icon icon="angle-down" class="me-2 left-nav-icon"></fa-icon>
        <app-desktop-buy-sell-list [isLoggedIn]="componentData.loggedIn" [items]="componentData.buySellDropdownItems"></app-desktop-buy-sell-list>
      </a>
      <a class="nav-menu-link main-menu d-none d-lg-block" routerLink="/about-us/">About Us</a>
      <a class="nav-menu-link main-menu d-none d-lg-block" routerLink="/help-center/">Help Center</a>
      <div class="menu-container align-self-center ms-auto">
        <a class="nav-menu-link d-none d-lg-block" (click)="navigateToSearchHouses()">
          <fa-icon [icon]="['fas', 'magnifying-glass']"></fa-icon>
        </a>
        <a class="nav-menu-link d-none d-lg-block" (click)="navigateToAlertsNotifications()" pTooltip="Notifications" tooltipPosition="right">
          <fa-icon [icon]="['far', 'bell']"></fa-icon>
        </a>
        <a class="nav-menu-link d-none d-lg-block" (click)="navigateToWatchList()" pTooltip="Saved properties" tooltipPosition="right">
          <fa-icon [icon]="['far', 'heart']"></fa-icon>
        </a>
        <div #profile style="position: relative">
          <button pButton *ngIf="!componentData.loggedIn" class="p-button-secondary login-btn ml-5" (click)="login()">Login or Sign up</button>
          <button pButton *ngIf="componentData.loggedIn" class="p-button-secondary login-btn ml-5" (click)="showProfileSettings($event)">
            <div
              *ngIf="componentData.asset && componentData.user?.UserType === userTypes.Investor"
              class="colored-dot"
              [ngStyle]="{ backgroundColor: componentData.asset.color }"></div>
            {{ componentData.userName }}
          </button>
          <p-overlayPanel #op [showCloseIcon]="false" [baseZIndex]="10001" [style]="{ width: '16.875rem' }" [appendTo]="profile" styleClass="logged-user-panel">
            <ng-template pTemplate *ngIf="componentData.user && componentData.isLargeScreen">
              <div class="profile">
                <img
                  *ngIf="!!componentData.asset && componentData?.user?.UserType === userTypes.Investor"
                  [src]="componentData.asset.path"
                  [alt]="componentData.asset.alt"
                  class="vip-level-image" />
                <div
                  class="pb-3 text-break"
                  *ngIf="componentData?.user"
                  [class]="{ 'text-center': !componentData.asset || componentData.user.UserType !== userTypes.Investor }">
                  <div>{{ componentData.user.Name }}</div>
                  <div class="footnote text-secondary">{{ componentData.user.UserType }}</div>
                  <div class="small text-secondary">{{ componentData.user.DisplayName }}</div>
                  <div class="small">{{ componentData.user.Email }}</div>
                </div>
              </div>

              <hr />
              <div class="pt-2 pb-2">
                <a class="text-decoration-none primary-blue-dark" routerLink="/my-profile/" (click)="op.hide()">My Profile</a>
              </div>
              <hr />
              <div class="pt-2 pb-2">
                <a class="text-decoration-none primary-blue-dark" (click)="logOut()">Logout</a>
              </div>
            </ng-template>
          </p-overlayPanel>
        </div>
      </div>
    </div>
  </div>
</ng-container>
