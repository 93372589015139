import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MultiSelectModule } from 'primeng/multiselect';
import { SharedModule } from '../shared/shared.module';
import { VendorModule } from '../vendor.module';

// Global modals
import { FeedbackModalComponent } from './modals/feedback/feedback.component';
import { LogoutModalComponent } from './modals/logout/logout-modal.component';

// Shared Forms
import { ForgotPasswordFormComponent } from './forgot-password-form/forgot-password-form.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { PasswordChangeFormComponent } from './password-change-form/password-change-form.component';
import { PasswordStrengthInputComponent } from './password-strength-input/password-strength-input.component';
import { SignUpFormComponent } from './sign-up-form/sign-up-form.component';

// Layout
import { FooterComponent } from './masterpage/footer/footer.component';
import { HeaderComponent } from './masterpage/header/header.component';
import { LoginHeaderComponent } from './masterpage/login-header/login-header.component';
import { LayoutMainComponent } from './masterpage/main/layout-main.component';
import { NavComponent } from './masterpage/nav/nav.component';

import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { AuctionTypeIndicatorComponent } from './property-card/auction-type-indicator/auction-type-indicator.component';
import { CountdownTimerComponent } from './property-card/countdown/countdown-timer.component';
import { PropertyCardComponent } from './property-card/property-card.component';
import { PropertySaveButtonComponent } from './property-card/property-save-button/property-save-button.component';
import { LocationSearchInputComponent } from './property-search-bar/location-search-input/location-search-input.component';
import { PropertySearchBarComponent } from './property-search-bar/property-search-bar.component';
import { ToolbarComponent } from './toolbar/toolbar.component';

import { LoginComponent } from '../routes/login/login.component';
import { BreadcrumbsComponent } from './breadcrumbs/breadcrumbs.component';
import { CarouselComponent } from './carousel/carousel.component';
import { GalleriaComponent } from './galleria/galleria.component';
import { InputErrorComponent } from './input-error/input-error.component';
import { DesktopBuySellListComponent } from './masterpage/nav/desktop-buy-sell-list/desktop-buy-sell-list.component';
import { MobileNavigationComponent } from './mobile-navigation/mobile-navigation.component';
import { PageHeaderComponent } from './page-header/page-header.component';
import { BedsBathDropdownComponent } from './toolbar/beds-bath-dropdown/beds-bath-dropdown.component';
import { CovDropdownComponent } from './toolbar/cov-dropdown/cov-dropdown.component';
import { MoreComponent } from './toolbar/more/more.component';
import { SaveSearchFormComponent } from './toolbar/save-search-form/save-search-form.component';
import { WizardNavComponent } from './wizard-nav/wizard-nav.component';

import { BidButtonComponent } from './bid-button/bid-button.component';
import { ModalWizardModule } from './modal-wizard/modal-wizard.module';
import { BiddingModalComponent } from './modals/bidding/bidding.component';
import { BidDepositComponent } from './modals/bidding/components/bid-deposit/bid-deposit.component';
import { BidResultComponent } from './modals/bidding/components/bid-result/bid-result.component';
import { BidTermsOfServiceComponent } from './modals/bidding/components/bid-terms-of-service/bid-terms-of-service.component';
import { BuyerAgentFormComponent } from './modals/bidding/components/buyer-agent-form/buyer-agent-form.component';
import { BuyerAgentInfoComponent } from './modals/bidding/components/buyer-agent-info/buyer-agent-info.component';
import { ConfirmBidComponent } from './modals/bidding/components/confirm-bid/confirm-bid.component';
import { PlaceBidForFormComponent } from './modals/bidding/components/place-bid-for-form/place-bid-for-form.component';
import { PlaceBidFormComponent } from './modals/bidding/components/place-bid-form/place-bid-form.component';
import { BiddingService } from './modals/bidding/shared/services/bidding.service';

import { AddEditCustomerComponent } from './add-edit-customer/add-edit-customer.component';
import { BidSummaryComponent } from './modals/bidding/components/bid-summary/bid-summary.component';
import { BidConfirmStepContentComponent } from './modals/bidding/steps/bid-confirm-step-content/bid-confirm-step-content.component';
import { BidDepositStepContentComponent } from './modals/bidding/steps/bid-deposit-step-content/bid-deposit-step-content.component';
import { BidResultStepContentComponent } from './modals/bidding/steps/bid-result-step-content/bid-result-step-content.component';
import { BidTermsOfServiceStepContentComponent } from './modals/bidding/steps/bid-terms-of-service-step-content/bid-terms-of-service-step-content.component';
import { BuyerAgentStepContentComponent } from './modals/bidding/steps/buyer-agent-step-content/buyer-agent-step-content.component';
import { PlaceBidForStepContentComponent } from './modals/bidding/steps/place-bid-for-step-content/place-bid-for-step-content.component';
import { PlaceBidFormStepContentComponent } from './modals/bidding/steps/place-bid-form-step-content/place-bid-form-step-content.component';

import { AuthorizenetModule } from './authorize-net/authorize-net.module';
import { FeatureNavComponent } from './feature-nav/feature-nav.component';
import { PropertyFeaturesCommonComponent } from './flow-components/property-features/property-features-common.component';
import { FORM_INPUT_COMPONENTS } from './form-inputs';
import { HorizontalStepperComponent } from './horizontal-stepper/horizontal-stepper.component';
import { ListSelectComponent } from './list-select/list-select.component';
import { NumberOfSteppersComponent, PropertyInputDetailsComponent, PropertyOptionsComponent, TableListOptionsComponent } from './manage-property';
import { MobileLayoutComponent } from './masterpage/mobile-layout/mobile-layout.component';
import { AppMobileHeaderLevel1Component } from './mobile-navigation/headers/app-mobile-header-level-1.component';
import { AppMobileHeaderLevel2Component } from './mobile-navigation/headers/app-mobile-header-level-2.component';
import { AppMobileHeaderLevel3Component } from './mobile-navigation/headers/app-mobile-header-level-3.component';
import { ProgramInformationBadgeComponent } from './program-information-badge/program-information-badge.component';
import { StateLicenseComponent } from './state-license/state-license.component';
import { TermsOfServicePartsModule } from './terms-of-service-parts/terms-of-service-parts.module';
import { SavedSearchFormComponent } from './toolbar/save-search-form/saved-search-form/saved-search-form.component';
import { UploadFilesComponent } from './upload-files/upload-files.component';
import { IconDropdownComponent } from './property-search-bar/icon-dropdown/icon-dropdown.component';

// Modals include
const APP_MODALS = [LogoutModalComponent, FeedbackModalComponent, BiddingModalComponent];

// Components to include
export const APP_COMPONENTS = [
  ...APP_MODALS,
  FooterComponent,
  HeaderComponent,
  LoginHeaderComponent,
  LayoutMainComponent,
  NavComponent,
  PropertySearchBarComponent,
  PropertyCardComponent,
  ToolbarComponent,
  LoadingSpinnerComponent,
  PasswordChangeFormComponent,
  PasswordStrengthInputComponent,
  ForgotPasswordFormComponent,
  LoginFormComponent,
  SignUpFormComponent,
  PropertySaveButtonComponent,
  AuctionTypeIndicatorComponent,
  CountdownTimerComponent,
  LocationSearchInputComponent,
  CovDropdownComponent,
  BedsBathDropdownComponent,
  DesktopBuySellListComponent,
  InputErrorComponent,
  LoginComponent,
  MobileNavigationComponent,
  BreadcrumbsComponent,
  CarouselComponent,
  SaveSearchFormComponent,
  PageHeaderComponent,
  GalleriaComponent,
  WizardNavComponent,

  AddEditCustomerComponent,
  BidButtonComponent,
  BidDepositComponent,
  ConfirmBidComponent,
  BidResultComponent,
  BidTermsOfServiceComponent,
  BuyerAgentFormComponent,
  BuyerAgentInfoComponent,
  BidConfirmStepContentComponent,
  PlaceBidForFormComponent,
  PlaceBidFormComponent,
  PlaceBidFormStepContentComponent,
  BidDepositStepContentComponent,
  BuyerAgentStepContentComponent,
  PlaceBidForStepContentComponent,
  BidResultStepContentComponent,
  BidSummaryComponent,
  BidTermsOfServiceStepContentComponent,
  ProgramInformationBadgeComponent,
  StateLicenseComponent,
  UploadFilesComponent,
  FeatureNavComponent,
  AppMobileHeaderLevel1Component,
  AppMobileHeaderLevel2Component,
  AppMobileHeaderLevel3Component,
  ListSelectComponent,
  MobileLayoutComponent,
  SavedSearchFormComponent,
  NumberOfSteppersComponent,
  PropertyInputDetailsComponent,
  PropertyOptionsComponent,
  HorizontalStepperComponent,
  TableListOptionsComponent,
  PropertyFeaturesCommonComponent,
  MoreComponent,
  IconDropdownComponent,
  ...FORM_INPUT_COMPONENTS,
];

@NgModule({
  imports: [
    // Angular
    CommonModule,
    // Shared
    SharedModule,
    // Vendors
    VendorModule,
    MultiSelectModule,
    AuthorizenetModule,
    ModalWizardModule,
    TermsOfServicePartsModule,
  ],
  providers: [BiddingService],
  declarations: [APP_COMPONENTS],
  exports: [APP_COMPONENTS],
  entryComponents: [APP_MODALS],
})
export class ComponentsModule {}
