<div class="py-3 py-lg-4 g-lg-5 footer-container" [ngClass]="isHousesPage ? searchRouteSlug : ''">
  <footer>
    <div class="row align-items-center">
      <div class="col-lg-9">
        <a
          class="nav-menu-link main-link col-12 col-sm-12 col-md-12 col-lg-2"
          *ngFor="let nav of footerLinks"
          [href]="nav?.externalLink ? nav.externalLink : nav.routerLink"
          [target]="nav?.externalLink ? '_blank' : '_self'"
          [attr.rel]="nav?.externalLink ? 'noreferrer noreopen' : null">
          <span>{{ nav.label }}</span>
        </a>
      </div>
      <div class="p-0">
        <a class="nav-menu-link col-12 col-sm-12 col-md-12 col-lg-2" href="/privacy-policy">
          <span class="col-12 col-lg-2">Privacy Policy</span>
        </a>
        <a class="nav-menu-link col-12 col-sm-12 col-md-12 col-lg-2" href="/terms-of-service">
          <span class="col-12 col-lg-4">Bidder/Site Terms of Use</span>
        </a>
      </div>
      <div class="socials col-lg-3 lg-align-right">
        <a
          class="nav-menu-link me-4"
          *ngFor="let nav of icons"
          [href]="nav?.externalLink ? [nav.externalLink] : null"
          target="_blank"
          [attr.rel]="nav?.externalLink ? 'noreferrer noreopen' : null">
          <fa-icon [icon]="nav.icon || 'question'" *ngIf="!!nav.icon"></fa-icon>
        </a>
      </div>
      <div class="line col-12 col-sm-12 d-lg-none d-block"></div>
      <div class="line col-12 d-lg-block d-none"></div>
      <div class="flex logo-copyright-container">
        <div class="row footnotes">
          <p class="footnote col-12 col-lg-3 copyright">
            RealtyBid.com, a <a href="https://covius.com/" target="_blank" rel="noreferrer noreopen">Covius</a> Solution. Copyright © {{ thisYear }}. All rights
            reserved.
            <span class="ms-1 me-2">|</span>
            <a routerLink="/state-licenses/">State Licenses</a>
          </p>
        </div>
        <div class="logo-eho"><img src="/assets/images/logo-eho.png" /></div>
      </div>
    </div>
  </footer>
</div>
