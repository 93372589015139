import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { FEATURE_NAV_ROUTES_DEFAULT, FeatureNavRoute } from './feature-nav.model';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { FeatureNavService } from './feature-nav.service';
import { BehaviorSubject, Observable, Subject, take } from 'rxjs';
import { listenForSmallScreen } from '$utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-feature-nav',
  templateUrl: './feature-nav.component.html',
  styleUrls: ['./feature-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FeatureNavService],
})
export class FeatureNavComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() routes: FeatureNavRoute[] = [];
  @Input() featureRoute = '';
  @Input() showVisited = true;
  @Input() mode!: string;
  @Input() navAction$!: Observable<'back' | 'next'>;
  @Input() isNavValid$!: BehaviorSubject<boolean>;
  @Input() resetActiveRoute!: Subject<boolean>;
  @Input() saveAction!: Subject<{ route: FeatureNavRoute; isSaved: boolean }>;
  @Output() activeRoute: EventEmitter<FeatureNavRoute> = new EventEmitter<FeatureNavRoute>();
  @Output() inValidatedRoute: EventEmitter<string> = new EventEmitter<string>();

  listenForSmallScreen = listenForSmallScreen;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, public featureNavService: FeatureNavService) {
    this.featureNavService.activeRoute$.subscribe(val => {
      val ? this.activeRoute.emit(val) : null;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['routes'].currentValue.length > 0) {
      this.routes = this.routes.map((r: FeatureNavRoute) => {
        if (this.router.url.includes(r.relativeUrl)) {
          this.featureNavService.activeRoute$.next(r);
        }
        return { ...FEATURE_NAV_ROUTES_DEFAULT, ...r, relativeUrl: `${this.featureRoute}/${r.relativeUrl}` };
      });
    }
  }

  ngOnInit(): void {
    this.navAction$.pipe(untilDestroyed(this)).subscribe(action => {
      if (action === 'back') {
        const prevRoute = this.routes.find(route => route.id === this.featureNavService.activeRoute$.value?.previousRoute);
        if (prevRoute) {
          this.navigateTo(prevRoute, action);
        }
      }
      if (action === 'next') {
        const nextRoute = this.routes.find(route => route.id === this.featureNavService.activeRoute$.value?.nextRoute);
        if (nextRoute) {
          this.navigateTo(nextRoute, action);
        }
      }
    });
    this.resetActiveRoute.pipe(untilDestroyed(this)).subscribe(val => {
      if (val) {
        if (!this.isNavValid$.value) {
          this.featureNavService.activeRoute$.value ? (this.featureNavService.activeRoute$.value.visited = false) : null;
        }
        this.featureNavService.activeRoute$.next(this.routes[0]);
      }
    });
    this.saveAction.pipe(untilDestroyed(this)).subscribe(val => {
      const savedRoute = this.routes.find(r => r.id === val.route.id);
      if (val.isSaved && savedRoute) {
        savedRoute.isSaved = true;
      }
    });
  }

  ngAfterViewInit() {}

  navigateTo(route: FeatureNavRoute, action?: 'back' | 'next') {
    if (this.mode === 'add') {
      if (action === 'next') {
        const currentRoute = this.routes.find(r => r.id === route.previousRoute);
        currentRoute!.visited = true;
      }
    }

    this.router.navigate([route.relativeUrl], { relativeTo: this.activatedRoute.root, state: route.routeState ? route.routeState : {} }).then(() => {
      if (this.mode === 'edit') {
        route.visited = true;
      }
      this.featureNavService.activeRoute$.next(route);
    });
  }

  canNavigate(route: FeatureNavRoute) {
    if (this.mode === 'add') {
      const currentRoute = this.featureNavService.activeRoute$.value;
      const index = this.routes.findIndex(r => r.id === route.id);
      const invalidRoute = this.routes.slice(0, index).find(r => !r.visited && r.id !== currentRoute?.id);
      if (currentRoute && currentRoute.id > route.id) {
        this.navigateTo(route, 'back');
      } else if ((this.isNavValid$.value && !invalidRoute) || (this.isNavValid$.value && route.id === invalidRoute?.id)) {
        this.navigateTo(route, 'next');
      } else {
        this.inValidatedRoute.emit(invalidRoute?.title);
      }
    } else {
      this.navigateTo(route);
    }
  }
}
