import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-table-list-options',
  templateUrl: './table-list-options.component.html',
  styleUrls: ['./table-list-options.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableListOptionsComponent {
  @Input() options!: any[];
  @Input() placeholder!: string;
  @Input() optionValue!: string;
  @Input() optionLabel!: string;
  @Output() selectOption = new EventEmitter();
}
