import { Component, OnInit } from '@angular/core';
import { interval } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from '../../../shared/services';
import { listenForMediumScreenDown } from '../../../shared/utils';

export enum Actions {
  extend,
  logout,
  expired,
}

@UntilDestroy()
@Component({
  selector: 'app-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss'],
})
export class LogoutModalComponent implements OnInit {
  public counter = 60; // Log out after this many seconds

  public breakPoint$ = listenForMediumScreenDown();

  constructor(public dialogService: DialogService, public ref: DynamicDialogRef, public dialogConfig: DynamicDialogConfig, private auth: AuthService) {}

  ngOnInit() {
    // Allow different logout timings
    if (this.dialogConfig?.data) {
      this.counter = this.dialogConfig?.data;
    }
    // Create a timer obserable that counts down
    interval(1000)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        // If timer is greater than 0, count down.
        if (this.counter && this.counter > 1) {
          this.counter--;
        } else {
          this.ref.close(Actions.expired);
        }
      });
  }

  onExtendSessionClick() {
    this.ref.close(Actions.extend);
  }

  onLogoutClick() {
    this.ref.close(Actions.logout);
  }
}
