import { RouterModule, Routes } from '@angular/router';
// Bundle and lazy load these modules
const routes: Routes = [
  // App global components
  {
    path: '~',
    loadChildren: () => import('./components.module').then(m => m.ComponentsModule),
  },
  {
    path: '~',
    loadChildren: () => import('./modal-wizard/modal-wizard.module').then(m => m.ModalWizardModule),
  },
  {
    path: '~',
    loadChildren: () => import('./authorize-net/authorize-net.module').then(m => m.AuthorizenetModule),
  },
  {
    path: '~',
    loadChildren: () => import('../shared/fonts/fonts.module').then(m => m.FontsModule),
  },
  {
    path: '~',
    loadChildren: () => import('../shared/icons/icons.module').then(m => m.IconsModule),
  },
  // Angular libs
  {
    path: '~',
    loadChildren: () => import('@angular/forms').then(m => m.FormsModule),
  },
  {
    path: '~',
    loadChildren: () => import('@angular/forms').then(m => m.ReactiveFormsModule),
  },
  // Prime libs
  {
    path: '~',
    loadChildren: () => import('primeng/card').then(m => m.CardModule),
  },
  {
    path: '~',
    loadChildren: () => import('primeng/messages').then(m => m.MessagesModule),
  },
  {
    path: '~',
    loadChildren: () => import('primeng/table').then(m => m.TableModule),
  },
  {
    path: '~',
    loadChildren: () => import('primeng/tree').then(m => m.TreeModule),
  },
  {
    path: '~',
    loadChildren: () => import('@ntersol/wizard').then(m => m.NtsWizardModule),
  },
  {
    path: '~',
    loadChildren: () => import('@ntersol/forms').then(m => m.NtsFormsModule),
  },
  {
    path: '~',
    loadChildren: () => import('@ntersol/table').then(m => m.NtsTableModule),
  },
];

export const ComponentsLazyLoad = RouterModule.forChild(routes);
