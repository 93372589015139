import { Component, Input, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, tap, of, Observable } from 'rxjs';
import { get } from 'lodash';

import { environment } from '$env';
import { NotificationService } from '$services';
import { AuthorizenetConfig, DispatchDataResponse, CardData } from 'src/app/components/authorize-net/authorize-net';
import { AuthorizenetComponent } from 'src/app/components/authorize-net/authorize-net.component';
import { ModalWizardModels } from 'src/app/components/modal-wizard/shared/models/modal-wizard.model';
import { BiddingStoreService, BiddingStoreKey } from '../../store/bidding.store';

@UntilDestroy()
@Component({
  selector: 'app-bid-deposit-step-content',
  template: `
    <div class="app-bid-deposit-step-container">
      <app-authorizenet [isDevMode]="!isProductionMode" [config]="authorizenetConfig"></app-authorizenet>
      <app-bid-deposit (formReady)="onFormReady($event)" (valuesChanged)="onValuesChanged($event)"></app-bid-deposit>
    </div>
  `,
})
export class BidDepositStepContentComponent implements ModalWizardModels.StepContent {
  @Input() stepId = BiddingStoreKey.bidDeposit;

  @ViewChild(AuthorizenetComponent, { static: true }) private authorizenetComponent!: AuthorizenetComponent;

  readonly isProductionMode = environment.production;

  authorizenetConfig: AuthorizenetConfig = {
    apiLoginID: environment.licenses.authorizeNet.apiLoginId,
    clientKey: environment.licenses.authorizeNet.clientKey,
  };

  private form: FormGroup | null = null;
  private values: { [key: string]: any } = {};

  constructor(private biddingStore: BiddingStoreService, private notification: NotificationService) {}

  onFormReady(form: FormGroup) {
    this.form = form;
  }

  onValuesChanged(values: any): void {
    this.values = values;
    /**
     * Remove all dashes from card number to avoid exception (max length
     * validation fails) after passing data into Authorize.net "dispatchData"
     * method.
     */
    const cardNumber = (this.values['cardNumber'] && this.values['cardNumber'].replaceAll(' ', '')) || null;

    if (this.values && cardNumber) {
      this.values = { ...values, cardNumber };
    }
  }

  canEntry(args: ModalWizardModels.StepValidationArgs) {
    return true;
  }

  canExit(args: ModalWizardModels.StepValidationArgs) {
    const isFormValid = this.form ? this.form.valid : true;

    return isFormValid && this.authorizenetComponent
      ? this.authorizenetComponent
          .dispatchData({
            cardData: { ...(this.values as CardData) },
          })
          .pipe(untilDestroyed(this), tap(this.onDispatchDataSuccess.bind(this)), catchError(this.onDispatchDataFailed.bind(this)))
      : of(isFormValid);
  }

  /**
   * Handle data of successfully done "dispatch data"
   * request and return True if there is data.
   *
   * @param data  Response data.
   * @returns  Boolean
   */
  private onDispatchDataSuccess(data: DispatchDataResponse | null): boolean {
    // Update store with new data
    this.biddingStore.update({
      [BiddingStoreKey.bidDeposit]:
        data?.messages.resultCode === 'Ok'
          ? {
              DATAVALUE: data.opaqueData.dataValue,
              DATADESCRIPTOR: data.opaqueData.dataDescriptor,
            }
          : null,
    });
    return !!data;
  }

  /**
   * Handle failed "dispatch data" request. Return False all the time
   *
   * @param error  Failed response data.
   * @returns Boolean
   */
  private onDispatchDataFailed(error: any): Observable<boolean> {
    this.notification.showToast({
      styleClass: 'p-toast-message-error',
      closable: true,
      summary: 'Error',
      detail: 'Could not withdraw deposit. Please verify your card information and try again.',
    });
    console.error(get(error, 'messages.message[0].text'));
    return of(false);
  }
}
